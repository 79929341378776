import axios from "axios";
import { logout } from "../modules/utils";

export function insertCoupon({
    _coupon_title,
    _coupon_contents,
    _coupon_type,
    _coupon_price,
    _coupon_percent,
    _coupon_expired_date,
    _coupon_publish_date,
    _coupon_max_price,
    _coupon_min_price,
    _coupon_free_amount,
    _coupon_remain_amount,
    _coupon_specific_product,
    _coupon_identifier_code,
    _coupon_publish_count,
    _coupon_notice,
}) {
    return axios
        .post("/apis/coupon/insert-coupon", {
            couponTitle: _coupon_title,
            couponContents: _coupon_contents,
            couponType: _coupon_type,
            couponPrice: _coupon_price,
            couponPercent: _coupon_percent,
            couponExpiredDate: _coupon_expired_date,
            couponPublishDate: _coupon_publish_date,
            couponMaxPrice: _coupon_max_price,
            couponMinPrice: _coupon_min_price,
            freeAmount: _coupon_free_amount,
            couponSpecificProduct: _coupon_specific_product,
            identifierCode: _coupon_identifier_code,
            publishCount: _coupon_publish_count,
            couponNotice: _coupon_notice,
        })
        .then((result) => {
            return result;
        })
        .catch((e) => {
            return null;
        });
}
export function modifyCoupon({
    _coupon_code,
    _coupon_title,
    _coupon_contents,
    _coupon_type,
    _coupon_price,
    _coupon_percent,
    _coupon_expired_date,
    _coupon_publish_date,
    _coupon_max_price,
    _coupon_min_price,
    _coupon_free_amount,
    _coupon_remain_amount,
    _coupon_specific_product,
    _coupon_notice,
    _coupon_used_yn,
    _coupon_registed_yn,
    _coupon_registed_date,
}) {
    return axios
        .post("/apis/coupon/modify-coupon", {
            couponCode: _coupon_code,
            couponTitle: _coupon_title,
            couponContents: _coupon_contents,
            couponType: _coupon_type,
            couponPrice: _coupon_price,
            couponPercent: _coupon_percent,
            couponExpiredDate: _coupon_expired_date,
            couponPublishDate: _coupon_publish_date,
            couponMaxPrice: _coupon_max_price,
            couponMinPrice: _coupon_min_price,
            freeAmount: _coupon_free_amount,
            remainAmount: _coupon_remain_amount,
            couponSpecificProduct: _coupon_specific_product,
            couponNotice: _coupon_notice,
            couponUsed: _coupon_used_yn,
            couponRegisted: _coupon_registed_yn,
            couponRegistedDate: _coupon_registed_date,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
export function deleteCoupon({ _coupon_code }) {
    return axios
        .post("/apis/coupon/delete-coupon", {
            couponCode: _coupon_code,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}

export function getCouponData({ _category, _size, _pageNo }) {
    return axios
        .post("/apis/coupon/get-coupon-list", {
            category: _category,
            size: _size,
            pageNo: _pageNo,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
