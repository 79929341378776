import React, { Component } from "react";
import { Badge, Card, Container, Row } from "reactstrap";
import lodash from "lodash";
import NormalHeader from "../../components/Headers/NormalHeader.js";
import { convertDate } from "../../modules/utils.js";
import EventDetail from "./EventDetail";
import { getEventData } from "../../lib/event.js";
import { BiSubdirectoryRight } from "react-icons/bi";
import PhotologTable, { SortIcon } from "../../modules/PhotologTable.js";

const eventStateList = [
    {
        state: "prev",
        state_name: "진행전",
        color: "info",
    },
    {
        state: "proceed",
        state_name: "진행중",
        color: "success",
    },
    {
        state: "end",
        state_name: "종료",
        color: "danger",
    },
];

const Event = () => {
    const columns = [
        {
            dataField: "event_seq",
            text: "Seq",
            headerStyle: { width: "5%" },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
        },
        {
            dataField: "event_title",
            text: "제목",
            headerStyle: { width: "15%", minWidth: 100 },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
            formatter: (cell, row) => {
                return (
                    <div>
                        <span>{cell}</span>
                        {row.event_desc !== "null" && row.event_desc !== null && (
                            <div>
                                <span style={{ color: "gray", fontSize: 12 }}>
                                    <BiSubdirectoryRight className={"mb-1"} />
                                    {row.event_desc}
                                </span>
                            </div>
                        )}
                    </div>
                );
            },
        },
        {
            dataField: "event_start_date",
            text: "이벤트 시작일시",
            sort: true,
            headerStyle: { width: "12%" },
            headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
                return (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {column.text}
                        <SortIcon order={sortElement.props.order} />
                    </div>
                );
            },
            formatter: (cell, row) => {
                return <span>{convertDate({ _originDate: cell, _convertFormat: "YYYY-MM-DD HH:mm:ss" })}</span>;
            },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
        },
        {
            dataField: "event_end_date",
            text: "이벤트 종료일시",
            sort: true,
            headerStyle: { width: "12%" },
            headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
                return (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {column.text}
                        <SortIcon order={sortElement.props.order} />
                    </div>
                );
            },
            formatter: (cell, row) => {
                return <span>{convertDate({ _originDate: cell, _convertFormat: "YYYY-MM-DD HH:mm:ss" })}</span>;
            },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
        },
        {
            dataField: "event_state",
            text: "상태",
            headerStyle: { width: "5%", minWidth: 100 },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
            formatter: (cell, row) => {
                return (
                    <div>
                        <Badge color={lodash.find(eventStateList, { state: cell }).color}>{lodash.find(eventStateList, { state: cell }).state_name} </Badge>
                    </div>
                );
            },
        },
    ];

    React.useEffect(() => {}, []);

    return (
        <>
            <NormalHeader />
            <Container className="mt--7 pb-7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow border-0 pt-4 pb-4">
                            <PhotologTable
                                category={5}
                                keyField={"event_seq"}
                                columns={columns}
                                detailPage={EventDetail}
                                getData={({ _category, _size, _pageNo }) => {
                                    return getEventData({ _category: _category, _size: _size, _pageNo: _pageNo });
                                }}
                                enableInsertButton
                            />
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Event;
