import axios from "axios";
import { logout } from "../modules/utils";

export function getCouponType() {
    return axios
        .post("/apis/basic-data/get-coupon-type", {})
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
export function getProductList() {
    return axios
        .post("/apis/basic-data/get-product-list", {})
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
export function getRegionNameByCode(_divisionCode = "KOREA") {
    return axios
        .post("/apis/basic-data/get-region-name-by-code", {
            divisionCode: _divisionCode,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
