import axios from "axios";
import md5 from "md5";
import { logout } from "../modules/utils";

export async function managerLogin({ _userId, _userPass }) {
    try {
        const result = await axios.post("/apis/manager/manager-login", {
            userId: _userId,
            userPass: md5(`${_userPass}`),
        });
        if (result.data.res_code === 1 || result.data.res_code === 3) {
            logout();
            return null;
        } else return result;
    } catch (e) {
        return null;
    }
}
export async function modifyUserInfo({ _userName, _userPhone, _userGender, _userBirthday, _userPass }) {
    try {
        const result = await axios.post("/apis/manager/modify-manager-info", {
            userName: _userName,
            userPhone: _userPhone,
            userGender: _userGender,
            userBirthday: _userBirthday,
            userPass: md5(`${_userPass}`),
        });
        if (result.data.res_code === 1 || result.data.res_code === 3) {
            logout();
            return null;
        } else return result;
    } catch (e) {
        return null;
    }
}

export async function managerLogout() {
    try {
        const result = await axios.post("/apis/manager/manager-logout", {});
        if (result.data.res_code === 1 || result.data.res_code === 3) {
            logout();
            return null;
        } else return result;
    } catch (e) {
        return null;
    }
}
export async function deleteManager({ _managerId }) {
    try {
        const result = await axios.post("/apis/manager/delete-manager", {
            managerId: _managerId,
        });
        if (result.data.res_code === 1 || result.data.res_code === 3) {
            logout();
            return null;
        } else return result;
    } catch (e) {
        return null;
    }
}
export async function getUsersGrant({ _userId }) {
    try {
        const result = await axios.post("/apis/manager/get-manager-grant", {
            userId: _userId,
        });
        if (result.data.res_code === 1 || result.data.res_code === 3) {
            logout();
            return null;
        } else return result;
    } catch (e) {
        return null;
    }
}
export async function modifyGrant({ _managerId, _grantList }) {
    try {
        const result = await axios.post("/apis/manager/modify-grant", {
            grantTarget: _managerId,
            grantList: _grantList,
        });
        if (result.data.res_code === 1 || result.data.res_code === 3) {
            logout();
            return null;
        } else return result;
    } catch (e) {
        return null;
    }
}
export async function me() {
    try {
        const result = await axios.post("/apis/manager/get-current-user-info", {});
        if (result.data.res_code === 1 || result.data.res_code === 3) {
            logout();
            return null;
        } else return result;
    } catch (e) {
        return null;
    }
}
export async function getManagerData({ _category, _size, _pageNo }) {
    try {
        const result = await axios.post("/apis/manager/get-manager-list", {
            category: _category,
            size: _size,
            pageNo: _pageNo,
        });
        if (result.data.res_code === 1 || result.data.res_code === 3) {
            logout();
            return null;
        } else return result;
    } catch (e) {
        return null;
    }
}
