import axios from "axios";
import { logout } from "../modules/utils";

export function getContentsData({ _category, _size, _pageNo }) {
    return axios
        .post("/apis/contents/get-story-contents", {
            category: _category,
            size: _size,
            pageNo: _pageNo,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
export function getContentsDetail({ _seq }) {
    return axios
        .post("/apis/contents/get-contents-detail", {
            contentsIdx: _seq,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
export function getMemoCategory() {
    return axios
        .post("/apis/contents/memo-category", {})
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
export function insertManagerContents({ _tripTitle, _tripMemo, _hashtag, _stars, _placeType, _placeConvenience, _specificAddress, _tripImages }) {
    var formData = new FormData();

    _tripImages.map((item, index) => {
        formData.append("file", item);
    });

    formData.append("tripTitle", _tripTitle);
    formData.append("tripMemo", _tripMemo);
    formData.append("hashtag", _hashtag);
    formData.append("stars", _stars);
    formData.append("placeType", _placeType);
    formData.append("placeConvenience", _placeConvenience);
    formData.append("specificAddress", _specificAddress);

    return axios
        .post("/apis/contents/insert-manager-contents", formData)
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
export function modifyManagerContents({ _seq, _trip_memo, _hashtag, _stars, _place_type, _place_convenience, _trip_place, _public_yn }) {
    return axios
        .post("/apis/contents/modify-manager-contents", {
            contentsIdx: _seq,
            tripMemo: _trip_memo,
            hashtag: _hashtag,
            stars: _stars,
            placeType: _place_type,
            placeConvenience: _place_convenience,
            tripPlace: _trip_place,
            publicYn: _public_yn,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
export function deleteManagerContents({ _contents }) {
    return axios
        .post("/apis/contents/delete-manager-contents", {
            contentsIdx: _contents.seq,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
