import React from "react";
import { Card, Container, Row } from "reactstrap";
import NormalHeader from "../../components/Headers/NormalHeader.js";
import { convertDate } from "../../modules/utils.js";
import PopupDetail from "./PopupDetail";
import { getPopupData } from "../../lib/popup.js";
import PhotologTable, { SortIcon } from "../../modules/PhotologTable.js";

const Popup = () => {
    const columns = [
        {
            dataField: "popup_seq",
            text: "Seq",
            headerStyle: { width: "5%" },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
        },
        {
            dataField: "popup_title",
            text: "제목",
            headerStyle: { width: "15%", minWidth: 100 },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
        },
        {
            dataField: "popup_start_date",
            text: "팝업 시작일시",
            sort: true,
            headerStyle: { width: "12%" },
            headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
                return (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {column.text}
                        <SortIcon order={sortElement.props.order} />
                    </div>
                );
            },
            formatter: (cell, row) => {
                return <span>{convertDate({ _originDate: cell, _convertFormat: "YYYY-MM-DD HH:mm:ss" })}</span>;
            },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
        },
        {
            dataField: "popup_end_date",
            text: "팝업 종료일시",
            sort: true,
            headerStyle: { width: "12%" },
            headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
                return (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {column.text}
                        <SortIcon order={sortElement.props.order} />
                    </div>
                );
            },
            formatter: (cell, row) => {
                return <span>{convertDate({ _originDate: cell, _convertFormat: "YYYY-MM-DD HH:mm:ss" })}</span>;
            },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
        },
    ];

    React.useEffect(() => {
        // doGetContents({ _category: 5, _size: 10, _pageNo: 1 });
    }, []);

    return (
        <>
            <NormalHeader />
            <Container className="mt--7 pb-7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow border-0 pt-4 pb-4">
                            <PhotologTable
                                category={5}
                                keyField={"popup_seq"}
                                columns={columns}
                                detailPage={PopupDetail}
                                getData={({ _category, _size, _pageNo }) => {
                                    return getPopupData({ _category: _category, _size: _size, _pageNo: _pageNo });
                                }}
                                enableInsertButton
                            />
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Popup;
