import React from "react";

import { convertDate, History } from "../../modules/utils.js";
import { Card, Col, Container, FormGroup, Input, Label, Row, Carousel, CarouselItem, CarouselControl, CarouselIndicators, Button } from "reactstrap";
import ToggleSwitch from "../../modules/ToggleSwitch.js";
import { PLACETYPE, CONVENIENCE } from "../../modules/utils";
import TagsInput from "react-tagsinput";
import Rating from "@material-ui/lab/Rating";
import { Radio, FormControlLabel, Checkbox, Chip, Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import lodash from "lodash";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/ko";
import { PhotologDialog } from "../../modules/PhotologDialog.js";
import { modifyManagerContents } from "../../lib/contents";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const ContentsDetail = (props) => {
    const [showItem, setShowItem] = React.useState(null);
    const [publicYn, setPublicYn] = React.useState(false);
    const [addMode, setAddMode] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [tripTags, setTripTags] = React.useState([]);
    const [openCal, setOpenCal] = React.useState(false);

    const [showMessage, setShowMessage] = React.useState(false);

    const [showBackdrop, setShowBackdrop] = React.useState(false);

    const message = React.useRef({
        tag: "",
        title: "",
        message: null,
        body: null,
        button: [],
        keyboard: true,
        toggle: true,
        renderFooter: null,
        showFooter: true,
    });

    const tripMemoRef = React.useRef(null);
    const classes = useStyles();

    React.useEffect(() => {
        if (props === undefined) {
            History.replace("/admin/contents");
            window.location.reload();
            return;
        } else {
            if (showItem === null) {
                var newTagsArr = [];
                if (props.getContentsItem.hashtag.length > 1) {
                    newTagsArr = props.getContentsItem.hashtag.split("#");
                } else {
                    newTagsArr.push(props.getContentsItem.hashtag);
                }

                setShowItem({
                    contents: {
                        ...props.getContentsItem,
                        hashtag: newTagsArr.filter((item) => item !== ""),
                    },
                    details: props.getContentsItemDetail,
                });
                setPublicYn(props.getContentsItem.public_yn === 1 ? true : false);
                setTripTags(newTagsArr.filter((item) => item !== ""));
            } else {
            }
        }
    }, [showItem, props]);

    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);

    const carouselMoveToNext = () => {
        if (animating) return;
        const nextIndex = activeIndex === showItem.details.images.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const carouselMoveToPrev = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? showItem.details.images.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const carouselMoveToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };
    const doValidation = () => {
        if (editMode) {
            // 값이 변한게 없는 경우
            if (lodash.isEqual(props.getContentsItem, showItem.contents)) {
                return {
                    errCode: -1,
                    errMsg: "바뀐값이 없음",
                };
            }
        }

        // 여행지 ID
        if (showItem.contents.trip_idx === null || showItem.contents.trip_idx.length === 0) {
            return {
                errCode: 1,
                errMsg: "여행지ID 입력란을 확인하세요",
            };
        }
        // 여행일시
        if (showItem.contents.trip_date === null || showItem.contents.trip_date.length === 0) {
            return {
                errCode: 1,
                errMsg: "여행날짜 입력란을 확인하세요",
            };
        }
        //여행장소
        if (showItem.contents.trip_place === null || showItem.contents.trip_place.length === 0) {
            return {
                errCode: 1,
                errMsg: "여행장소 입력란을 확인하세요",
            };
        }
        //여행 카테고리
        if (showItem.contents.place_type === null || showItem.contents.place_type.length === 0) {
            return {
                errCode: 1,
                errMsg: "여행 카테고리 입력란을 확인하세요",
            };
        }
        //여행 편의시설 (선택)
        //별점 (선택)
        //해시태그 (선택)
        // 공개여부
        if (showItem.contents.public_yn === null || showItem.contents.public_yn.length === 0) {
            return {
                errCode: 1,
                errMsg: "공개여부 입력란을 확인하세요",
            };
        }
        // 여행메모
        if (showItem.contents.trip_memo === null || showItem.contents.trip_memo.length === 0) {
            return {
                errCode: 1,
                errMsg: "여행메모 입력란을 확인하세요",
            };
        }

        return {
            errCode: 0,
            errMsg: "문제 발견되지 않음",
        };
    };

    if (showItem === null) {
        return <div></div>;
    } else {
        return (
            <>
                <Container className="mt--7">
                    {addMode ? (
                        <Row>
                            <Col className={"col-12 mx-auto mt-6 m-3"}></Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col className={"col-12 mx-auto mt-6 m-3"}>
                                <Label for="contents_editMode" style={{ color: "black" }}>
                                    수정모드
                                </Label>
                                <Row className={"ml-0"}>
                                    <ToggleSwitch
                                        id="contents_editMode"
                                        checked={editMode}
                                        onChange={(v) => {
                                            if (editMode === true) {
                                                // 수정모드 가능->불가능
                                                var newTagsArr = [];
                                                if (props.getContentsItem.hashtag.length > 1) {
                                                    newTagsArr = props.getContentsItem.hashtag.split("#");
                                                } else {
                                                    newTagsArr.push(props.getContentsItem.hashtag);
                                                }

                                                setShowItem({
                                                    contents: {
                                                        ...props.getContentsItem,
                                                        hashtag: newTagsArr.filter((item) => item !== ""),
                                                    },
                                                    details: props.getContentsItemDetail,
                                                });
                                                setPublicYn(props.getContentsItem.public_yn === 1 ? true : false);

                                                setTripTags(newTagsArr.filter((item) => item !== ""));
                                            }
                                            setEditMode(v);
                                        }}
                                        optionLabels={["가능", "불가"]}
                                    />
                                </Row>
                            </Col>
                        </Row>
                    )}
                    <Card className="shadow border-0 pb-4">
                        <Row className={"m-3"}>
                            <Col className={"col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                <Label for="contents_images">여행사진</Label>
                                <Col className={"col-lg-8 col-md-12 col-sm-12 col-12 mx-auto"}>
                                    <Carousel id="contents_images" activeIndex={activeIndex} next={carouselMoveToNext} previous={carouselMoveToPrev} interval={false}>
                                        <CarouselIndicators items={showItem.details.images} activeIndex={activeIndex} onClickHandler={carouselMoveToIndex} />
                                        {showItem.details.images.map((item) => {
                                            return (
                                                <CarouselItem onExiting={() => setAnimating(false)} onExited={() => setAnimating(false)} key={item.image_path}>
                                                    <img className={"rounded d-block w-100"} src={item.image_path} alt={item.altText} style={{ objectFit: "cover", height: 500 }} />
                                                    {
                                                        //    <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
                                                    }
                                                </CarouselItem>
                                            );
                                        })}
                                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={carouselMoveToPrev} style={{ background: "red" }} />
                                        <CarouselControl direction="next" directionText="Next" onClickHandler={carouselMoveToNext} />
                                    </Carousel>
                                </Col>
                            </Col>
                        </Row>
                        <Row className={"ml-3 mr-3 mt-3"}>
                            <Col className={"col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                <Label for="contents_seq">순번</Label>
                                <Input type="text" name="seq" id="contents_seq" value={showItem.contents.seq} disabled />
                            </Col>
                            <Col className={"col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                <Label for="contents_user_id">사용자 ID</Label>
                                <Input type="text" name="user_id" id="contents_user_id" value={showItem.contents.user_id} disabled />
                            </Col>
                            <Col className={"col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                <Label for="contents_trip_idx">여행지 ID</Label>
                                <Input type="text" name="trip_idx" id="contents_trip_idx" value={showItem.contents.trip_idx} disabled={addMode ? false : !editMode} />
                            </Col>
                            <Col className={"col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                <Label for="contents_trip_date">여행날짜</Label>
                                <Input
                                    type="text"
                                    name="trip_date"
                                    id="contents_trip_date"
                                    value={convertDate({ _originDate: showItem.contents.trip_date, _convertFormat: "YYYY-MM-DD HH:mm:ss" })}
                                    disabled={addMode ? false : !editMode}
                                    onClick={() => {
                                        setOpenCal(true);
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className={"ml-3 mr-3 mt-3"}>
                            <Col className={"col-12 mx-auto"}>
                                <Label for="contents_trip_place">여행장소</Label>
                                <Input
                                    type="text"
                                    name="trip_place"
                                    id="contents_trip_place"
                                    value={showItem.contents.trip_place}
                                    disabled={addMode ? false : !editMode}
                                    onChange={(e) => {
                                        setShowItem({
                                            contents: {
                                                ...showItem.contents,
                                                trip_place: e.target.value,
                                            },
                                            details: {
                                                ...showItem.details,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className={"ml-3 mr-3 mt-3"}>
                            <Col className={"col-12 mx-auto"}>
                                <Label for="contents_place_type">여행 카테고리</Label>
                                <Row className={"ml-0"}>
                                    <FormGroup row className={"custom-control-inline"} id="contents_place_type" name="place_type" value={showItem.contents.place_type}>
                                        {PLACETYPE.map((item, index) => {
                                            return (
                                                <Col className={"col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 "}>
                                                    <FormControlLabel
                                                        checked={String(showItem.contents.place_type) === String(item.iconNumber)}
                                                        key={`place_type_${index}`}
                                                        control={
                                                            <Radio
                                                                name={item.iconNumber}
                                                                value={item.iconNumber}
                                                                onChange={(e) => {
                                                                    setShowItem({
                                                                        contents: {
                                                                            ...showItem.contents,
                                                                            place_type: e.target.value,
                                                                        },
                                                                        details: {
                                                                            ...showItem.details,
                                                                        },
                                                                    });
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            <div>
                                                                {item.icon({ width: 18, height: 18, color: item.iconActiveColor })}
                                                                <span style={{ fontSize: 16 }}>{` ${item.iconDescription} `}</span>
                                                            </div>
                                                        }
                                                        disabled={addMode ? false : !editMode}
                                                    />
                                                </Col>
                                            );
                                        })}
                                    </FormGroup>
                                </Row>
                            </Col>
                        </Row>
                        <Row className={"ml-3 mr-3 mt--3"}>
                            <Col className={"col-12 mx-auto"}>
                                <Label for="contents_place_convenience">여행 편의시설</Label>
                                <Row className={"ml-0"}>
                                    <FormGroup row className={"custom-control-inline"} id="contents_place_convenience" name="place_convenience" value={showItem.contents.place_convenience}>
                                        {CONVENIENCE.map((item, index) => {
                                            let isChecked = false;
                                            // 전달받은 값이 null 값인 경우 (선택되지 않음)
                                            if (showItem.contents.place_convenience === null || showItem.contents.place_convenience === "null") {
                                                isChecked = false;
                                            } else if (typeof showItem.contents.place_convenience === "string") {
                                                // 여러개가 선택되어있는 경우, "1,3,2" 식으로 저장되어 올 수 있는 케이스
                                                if (showItem.contents.place_convenience.includes(",")) {
                                                    isChecked = lodash.indexOf(showItem.contents.place_convenience.split(","), String(item.iconNumber)) > -1;
                                                } else {
                                                    isChecked = lodash.indexOf(showItem.contents.place_convenience, String(item.iconNumber)) > -1;
                                                }
                                            } else {
                                                // null 또는 단 한개만 선택되어 전달되는 경우

                                                if (showItem.contents.place_convenience.length > 0) {
                                                    isChecked = lodash.indexOf(showItem.contents.place_convenience, String(item.iconNumber)) > -1;
                                                } else {
                                                    isChecked = String(showItem.contents.place_convenience) === String(item.iconNumber);
                                                }
                                            }
                                            return (
                                                <Col className={"col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"}>
                                                    <FormControlLabel
                                                        key={`conv_${index}`}
                                                        control={
                                                            <Checkbox
                                                                checked={isChecked}
                                                                name={item.iconNumber}
                                                                value={item.iconNumber}
                                                                onChange={(e) => {
                                                                    let tempConv = [];

                                                                    // 처음 서버로부터 데이터를 받을대만 문자열 그 이후에는 배열로 저장하고 내부적으로 관리됨

                                                                    if (showItem.contents.place_convenience === null || typeof showItem.contents.place_convenience === "string") {
                                                                        // 문자열인 경우는 null이거나 값이 단 하나인 경우
                                                                        if (showItem.contents.place_convenience === null || showItem.contents.place_convenience === "null") {
                                                                            // null 값이 들어가있는 경우
                                                                            tempConv.push(String(e.target.value));
                                                                        } else {
                                                                            // 값이 하나가 들어있는 경우
                                                                            if (showItem.contents.place_convenience.includes(",")) {
                                                                                // 기존에 값이 존재하는 경우 ( 예: "1,4")
                                                                                tempConv = showItem.contents.place_convenience.split(",");
                                                                                tempConv.push(String(e.target.value));
                                                                            } else {
                                                                                tempConv.push(String(showItem.contents.place_convenience));
                                                                                tempConv.push(String(e.target.value));
                                                                            }
                                                                        }
                                                                    } else {
                                                                        tempConv = [...showItem.contents.place_convenience];
                                                                        if (e.target.checked) {
                                                                            //기존에 값이 없는 경우
                                                                            tempConv.push(String(e.target.value));
                                                                        } else {
                                                                            //기존에 값이 있는 경우
                                                                            tempConv = tempConv.filter((item) => item !== e.target.value);
                                                                        }
                                                                    }
                                                                    setShowItem({
                                                                        contents: {
                                                                            ...showItem.contents,
                                                                            place_convenience: tempConv,
                                                                        },
                                                                        details: {
                                                                            ...showItem.details,
                                                                        },
                                                                    });
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            <div>
                                                                {item.icon({ width: 18, height: 18, color: item.iconActiveColor })}
                                                                <span style={{ fontSize: 16 }}>{` ${item.iconDescription} `}</span>
                                                            </div>
                                                        }
                                                        disabled={addMode ? false : !editMode}
                                                    />
                                                </Col>
                                            );
                                        })}
                                    </FormGroup>
                                </Row>
                            </Col>
                        </Row>
                        <Row className={"ml-3 mr-3 mt--3"}>
                            <Col className={"col-12 mx-auto"}>
                                <Label for="contents_stars">별점</Label>
                                <Row className={"ml-0"}>
                                    <Rating
                                        id="contents_stars"
                                        name="stars"
                                        value={Number(showItem.contents.stars)}
                                        precision={1}
                                        size="large"
                                        onChange={(e) => {
                                            setShowItem({
                                                contents: {
                                                    ...showItem.contents,
                                                    stars: e.target.value,
                                                },
                                                details: {
                                                    ...showItem.details,
                                                },
                                            });
                                        }}
                                        disabled={addMode ? false : !editMode}
                                    />
                                </Row>
                            </Col>
                        </Row>
                        <Row className={"ml-3 mr-3 mt-3"}>
                            <Col className={"col-12 mx-auto"}>
                                <Label for="contents_hashtag">해시태그</Label>
                                <Row className={"ml--3"}>
                                    <Col className={"col-12 mx-auto"}>
                                        <TagsInput
                                            id={"contents_hashtag"}
                                            value={tripTags.length > 0 ? tripTags : []}
                                            addKeys={[9, 13, 32]}
                                            onChange={(v) => {
                                                var tagList = v.map((item) => {
                                                    // 특수문자 제거
                                                    var cleanItem = item.replace(/[^a-zA-Z0-9가-힣]/g, "");
                                                    if (cleanItem === "") return null;
                                                    if (cleanItem.includes("#")) {
                                                        return cleanItem;
                                                    } else return `#${cleanItem.replace(" ", "")}`;
                                                });

                                                setShowItem({
                                                    contents: {
                                                        ...showItem.contents,
                                                        hashtag: tagList,
                                                    },
                                                    details: {
                                                        ...showItem.details,
                                                    },
                                                });
                                                setTripTags(tagList);
                                            }}
                                            name="hashtag"
                                            onlyUnique
                                            disabled={addMode ? false : !editMode}
                                            renderLayout={(tagComponents, inputComponent) => {
                                                return (
                                                    <div>
                                                        {inputComponent}
                                                        {tagComponents}
                                                    </div>
                                                );
                                            }}
                                            renderInput={(props) => {
                                                let { onChange, value, addTag, ...other } = props;

                                                return (
                                                    <Input
                                                        {...other}
                                                        type="text"
                                                        name="hashtag"
                                                        id="contents_hashtag"
                                                        value={value}
                                                        disabled={!editMode}
                                                        onChange={onChange}
                                                        placeholder={"해시태그를 입력 후 Tab 또는 Enter를 누르세요"}
                                                    />
                                                );
                                            }}
                                            renderTag={(props) => {
                                                let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
                                                var cleanItem = tag.replace(/[^a-zA-Z0-9가-힣]/g, "");

                                                if (cleanItem === "") {
                                                    return null;
                                                }
                                                return (
                                                    <Chip
                                                        {...other}
                                                        className={key === 0 ? "ml-0 mt-3" : "ml-2 mt-3"}
                                                        key={key}
                                                        label={`#${getTagDisplayValue(cleanItem.replace(" ", ""))}`}
                                                        color={"primary"}
                                                        onDelete={(e) => onRemove(key)}
                                                        disabled={!editMode}
                                                    />
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className={"ml-3 mr-3 mt-3"}>
                            <Col className={"col-12 mx-auto"}>
                                <Label for="contents_public_yn">공개여부</Label>
                                <Row className={"ml-0"}>
                                    <ToggleSwitch
                                        id="contents_public_yn"
                                        checked={publicYn}
                                        onChange={(v) => {
                                            setPublicYn(v);
                                        }}
                                        optionLabels={["공개", ""]}
                                        disabled={addMode ? false : !editMode}
                                    />
                                </Row>
                            </Col>
                        </Row>

                        <Row className={"ml-3 mr-3 mt-3"}>
                            <Col className={"col-12 mx-auto"}>
                                <Label for="contents_trip_memo">여행 메모</Label>
                                <Input
                                    type="textarea"
                                    name="trip_memo"
                                    id="contents_trip_memo"
                                    value={showItem.contents.trip_memo}
                                    disabled={addMode ? false : !editMode}
                                    onChange={(e) => {
                                        setShowItem({
                                            contents: {
                                                ...showItem.contents,
                                                trip_memo: e.target.value,
                                            },
                                            details: {
                                                ...showItem.details,
                                            },
                                        });
                                    }}
                                    style={{ minHeight: 100 }}
                                />
                                {/*<ReactQuill
                                    id="contents_trip_memo"
                                    readOnly={addMode ? false : !editMode}
                                    style={{ height: 150 }}
                                    value={`${showItem.contents.trip_memo}`}
                                    onChange={(content, delta, source, editor) => {
                                        tripMemoRef.current = editor.getText();
                                        setShowItem({
                                            contents: {
                                                ...showItem.contents,
                                                trip_memo: `${content}`,
                                            },
                                            details: {
                                                ...showItem.details,
                                            },
                                        });
                                    }}
                                /> */}
                            </Col>
                        </Row>
                        <Row className={"mt-8"}>
                            <Col className={"col-11 mx-auto"}>
                                <div className={"float-right"}>
                                    <Button
                                        className={"btn btn-default btn-lg"}
                                        style={{ width: 100 }}
                                        onClick={() => {
                                            message.current.title = "알림";
                                            message.current.message = "수정하시겠습니까?";
                                            message.current.button = [
                                                {
                                                    title: "수정",
                                                    action: () => {
                                                        setShowMessage(false);
                                                        // setShowBackdrop(true);

                                                        const validationResult = doValidation();

                                                        if (validationResult.errCode === 0) {
                                                            modifyManagerContents({
                                                                _seq: showItem.contents.seq,
                                                                _trip_memo: showItem.contents.trip_memo,
                                                                _hashtag:
                                                                    publicYn === false
                                                                        ? ""
                                                                        : tripTags === null
                                                                        ? null
                                                                        : `${tripTags
                                                                              .map((item) => {
                                                                                  if (item.includes("#")) return item;
                                                                                  else return `#${item}`;
                                                                              })
                                                                              .join("")}`,
                                                                _stars: Number(showItem.contents.stars),
                                                                _place_type: publicYn === false ? 0 : Number(showItem.contents.place_type),
                                                                _place_convenience:
                                                                    publicYn === false
                                                                        ? null
                                                                        : showItem.contents.place_convenience === null || showItem.contents.place_convenience === "null"
                                                                        ? null
                                                                        : showItem.contents.place_convenience.includes(",") || showItem.contents.place_convenience.length === 1
                                                                        ? showItem.contents.place_convenience
                                                                        : `${showItem.contents.place_convenience.join(",")}`,
                                                                _trip_place: showItem.contents.trip_place,
                                                                _public_yn: publicYn,
                                                            }).then((modifyManagerContentsResult) => {
                                                                if (modifyManagerContentsResult.data.res_code === 2) {
                                                                    const showItems = showItem.contents;
                                                                    showItems.hashtag =
                                                                        tripTags === null
                                                                            ? null
                                                                            : `${tripTags
                                                                                  .map((item) => {
                                                                                      if (item.includes("#")) return item;
                                                                                      else return `#${item}`;
                                                                                  })
                                                                                  .join("")}`;
                                                                    showItems.place_type = Number(showItem.contents.place_type);
                                                                    showItems.place_convenience =
                                                                        showItem.contents.place_convenience === null || showItem.contents.place_convenience === "null"
                                                                            ? null
                                                                            : showItem.contents.place_convenience.includes(",") || showItem.contents.place_convenience.length === 1
                                                                            ? showItem.contents.place_convenience
                                                                            : `${showItem.contents.place_convenience.join(",")}`;

                                                                    // 수정완료
                                                                    props.onResult({
                                                                        actionCode: modifyManagerContentsResult.data.res_code,
                                                                        actionName: "edit",
                                                                        changedData: {
                                                                            ...showItem,
                                                                        },
                                                                    });
                                                                } else {
                                                                    // props.onResult({ actionCode: modifyManagerContentsResult.data.res_code });
                                                                }
                                                            });
                                                        } else {
                                                            message.current.title = "알림";
                                                            message.current.message = `${validationResult.errMsg}`;
                                                            message.current.button = [
                                                                {
                                                                    title: "확인",
                                                                    action: () => {
                                                                        setShowMessage(false);
                                                                    },
                                                                    background: "purple",
                                                                    textColor: "white",
                                                                },
                                                            ];
                                                            message.current.body = null;
                                                            message.current.showFooter = true;
                                                            message.current.keyboard = true;
                                                            message.current.renderFooter = null;
                                                            setTimeout(() => {
                                                                setShowMessage(true);
                                                            }, 500);
                                                        }
                                                    },
                                                    background: "red",
                                                    textColor: "white",
                                                },
                                                {
                                                    title: "닫기",
                                                    action: () => {
                                                        setShowMessage(false);
                                                    },
                                                    background: "purple",
                                                    textColor: "white",
                                                },
                                            ];
                                            message.current.body = null;
                                            message.current.showFooter = true;
                                            message.current.keyboard = true;
                                            message.current.renderFooter = null;
                                            setShowMessage(true);
                                        }}>
                                        수정
                                    </Button>
                                    <Button className={"btn btn-danger btn-lg"} style={{ width: 100 }} onClick={() => {}}>
                                        삭제
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Container>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                        margin="normal"
                        id="date-picker-dialog"
                        name="coupon_expired_date"
                        // disableToolbar
                        okLabel="확인"
                        cancelLabel="취소"
                        style={{ display: "none" }}
                        value={showItem.contents.trip_date}
                        onChange={(v) => {
                            setShowItem({
                                contents: {
                                    ...showItem.contents,
                                    trip_date: convertDate({ _originDate: v, _convertFormat: "YYYY-MM-DD HH:mm" }),
                                },
                                details: {
                                    ...showItem.details,
                                },
                            });
                        }}
                        open={openCal}
                        onOpen={() => setOpenCal(true)}
                        onClose={() => setOpenCal(false)}
                        ampm={false}
                        disableFuture
                        disabled={!editMode}
                    />
                </MuiPickersUtilsProvider>
                <PhotologDialog
                    isOpen={showMessage}
                    toggle={() => setShowMessage(!showMessage)}
                    title={message.current.title}
                    message={message.current.message}
                    body={message.current.message !== null ? null : message.current.body}
                    button={message.current.button}
                    backdrop={"static"}
                    keyboard={message.current.keyboard}
                    showCenter
                    showFooter={message.current.showFooter}
                    renderFooter={message.current.renderFooter}
                />
                <Backdrop className={classes.backdrop} open={showBackdrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        );
    }
};

export default ContentsDetail;
