import React, { Component } from "react";

import { convertDate, History } from "../../modules/utils.js";
import NormalHeader from "../../components/Headers/NormalHeader.js";
import { Card, Col, Container, Form, FormGroup, Input, Label, Row, Button } from "reactstrap";
import ToggleSwitch from "../../modules/ToggleSwitch.js";
import "react-quill/dist/quill.snow.css";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import lodash from "lodash";
import { MuiPickersUtilsProvider, TimePicker, DatePicker, DateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/ko";
import { PhotologDialog } from "../../modules/PhotologDialog.js";
import { getUsersGrant, modifyGrant, modifyUserInfo } from "../../lib/manager.js";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const ManagerDetail = (props) => {
    const [showItem, setShowItem] = React.useState(null);
    const [addMode, setAddMode] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);

    const [openCal, setOpenCal] = React.useState(false);
    const [openCalTag, setOpenCalTag] = React.useState("birthday");
    const [showMessage, setShowMessage] = React.useState(false);
    const [showBackdrop, setShowBackdrop] = React.useState(false);

    const grantList = ["User", "Push", "Content", "Sale", "Coupon", "Popup", "Notice", "Grant"];

    const message = React.useRef({
        tag: "",
        title: "",
        message: null,
        body: null,
        button: [],
        keyboard: true,
        toggle: true,
        renderFooter: null,
        showFooter: true,
    });

    const classes = useStyles();
    React.useEffect(() => {
        if (props === undefined) {
            History.replace("/admin/manager");
            window.location.reload();
            return;
        } else {
            if (showItem === null) {
                if (props.mode === "add") {
                    setShowItem({
                        contents: {
                            user_id: null,
                            user_name: null,
                            user_phone: null,
                            user_gender: null,
                            user_regist_date: null,
                            user_birthday: null,
                        },
                        details: grantList.map((item, index) => {
                            return {
                                user_id: null,
                                grant_code: index,
                                grant_create: 1,
                                grant_delete: 1,
                                grant_modify: 1,
                                grant_read: 1,
                                grant_regist_date: null,
                            };
                        }),
                    });
                    setAddMode(true);
                } else {
                    getUsersGrant({ _userId: props.getContentsItem.user_id }).then((getUsersGrantResult) => {
                        if (getUsersGrantResult.data.res_code === 2) {
                            setShowItem({
                                contents: props.getContentsItem,
                                details: getUsersGrantResult.data.result,
                            });
                        } else if (getUsersGrantResult.data.res_code === 3) {
                            History.push("/auth");
                            window.location.reload();
                        }
                    });
                }
            } else {
            }
        }
    }, []);

    const doValidation = () => {
        if (lodash.isEqual(props.getContentsItem, showItem.contents) && lodash.isEqual(props.getContentsItemDetail, showItem.details)) {
            return {
                errCode: -1,
                errMsg: "바뀐값이 없음",
            };
        } else {
            if (showItem.contents.user_birthday === null && showItem.contents.user_birthday.length === 0) {
                // 값이 없을때
                return {
                    errCode: 1,
                    errMsg: "생년월일 입력란을 확인하세요",
                };
            }
            if (showItem.contents.user_gender === null && showItem.contents.user_gender.length === 0) {
                // 값이 없을때
                return {
                    errCode: 1,
                    errMsg: "성별 입력란을 확인하세요",
                };
            }
            if (showItem.contents.user_id === null && showItem.contents.user_id.length === 0) {
                // 값이 없을때
                return {
                    errCode: 1,
                    errMsg: "ID 입력란을 확인하세요",
                };
            }
            if (showItem.contents.user_name === null && showItem.contents.user_name.length === 0) {
                // 값이 없을때
                return {
                    errCode: 1,
                    errMsg: "이름 입력란을 확인하세요",
                };
            }
            if (showItem.contents.user_phone === null && showItem.contents.user_phone.length === 0) {
                // 값이 없을때
                return {
                    errCode: 1,
                    errMsg: "휴대전화 입력란을 확인하세요",
                };
            }
            if (showItem.contents.user_regist_date === null && showItem.contents.user_regist_date.length === 0) {
                // 값이 없을때
                return {
                    errCode: 1,
                    errMsg: "가입일 입력란을 확인하세요",
                };
            }
        }

        return {
            errCode: 0,
            errMsg: "문제 발견되지 않음",
        };
    };

    if (showItem === null) {
        return <div></div>;
    } else {
        return (
            <div>
                <Container className="mt--7">
                    {addMode ? (
                        <Row>
                            <Col className={"col-12 mx-auto mt-6 m-3"}></Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col className={"col-12 mx-auto mt-6 m-3"}>
                                <Label for="contents_editMode" style={{ color: "black" }}>
                                    수정모드
                                </Label>
                                <Row className={"ml-0"}>
                                    <ToggleSwitch
                                        id="contents_editMode"
                                        checked={editMode}
                                        onChange={(v) => {
                                            if (editMode === true) {
                                                // 수정모드 가능->불가능
                                                setShowItem({
                                                    contents: props.getContentsItem,
                                                    details: props.getContentsItemDetail,
                                                });
                                            }
                                            setEditMode(v);
                                        }}
                                        optionLabels={["가능", "불가"]}
                                    />
                                </Row>
                            </Col>
                        </Row>
                    )}
                    <Card className="shadow border-0 pb-4">
                        <Row className={"m-3"}>
                            <Col className={"col-lg-4 col-md-4 col-sm-4 col-4 mx-auto mt-3"}>
                                <Label for="contents_user_id">ID</Label>
                                <Input type="text" name="user_id" id="contents_user_id" value={showItem.contents.user_id} disabled={addMode ? false : !editMode} />
                            </Col>
                            <Col className={"col-lg-4 col-md-4 col-sm-4 col-4 mx-auto mt-3"}>
                                <Label for="contents_user_name">이름</Label>
                                <Input
                                    type="text"
                                    name="user_name"
                                    id="contents_user_name"
                                    value={showItem.contents.user_name}
                                    disabled={addMode ? false : !editMode}
                                    onChange={(e) => {
                                        setShowItem({
                                            contents: {
                                                ...showItem.contents,
                                                user_name: e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </Col>

                            <Col className={"col-lg-4 col-md-4 col-sm-4 col-4 mx-auto mt-3"}>
                                <Label for="contents_birthday">생년월일</Label>
                                <Input
                                    type="text"
                                    name="birthday"
                                    id="contents_birthday"
                                    value={convertDate({ _originDate: showItem.contents.birthday, _convertFormat: "YYYY.MM.DD" })}
                                    disabled={addMode ? false : !editMode}
                                    onClick={() => {
                                        // setOpenCal({ status: true, tag: "birthday" });
                                        setOpenCalTag("birthday");
                                        setTimeout(() => {
                                            setOpenCal(true);
                                        }, 300);
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className={"ml-3 mr-3 mt-3"}>
                            <Col className={"col-lg-4 col-md-4 col-sm-4 col-4 mx-auto mt-3"}>
                                <Label for="contents_user_phone">휴대전화</Label>
                                <Input type="text" name="user_phone" id="contents_user_phone" value={showItem.contents.user_phone} disabled />
                            </Col>
                            <Col className={"col-lg-4 col-md-4 col-sm-4 col-4 mx-auto mt-3"}>
                                <Label for="contents_user_regist_date">가입일</Label>
                                <Input
                                    type="text"
                                    name="user_regist_date"
                                    id="contents_user_regist_date"
                                    value={convertDate({ _originDate: showItem.contents.user_regist_date, _convertFormat: "YYYY.MM.DD" })}
                                    disabled={addMode ? false : !editMode}
                                    onClick={() => {
                                        // setOpenCal({ status: true, tag: "user_regist_date" });
                                        setOpenCalTag("user_regist_date");
                                        setTimeout(() => {
                                            setOpenCal(true);
                                        }, 300);
                                    }}
                                />
                            </Col>
                            <Col className={"col-lg-4 col-md-4 col-sm-4 col-4 mx-auto mt-3"}>
                                <Label for="contents_user_gender">성별</Label>
                                <Row style={{ marginInlineStart: 2, marginTop: 4 }}>
                                    <ToggleSwitch
                                        id="contents_user_gender"
                                        checked={showItem.contents.user_gender === "M" ? true : false}
                                        onChange={(v) => {
                                            if (showItem.contents.user_gender === "M") {
                                                setShowItem({
                                                    contents: {
                                                        ...showItem.contents,
                                                        user_gender: "F",
                                                    },
                                                });
                                            } else {
                                                if (showItem.contents.user_gender === "F") {
                                                    setShowItem({
                                                        contents: {
                                                            ...showItem.contents,
                                                            user_gender: "M",
                                                        },
                                                    });
                                                }
                                            }
                                        }}
                                        optionLabels={["남", "여"]}
                                        disabled={addMode ? false : !editMode}
                                    />
                                </Row>
                            </Col>
                        </Row>
                        <Row className={"ml-3 mr-3 mt-3"}>
                            <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                <FormGroup className={"custom-control-inline"} id="contents_grant_list" name="grant_list" onChange={(v) => {}} value={showItem.details}>
                                    {showItem.details.slice(0, showItem.details.length / 2).map((item, index) => {
                                        return (
                                            <Col className={"col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 "}>
                                                <div className={"pt-3"}>
                                                    <span>{grantList[index]}</span>
                                                </div>
                                                <Row>
                                                    <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}>
                                                        <FormControlLabel
                                                            key={`grantCode${index}-1`}
                                                            control={
                                                                <Checkbox
                                                                    checked={item.grant_create === 1}
                                                                    name={"grant_create"}
                                                                    value={item.grant_create}
                                                                    onChange={(e) => {
                                                                        let tempDetails = [...showItem.details];
                                                                        tempDetails[index] = {
                                                                            ...tempDetails[index],
                                                                            [e.target.name]: Number(e.target.checked),
                                                                        };
                                                                        setShowItem({
                                                                            contents: {
                                                                                ...showItem.contents,
                                                                            },
                                                                            details: tempDetails,
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                <div>
                                                                    <span style={{ fontSize: 14 }}>{`추가`}</span>
                                                                </div>
                                                            }
                                                            disabled={addMode ? false : !editMode}
                                                        />
                                                    </Col>
                                                    <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}>
                                                        <FormControlLabel
                                                            key={`grantCode${index}-4`}
                                                            control={
                                                                <Checkbox
                                                                    checked={item.grant_read === 1}
                                                                    name={"grant_read"}
                                                                    value={item.grant_read}
                                                                    onChange={(e) => {
                                                                        let tempDetails = [...showItem.details];
                                                                        tempDetails[index] = {
                                                                            ...tempDetails[index],
                                                                            [e.target.name]: Number(e.target.checked),
                                                                        };
                                                                        setShowItem({
                                                                            contents: {
                                                                                ...showItem.contents,
                                                                            },
                                                                            details: tempDetails,
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                <div>
                                                                    <span style={{ fontSize: 14 }}>{`읽기`}</span>
                                                                </div>
                                                            }
                                                            disabled={addMode ? false : !editMode}
                                                        />
                                                    </Col>
                                                    <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}>
                                                        <FormControlLabel
                                                            key={`grantCode${index}-3`}
                                                            control={
                                                                <Checkbox
                                                                    checked={item.grant_modify === 1}
                                                                    name={"grant_modify"}
                                                                    value={item.grant_modify}
                                                                    onChange={(e) => {
                                                                        let tempDetails = [...showItem.details];
                                                                        tempDetails[index] = {
                                                                            ...tempDetails[index],
                                                                            [e.target.name]: Number(e.target.checked),
                                                                        };
                                                                        setShowItem({
                                                                            contents: {
                                                                                ...showItem.contents,
                                                                            },
                                                                            details: tempDetails,
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                <div>
                                                                    <span style={{ fontSize: 14 }}>{`수정`}</span>
                                                                </div>
                                                            }
                                                            disabled={addMode ? false : !editMode}
                                                        />
                                                    </Col>
                                                    <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}>
                                                        <FormControlLabel
                                                            key={`grantCode${index}-2`}
                                                            control={
                                                                <Checkbox
                                                                    checked={item.grant_delete === 1}
                                                                    name={"grant_delete"}
                                                                    value={item.grant_delete}
                                                                    onChange={(e) => {
                                                                        let tempDetails = [...showItem.details];
                                                                        tempDetails[index] = {
                                                                            ...tempDetails[index],
                                                                            [e.target.name]: Number(e.target.checked),
                                                                        };
                                                                        setShowItem({
                                                                            contents: {
                                                                                ...showItem.contents,
                                                                            },
                                                                            details: tempDetails,
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                <div>
                                                                    <span style={{ fontSize: 14 }}>{`삭제`}</span>
                                                                </div>
                                                            }
                                                            disabled={addMode ? false : !editMode}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        );
                                    })}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className={"ml-3 mr-3 mt-3"}>
                            <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                <FormGroup className={"custom-control-inline"} id="contents_grant_list" name="grant_list" onChange={(v) => {}} value={showItem.details}>
                                    {showItem.details.slice(showItem.details.length / 2, showItem.details.length).map((item, index) => {
                                        return (
                                            <Col className={"col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 "}>
                                                <div style={{ paddingTop: 8 }}>
                                                    <span>{grantList[index + showItem.details.length / 2]}</span>
                                                </div>
                                                <Row>
                                                    <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}>
                                                        <FormControlLabel
                                                            key={`grantCode${index}-1`}
                                                            control={
                                                                <Checkbox
                                                                    checked={item.grant_create === 1}
                                                                    name={"grant_create"}
                                                                    value={item.grant_create}
                                                                    onChange={(e) => {
                                                                        let tempDetails = [...showItem.details];
                                                                        tempDetails[index + showItem.details.length / 2] = {
                                                                            ...tempDetails[index + showItem.details.length / 2],
                                                                            [e.target.name]: Number(e.target.checked),
                                                                        };
                                                                        setShowItem({
                                                                            contents: {
                                                                                ...showItem.contents,
                                                                            },
                                                                            details: tempDetails,
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                <div>
                                                                    <span style={{ fontSize: 14 }}>{`추가`}</span>
                                                                </div>
                                                            }
                                                            disabled={addMode ? false : !editMode}
                                                        />
                                                    </Col>
                                                    <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}>
                                                        <FormControlLabel
                                                            key={`grantCode${index}-4`}
                                                            control={
                                                                <Checkbox
                                                                    checked={item.grant_read === 1}
                                                                    name={"grant_read"}
                                                                    value={item.grant_read}
                                                                    onChange={(e) => {
                                                                        let tempDetails = [...showItem.details];
                                                                        tempDetails[index + showItem.details.length / 2] = {
                                                                            ...tempDetails[index + showItem.details.length / 2],
                                                                            [e.target.name]: Number(e.target.checked),
                                                                        };
                                                                        setShowItem({
                                                                            contents: {
                                                                                ...showItem.contents,
                                                                            },
                                                                            details: tempDetails,
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                <div>
                                                                    <span style={{ fontSize: 14 }}>{`읽기`}</span>
                                                                </div>
                                                            }
                                                            disabled={addMode ? false : !editMode}
                                                        />
                                                    </Col>
                                                    <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}>
                                                        <FormControlLabel
                                                            key={`grantCode${index}-3`}
                                                            control={
                                                                <Checkbox
                                                                    checked={item.grant_modify === 1}
                                                                    name={"grant_modify"}
                                                                    value={item.grant_modify}
                                                                    onChange={(e) => {
                                                                        let tempDetails = [...showItem.details];
                                                                        tempDetails[index + showItem.details.length / 2] = {
                                                                            ...tempDetails[index + showItem.details.length / 2],
                                                                            [e.target.name]: Number(e.target.checked),
                                                                        };
                                                                        setShowItem({
                                                                            contents: {
                                                                                ...showItem.contents,
                                                                            },
                                                                            details: tempDetails,
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                <div>
                                                                    <span style={{ fontSize: 14 }}>{`수정`}</span>
                                                                </div>
                                                            }
                                                            disabled={addMode ? false : !editMode}
                                                        />
                                                    </Col>
                                                    <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"}>
                                                        <FormControlLabel
                                                            key={`grantCode${index}-2`}
                                                            control={
                                                                <Checkbox
                                                                    checked={item.grant_delete === 1}
                                                                    name={"grant_delete"}
                                                                    value={item.grant_delete}
                                                                    onChange={(e) => {
                                                                        let tempDetails = [...showItem.details];
                                                                        tempDetails[index + showItem.details.length / 2] = {
                                                                            ...tempDetails[index + showItem.details.length / 2],
                                                                            [e.target.name]: Number(e.target.checked),
                                                                        };
                                                                        setShowItem({
                                                                            contents: {
                                                                                ...showItem.contents,
                                                                            },
                                                                            details: tempDetails,
                                                                        });
                                                                    }}
                                                                />
                                                            }
                                                            label={
                                                                <div>
                                                                    <span style={{ fontSize: 14 }}>{`삭제`}</span>
                                                                </div>
                                                            }
                                                            disabled={addMode ? false : !editMode}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        );
                                    })}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className={"mt-8"}>
                            <Col className={"col-11 mx-auto"}>
                                <div className={"float-right"}>
                                    <Button
                                        className={"btn btn-default btn-lg"}
                                        style={{ width: 100 }}
                                        onClick={() => {
                                            message.current.title = "알림";
                                            message.current.message = "수정하시겠습니까?";
                                            message.current.button = [
                                                {
                                                    title: "수정",
                                                    action: () => {
                                                        setShowMessage(false);
                                                        if (doValidation().errCode === 0) {
                                                            setShowBackdrop(true);

                                                            let newPermission = [];

                                                            newPermission = showItem.details.map((item, index) => {
                                                                item.grantCode = item.grant_code;
                                                                item.createGrant = item.grant_create;
                                                                item.modifyGrant = item.grant_modify;
                                                                item.readGrant = item.grant_read;
                                                                item.deleteGrant = item.grant_delete;
                                                                return item;
                                                            });

                                                            modifyGrant({ _managerId: showItem.contents.user_id, _grantList: newPermission }).then((modifyGrantResult) => {
                                                                setShowBackdrop(false);
                                                                // if (modifyGrantResult.data.res_code === 2) {
                                                                //     toast.current.title = "작업 완료";
                                                                //     toast.current.message = "수정이 완료되었습니다";
                                                                //     setShowToast(true);
                                                                // }
                                                            });
                                                        }
                                                        // modifyManagerContents({_contents: showItem.contents}).then((modifyManagerContentsResult)=>{
                                                        // })
                                                    },
                                                    background: "red",
                                                    textColor: "white",
                                                },
                                                {
                                                    title: "닫기",
                                                    action: () => {
                                                        setShowMessage(false);
                                                    },
                                                    background: "purple",
                                                    textColor: "white",
                                                },
                                            ];
                                            message.current.body = null;
                                            message.current.showFooter = true;
                                            message.current.keyboard = true;
                                            message.current.renderFooter = null;
                                            setShowMessage(true);
                                        }}>
                                        수정
                                    </Button>
                                    <Button className={"btn btn-danger btn-lg"} style={{ width: 100 }} onClick={() => {}}>
                                        삭제
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Container>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        // disableToolbar
                        okLabel="확인"
                        cancelLabel="취소"
                        style={{ display: "none" }}
                        value={openCalTag === "birthday" ? showItem.contents.user_birthday : showItem.contents.user_regist_date}
                        onChange={(v) => {
                            if (openCalTag === "birthday") {
                                setShowItem({
                                    contents: {
                                        ...showItem.contents,
                                        user_birthday: convertDate({ _originDate: v, _convertFormat: "YYYY-MM-DD HH:mm" }),
                                    },
                                });
                            } else {
                                setShowItem({
                                    contents: {
                                        ...showItem.contents,
                                        user_regist_date: convertDate({ _originDate: v, _convertFormat: "YYYY-MM-DD HH:mm" }),
                                    },
                                });
                            }
                        }}
                        open={openCal}
                        // onOpen={() =>
                        //     setOpenCal({
                        //         ...openCalTag,
                        //     })
                        // }
                        onClose={() => setOpenCal(false)}
                        ampm={false}
                        disabled={addMode ? false : !editMode}
                        disableFuture
                    />
                </MuiPickersUtilsProvider>
                <PhotologDialog
                    isOpen={showMessage}
                    toggle={() => setShowMessage(!showMessage)}
                    title={message.current.title}
                    message={message.current.message}
                    body={message.current.message !== null ? null : message.current.body}
                    button={message.current.button}
                    backdrop={"static"}
                    keyboard={message.current.keyboard}
                    showCenter
                    showFooter={message.current.showFooter}
                    renderFooter={message.current.renderFooter}
                />
                <Backdrop
                    className={classes.backdrop}
                    open={showBackdrop}
                    // invisible={true}
                    onClick={() => {}}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        );
    }
};

export default ManagerDetail;
