import React from "react";
import { Container } from "reactstrap";

class NormalHeader extends React.Component {
    render() {
        return (
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
                <Container fluid></Container>
            </div>
        );
    }
}

export default NormalHeader;
