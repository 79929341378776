import React from "react";

// reactstrap components
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Row, Col } from "reactstrap";

import LogoImage from "../../assets/img/brand/logo-with-name.png";
import { managerLogin } from "../../lib/manager";
import { PhotologDialog } from "../../modules/PhotologDialog.js";
import { History } from "../../modules/utils";

const Login = () => {
    const [showMessage, setShowMessage] = React.useState(false);
    const message = React.useRef({
        tag: "",
        title: "",
        message: null,
        body: null,
        button: [],
        keyboard: true,
        toggle: true,
        renderFooter: null,
        showFooter: true,
    });
    const loginInfo = React.useRef({
        userId: null,
        userPass: null,
    });

    const doLogin = ({ _userId, _userPass }) => {
        managerLogin({ _userId: _userId, _userPass: _userPass }).then((managerLoginResult) => {
            if (managerLoginResult !== null && managerLoginResult.data.res_code === 2) {
                History.replace("/admin/index");
                window.location.reload();
            } else {
                message.current.title = "알림";
                message.current.message = "아이디와 비밀번호를 확인하세요";
                message.current.button = [
                    {
                        title: "확인",
                        action: () => {
                            setShowMessage(false);
                        },
                        background: "purple",
                        textColor: "white",
                    },
                ];
                message.current.body = null;
                message.current.showFooter = true;
                message.current.keyboard = true;
                message.current.renderFooter = null;
                setShowMessage(true);
            }
        });
    };

    const checkValidation = () => {
        if (loginInfo.current.userId === null || loginInfo.current.userId.length === 0) {
            // 아이디 입력값 확인
            return;
        }

        if (loginInfo.current.userPass === null || loginInfo.current.userPass.length === 0) {
            // 비밀번호 입력값 확인
            return;
        }

        doLogin({ _userId: loginInfo.current.userId, _userPass: loginInfo.current.userPass });
    };

    return (
        <>
            <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent pb-5">
                        <div className="btn-wrapper text-center">
                            <img alt="PhotoLog" src={LogoImage} style={{ width: 200 }} />
                        </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                        <Form role="form">
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-email-83" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Email"
                                        type="email"
                                        onChange={(e) => {
                                            loginInfo.current.userId = e.target.value;
                                        }}
                                        onKeyPress={(event) => {
                                            if (event.key === "Enter") {
                                                checkValidation();
                                            }
                                        }}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Password"
                                        type="password"
                                        onChange={(e) => {
                                            loginInfo.current.userPass = e.target.value;
                                        }}
                                        onKeyPress={(event) => {
                                            if (event.key === "Enter") {
                                                checkValidation();
                                            }
                                        }}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                                <input className="custom-control-input" id=" customCheckLogin" type="checkbox" />
                                <label className="custom-control-label" htmlFor=" customCheckLogin">
                                    <span className="text-muted">Remember me</span>
                                </label>
                            </div>
                            <div className="text-center">
                                <Button
                                    className="my-4"
                                    color="primary"
                                    type="button"
                                    onClick={() => {
                                        checkValidation();
                                    }}>
                                    Sign in
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
            <PhotologDialog
                isOpen={showMessage}
                toggle={() => setShowMessage(!showMessage)}
                title={message.current.title}
                message={message.current.message}
                body={message.current.message !== null ? null : message.current.body}
                button={message.current.button}
                backdrop={"static"}
                keyboard={message.current.keyboard}
                showCenter
                showFooter={message.current.showFooter}
                renderFooter={message.current.renderFooter}
            />
        </>
    );
};

export default Login;
