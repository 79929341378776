import React from "react";

import { convertDate, diffDate, History, randomInteger } from "../../modules/utils.js";
import { Card, Col, Container, Input, Label, Row, Button } from "reactstrap";
import ToggleSwitch from "../../modules/ToggleSwitch.js";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import lodash from "lodash";
import { MuiPickersUtilsProvider, TimePicker, DateTimePicker } from "@material-ui/pickers";

import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/ko";
import { PhotologDialog } from "../../modules/PhotologDialog.js";
import { deleteEvent, insertEvent, modifyEvent } from "../../lib/event.js";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import { RiCloseFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));
class RenderImageFromFile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            image: null,
        };
    }
    readFile(file) {
        let fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onloadend = (r) => {
            this.setState({ image: r.target.result });
        };
    }
    componentDidMount() {
        if (this.props.type === "local") this.readFile(this.props.image);
        else this.setState({ image: this.props.image });
    }
    componentWillUnmount() {}
    componentDidUpdate(prevProps) {}
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.image !== nextProps.image) {
            if (this.props.type === "local") this.readFile(nextProps.image);
            else this.setState({ image: nextProps.image });
            return true;
        }
        if (this.state.image !== nextState.image) {
            // this.readFile(nextProps.image);
            return true;
        }
        return false;
    }

    render() {
        if (this.state.image === null) {
            return <div />;
        } else {
            return (
                <div className={"mt-2"}>
                    {this.props.type === "local" && (
                        <Button
                            style={{ position: "absolute", top: this.props.type === "local" ? 80 : 100, right: 20 }}
                            className="btn btn-danger"
                            size="sm"
                            onClick={() => {
                                this.props.onClose();
                                this.setState({ image: null });
                            }}>
                            <RiCloseFill size={15} />
                        </Button>
                    )}
                    <img
                        key={`image-${randomInteger(0, 100)}`}
                        src={this.state.image}
                        alt=""
                        style={{ ...this.props.imageStyle, width: "100%", minWidth: "100%", minHeight: 300 }}
                        className={"border border-2"}
                    />
                </div>
            );
        }
    }
}
const EventDetail = (props) => {
    const [showItem, setShowItem] = React.useState(null);
    const [addMode, setAddMode] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [tempImageContents, setTempImageContents] = React.useState(null);
    const [tempImageThumbnail, setTempImageThumbnail] = React.useState(null);
    const [openCal, setOpenCal] = React.useState(false);
    const [openCalTag, setOpenCalTag] = React.useState("start_date");
    const [showMessage, setShowMessage] = React.useState(false);
    const [showBackdrop, setShowBackdrop] = React.useState(false);

    const message = React.useRef({
        tag: "",
        title: "",
        message: null,
        body: null,
        button: [],
        keyboard: true,
        toggle: true,
        renderFooter: null,
        showFooter: true,
    });

    const classes = useStyles();
    React.useEffect(() => {
        if (props === undefined) {
            History.replace("/admin/event");
            window.location.reload();
            return;
        } else {
            if (showItem === null) {
                if (props.mode === "add") {
                    setShowItem({
                        contents: {
                            event_seq: -1,
                            event_title: null,
                            event_desc: null,
                            event_button: null,
                            event_button_contents: null,
                            event_date: null,
                            event_state: null,
                            event_end_date: null,
                            event_contents: null,
                            event_thumbnail: null,
                            event_forward_type: null,
                            event_forward_url: null,
                            event_navigation_category: null,
                            event_navigation_seq: null,
                            event_navigation_code: null,
                        },
                    });
                    setAddMode(true);
                } else {
                    setShowItem({
                        contents: props.getContentsItem,
                    });
                    setTempImageContents(props.getContentsItem.event_contents);
                    setTempImageThumbnail(props.getContentsItem.event_thumbnail);
                }
            }
        }
    }, [showItem, props]);

    const doValidation = () => {
        if (editMode) {
            if (lodash.isEqual(props.getContentsItem, showItem.contents)) {
                return {
                    errCode: -1,
                    errMsg: "바뀐값이 없음",
                };
            }
        }

        if (showItem.contents.event_title === null || showItem.contents.event_title.length === 0) {
            return {
                errCode: 1,
                errMsg: "이벤트 제목 입력란을 확인하세요",
            };
        }

        if (showItem.contents.event_forward_type === null || showItem.contents.event_forward_type.length === 0) {
            return {
                errCode: 1,
                errMsg: "이벤트 이동 입력란을 확인하세요",
            };
        } else {
            if (showItem.contents.event_forward_type === "navigation") {
                if (showItem.contents.event_navigation_category === null || showItem.contents.event_navigation_category.length === 0) {
                    return {
                        errCode: 1,
                        errMsg: "내부이동 화면을 확인하세요",
                    };
                } else {
                    if (showItem.contents.event_navigation_category === "null") {
                        setShowItem({
                            contents: {
                                ...showItem.contents,
                                event_navigation_category: null,
                            },
                        });
                    }
                    if (showItem.contents.event_navigation_seq === null || showItem.contents.event_navigation_seq.length === 0) {
                        return {
                            errCode: 1,
                            errMsg: "Seq 입력란을 확인하세요",
                        };
                    }
                }
            } else if (showItem.contents.event_forward_type === "external_url") {
                if (showItem.contents.event_forward_url === null || showItem.contents.event_forward_url.length === 0) {
                    return {
                        errCode: 1,
                        errMsg: "외부이동 URL 확인하세요",
                    };
                } else if (!showItem.contents.event_forward_url.startsWith("http")) {
                    return {
                        errCode: 1,
                        errMsg: "외부이동 URL주소는 http로 시작해야합니다",
                    };
                }
            }
        }
        if (showItem.contents.event_state === null || showItem.contents.event_state.length === 0) {
            return {
                errCode: 1,
                errMsg: "이벤트 상태 입력란을 확인하세요",
            };
        }
        if (showItem.contents.event_button === null || showItem.contents.event_button.length === 0) {
            return {
                errCode: 1,
                errMsg: "버튼 유무 입력란을 확인하세요",
            };
        } else {
            // 표시
            if (showItem.contents.event_button === String(1)) {
                if (showItem.contents.event_button_contents === null || showItem.contents.event_button_contents.length === 0) {
                    return {
                        errCode: 2,
                        errMsg: "버튼 내용 입력란을 확인하세요",
                    };
                }
            }
        }
        if (showItem.contents.event_date === null || showItem.contents.event_date.length === 0) {
            return {
                errCode: 1,
                errMsg: "이벤트 시작일시 입력란을 확인하세요",
            };
        }
        if (showItem.contents.event_end_date === null || showItem.contents.event_end_date.length === 0) {
            return {
                errCode: 1,
                errMsg: "이벤트 종료일시 입력란을 확인하세요",
            };
        }

        let diffTime = diffDate({ _date1: showItem.contents.event_date, _date2: showItem.contents.event_end_date });

        if (diffTime.d === 0 && diffTime.h === 0 && diffTime.m === 0) {
            return {
                errCode: 1,
                errMsg: "이벤트 시작일시와 이벤트 종료일시가 같습니다",
            };
        } else {
            if (diffTime.d <= -1 || diffTime.h <= -1 || diffTime.m <= -1) {
                return {
                    errCode: 1,
                    errMsg: "시작일시와 종료일시를 확인하세요",
                };
            }
        }

        if (tempImageContents === null) {
            return {
                errCode: 1,
                errMsg: "메인 이미지를 추가하세요",
            };
        }
        if (tempImageThumbnail === null) {
            return {
                errCode: 1,
                errMsg: "썸네일 이미지를 추가하세요",
            };
        }
        return {
            errCode: 0,
            errMsg: "문제 발견되지 않음",
        };
    };

    if (showItem === null) {
        return <div></div>;
    } else {
        return (
            <>
                <Container className="mt--7">
                    {addMode ? (
                        <Row>
                            <Col className={"col-12 mx-auto mt-6 m-3"}></Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col className={"col-12 mx-auto mt-6 m-3"}>
                                <Label for="contents_editMode" style={{ color: "black" }}>
                                    수정모드
                                </Label>
                                <Row className={"ml-0"}>
                                    <ToggleSwitch
                                        id="contents_editMode"
                                        checked={editMode}
                                        onChange={(v) => {
                                            if (editMode === true) {
                                                // 수정모드 가능->불가능
                                                setShowItem({
                                                    contents: props.getContentsItem,
                                                });
                                            }
                                            setEditMode(v);
                                        }}
                                        optionLabels={["가능", "불가"]}
                                    />
                                </Row>
                            </Col>
                        </Row>
                    )}
                    <Card className="shadow border-0 pb-4">
                        <Row className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                            <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto"}>
                                <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                    <Label for="contents_event_title">이벤트 제목</Label>
                                    <Input
                                        type="text"
                                        name="event_title"
                                        id="contents_event_title"
                                        value={showItem.contents.event_title}
                                        disabled={addMode ? false : !editMode}
                                        onChange={(e) => {
                                            setShowItem({
                                                contents: {
                                                    ...showItem.contents,
                                                    event_title: e.target.value,
                                                },
                                            });
                                        }}
                                    />
                                </Col>
                                <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                    <Label for="contents_event_desc">이벤트 설명</Label>
                                    <Input
                                        type="textarea"
                                        name="event_desc"
                                        id="contents_event_desc"
                                        value={showItem.contents.event_desc === null || showItem.contents.event_desc === "null" ? "" : showItem.contents.event_desc}
                                        disabled={addMode ? false : !editMode}
                                        onChange={(e) => {
                                            setShowItem({
                                                contents: {
                                                    ...showItem.contents,
                                                    event_desc: e.target.value,
                                                },
                                            });
                                        }}
                                    />
                                </Col>
                                <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                    <Label for="contents_event_forward_type">이벤트 이동</Label>
                                    <Row className={"ml-1"} id="contents_event_forward_type">
                                        <FormControl component="fieldset" disabled={addMode ? false : !editMode}>
                                            <RadioGroup
                                                name="event_forward_type"
                                                row={true}
                                                value={showItem.contents.event_forward_type}
                                                onChange={(e) => {
                                                    setShowItem({
                                                        contents: {
                                                            ...showItem.contents,
                                                            // event_forward_url: null,
                                                            event_forward_type: e.target.value,
                                                            event_navigation_category: null,
                                                            event_navigation_seq: null,
                                                            event_navigation_code: null,
                                                        },
                                                    });
                                                }}>
                                                <FormControlLabel value="external_url" control={<Radio />} label="외부링크" />
                                                <FormControlLabel value="navigation" control={<Radio />} label="내부이동" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Row>
                                </Col>
                                <Col
                                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}
                                    hidden={showItem.contents.event_forward_type !== "navigation" || showItem.contents.event_forward_type === null}>
                                    <Label for="contents_event_navigation_category">내부이동 화면</Label>
                                    <Row className={"ml-1"} id="contents_event_navigation_category">
                                        <FormControl component="fieldset" disabled={addMode ? false : !editMode}>
                                            <RadioGroup
                                                name="event_navigation_category"
                                                row={true}
                                                value={showItem.contents.event_navigation_category}
                                                onChange={(e) => {
                                                    setShowItem({
                                                        contents: {
                                                            ...showItem.contents,
                                                            event_navigation_category: e.target.value,
                                                        },
                                                    });
                                                }}>
                                                <FormControlLabel value="event" control={<Radio />} label="이벤트" />
                                                <FormControlLabel value="store" control={<Radio />} label="스토어" />
                                                <FormControlLabel value="notice" control={<Radio />} label="공지사항" />
                                                <FormControlLabel value="null" control={<Radio />} label="선택안함" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Row>
                                </Col>
                                <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"} hidden={showItem.contents.event_forward_type !== "external_url"}>
                                    <Label for="contents_event_forward_url">외부이동 URL</Label>
                                    <Input
                                        type="text"
                                        name="event_forward_url"
                                        id="contents_event_forward_url"
                                        value={showItem.contents.event_forward_url}
                                        disabled={addMode ? false : !editMode}
                                        onChange={(e) => {
                                            setShowItem({
                                                contents: {
                                                    ...showItem.contents,
                                                    event_forward_url: e.target.value,
                                                },
                                            });
                                        }}
                                    />
                                </Col>
                                <Col
                                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}
                                    hidden={showItem.contents.event_navigation_category === "null" || showItem.contents.event_forward_type === "external_url"}>
                                    <Label for="contents_event_navigation_seq">Seq</Label>

                                    {showItem.contents.event_navigation_category === "store" ? (
                                        <Row className={"ml-1"} id="contents_event_navigation_seq_store">
                                            <FormControl component="fieldset" disabled={addMode ? false : !editMode}>
                                                <RadioGroup
                                                    name="event_navigation_seq_store"
                                                    row={true}
                                                    value={showItem.contents.event_navigation_seq === null ? "" : Number(showItem.contents.event_navigation_seq)}
                                                    onChange={(e) => {
                                                        setShowItem({
                                                            contents: {
                                                                ...showItem.contents,
                                                                event_navigation_seq: e.target.value,
                                                            },
                                                        });
                                                    }}>
                                                    <FormControlLabel value={-1} control={<Radio />} label="스토어 홈" />
                                                    <FormControlLabel value={0} control={<Radio />} label="로그카드" />
                                                    <FormControlLabel value={1} control={<Radio />} label="로그인맵 지도" />
                                                    <FormControlLabel value={2} control={<Radio />} label="로그인맵 스티커" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Row>
                                    ) : (
                                        <Fragment>
                                            <Input
                                                type="number"
                                                name="event_navigation_seq"
                                                id="contents_event_navigation_seq"
                                                value={showItem.contents.event_navigation_seq === null ? "" : showItem.contents.event_navigation_seq}
                                                disabled={addMode ? false : !editMode}
                                                onChange={(e) => {
                                                    setShowItem({
                                                        contents: {
                                                            ...showItem.contents,
                                                            event_navigation_seq: e.target.value,
                                                        },
                                                    });
                                                }}
                                            />
                                            <div className={"mt-3"}>
                                                <Label for="contents_event_navigation_code">Code</Label>
                                                <Input
                                                    type="text"
                                                    name="event_navigation_code"
                                                    id="contents_event_navigation_code"
                                                    value={showItem.contents.event_navigation_code === null ? "" : showItem.contents.event_navigation_code}
                                                    disabled={addMode ? false : !editMode}
                                                    onChange={(e) => {
                                                        setShowItem({
                                                            contents: {
                                                                ...showItem.contents,
                                                                event_navigation_code: e.target.value,
                                                            },
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </Fragment>
                                    )}
                                </Col>
                                <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                    <Label for="contents_event_state">이벤트 상태</Label>
                                    <Row className={"ml-1"} id="contents_event_state">
                                        <FormControl component="fieldset" disabled={addMode ? false : !editMode}>
                                            <RadioGroup
                                                name="event_state"
                                                row={true}
                                                value={showItem.contents.event_state}
                                                onChange={(e) => {
                                                    setShowItem({
                                                        contents: {
                                                            ...showItem.contents,
                                                            event_state: e.target.value,
                                                        },
                                                    });
                                                }}>
                                                <FormControlLabel value={"prev"} control={<Radio />} label="진행전" />
                                                <FormControlLabel value={"proceed"} control={<Radio />} label="진행중" />
                                                <FormControlLabel value={"end"} control={<Radio />} label="종료" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Row>
                                </Col>
                                <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                    <Label for="contents_event_button">버튼 유무</Label>
                                    <Row className={"ml-1"} id="contents_event_button">
                                        <FormControl component="fieldset" disabled={addMode ? false : !editMode}>
                                            <RadioGroup
                                                name="event_button"
                                                row={true}
                                                value={String(showItem.contents.event_button)}
                                                onChange={(e) => {
                                                    setShowItem({
                                                        contents: {
                                                            ...showItem.contents,
                                                            event_button: String(e.target.value),
                                                        },
                                                    });
                                                }}>
                                                <FormControlLabel value={String(1)} control={<Radio />} label="표시" />
                                                <FormControlLabel value={String(0)} control={<Radio />} label="미표시" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Row>
                                </Col>
                                {String(showItem.contents.event_button) === "1" && (
                                    <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto"}>
                                        <Label for="contents_event_button_contents">버튼 내용</Label>
                                        <Input
                                            type="text"
                                            name="event_button_contents"
                                            id="contents_event_button_contents"
                                            value={showItem.contents.event_button_contents}
                                            disabled={addMode ? false : !editMode}
                                            onChange={(e) => {
                                                setShowItem({
                                                    contents: {
                                                        ...showItem.contents,
                                                        event_button_contents: e.target.value,
                                                    },
                                                });
                                            }}
                                        />
                                    </Col>
                                )}
                            </Col>
                            <Row className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto"}>
                                <Col className={"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mx-auto mt-3"}>
                                    <Label for="contents_event_date">이벤트 시작일시</Label>
                                    <Input
                                        type="text"
                                        name="event_date"
                                        id="contents_event_date"
                                        value={convertDate({ _originDate: showItem.contents.event_date, _convertFormat: "YYYY-MM-DD HH:mm" })}
                                        disabled={addMode ? false : !editMode}
                                        onClick={() => {
                                            // setOpenCal({ status: true, tag: "start_date" });
                                            setOpenCalTag("start_date");
                                            setTimeout(() => {
                                                setOpenCal(true);
                                            }, 300);
                                        }}
                                    />
                                </Col>
                                <Col className={"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mx-auto mt-3"}>
                                    <Label for="contents_event_end_date">이벤트 종료일시</Label>
                                    <Input
                                        type="text"
                                        name="event_end_date"
                                        id="contents_event_end_date"
                                        value={convertDate({ _originDate: showItem.contents.event_end_date, _convertFormat: "YYYY-MM-DD HH:mm" })}
                                        disabled={addMode ? false : !editMode}
                                        onClick={() => {
                                            // setOpenCal({ status: true, tag: "end_date" });
                                            setOpenCalTag("end_date");
                                            setTimeout(() => {
                                                setOpenCal(true);
                                            }, 300);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto"}>
                                <Col className={"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mx-auto mt-3"}>
                                    <Label for="contents_event_contents">메인이미지</Label>
                                    {addMode && (
                                        <Input
                                            type="file"
                                            name="event_contents"
                                            id="contents_event_contents"
                                            value={showItem.contents.event_contents}
                                            disabled={addMode ? false : !editMode}
                                            onChange={(e) => {
                                                setTempImageContents(e.target.files[0]);
                                            }}
                                        />
                                    )}
                                    {tempImageContents !== null && (
                                        <RenderImageFromFile
                                            type={addMode ? "local" : "remote"}
                                            image={tempImageContents}
                                            className={"border border-2"}
                                            onClose={() => {
                                                setTempImageContents(null);
                                            }}
                                        />
                                    )}
                                </Col>
                                <Col className={"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mx-auto mt-3"}>
                                    <Label for="contents_event_thumbnail">썸네일</Label>
                                    {addMode && (
                                        <Input
                                            type="file"
                                            name="event_thumbnail"
                                            id="contents_event_thumbnail"
                                            value={showItem.contents.event_thumbnail}
                                            disabled={addMode ? false : !editMode}
                                            onChange={(e) => {
                                                setTempImageThumbnail(e.target.files[0]);
                                            }}
                                        />
                                    )}
                                    {tempImageThumbnail !== null && (
                                        <RenderImageFromFile
                                            type={addMode ? "local" : "remote"}
                                            image={tempImageThumbnail}
                                            className={"border border-2"}
                                            onClose={() => {
                                                setTempImageThumbnail(null);
                                            }}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Row>
                        <Row className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto"}></Row>
                        <Row className={"mt-5"}>
                            <Col className={"col-11 mx-auto"}>
                                <div className={"float-right"}>
                                    {addMode ? (
                                        <Button
                                            className={"btn btn-default btn-lg"}
                                            style={{ width: 100 }}
                                            onClick={() => {
                                                const validationResult = doValidation();
                                                if (validationResult.errCode === 0) {
                                                    message.current.title = "알림";
                                                    message.current.message = "추가하시겠습니까?";
                                                    message.current.button = [
                                                        {
                                                            title: "추가",
                                                            action: () => {
                                                                setShowMessage(false);
                                                                setShowBackdrop(true);
                                                                insertEvent({
                                                                    _eventTitle: showItem.contents.event_title,
                                                                    _eventDesc: showItem.contents.event_desc,
                                                                    _eventButton: showItem.contents.event_button === "1" ? true : false,
                                                                    _eventButtonContents: showItem.contents.event_button_contents,
                                                                    _eventDate: showItem.contents.event_date,
                                                                    _eventState: showItem.contents.event_state,
                                                                    _eventEndDate: showItem.contents.event_end_date,
                                                                    _eventMainImage: tempImageContents,
                                                                    _eventSubImage: tempImageThumbnail,
                                                                    _forwardType: showItem.contents.event_forward_type,
                                                                    _forwardUrl: showItem.contents.event_forward_url,
                                                                    _navigationCategory: showItem.contents.event_navigation_category,
                                                                    _navigationSeq: showItem.contents.event_navigation_seq,
                                                                    _navigationCode: showItem.contents.event_navigation_code,
                                                                }).then((insertEventResult) => {
                                                                    if (insertEventResult.data.res_code === 2) {
                                                                        toast.success("🎉 완료되었습니다");
                                                                        setTimeout(() => {
                                                                            window.location.reload();
                                                                        }, 2000);
                                                                    } else {
                                                                        // props.onResult({ actionCode: insertEventResult.data.res_code });
                                                                        toast.error("🤔 에러가 발생되었습니다");
                                                                    }
                                                                });
                                                            },
                                                            background: "red",
                                                            textColor: "white",
                                                        },
                                                        {
                                                            title: "닫기",
                                                            action: () => {
                                                                setShowMessage(false);
                                                            },
                                                            background: "purple",
                                                            textColor: "white",
                                                        },
                                                    ];
                                                    message.current.body = null;
                                                    message.current.showFooter = true;
                                                    message.current.keyboard = true;
                                                    message.current.renderFooter = null;
                                                    setShowMessage(true);
                                                } else {
                                                    message.current.title = "알림";
                                                    message.current.message = `${validationResult.errMsg}`;
                                                    message.current.button = [
                                                        {
                                                            title: "확인",
                                                            action: () => {
                                                                setShowMessage(false);
                                                            },
                                                            background: "purple",
                                                            textColor: "white",
                                                        },
                                                    ];
                                                    message.current.body = null;
                                                    message.current.showFooter = true;
                                                    message.current.keyboard = true;
                                                    message.current.renderFooter = null;
                                                    setShowMessage(true);
                                                }
                                            }}>
                                            추가
                                        </Button>
                                    ) : (
                                        <div>
                                            <Button
                                                className={"btn btn-default btn-lg"}
                                                style={{ width: 100 }}
                                                onClick={() => {
                                                    const validationResult = doValidation();

                                                    if (validationResult.errCode === 0) {
                                                        message.current.title = "알림";
                                                        message.current.message = "수정하시겠습니까?";
                                                        message.current.button = [
                                                            {
                                                                title: "수정",
                                                                action: () => {
                                                                    setShowMessage(false);

                                                                    setShowBackdrop(true);
                                                                    modifyEvent({
                                                                        _eventIdx: showItem.contents.event_seq,
                                                                        _eventTitle: showItem.contents.event_title,
                                                                        _eventDesc: showItem.contents.event_desc,
                                                                        _eventButton: showItem.contents.event_button === "1" ? true : false,
                                                                        _eventButtonContents: showItem.contents.event_button_contents,
                                                                        _eventDate: showItem.contents.event_date,
                                                                        _eventState: showItem.contents.event_state,
                                                                        _eventEndDate: showItem.contents.event_end_date,
                                                                        _forwardType: showItem.contents.event_forward_type,
                                                                        _forwardUrl: showItem.contents.event_forward_url,
                                                                        _navigationCategory: showItem.contents.event_navigation_category,
                                                                        _navigationSeq: showItem.contents.event_navigation_seq,
                                                                        _navigationCode: showItem.contents.event_navigation_code,
                                                                    }).then((modifyEventResult) => {
                                                                        if (modifyEventResult.data.res_code === 2) {
                                                                            // 수정완료
                                                                            props.onResult({
                                                                                actionCode: modifyEventResult.data.res_code,
                                                                                actionName: "edit",
                                                                                changedData: {
                                                                                    ...showItem,
                                                                                },
                                                                            });
                                                                        } else {
                                                                            // props.onResult({ actionCode: modifyEventResult.data.res_code });
                                                                            toast.error("🤔 에러가 발생되었습니다");
                                                                        }
                                                                    });
                                                                },
                                                                background: "red",
                                                                textColor: "white",
                                                            },
                                                            {
                                                                title: "닫기",
                                                                action: () => {
                                                                    setShowMessage(false);
                                                                },
                                                                background: "purple",
                                                                textColor: "white",
                                                            },
                                                        ];
                                                        setShowMessage(true);
                                                    } else {
                                                        message.current.title = "알림";
                                                        message.current.message = `${validationResult.errMsg}`;
                                                        message.current.button = [
                                                            {
                                                                title: "확인",
                                                                action: () => {
                                                                    setShowMessage(false);
                                                                },
                                                                background: "purple",
                                                                textColor: "white",
                                                            },
                                                        ];
                                                        message.current.body = null;
                                                        message.current.showFooter = true;
                                                        message.current.keyboard = true;
                                                        message.current.renderFooter = null;
                                                        setShowMessage(true);
                                                    }
                                                }}>
                                                수정
                                            </Button>
                                            <Button
                                                className={"btn btn-danger btn-lg"}
                                                style={{ width: 100 }}
                                                onClick={() => {
                                                    message.current.title = "알림";
                                                    message.current.message = "삭제하시겠습니까?";
                                                    message.current.button = [
                                                        {
                                                            title: "삭제",
                                                            action: () => {
                                                                setShowMessage(false);
                                                                setShowBackdrop(true);
                                                                deleteEvent({
                                                                    _eventIdx: showItem.contents.event_seq,
                                                                }).then((modifyEventResult) => {
                                                                    if (modifyEventResult.data.res_code === 2) {
                                                                        // 삭제완료
                                                                        props.onResult({
                                                                            actionCode: modifyEventResult.data.res_code,
                                                                            actionName: "del",
                                                                            changedData: {
                                                                                ...showItem,
                                                                            },
                                                                        });
                                                                    } else {
                                                                        // props.onResult({ actionCode: modifyEventResult.data.res_code });
                                                                        toast.error("🤔 에러가 발생되었습니다");
                                                                    }
                                                                });
                                                            },
                                                            background: "red",
                                                            textColor: "white",
                                                        },
                                                        {
                                                            title: "닫기",
                                                            action: () => {
                                                                setShowMessage(false);
                                                            },
                                                            background: "purple",
                                                            textColor: "white",
                                                        },
                                                    ];
                                                    message.current.body = null;
                                                    message.current.showFooter = true;
                                                    message.current.keyboard = true;
                                                    message.current.renderFooter = null;
                                                    setShowMessage(true);
                                                }}>
                                                삭제
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Container>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                        margin="normal"
                        id="date-picker-dialog"
                        name="event_start_date"
                        // disableToolbar
                        okLabel="확인"
                        cancelLabel="취소"
                        style={{ display: "none" }}
                        disablePast={true}
                        value={openCalTag === "start_date" ? showItem.contents.event_date : showItem.contents.event_end_date}
                        onChange={(v) => {
                            if (openCalTag === "start_date") {
                                setShowItem({
                                    contents: {
                                        ...showItem.contents,
                                        event_date: convertDate({ _originDate: v, _convertFormat: "YYYY-MM-DD HH:mm" }),
                                    },
                                });
                            } else {
                                setShowItem({
                                    contents: {
                                        ...showItem.contents,
                                        event_end_date: convertDate({ _originDate: v, _convertFormat: "YYYY-MM-DD HH:mm" }),
                                    },
                                });
                            }
                        }}
                        open={openCal}
                        // onOpen={() =>
                        //     setOpenCal({
                        //         ...openCalTag,
                        //     })
                        // }
                        onClose={() => setOpenCal(false)}
                        ampm={false}
                        disabled={addMode ? false : !editMode}
                    />
                </MuiPickersUtilsProvider>
                <PhotologDialog
                    isOpen={showMessage}
                    toggle={() => setShowMessage(!showMessage)}
                    title={message.current.title}
                    message={message.current.message}
                    body={message.current.message !== null ? null : message.current.body}
                    button={message.current.button}
                    backdrop={"static"}
                    keyboard={message.current.keyboard}
                    showCenter
                    showFooter={message.current.showFooter}
                    renderFooter={message.current.renderFooter}
                />
                <Backdrop
                    className={classes.backdrop}
                    open={showBackdrop}
                    // invisible={true}
                    onClick={() => {}}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        );
    }
};

export default EventDetail;
