import axios from "axios";
import { logout } from "../modules/utils";

export function getStoreList({ _category, _size, _pageNo }) {
    return axios
        .post("/apis/store-info/get-store-list", {
            category: _category,
            size: _size,
            pageNo: _pageNo,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
export function getStoreDetail({ _storeId }) {
    return axios
        .post("/apis/store-info/get-store-detail", {
            storeId: _storeId,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
export function rejectStoreInfo({ _rejectComment, _storeId }) {
    return axios
        .post("/apis/store-info/reject-store-info", {
            rejectComment: _rejectComment,
            storeId: _storeId,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
export function publishStoreInfo({ _storeId, _storeType }) {
    return axios
        .post("/apis/store-info/publish-store-info", {
            storeId: _storeId,
            storeType: _storeType,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
export function setStoreState({ _storeId }) {
    return axios
        .post("/apis/store-info/set-store-state", {
            storeId: _storeId,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
