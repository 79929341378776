// import jQuery from "jquery";
import $ from "jquery";
import { randomInteger } from "../utils";

export function makeStickerSVG({ url, codeArr, name, phoneNum }) {
    return new Promise(function (resolve, reject) {
        $.get(url)
            .then(async function (mapData) {
                let returnArr = [];
                for (var i = 0; i < codeArr.length; i++) {
                    let tempSVG = await makeImageSVG(mapData, codeArr[i], name, phoneNum);
                    if (tempSVG.fileName === "failed") {
                        reject("error");
                        break;
                    } else {
                        returnArr.push(tempSVG);
                    }
                }
                resolve(returnArr);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

function makeImageSVG(mapData, codeArr, name, phoneNum) {
    return new Promise(function (resolve, reject) {
        $.get("../result/0.5/image/" + codeArr + "-image.svg")
            .then(function (standardImage) {
                var imageNode = mapData.getElementById(codeArr + "T").getElementsByTagName("image")[0];
                var imageWidth = imageNode.getAttribute("width");
                var imageHeight = imageNode.getAttribute("height");
                var imageUrl = imageNode.getAttribute("xlink:href");

                var imageRatio = 0;
                var lastPhoneNum = phoneNum.substr(phoneNum.length - 4, 4);

                var pathWidth = Number(standardImage.getElementById("imagePath").getAttribute("path-width-info"));
                var pathHeight = Number(standardImage.getElementById("imagePath").getAttribute("path-height-info"));

                var tempX = pathWidth / imageWidth;
                var tempY = pathHeight / imageHeight;

                if (tempX > tempY) {
                    imageRatio = tempX;
                } else {
                    imageRatio = tempY;
                }

                standardImage.getElementsByTagName("image")[0].setAttribute("xlink:href", imageUrl);
                standardImage.getElementsByTagName("image")[0].setAttribute("width", imageWidth);
                standardImage.getElementsByTagName("image")[0].setAttribute("height", imageHeight);
                standardImage.getElementsByTagName("image")[0].setAttribute("transform", "matrix(" + imageRatio + " 0 0 " + imageRatio + " 10.5 10.5)");

                standardImage.getElementById("orderId").innerHTML = name + lastPhoneNum;

                var newPathId = codeArr + "_" + new Date().getTime() + "_" + lastPhoneNum;

                standardImage.getElementsByTagName("path")[0].setAttribute("id", newPathId);
                standardImage.getElementsByTagName("clipPath")[0].setAttribute("id", newPathId + "_clip");

                standardImage.getElementsByTagName("use")[0].setAttribute("xlink:href", "#" + newPathId);
                standardImage.getElementsByTagName("g")[2].setAttribute("style", "clip-path:url(#" + newPathId + "_clip);");

                standardImage.getElementById("regionName").setAttribute("id", newPathId + "_region");
                standardImage.getElementById("orderId").setAttribute("id", newPathId + "_order");

                var fakeTag = document.createElement("div");
                fakeTag.appendChild(standardImage.documentElement);

                resolve({ fileName: codeArr + "-" + name + "-" + lastPhoneNum + "-" + randomInteger(10000, 99999) + ".svg", svgString: fakeTag.innerHTML });
            })
            .catch((err) => {
                resolve({ fileName: "failed" });
            });
    });
}
