import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import Sidebar from "../components/Sidebar/Sidebar.js";

import { History } from "../modules/utils.js";

import routes from "../routes.js";
import Logo from "../assets/img/brand/logo-with-name.png";
import { me } from "../lib/manager.js";

class Admin extends React.Component {
    constructor() {
        super();
        this.state = {
            getMeStatus: 0,
            getMeResult: null,
        };
    }

    componentDidMount() {
        this.getMe();
    }
    componentDidUpdate(e) {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.mainContent.scrollTop = 0;
    }
    getRoutes(routes) {
        return routes.map((prop, key) => {
            return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
        });
    }
    getBrandText(path) {
        for (let i = 0; i < routes.length; i++) {
            if (this.props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
                return routes[i].name;
            }
        }
        return "";
    }
    getMe() {
        me()
            .then((meResult) => {
                this.setState({ getMeStatus: meResult.data.res_code, getMeResult: meResult.data.result });
            })
            .catch(() => {
                return 0;
            });
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.getMeStatus !== nextState.getMeStatus) {
            if (nextState.getMeStatus !== 2) {
                History.replace("/auth/login");
                window.location.reload();
            } else {
                return true;
            }
        }

        if (this.props !== nextProps) {
            return true;
        }
        return false;
    }
    render() {
        if (this.state.getMeStatus === 2) {
            return (
                <>
                    <Sidebar
                        {...this.props}
                        routes={routes}
                        logo={{
                            innerLink: "/admin/index",
                            imgSrc: Logo,
                            imgAlt: "...",
                        }}
                    />
                    <div className="main-content" ref="mainContent">
                        <AdminNavbar {...this.props} brandText={this.getBrandText(this.props.location.pathname)} managerInfo={this.state.getMeResult} />
                        <Switch>{this.getRoutes(routes)}</Switch>
                    </div>
                </>
            );
        } else {
            return <div></div>;
        }
    }
}

export default Admin;
