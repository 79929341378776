import React from "react";
import PropTypes from "prop-types";
import { Button, Fade, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import "../assets/scss/argon-dashboard-react.scss";
import DaumPostcode from "react-daum-postcode";
export class PostcodeDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            postcode: "",
            address1: "",
            address2: "",
        };
    }
    componentDidMount() {
        this.setState({
            postcode: "",
            address1: "",
            address2: "",
        });
    }
    componentWillUnmount() {}

    componentDidUpdate(prevProps) {}
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.refresh !== undefined) {
            if (this.props.refresh !== nextProps.refresh) {
                return true;
            }
        }
        if (this.props.isOpen !== nextProps.isOpen) {
            return true;
        }

        if (this.state.address1 !== nextState.address1) {
            return true;
        }
        return false;
    }
    render() {
        const { showCenter, large, onEditAction } = this.props;

        return (
            <Modal className={showCenter ? "modal-dialog-centered" : "modal-dialog"} size={large ? "lg" : "sm"} {...this.props}>
                <ModalBody className={"modal-body col-12"}>
                    <div className={"ml-3 mr-3"}>
                        <Row>
                            <DaumPostcode
                                onComplete={(data) => {
                                    this.setState({
                                        address1: data.address,
                                        postcode: data.zonecode,
                                    });
                                }}
                            />
                        </Row>
                        <Row className={"mt-3"}>
                            <Label for="contents_new_user_address1">주소</Label>
                            <Input type="text" name="new_user_address1" id="contents_new_user_address1" value={this.state.address1} disabled />
                        </Row>
                        <Row className={"mt-3"}>
                            <Label for="contents_new_user_address2">상세주소</Label>
                            <Input
                                type="text"
                                name="new_user_address2"
                                id="contents_new_user_address2"
                                onChange={(e) => {
                                    this.setState({
                                        address2: e.target.value,
                                    });
                                }}
                            />
                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter className={"modal-footer"}>
                    <Button
                        style={{ background: "purple", color: "white" }}
                        onClick={() => {
                            if (this.state.postcode !== "" && this.state.address1 !== "" && this.state.address2 !== "") {
                                onEditAction(`(${this.state.postcode}) ${this.state.address1}, ${this.state.address2}`);
                                return this.setState({
                                    postcode: "",
                                    address1: "",
                                    address2: "",
                                });
                            }
                        }}>
                        수정
                    </Button>
                    <Button
                        style={{ background: "red", color: "white" }}
                        onClick={() => {
                            this.props.toggle();
                        }}>
                        닫기
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}
