import axios from "axios";
import { logout } from "../modules/utils";

export function getOrderList({ _paymentCode }) {
    return axios
        .post("/apis/payment-info/get-order-list", {
            paymentCode: _paymentCode,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}

// export function getOrderListOthers({ _paymentCode }) {
//     return axios
//         .post("/apis/contents/getOrderListOthers", {
//             paymentCode: _paymentCode,
//         })
//         .then((result) => {
//             if (result.data.res_code === 1 || result.data.res_code === 3) { logout(); return null }
//             else return result;
//         })
//         .catch((e) => {
//             return null;
//         });
// }

export function modifyOrderState({ _orderList, _orderState }) {
    return axios
        .post("/apis/payment-info/modify-order-state", {
            orderList: _orderList,
            orderState: _orderState,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}

export function modifyPaymentInfo({ _shippingNumber, _orderState, _paymentCode, _userAddress }) {
    return axios
        .post("/apis/payment-info/modify-payment-info", {
            paymentCode: _paymentCode,
            shippingNumber: _shippingNumber,
            orderState: _orderState,
            userAddress: _userAddress,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}

export function getPaymentData({ _category, _size, _pageNo }) {
    return axios
        .post("/apis/payment-info/get-payment-info", {
            category: _category,
            size: _size,
            pageNo: _pageNo,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
