import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container, Row } from "reactstrap";
import AuthFooter from "../components/Footers/AuthFooter.js";

import routes from "../routes.js";
import { History } from "../modules/utils.js";
import { me } from "../lib/manager.js";

class Auth extends React.Component {
    constructor() {
        super();
        this.state = {
            getMeStatus: -1,
        };
    }

    componentDidMount() {
        document.body.classList.add("bg-default");
        this.getMe();
    }
    componentWillUnmount() {
        document.body.classList.remove("bg-default");
    }
    getRoutes(routes) {
        return routes.map((prop, key) => {
            if (prop.layout === "/auth") {
                return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
            } else {
                return null;
            }
        });
    }

    getMe() {
        me()
            .then((meResult) => {
                this.setState({ getMeStatus: meResult.data.res_code });
            })
            .catch(() => {
                return 0;
            });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.getMeStatus !== nextState.getMeStatus) {
            return true;
        }
        return false;
    }

    render() {
        if (this.state.getMeStatus === 2) {
            History.replace("/admin/index");
            window.location.reload();
        } else {
            return (
                <div>
                    <div className="main-content">
                        <div className="header bg-gradient-info py-7 py-lg-8">
                            <div className="separator separator-bottom separator-skew zindex-100">
                                <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
                                    <polygon className="fill-default" points="2560 0 2560 100 0 100" />
                                </svg>
                            </div>
                        </div>
                        {/* Page content */}
                        <Container className="mt--8 pb-5">
                            <Row className="justify-content-center">
                                <Switch>
                                    {this.getRoutes(routes)}
                                    <Redirect from="*" to="/auth/login" />
                                </Switch>
                            </Row>
                        </Container>
                    </div>
                    <AuthFooter />
                </div>
            );
        }
    }
}

export default Auth;
