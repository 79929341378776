import React from "react";

import { convertDate, History, diffDate, convertDateUTC } from "../../modules/utils.js";
import { Card, Col, Container, Form, FormGroup, Input, Label, Row, Button, Collapse } from "reactstrap";
import ToggleSwitch from "../../modules/ToggleSwitch.js";
// import Icon from "../../modules/VectorIcons"
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import lodash from "lodash";
import { MuiPickersUtilsProvider, TimePicker, DateTimePicker } from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";
import "moment/locale/ko";
import { PhotologDialog } from "../../modules/PhotologDialog.js";
import { RiCloseFill } from "react-icons/ri";
import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { insertNewPush } from "../../lib/push.js";

import { toast } from "react-toastify";
const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

class RenderImageFromFile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            image: null,
        };
    }
    readFile(file) {
        let fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onloadend = (r) => {
            this.setState({ image: r.target.result });
        };
    }
    componentDidMount() {
        if (this.props.type === "local") this.readFile(this.props.image);
        else this.setState({ image: this.props.image });
    }
    componentWillUnmount() {}
    componentDidUpdate(prevProps) {}
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.image !== nextProps.image) {
            if (this.props.type === "local") this.readFile(nextProps.image);
            else this.setState({ image: nextProps.image });
            return true;
        }
        if (this.state.image !== nextState.image) {
            // this.readFile(nextProps.image);
            return true;
        }
        return false;
    }

    render() {
        if (this.state.image === null) {
            return <div />;
        } else {
            return (
                <div className={"mt-2"}>
                    <Button
                        style={{ position: "absolute", top: this.props.type === "local" ? 80 : 100, right: 20 }}
                        className="btn btn-danger"
                        size="sm"
                        onClick={() => {
                            this.props.onClose();
                            this.setState({ image: null });
                        }}>
                        <RiCloseFill size={15} />
                    </Button>
                    <img key={`image-push`} src={this.state.image} alt="" style={{ ...this.props.imageStyle, width: "100%" }} className={"border border-2"} />
                </div>
            );
        }
    }
}

const PushDetail = (props) => {
    const [showItem, setShowItem] = React.useState(null);
    const [addMode, setAddMode] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [tempPushImageType, setTempPushImageType] = React.useState("local");
    const [tempPushImageLocal, setTempPushImageLocal] = React.useState(null);
    const [tempPushImageRemote, setTempPushImageRemote] = React.useState(null);
    const [tempPushTags, setTempPushTags] = React.useState([]);
    const [openNotUseItem, setOpenNotUseItem] = React.useState(false);
    const [tempPushTagType, setTempPushTagType] = React.useState(null);

    const [openCal, setOpenCal] = React.useState(false);
    const [openCalTag, setOpenCalTag] = React.useState("start_date");

    const [showMessage, setShowMessage] = React.useState(false);
    const [showBackdrop, setShowBackdrop] = React.useState(false);
    const message = React.useRef({
        tag: "",
        title: "",
        message: null,
        body: null,
        button: [],
        keyboard: true,
        toggle: true,
        renderFooter: null,
        showFooter: true,
    });
    const PUSH_TAGS_LIST = [
        { name: "10대", value: "10" },
        { name: "20대", value: "20" },
        { name: "30대", value: "30" },
        { name: "40대", value: "40" },
        { name: "남자", value: "male" },
        { name: "여자", value: "female" },
    ];
    const classes = useStyles();
    React.useEffect(() => {
        if (props === undefined) {
            History.replace("/admin/push");
            window.location.reload();
            return;
        } else {
            if (showItem === null) {
                if (props.mode === "add") {
                    setShowItem({
                        contents: {
                            push_seq: null, // 푸시 번호
                            push_title: null, // 메시지 제목
                            push_sub_title: null, // 메시지 서브 제목
                            push_message: null, // 메시지 내용
                            push_image: null, // 메시지 내 이미지
                            push_url: null, // 메시지 관련 url
                            push_type: null, // 타입 (direct || reserved)
                            push_tag: null, // 태그 (20대, 30대 등 태그, 현재는 ‘all’ 로 통일, 체크박스로 표시)
                            push_send_time: null, // 푸시 전송 시간 (direct일 경우, 현재 시각)
                            push_state: null, // 푸시 상태 (registered, sending, sended, error)
                            push_open_count: null, // 푸시 열람 횟수
                            push_send_success: null, // 성공 개수
                            push_send_failed: null, // 실패 개수
                            push_navigation_type: null, // 푸시 이동 타입
                            push_navigation_category: null, // 푸시 이동 카테고리
                            push_data_seq: null, // 푸시 관련 데이터 시퀀스
                            push_data_code: null, // 푸시 관련 데이터 코드
                        },
                    });
                    setAddMode(true);
                } else {
                    setShowItem({
                        contents: props.getContentsItem,
                    });
                    // setTempPushImageLocal(props.getContentsItem.push_image);
                    setTempPushImageType(props.getContentsItem.push_image === null ? null : "remote");
                    setTempPushImageRemote(props.getContentsItem.push_image);
                    setTempPushTagType(props.getContentsItem.push_tag === "all" ? "all" : "select");
                }
            }
        }
    }, [showItem, props]);

    const doValidation = () => {
        if (showItem.contents.push_title === null || showItem.contents.push_title.length === 0) {
            return {
                errCode: 1,
                errMsg: "제목 입력란을 확인하세요",
            };
        }
        // // push_sub_title

        if (showItem.contents.push_type === null || showItem.contents.push_type.length === 0) {
            return {
                errCode: 1,
                errMsg: "발송방법 입력란을 확인하세요",
            };
        } else {
            if (showItem.contents.push_type === "reserved") {
                // 발송시간이 현재 시간보다 늦은 경우
                // const diffTime = diffDate({ _date1: new Date(), _date2: showItem.contents.push_send_time });
                let diffTime = diffDate({ _date1: new Date(), _date2: showItem.contents.push_send_time });

                if (diffTime.h <= -1 || diffTime.m <= 0) {
                    return {
                        errCode: 2,
                        errMsg: `예약발송 시간을 확인하세요.`,
                    };
                }
            }
        }
        if (showItem.contents.push_tag === null || showItem.contents.push_tag.length === 0) {
            return {
                errCode: 1,
                errMsg: "발송대상 입력란을 확인하세요",
            };
        } else {
            if (showItem.contents.push_tag === "select") {
                if (tempPushTags === null || tempPushTags.length === 0) {
                    return {
                        errCode: 2,
                        errMsg: "발송대상을 선택하세요",
                    };
                } else {
                    setShowItem({
                        contents: {
                            ...showItem.contents,
                            push_tag: tempPushTags.join(","),
                        },
                    });
                }
            }
        }
        if (showItem.contents.push_message === null || showItem.contents.push_message.length === 0) {
            return {
                errCode: 1,
                errMsg: "내용 입력란을 확인하세요",
            };
        }
        return {
            errCode: 0,
            errMsg: "문제 발견되지 않음",
        };
    };

    const handlePushTag = (e) => {
        const foundIndex = lodash.findIndex(tempPushTags, e.target.name);
        foundIndex === -1 ? setTempPushTags(tempPushTags.concat({ [e.target.name]: e.target.value })) : setTempPushTags(tempPushTags.filter((item, index) => index !== foundIndex));
    };

    if (showItem === null) {
        return <div></div>;
    } else {
        return (
            <>
                <Container className="mt--7">
                    <Row>
                        <Col className={"col-12 mx-auto mt-6 m-3"}></Col>
                    </Row>
                    <Card className="shadow border-0 pb-4">
                        <Row className={"ml-3 mr-3"}>
                            <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                <Label for="contents_push_title">제목</Label>
                                <Input
                                    type="text"
                                    name="push_title"
                                    id="contents_push_title"
                                    value={showItem.contents.push_title}
                                    disabled={addMode ? false : !editMode}
                                    onChange={(e) => {
                                        setShowItem({
                                            contents: {
                                                ...showItem.contents,
                                                push_title: e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                            <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                <Label for="contents_push_sub_title">서브 제목</Label>
                                <Input
                                    type="text"
                                    name="push_sub_title"
                                    id="contents_push_sub_title"
                                    value={showItem.contents.push_sub_title}
                                    disabled={addMode ? false : !editMode}
                                    onChange={(e) => {
                                        setShowItem({
                                            contents: {
                                                ...showItem.contents,
                                                push_sub_title: e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                            <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"} hidden={addMode ? false : editMode && showItem.contents.push_image !== null ? false : true}>
                                <Label for="contents_tempPushImageType">첨부 이미지</Label>
                                <Row className={"ml-1"} id="contents_tempPushImageType">
                                    <FormControl component="fieldset" disabled={addMode ? false : !editMode}>
                                        <RadioGroup
                                            name="tempPushImageType"
                                            row={true}
                                            value={tempPushImageType}
                                            onChange={(e) => {
                                                setTempPushImageType(e.target.value);
                                            }}>
                                            <FormControlLabel value={"local"} control={<Radio />} label="이미지 파일" />
                                            <FormControlLabel value={"remote"} control={<Radio />} label="이미지 URL" />
                                        </RadioGroup>
                                    </FormControl>
                                </Row>
                            </Col>
                            {tempPushImageType === "local" ? (
                                <Col
                                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}
                                    hidden={addMode ? false : editMode && showItem.contents.push_image !== null ? false : true}>
                                    <Label for="contents_push_image_local">이미지 파일</Label>
                                    <Input
                                        type="file"
                                        name="push_image"
                                        id="contents_push_image_local"
                                        value={showItem.contents.push_image}
                                        disabled={addMode ? false : !editMode}
                                        onChange={(e) => {
                                            setTempPushImageLocal(e.target.files[0]);
                                            // setShowItem({
                                            //     contents: {
                                            //         ...showItem.contents,
                                            //         push_image: e.target.value,
                                            //     },
                                            // });
                                        }}
                                    />
                                    {tempPushImageLocal !== null && (
                                        <RenderImageFromFile
                                            type="local"
                                            image={tempPushImageLocal}
                                            className={"border border-2"}
                                            onClose={() => {
                                                setTempPushImageLocal(null);
                                            }}
                                        />
                                    )}
                                </Col>
                            ) : (
                                <Col
                                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}
                                    hidden={addMode ? false : editMode && showItem.contents.push_image !== null ? false : true}>
                                    <Label for="contents_push_image_remote">이미지 URL</Label>
                                    <Input
                                        type="text"
                                        name="push_image"
                                        id="contents_push_image_remote"
                                        value={showItem.contents.push_image}
                                        disabled={addMode ? false : !editMode}
                                        onChange={(e) => {
                                            setShowItem({
                                                contents: {
                                                    ...showItem.contents,
                                                    push_url: e.target.value,
                                                },
                                            });
                                            setTempPushImageRemote(e.target.value.length === 0 ? null : e.target.value);
                                        }}
                                    />
                                    {tempPushImageRemote !== null && (
                                        <RenderImageFromFile
                                            type="remote"
                                            image={tempPushImageRemote}
                                            className={"border border-2"}
                                            onClose={() => {
                                                // setTempPushImage(null);
                                                setTempPushImageRemote(null);
                                            }}
                                        />
                                    )}
                                </Col>
                            )}

                            <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-4"}>
                                <Label for="contents_push_url">URL</Label>
                                <Input
                                    type="text"
                                    name="push_url"
                                    id="contents_push_url"
                                    value={showItem.contents.push_url}
                                    disabled={addMode ? false : !editMode}
                                    onChange={(e) => {
                                        setShowItem({
                                            contents: {
                                                ...showItem.contents,
                                                push_url: e.target.value,
                                            },
                                        });
                                        setTempPushImageRemote(e.target.value.length === 0 ? null : e.target.value);
                                    }}
                                />
                            </Col>
                            <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                <Label for="contents_push_type">발송방법</Label>
                                <Row className={"ml-1"} id="contents_push_type">
                                    <FormControl component="fieldset" disabled={addMode ? false : !editMode}>
                                        <RadioGroup
                                            name="push_type"
                                            row={true}
                                            value={showItem.contents.push_type === null ? "" : showItem.contents.push_type}
                                            onChange={(e) => {
                                                if (e.target.value === "direct") {
                                                    setShowItem({
                                                        contents: {
                                                            ...showItem.contents,
                                                            push_type: e.target.value,
                                                            push_send_time: new Date(),
                                                        },
                                                    });
                                                } else {
                                                    setShowItem({
                                                        contents: {
                                                            ...showItem.contents,
                                                            push_type: e.target.value,
                                                            push_send_time: null,
                                                        },
                                                    });
                                                }
                                            }}>
                                            <FormControlLabel value={"direct"} control={<Radio />} label="즉시발송" />
                                            <FormControlLabel value={"reserved"} control={<Radio />} label="예약발송" />
                                        </RadioGroup>
                                    </FormControl>
                                </Row>
                            </Col>
                            <Col
                                className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto"}
                                hidden={addMode ? (showItem.contents.push_type === "reserved" ? false : true) : showItem.contents.push_send_time !== null ? false : true}>
                                <Label for="contents_push_send_time">발송시간</Label>

                                {!addMode ? (
                                    <Input
                                        type="text"
                                        name="push_url"
                                        id="contents_push_url"
                                        value={convertDate({ _originDate: showItem.contents.push_send_time, _convertFormat: "YYYY-MM-DD HH:mm" })}
                                        disabled={addMode ? false : !editMode}
                                    />
                                ) : (
                                    <Row className={"ml-1 mt--2"}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <DateTimePicker
                                                margin="dense"
                                                id="contents_push_send_time"
                                                name="push_send_time"
                                                // disableToolbar
                                                okLabel="확인"
                                                cancelLabel="취소"
                                                style={{}}
                                                value={convertDate({ _originDate: showItem.contents.push_send_time, _convertFormat: "YYYY-MM-DD HH:mm" })}
                                                disablePast={addMode ? true : false}
                                                onChange={(v) => {
                                                    setShowItem({
                                                        contents: {
                                                            ...showItem.contents,
                                                            push_send_time: convertDate({ _originDate: v, _convertFormat: "YYYY-MM-DD HH:mm" }),
                                                        },
                                                    });
                                                }}
                                                open={openCal}
                                                onOpen={() => setOpenCal(true)}
                                                onClose={() => setOpenCal(false)}
                                                ampm={false}
                                                disabled={addMode ? false : !editMode}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Row>
                                )}
                            </Col>

                            <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                <Label for="contents_tempPushTagType">발송대상</Label>
                                <Row className={"ml-1"} id="contents_tempPushTagType">
                                    <FormControl component="fieldset" disabled={addMode ? false : !editMode}>
                                        <RadioGroup
                                            name="tempPushTagType"
                                            row={true}
                                            value={tempPushTagType}
                                            onChange={(e) => {
                                                setTempPushTagType(e.target.value);
                                                if (e.target.value === "all") {
                                                    setShowItem({
                                                        contents: {
                                                            ...showItem.contents,
                                                            push_tag: e.target.value,
                                                        },
                                                    });
                                                } else {
                                                    setShowItem({
                                                        contents: {
                                                            ...showItem.contents,
                                                            push_tag: "select",
                                                        },
                                                    });
                                                }
                                            }}>
                                            <FormControlLabel value={"all"} control={<Radio />} label="전체발송" />
                                            <FormControlLabel value={"select"} control={<Radio />} label="대상선택" />
                                        </RadioGroup>
                                    </FormControl>
                                </Row>
                            </Col>
                            {tempPushTagType === "select" && (
                                <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                    <Label for="contents_push_tag">발송대상선택</Label>
                                    <Row className={"ml-1"} id="contents_push_tag">
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormGroup>
                                                {PUSH_TAGS_LIST.map((item, index) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={`push_tag_${index}`}
                                                            control={<Checkbox onChange={handlePushTag} name={item.name} value={item.value} />}
                                                            label={item.name}
                                                        />
                                                    );
                                                })}
                                            </FormGroup>
                                        </FormControl>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                        <Row className={"ml-3 mr-3 mt-3"}>
                            <Col className={"col-12 mx-auto"}>
                                <Label for="contents_push_message">내용</Label>
                                <Input
                                    type="textarea"
                                    name="push_message"
                                    id="contents_push_message"
                                    value={showItem.contents.push_message}
                                    disabled={addMode ? false : !editMode}
                                    onChange={(e) => {
                                        setShowItem({
                                            contents: {
                                                ...showItem.contents,
                                                push_message: e.target.value,
                                            },
                                        });
                                    }}
                                    style={{ minHeight: 100 }}
                                />
                            </Col>
                        </Row>
                        <div className={"ml-3 mr-3 mt-3 shadow-lg p-3 bg-body rounded border"} hidden={true}>
                            <Row onClick={() => setOpenNotUseItem((prev) => !prev)}>
                                <Col className={"col-10"}>
                                    <span>미사용 항목</span>
                                </Col>
                                <Col className={"col-2"}>
                                    <span className={"float-right"}>{openNotUseItem ? <MdExpandLess /> : <MdExpandMore />}</span>
                                </Col>
                            </Row>
                            <Collapse isOpen={openNotUseItem} className={"mt-3"}>
                                <Row className={""}>
                                    <Col className={"col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                        <Label for="contents_push_navigation_type">이동 타입</Label>
                                        <Input type="text" name="push_navigation_type" id="contents_push_navigation_type" value={showItem.contents.push_navigation_type} disabled />
                                    </Col>
                                    <Col className={"col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                        <Label for="contents_push_navigation_category">이동 카테고리</Label>
                                        <Input type="text" name="push_navigation_category" id="contents_push_navigation_category" value={showItem.contents.push_navigation_category} disabled />
                                    </Col>
                                    <Col className={"col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                        <Label for="contents_push_data_seq">데이터 시퀀스</Label>
                                        <Input type="text" name="push_data_seq" id="contents_push_data_seq" value={showItem.contents.push_data_seq} disabled />
                                    </Col>
                                    <Col className={"col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                        <Label for="contents_push_data_code">데이터 코드</Label>
                                        <Input type="text" name="push_data_code" id="contents_push_data_code" value={showItem.contents.push_data_code} disabled />
                                    </Col>
                                </Row>
                            </Collapse>
                        </div>
                        <Row className={"mt-8"} hidden={addMode ? false : true}>
                            <Col className={"col-11 mx-auto"}>
                                <div className={"float-right"}>
                                    <Button
                                        className={"btn btn-default btn-lg"}
                                        style={{ width: 100 }}
                                        onClick={() => {
                                            const validationResult = doValidation();

                                            if (validationResult.errCode === 0) {
                                                message.current.title = "알림";
                                                message.current.message = "계속 진행하시겠습니까?";
                                                message.current.button = [
                                                    {
                                                        title: "추가",
                                                        action: () => {
                                                            setShowMessage(false);
                                                            insertNewPush({
                                                                _pushTitle: showItem.contents.push_title,
                                                                _pushSubTitle: showItem.contents.push_sub_title,
                                                                _pushMessage: showItem.contents.push_message,
                                                                _pushImage: showItem.contents.push_image,
                                                                _pushUrl: showItem.contents.push_url,
                                                                _pushType: showItem.contents.push_type,
                                                                _pushTag: showItem.contents.push_tag,
                                                                _pushSendTime: showItem.contents.push_send_time,
                                                                _pushNavigationType: showItem.contents.push_navigation_type,
                                                                _pushNavigationCategory: showItem.contents.push_navigation_category,
                                                                _pushDataSeq: showItem.contents.push_data_seq,
                                                                _pushDataCode: showItem.contents.push_data_code,
                                                            }).then((insertPopupResult) => {
                                                                if (insertPopupResult.data.res_code === 2) {
                                                                    toast.success("🎉 완료되었습니다");
                                                                    setTimeout(() => {
                                                                        window.location.reload();
                                                                    }, 2500);
                                                                } else {
                                                                    // props.onResult({ actionCode: insertPopupResult.data.res_code });
                                                                    toast.error("🤔 에러가 발생되었습니다");
                                                                }
                                                            });
                                                        },
                                                        background: "red",
                                                        textColor: "white",
                                                    },
                                                    {
                                                        title: "닫기",
                                                        action: () => {
                                                            setShowMessage(false);
                                                        },
                                                        background: "purple",
                                                        textColor: "white",
                                                    },
                                                ];
                                                message.current.body = null;
                                                message.current.showFooter = true;
                                                message.current.keyboard = true;
                                                message.current.renderFooter = null;
                                                setShowMessage(true);
                                            } else {
                                                message.current.title = "알림";
                                                message.current.message = `${validationResult.errMsg}`;
                                                message.current.button = [
                                                    {
                                                        title: "확인",
                                                        action: () => {
                                                            setShowMessage(false);
                                                        },
                                                        background: "purple",
                                                        textColor: "white",
                                                    },
                                                ];
                                                message.current.body = null;
                                                message.current.showFooter = true;
                                                message.current.keyboard = true;
                                                message.current.renderFooter = null;
                                                setShowMessage(true);
                                            }
                                        }}>
                                        전송
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Container>

                <PhotologDialog
                    isOpen={showMessage}
                    toggle={() => setShowMessage(!showMessage)}
                    title={message.current.title}
                    message={message.current.message}
                    body={message.current.message !== null ? null : message.current.body}
                    button={message.current.button}
                    backdrop={"static"}
                    keyboard={message.current.keyboard}
                    showCenter
                    showFooter={message.current.showFooter}
                    renderFooter={message.current.renderFooter}
                />
                <Backdrop
                    className={classes.backdrop}
                    open={showBackdrop}
                    // invisible={true}
                    onClick={() => {}}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        );
    }
};

export default PushDetail;
