import React, { Component } from "react";

import { convertDate, History } from "../../modules/utils.js";
import NormalHeader from "../../components/Headers/NormalHeader.js";
import { Card, Col, Container, Form, FormGroup, Input, Label, Row, Button } from "reactstrap";
import ToggleSwitch from "../../modules/ToggleSwitch.js";
// import Icon from "../../modules/VectorIcons"

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import lodash from "lodash";
import { MuiPickersUtilsProvider, TimePicker, DateTimePicker } from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";
import "moment/locale/ko";
import { PhotologDialog } from "../../modules/PhotologDialog.js";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const UsersDetail = (props) => {
    const [showItem, setShowItem] = React.useState(null);
    const [addMode, setAddMode] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);

    const [openCal, setOpenCal] = React.useState(false);
    const [openCalTag, setOpenCalTag] = React.useState("user_regist_date");
    const [showMessage, setShowMessage] = React.useState(false);
    const [showBackdrop, setShowBackdrop] = React.useState(false);
    const message = React.useRef({
        tag: "",
        title: "",
        message: null,
        body: null,
        button: [],
        keyboard: true,
        toggle: true,
        renderFooter: null,
        showFooter: true,
    });

    const classes = useStyles();
    React.useEffect(() => {
        if (props === undefined) {
            History.replace("/admin/users");
            window.location.reload();
            return;
        } else {
            if (showItem === null) {
                if (props.mode === "add") {
                    setShowItem({
                        contents: {
                            user_id: null,
                            user_login_type: null,
                            user_migration_yn: 0,
                            user_birthday: null,
                            user_gender: null,
                            user_nickname: null,
                            user_regist_date: null,
                            user_validation_yn: 0,
                        },
                    });
                    setAddMode(true);
                } else {
                    setShowItem({
                        contents: props.getContentsItem,
                    });
                }
            }
        }
    }, [showItem, props]);

    if (showItem === null) {
        return <div></div>;
    } else {
        return (
            <>
                <Container className="mt--7">
                    <Row>
                        <Col className={"col-12 mx-auto mt-6 m-3"}>
                            <Label for="contents_editMode" style={{ color: "black" }}>
                                수정모드
                            </Label>
                            <Row className={"ml-0"}>
                                <ToggleSwitch
                                    id="contents_editMode"
                                    checked={editMode}
                                    onChange={(v) => {
                                        if (editMode === true) {
                                            // 수정모드 가능->불가능
                                            setShowItem({
                                                contents: props.getContentsItem,
                                            });
                                        }
                                        setEditMode(v);
                                    }}
                                    optionLabels={["가능", "불가"]}
                                />
                            </Row>
                        </Col>
                    </Row>
                    <Card className="shadow border-0 pb-4">
                        <Row className={"m-3"}>
                            <Col className={"col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mx-auto mt-3"}>
                                <Label for="contents_user_id">ID</Label>
                                <Input type="text" name="user_id" id="contents_user_id" value={showItem.contents.user_id} disabled />
                            </Col>
                            <Col className={"col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mx-auto mt-3"}>
                                <Label for="contents_user_nickname">닉네임</Label>
                                <Input
                                    type="text"
                                    name="user_nickname"
                                    id="contents_user_nickname"
                                    value={showItem.contents.user_nickname}
                                    disabled={!editMode}
                                    onChange={(e) => {
                                        setShowItem({
                                            contents: {
                                                ...showItem.contents,
                                                user_nickname: e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                            <Col className={"col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mx-auto mt-3"}>
                                <Label for="contents_user_birthday">생년월일</Label>
                                <Input
                                    type="text"
                                    name="user_birthday"
                                    id="contents_user_birthday"
                                    value={convertDate({ _originDate: showItem.contents.user_birthday, _convertFormat: "YYYY.MM.DD" })}
                                    disabled={!editMode}
                                    onClick={() => {
                                        // setOpenCal({ status: true, tag: "user_birthday" });
                                        setOpenCalTag("user_birthday");
                                        setTimeout(() => {
                                            setOpenCal(true);
                                        }, 300);
                                    }}
                                />
                            </Col>
                            <Col className={"col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mx-auto mt-3"}>
                                <Label for="contents_user_regist_dates">가입일시</Label>
                                <Input
                                    type="text"
                                    name="user_regist_date"
                                    id="contents_user_regist_date"
                                    value={convertDate({ _originDate: showItem.contents.user_regist_date, _convertFormat: "YYYY-MM-DD HH:mm:ss" })}
                                    disabled={!editMode}
                                    onClick={() => {
                                        // setOpenCal({ status: true, tag: "user_regist_date" });
                                        setOpenCalTag("user_regist_date");
                                        setTimeout(() => {
                                            setOpenCal(true);
                                        }, 300);
                                    }}
                                />
                            </Col>
                            <Col className={"col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                <Label for="contents_user_login_type">가입방법</Label>
                                <Input type="text" name="user_login_type" id="contents_user_login_type" value={showItem.contents.user_login_type} disabled={!editMode} />
                            </Col>
                            <Col className={"col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                <Label for="contents_user_gender">성별</Label>
                                <Row className={"ml-0 mt-2"}>
                                    <ToggleSwitch
                                        id="contents_user_gender"
                                        checked={showItem.contents.user_gender === "M" ? true : false}
                                        onChange={(v) => {
                                            if (showItem.contents.user_gender === "M") {
                                                setShowItem({
                                                    contents: {
                                                        ...showItem.contents,
                                                        user_gender: "M",
                                                    },
                                                });
                                            } else {
                                                if (showItem.contents.user_gender === "F") {
                                                    setShowItem({
                                                        contents: {
                                                            ...showItem.contents,
                                                            user_gender: "F",
                                                        },
                                                    });
                                                }
                                            }
                                        }}
                                        optionLabels={["여", "남"]}
                                        disabled={!editMode}
                                    />
                                </Row>
                            </Col>
                            <Col className={"col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                <Label for="contents_user_migration_yn">마이그레이션</Label>
                                <Row className={"ml-0 mt-2"}>
                                    <ToggleSwitch
                                        id="contents_user_migration_yn"
                                        checked={showItem.contents.user_migration_yn === 1 ? true : false}
                                        onChange={(v) => {
                                            if (showItem.contents.user_migration_yn === 1) {
                                                setShowItem({
                                                    contents: {
                                                        ...showItem.contents,
                                                        user_migration_yn: 0,
                                                    },
                                                });
                                            } else {
                                                if (showItem.contents.user_migration_yn === 0) {
                                                    setShowItem({
                                                        contents: {
                                                            ...showItem.contents,
                                                            user_migration_yn: 1,
                                                        },
                                                    });
                                                }
                                            }
                                        }}
                                        optionLabels={["Y", "N"]}
                                        disabled={!editMode}
                                    />
                                </Row>
                            </Col>
                            <Col className={"col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                <Label for="contents_user_validation_yn">인증</Label>
                                <Row className={"ml-0 mt-2"}>
                                    <ToggleSwitch
                                        id="contents_user_validation_yn"
                                        checked={showItem.contents.user_validation_yn === 1 ? true : false}
                                        onChange={(v) => {
                                            if (showItem.contents.user_validation_yn === 1) {
                                                setShowItem({
                                                    contents: {
                                                        ...showItem.contents,
                                                        user_validation_yn: 0,
                                                    },
                                                });
                                            } else {
                                                if (showItem.contents.user_validation_yn === 0) {
                                                    setShowItem({
                                                        contents: {
                                                            ...showItem.contents,
                                                            user_validation_yn: 1,
                                                        },
                                                    });
                                                }
                                            }
                                        }}
                                        optionLabels={["Y", "N"]}
                                        disabled={!editMode}
                                    />
                                </Row>
                            </Col>
                            <Col className={"col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                <Label for="contents_user_alarm_yn">알림설정</Label>
                                <Row className={"ml-0 mt-2"}>
                                    <ToggleSwitch
                                        id="contents_user_alarm_yn"
                                        checked={showItem.contents.user_alarm_yn === 1 ? true : false}
                                        onChange={(v) => {
                                            if (showItem.contents.user_alarm_yn === 1) {
                                                setShowItem({
                                                    contents: {
                                                        ...showItem.contents,
                                                        user_alarm_yn: 0,
                                                    },
                                                });
                                            } else {
                                                if (showItem.contents.user_alarm_yn === 0) {
                                                    setShowItem({
                                                        contents: {
                                                            ...showItem.contents,
                                                            user_alarm_yn: 1,
                                                        },
                                                    });
                                                }
                                            }
                                        }}
                                        optionLabels={["Y", "N"]}
                                        disabled={!editMode}
                                    />
                                </Row>
                            </Col>
                        </Row>

                        <Row className={"mt-8"} hidden={true}>
                            <Col className={"col-11 mx-auto"}>
                                <div className={"float-right"}>
                                    <Button
                                        className={"btn btn-default btn-lg"}
                                        style={{ width: 100 }}
                                        onClick={() => {
                                            message.current.title = "알림";
                                            message.current.message = "수정하시겠습니까?";
                                            message.current.button = [
                                                {
                                                    title: "수정",
                                                    action: () => {
                                                        setShowMessage(false);
                                                        setShowBackdrop(true);

                                                        // modifyManagerContents({_contents: showItem.contents}).then((modifyManagerContentsResult)=>{

                                                        // })
                                                    },
                                                    background: "red",
                                                    textColor: "white",
                                                },
                                                {
                                                    title: "닫기",
                                                    action: () => {
                                                        setShowMessage(false);
                                                    },
                                                    background: "purple",
                                                    textColor: "white",
                                                },
                                            ];
                                            message.current.body = null;
                                            message.current.showFooter = true;
                                            message.current.keyboard = true;
                                            message.current.renderFooter = null;
                                            setShowMessage(true);
                                        }}>
                                        수정
                                    </Button>
                                    <Button className={"btn btn-danger btn-lg"} style={{ width: 100 }} onClick={() => {}}>
                                        삭제
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Container>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                        margin="normal"
                        id="date-picker-dialog"
                        name="popup_start_date"
                        // disableToolbar
                        okLabel="확인"
                        cancelLabel="취소"
                        style={{ display: "none" }}
                        value={openCalTag === "user_regist_date" ? showItem.contents.user_regist_date : showItem.contents.user_birthday}
                        onChange={(v) => {
                            if (openCalTag === "user_regist_date") {
                                setShowItem({
                                    contents: {
                                        ...showItem.contents,
                                        popup_start_date: convertDate({ _originDate: v, _convertFormat: "YYYY-MM-DD HH:mm" }),
                                    },
                                });
                            } else {
                                setShowItem({
                                    contents: {
                                        ...showItem.contents,
                                        user_birthday: convertDate({ _originDate: v, _convertFormat: "YYYY-MM-DD HH:mm" }),
                                    },
                                });
                            }
                        }}
                        open={openCal}
                        // onOpen={() =>
                        //     setOpenCal({
                        //         ...openCalTag,
                        //     })
                        // }
                        onClose={() => setOpenCal(false)}
                        ampm={false}
                        disabled={!editMode}
                    />
                </MuiPickersUtilsProvider>
                <PhotologDialog
                    isOpen={showMessage}
                    toggle={() => setShowMessage(!showMessage)}
                    title={message.current.title}
                    message={message.current.message}
                    body={message.current.message !== null ? null : message.current.body}
                    button={message.current.button}
                    backdrop={"static"}
                    keyboard={message.current.keyboard}
                    showCenter
                    showFooter={message.current.showFooter}
                    renderFooter={message.current.renderFooter}
                />
                <Backdrop
                    className={classes.backdrop}
                    open={showBackdrop}
                    // invisible={true}
                    onClick={() => {}}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        );
    }
};

export default UsersDetail;
