import React, { Component } from "react";
import PropTypes from "prop-types";
import lodash, { size } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory, { PaginationProvider, PaginationListStandalone, PaginationTotalStandalone } from "react-bootstrap-table2-paginator";
import { convertDate, numberWithComma } from "./utils";
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import { Backdrop, CircularProgress } from "@material-ui/core";

export class PhotologTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowsPerPage: 10,
            totalSize: 0,
            nowPageIndex: 1,
            contentsData: null,
            contentsDataOrigin: null,
            sizePerPageOpen: false,
            modalParams: null,
            showBigModal: false,
            selectedRows: [],
            showBackdrop: false,
        };
        this.doGetContents = this.doGetContents.bind(this);
        this.defaultTableOptions = this.defaultTableOptions.bind(this);
        this.rowEvents = this.rowEvents.bind(this);
    }

    doGetContents({ _size, _pageNo }) {
        this.props.getData({ _category: this.props.category, _size: _size, _pageNo: _pageNo }).then((getContentsResult) => {
            if (getContentsResult !== null && getContentsResult.data.res_code === 2) {
                if (_pageNo === 1) {
                    const newData = lodash.uniqBy(getContentsResult.data.result, this.props.keyField);
                    this.setState({
                        contentsData: newData,
                        contentsDataOrigin: newData,
                        totalSize: getContentsResult.data.result[0] === undefined ? 0 : getContentsResult.data.result[0].total_count,
                    });
                } else {
                    const newData = lodash.uniqBy(this.state.contentsData.concat(getContentsResult.data.result), this.props.keyField);
                    this.setState({
                        contentsData: newData,
                        contentsDataOrigin: newData,
                        totalSize: getContentsResult.data.result[0] === undefined ? 0 : getContentsResult.data.result[0].total_count,
                    });
                }
            }
        });
    }

    defaultTableOptions({ _totalSize, _sizePerPage }) {
        return {
            totalSize: _totalSize,
            sizePerPage: _sizePerPage,
            custom: true,
            hideSizePerPage: false,
            // hidePageListOnlyOnePage: false,
            hidePageListOnlyOnePage: true,
            paginationSize: 5,
            pageStartIndex: 1,
            alwaysShowAllBtns: true,
            withFirstAndLast: false,
            // hideSizePerPage: true,
            // hidePageListOnlyOnePage: true,
            firstPageText: "First",
            prePageText: "Back",
            nextPageText: "Next",
            lastPageText: "Last",
            nextPageTitle: "First page",
            prePageTitle: "Pre page",
            firstPageTitle: "Next page",
            lastPageTitle: "Last page",
            showTotal: true,
            // paginationTotalRenderer: customTotal,
            disablePageTitle: true,
            paginationTotalRenderer: (from, to, size) => (
                <div>
                    <Button className="btn btn-primary" onClick={() => {}}>
                        전체 데이터 : {numberWithComma(size)}
                    </Button>
                </div>
            ),
            onSizePerPageChange: (sizePerPage, page) => {
                if (sizePerPage >= this.state.contentsData.length) {
                    // sizePerPage가 현재 갖고있는 데이터의 수보다 많이 측정된 경우, 더 필요한 데이터를 가져온다
                    this.doGetContents({ _size: sizePerPage, _pageNo: page });
                }
                this.setState({
                    rowsPerPage: sizePerPage,
                    nowPageIndex: page === null ? (Math.round(this.state.contentsData.length / sizePerPage) === 0 ? 1 : Math.round(this.state.contentsData.length / sizePerPage)) : page,
                });
            },
            onPageChange: (page, sizePerPage) => {
                const lastIndex = (page - 1) * sizePerPage;

                if (this.state.contentsData.length === 0) {
                    // do nothing
                } else {
                    if (lastIndex >= this.state.contentsDataOrigin.length) {
                        // 받아온 컨텐츠보다 보여질 최상단의 인덱스번호가 더 높은 경우 (데이터부족)
                        if (page - this.state.nowPageIndex > 1) {
                            // "페이지를 2페이지 이상 넘어간 경우 ";
                            this.doGetContents({ _size: page * sizePerPage, _pageNo: 1 });
                        } else {
                            // "페이지를 1페이지 넘어간 경우 ";
                            this.doGetContents({ _size: sizePerPage, _pageNo: page });
                        }
                    }

                    this.setState({
                        rowsPerPage: sizePerPage,
                        nowPageIndex: page === null ? (Math.round(this.state.contentsData.length / sizePerPage) === 0 ? 1 : Math.round(this.state.contentsData.length / sizePerPage)) : page,
                    });
                }
            },
        };
    }

    setModalParams(mode = "view", rowItem, detail = null) {
        this.setState({
            modalParams: {
                mode: mode,
                getContentsItem: rowItem,
                getContentsItemDetail: detail,
                onResult: ({ actionCode, actionName, changedData }) => {
                    if (actionCode === 2) {
                        if (actionName === "edit") {
                            this.setState({
                                contentsData: this.state.contentsData.map((item, index) => {
                                    if (item[this.props.keyField] === rowItem[this.props.keyField]) {
                                        return changedData.contents;
                                    } else {
                                        return item;
                                    }
                                }),
                                showBigModal: false,
                            });

                            toast.success("🎉 수정되었습니다");
                        } else if (actionName === "del") {
                            this.setState({
                                contentsData: this.state.contentsData.filter((item) => item[this.props.keyField] !== rowItem[this.props.keyField]),
                                showBigModal: false,
                            });
                            toast.success("🎉 삭제되었습니다");
                        }
                    } else {
                        toast.error("🤔 에러가 발생되었습니다");
                    }
                },
            },
            showBigModal: true,
        });
    }

    rowEvents(rowClickEvent) {
        return {
            onClick: (e, row, rowIndex) => {
                if (rowClickEvent !== undefined) {
                    rowClickEvent(e, row, rowIndex)
                        .then((result) => {
                            if (result.data.res_code === 2) {
                                let detail = null;
                                if (this.props.category === 7) {
                                    detail = {
                                        orderInfo: result.data.result,
                                        orderCountInfo: {
                                            logcard: row.logcard_count,
                                            loginmap_map: row.loginmap_count,
                                            loginmap_sticker: row.sticker_count,
                                        },
                                    };
                                } else {
                                    detail = result.data.result;
                                }
                                this.setModalParams("view", row, detail);
                            }
                        })
                        .catch((err) => {});
                } else {
                    this.setModalParams("view", row);
                }
            },
        };
    }

    componentDidMount() {
        if (this.state.contentsData === null) {
            this.doGetContents({ _size: 10, _pageNo: 1 });
        }
    }
    componentWillUnmount() {}
    componentDidUpdate(prevProps) {}
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.contentsData !== nextState.contentsData) {
            return true;
        }
        if (this.state.sizePerPageOpen !== nextState.sizePerPageOpen) {
            return true;
        }
        if (this.state.rowsPerPage !== nextState.rowsPerPage) {
            return true;
        }
        if (this.state.nowPageIndex !== nextState.nowPageIndex) {
            return true;
        }
        if (this.state.showBigModal !== nextState.showBigModal) {
            return true;
        }
        if (this.state.showBackdrop !== nextState.showBackdrop) {
            return true;
        }
        if (this.state.selectedRows !== nextState.selectedRows) {
            return true;
        }
        return false;
    }

    render() {
        if (this.state.contentsData !== null) {
            const DetailPage = this.props.detailPage;

            return (
                <div>
                    <PaginationProvider
                        pagination={paginationFactory({
                            ...this.defaultTableOptions({ _totalSize: this.state.totalSize, _sizePerPage: this.state.rowsPerPage }),
                        })}>
                        {({ paginationProps, paginationTableProps }) => (
                            <div>
                                <ToolkitProvider
                                    keyField={this.props.keyField}
                                    columns={this.props.columns}
                                    data={this.state.contentsData.slice(
                                        (this.state.nowPageIndex - 1) * this.state.rowsPerPage,
                                        (this.state.nowPageIndex - 1) * this.state.rowsPerPage + this.state.rowsPerPage,
                                    )}
                                    exportCSV={{
                                        fileName: `${convertDate({ _orignDate: new Date(), _convertFormat: "YYYYMMDD_HHmmss" })}.csv`,
                                        separator: `,`,
                                        onlyExportSelection: true,
                                        exportAll: false,
                                        ignoreHeader: false,
                                        blobType: "text/csv;charset=ansi",
                                        noAutoBOM: false,
                                    }}>
                                    {(props) => (
                                        <div>
                                            <div className="mb-3 ml-3 mr-3" hidden={!this.props.showSizePerPage}>
                                                <Row>
                                                    <Col xs>
                                                        <div className={"float-left"}>
                                                            <Dropdown isOpen={this.state.sizePerPageOpen} toggle={() => this.setState({ sizePerPageOpen: !this.state.sizePerPageOpen })}>
                                                                <DropdownToggle caret>{this.state.rowsPerPage}</DropdownToggle>
                                                                <DropdownMenu>
                                                                    {this.props.sizePerPageList.map((option, index) => {
                                                                        return (
                                                                            <DropdownItem
                                                                                key={`sizePerPage-${index}`}
                                                                                onClick={() => {
                                                                                    paginationProps.onSizePerPageChange(option.value, this.state.nowPageIndex);
                                                                                }}>
                                                                                {option.text}
                                                                            </DropdownItem>
                                                                        );
                                                                    })}
                                                                </DropdownMenu>
                                                            </Dropdown>
                                                        </div>
                                                    </Col>
                                                    <Col xs hidden={!this.props.showTotalSize}>
                                                        <div className={"float-right"}>
                                                            <PaginationTotalStandalone {...paginationProps} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                remote
                                                filter={filterFactory()}
                                                rowEvents={this.rowEvents(this.props.rowClickEvent)}
                                                noDataIndication="데이터가 없습니다"
                                                hover
                                                selectRow={{
                                                    mode: "checkbox",
                                                    headerColumnStyle: { width: "4%", maxWidth: 50, minWidth: 50 },
                                                    selectColumnStyle: { width: "4%", maxWidth: 50, minWidth: 50 },
                                                    clickToSelect: false,
                                                    hideSelectColumn: !this.props.enableSelectRow,
                                                    onSelect: (row, isSelect, rowIndex, e) => {
                                                        this.setState({
                                                            selectedRows: isSelect ? lodash.uniq(this.state.selectedRows.concat(row)) : this.state.selectedRows.filter((item) => item !== row),
                                                        });
                                                    },
                                                    onSelectAll: (isSelect, rows, e) => {
                                                        this.setState({
                                                            selectedRows: isSelect ? rows : [],
                                                        });
                                                    },
                                                }}
                                                onTableChange={(type, { page, sizePerPage, sortField, sortOrder, data, filters }) => {
                                                    // setContentsData(lodash.orderBy(contentsData, [sortField], [sortOrder]));

                                                    if (this.props.category === 7) {
                                                        let filteredData = null;
                                                        if (filters.order_state !== undefined) {
                                                            if (filters.order_state.filterVal === "no_status") {
                                                                filteredData = lodash.orderBy(this.state.contentsDataOrigin, [sortField], [sortOrder]);

                                                                if (filteredData.length !== this.state.contentsData.length) {
                                                                    this.setState({
                                                                        contentsData: filteredData,
                                                                        totalSize: this.state.contentsDataOrigin[0].total_count,
                                                                    });
                                                                    setTimeout(() => {
                                                                        paginationProps.onPageChange(page, sizePerPage);
                                                                    }, 500);
                                                                }
                                                            } else {
                                                                filteredData = lodash
                                                                    .orderBy(this.state.contentsDataOrigin, [sortField], [sortOrder])
                                                                    .filter((item) => item.order_state === filters.order_state.filterVal);

                                                                if (filteredData.length !== this.state.contentsData.length) {
                                                                    this.setState({
                                                                        contentsData: filteredData,
                                                                        totalSize: filteredData.length,
                                                                    });
                                                                    setTimeout(() => {
                                                                        paginationProps.onPageChange(page, sizePerPage);
                                                                    }, 500);
                                                                }
                                                            }
                                                        }
                                                    }
                                                }}
                                                {...paginationTableProps}
                                                {...this.props}
                                            />
                                            <div className="mt-3 ml-3 mr-3">
                                                <Row>
                                                    <Col className={"col-sm-6 col-12"}>
                                                        <div className={"float-left"}>
                                                            <PaginationListStandalone {...paginationProps} />
                                                        </div>
                                                    </Col>
                                                    <Col className={"col-sm-6 col-12"}>
                                                        <div className={"float-right"}>
                                                            {this.props.enableExportCSV && (
                                                                <Button
                                                                    className="btn btn-danger"
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        this.state.selectedRows.length !== 0 && props.csvProps.onExport();
                                                                    }}>
                                                                    Export CSV
                                                                </Button>
                                                            )}
                                                            {this.props.bottomButton !== null && this.props.bottomButton(this.state.selectedRows, this.state.contentsData)}
                                                            {this.props.enableInsertButton && (
                                                                <Button
                                                                    className="btn btn-danger"
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        this.setModalParams("add");
                                                                    }}>
                                                                    추가
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </div>
                        )}
                    </PaginationProvider>
                    <Modal isOpen={this.state.showBigModal} toggle={() => this.setState({ showBigModal: !this.state.showBigModal })} style={{ minWidth: "70%", padding: 0 }}>
                        <ModalHeader className={"modal-title"} toggle={() => this.setState({ showBigModal: !this.state.showBigModal })} style={{ zIndex: 10 }} />
                        <ModalBody className={"col-12"}>
                            <DetailPage {...this.state.modalParams} {...this.props.detailPageParams} />
                        </ModalBody>
                    </Modal>
                    <ToastContainer
                        autoClose={3000}
                        draggable={false}
                        limit={1}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick={true}
                        pauseOnHover={false}
                        position={toast.POSITION.BOTTOM_RIGHT}
                    />
                    <Backdrop
                        style={{ zIndex: 99, color: "#fff" }}
                        open={this.state.showBackdrop}
                        // invisible={true}
                        onClick={() => {}}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
            );
        } else {
            return <div />;
        }
    }
}

export default PhotologTable;
PhotologTable.propTypes = {
    category: PropTypes.number.isRequired,
    keyField: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    getData: PropTypes.func.isRequired,
};
PhotologTable.defaultProps = {
    sizePerPageList: [
        {
            text: "5개씩 보기",
            value: 5,
        },
        {
            text: "10개씩 보기",
            value: 10,
        },
        {
            text: "50개씩 보기",
            value: 50,
        },
        {
            text: "100개씩 보기",
            value: 100,
        },
    ],
    showSizePerPage: true,
    showTotalSize: true,
    bottomButton: null,
    enableInsertButton: false,
    enableSelectRow: false,
    enableExportCSV: false,
    detailPageParams: {},
};

export class SortIcon extends Component {
    // constructor(props) {
    //     super(props);
    // }
    componentDidMount() {}
    componentWillUnmount() {}
    componentDidUpdate(prevProps) {}
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.order !== nextProps.order) {
            return true;
        }

        return false;
    }

    render() {
        switch (this.props.order) {
            case "asc": {
                return (
                    <div className="ml-2">
                        <i className="ni ni-bold-up" />
                        <i className="ni ni-bold-down" style={{ color: "rgba(255,200,200,0.9)" }} />
                    </div>
                );
            }
            case "desc": {
                return (
                    <div className="ml-2">
                        <i className="ni ni-bold-up" style={{ color: "rgba(255,200,200,0.9)" }} />
                        <i className="ni ni-bold-down" />
                    </div>
                );
            }
            default: {
                return (
                    <div className="ml-2">
                        <i className="ni ni-bold-up" style={{ color: "rgba(255,200,200,0.9)" }} />
                        <i className="ni ni-bold-down" style={{ color: "rgba(255,200,200,0.9)" }} />
                    </div>
                );
            }
        }
    }
}
