import React, { Component } from "react";
import { Doughnut, Pie, Line, Bar } from "react-chartjs-2";
import { Card, Col, Container, Form, FormGroup, Input, Label, Row, Button } from "reactstrap";
import lodash from "lodash";
import { ageGradeList, loginTypeList } from "./Users";
import "chartjs-plugin-labels";
// import "chartjs-plugin-datalabels";
import moment from "moment";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { convertDate } from "../../modules/utils";
const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const UsersAnalytics = (props) => {
    const [showBackdrop, setShowBackdrop] = React.useState(true);
    const [userAgeGrade, setUserAgeGrade] = React.useState([]);
    const [userLoginType, setUserLoginType] = React.useState(null);
    const [userRegistDateTime, setUserRegistDateTime] = React.useState([]);
    const [userRegistDateDay, setUserRegistDateDay] = React.useState([]);
    const [onReady, setOnReady] = React.useState(false);

    React.useEffect(() => {
        if (userAgeGrade.length === 0) {
            Promise.all([
                lodash.map(lodash.orderBy(props.getContentsItem, ["grade"], ["asc"]), "grade"),
                lodash.map(props.getContentsItem, "user_login_type"),
                lodash.map(
                    lodash.orderBy(
                        props.getContentsItem.map((item) => {
                            // return { ...item, user_regist_time: moment(item.user_regist_date, "YYYY-").format("HHmm") };
                            return { ...item, user_regist_time: convertDate({ _originDate: item.user_regist_date, _convertFormat: "HHmm" }) };
                        }),
                        ["user_regist_time"],
                        ["asc"],
                    ),
                    "user_regist_date",
                ),
                lodash.map(
                    lodash.orderBy(
                        props.getContentsItem.map((item) => {
                            // return { ...item, user_regist_time: moment(item.user_regist_date, "YYYY-").format("HHmm") };
                            return { ...item, user_regist_time: convertDate({ _originDate: item.user_regist_date, _convertFormat: "MMDD" }) };
                        }),
                        ["user_regist_day"],
                        ["asc"],
                    ),
                    "user_regist_date",
                ),
                // lodash.orderBy(props.getContentsItem, ["user_regist_date"], ["asc"]),
            ]).then((res) => {
                setUserAgeGrade({
                    origin: res[0],
                    labels: lodash.uniq(
                        res[0].map((item) => {
                            if (lodash.find(ageGradeList, { grade: item }) === undefined) return lodash.find(ageGradeList, { grade: -1 }).label;
                            else return lodash.find(ageGradeList, { grade: item }).label;
                        }),
                    ),
                    datasets: [
                        {
                            data: lodash.uniq(res[0]).map((item) => {
                                return lodash.groupBy(res[0])[item].length;
                            }),
                            backgroundColor: lodash.uniq(res[0]).map((item) => {
                                if (lodash.find(ageGradeList, { grade: item }) === undefined) return lodash.find(ageGradeList, { grade: -1 }).bgColor;
                                else return lodash.find(ageGradeList, { grade: item }).bgColor;
                            }),
                            textColor: lodash.uniq(res[0]).map((item) => {
                                if (lodash.find(ageGradeList, { grade: item }) === undefined) return lodash.find(ageGradeList, { grade: -1 }).textColor;
                                else return lodash.find(ageGradeList, { grade: item }).textColor;
                            }),
                        },
                    ],
                });
                setUserLoginType({
                    origin: res[1],
                    labels: lodash.uniq(
                        res[1].map((item) => {
                            return lodash.find(loginTypeList, { value: item }).label;
                        }),
                    ),
                    datasets: [
                        {
                            data: lodash.uniq(res[1]).map((item) => {
                                return lodash.groupBy(res[1])[item].length;
                            }),
                            backgroundColor: lodash.uniq(res[1]).map((item) => {
                                return lodash.find(loginTypeList, { value: item }).bgColor;
                            }),
                            textColor: lodash.uniq(res[1]).map((item) => {
                                return lodash.find(loginTypeList, { value: item }).textColor;
                            }),
                        },
                    ],
                });
                setUserRegistDateTime({
                    origin: res[2],
                    labels: lodash.uniq(
                        res[2].map((item) => {
                            // return moment(item).format("HH");
                            return convertDate({ _originDate: item, _convertFormat: "HH" });
                        }),
                    ),
                    datasets: [
                        {
                            data: lodash
                                .uniq(
                                    res[2].map((item) => {
                                        return convertDate({ _originDate: item, _convertFormat: "HH" });
                                    }),
                                )
                                .map((userRegistDateItem) => {
                                    return {
                                        x: userRegistDateItem,
                                        y: lodash.groupBy(
                                            res[2].map((item) => {
                                                return convertDate({ _originDate: item, _convertFormat: "HH" });
                                            }),
                                        )[userRegistDateItem].length,
                                    };
                                }),
                        },
                    ],
                });
                setUserRegistDateDay({
                    origin: res[3],
                    labels: lodash.uniq(
                        res[3].map((item) => {
                            return convertDate({ _originDate: item, _convertFormat: "MMDD" });
                        }),
                    ),
                    datasets: [
                        {
                            data: lodash
                                .uniq(
                                    res[3].map((item) => {
                                        return convertDate({ _originDate: item, _convertFormat: "MMDD" });
                                    }),
                                )
                                .map((userRegistDateItem) => {
                                    return {
                                        x: userRegistDateItem,
                                        y: lodash.groupBy(
                                            res[3].map((item) => {
                                                return convertDate({ _originDate: item, _convertFormat: "MMDD" });
                                            }),
                                        )[userRegistDateItem].length,
                                    };
                                }),
                        },
                    ],
                });
                setShowBackdrop(false);
                setOnReady(true);
            });
        }
    }, []);

    if (!onReady) {
        return <div></div>;
    } else {
        return (
            <>
                <Container className="mt--7">
                    <Row>
                        <Col className={"col-12 mx-auto mt-6 m-3"}></Col>
                    </Row>
                    <Card className="shadow border-0">
                        <div className={"shadow-lg p-3 bg-body rounded border pb-5"}>
                            <Row onClick={() => null}>
                                <Col className={"col-6 mx-auto mt-3"} style={{ maxHeight: 250 }}>
                                    <Label for="chart_user_login_type">로그인 유형</Label>
                                    <Pie
                                        id={"chart_user_login_type"}
                                        data={{
                                            labels: userLoginType.labels,
                                            datasets: userLoginType.datasets,
                                        }}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            legend: {
                                                fullWidth: false,
                                                position: "bottom",
                                                labels: {
                                                    boxWidth: 10,
                                                },
                                            },
                                            plugins: {
                                                labels: {
                                                    render: "percentage",
                                                    fontColor: function (args) {
                                                        return args.dataset.textColor[args.index];
                                                    },
                                                    arc: false,
                                                    overlap: false,
                                                },
                                            },
                                        }}
                                    />
                                </Col>
                                <Col className={"col-6 mx-auto mt-3"} style={{ maxHeight: 250 }}>
                                    <Label for="chart_user_birthday">연령대</Label>
                                    <Pie
                                        id={"chart_user_birthday"}
                                        data={{
                                            labels: userAgeGrade.labels,
                                            datasets: userAgeGrade.datasets,
                                        }}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            legend: {
                                                position: "bottom",
                                                labels: {
                                                    boxWidth: 10,
                                                },
                                            },
                                            plugins: {
                                                labels: {
                                                    render: function (args) {
                                                        // args will be something like:
                                                        // { label: 'Label', value: 123, percentage: 50, index: 0, dataset: {...} }
                                                        return args.percentage + "%";

                                                        // return object if it is image
                                                        // return { src: 'image.png', width: 16, height: 16 };
                                                    },
                                                    fontColor: function (args) {
                                                        // args will be something like:
                                                        // { index: 0, dataset: {...} }
                                                        return args.dataset.textColor[args.index];
                                                    },
                                                    arc: false,
                                                    overlap: false,
                                                },
                                            },
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className={"mt-4"}>
                                <Col className={"col-12 mx-auto mt-3 "} style={{ maxHeight: 250 }}>
                                    <Label for="chart_user_regist_date_time">시간대별 가입자수</Label>
                                    <Line
                                        id={"chart_user_regist_date_time"}
                                        data={{
                                            labels: userRegistDateTime.labels,
                                            datasets: userRegistDateTime.datasets,
                                        }}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            legend: {
                                                display: false,
                                            },
                                            scales: {
                                                yAxes: [
                                                    {
                                                        ticks: {
                                                            beginAtZero: true,
                                                        },
                                                    },
                                                ],
                                            },
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className={"mt-4"}>
                                <Col className={"col-12 mx-auto mt-3 "} style={{ maxHeight: 250 }}>
                                    <Label for="chart_user_regist_date_day">일자별 가입자수</Label>
                                    <Line
                                        id={"chart_user_regist_date_day"}
                                        data={{
                                            labels: userRegistDateDay.labels,
                                            datasets: userRegistDateDay.datasets,
                                        }}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            legend: {
                                                display: false,
                                            },
                                            plugins: {
                                                labels: {
                                                    render: function (args) {
                                                        return `${args.value.y}명`;
                                                    },
                                                    arc: false,
                                                    overlap: false,
                                                },
                                            },
                                            scales: {
                                                yAxes: [
                                                    {
                                                        ticks: {
                                                            beginAtZero: true,
                                                        },
                                                    },
                                                ],
                                            },
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Container>
                <Backdrop
                    style={{ zIndex: 9999, color: "#fff" }}
                    open={showBackdrop}
                    // invisible={true}
                    onClick={() => {}}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        );
    }
};

export default UsersAnalytics;
