import Index from "./views/Index.js";

// photolog
import Notice from "./views/admin/Notice.js";
import Users from "./views/admin/Users.js";
import Contents from "./views/admin/Contents.js";
import Popup from "./views/admin/Popup.js";
import Manager from "./views/admin/Manager.js";
import Coupon from "./views/admin/Coupon.js";
import Push from "./views/admin/Push.js";
import Payment from "./views/admin/Payment.js";
import Event from "./views/admin/Event.js";
import Login from "./views/auth/Login";
import StoreNotice from "./views/store/StoreNotice";
import StoreList from "./views/store/StoreList";
import MyProfile from "./views/admin/MyProfile";

import { AiOutlineGift } from "react-icons/ai";
import { BiBookContent, BiMessageAltDetail, BiGroup, BiKey } from "react-icons/bi";
import { FiMonitor } from "react-icons/fi";
import { MdNotificationsNone, MdPayment, MdStore } from "react-icons/md";
import { VscNewFolder } from "react-icons/vsc";

var routes = [
    {
        path: "/index",
        name: "Dashboard",
        icon: "ni ni-tv-2 text-primary",
        iconComponent: FiMonitor,
        component: Index,
        layout: "/admin",
    },
    {
        path: "/login",
        name: "Login",
        icon: "ni ni-key-25 text-info",
        component: Login,
        layout: "/auth",
    },

    // photolog
    {
        path: "/notice",
        name: "공지사항",
        icon: "ni ni-circle-08 text-pink",
        iconComponent: MdNotificationsNone,
        component: Notice,
        layout: "/admin",
    },
    {
        path: "/users",
        name: "사용자 목록",
        icon: "ni ni-circle-08 text-pink",
        iconComponent: BiGroup,
        component: Users,
        layout: "/admin",
    },
    {
        path: "/contents",
        name: "컨텐츠",
        icon: "ni ni-planet text-blue",
        iconComponent: BiBookContent,
        component: Contents,
        layout: "/admin",
    },
    {
        path: "/popup",
        name: "팝업",
        icon: "ni ni-planet text-blue",
        iconComponent: VscNewFolder,
        component: Popup,
        layout: "/admin",
    },
    {
        path: "/event",
        name: "이벤트",
        icon: "ni ni-planet text-blue",
        iconComponent: VscNewFolder,
        component: Event,
        layout: "/admin",
    },
    {
        path: "/manager",
        name: "관리자",
        icon: "ni ni-planet text-blue",
        iconComponent: BiKey,
        component: Manager,
        layout: "/admin",
    },
    {
        path: "/coupon",
        name: "쿠폰",
        icon: "ni ni-planet text-blue",
        iconComponent: AiOutlineGift,
        component: Coupon,
        layout: "/admin",
    },
    {
        path: "/push",
        name: "푸시",
        icon: "ni ni-planet text-blue",
        iconComponent: BiMessageAltDetail,
        component: Push,
        layout: "/admin",
    },
    {
        path: "/payment",
        name: "결제내역",
        icon: "ni ni-planet text-blue",
        iconComponent: MdPayment,
        component: Payment,
        layout: "/admin",
    },
    {
        path: "/notice",
        name: "상점 공지사항",
        icon: "ni ni-planet text-blue",
        iconComponent: MdNotificationsNone,
        component: StoreNotice,
        layout: "/store",
    },
    {
        path: "/list",
        name: "상점 목록",
        icon: "ni ni-planet text-blue",
        iconComponent: MdStore,
        component: StoreList,
        layout: "/store",
    },
    {
        path: "/profile",
        name: "내 정보",
        icon: "ni ni-planet text-blue",
        iconComponent: MdPayment,
        component: MyProfile,
        layout: "/user",
    },
];
export default routes;
