import React, { Component } from "react";
import PropTypes from "prop-types";
import Script from "react-load-script";

export class GoogleTrends extends Component {
    constructor(props) {
        super(props);
        this.renderGoogleTrend = this.renderGoogleTrend.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps !== this.props) {
            return true;
        }
        return false;
    }

    renderGoogleTrend(props) {
        let comparisonList = props.keyword.map((item) => {
            return { keyword: item, geo: props.geo, time: props.time };
        });

        let queryList = encodeURI(props.keyword.join(","));

        let timeList = props.keyword
            .map((item) => {
                return `${props.time}`;
            })
            .join(",");

        return (
            <Script
                url={"https://ssl.gstatic.com/trends_nrtr/2402_RC03/embed_loader.js"}
                onLoad={(_) => {
                    window.trends.embed.renderExploreWidgetTo(
                        document.getElementById(`${props.target}`),
                        props.type,
                        {
                            comparisonItem: comparisonList,
                            category: 0,
                            property: "",
                        },
                        {
                            exploreQuery: `q=${queryList}&geo=${props.geo}&date=${timeList}`,
                            guestPath: "https://trends.google.com:443/trends/embed/",
                        },
                    );
                }}
            />
        );
    }

    render() {
        return <div className="googleTrend">{this.renderGoogleTrend(this.props)}</div>;
    }
}

GoogleTrends.defaultProps = {
    target: "",
    type: "TIMESERIES",
    keyword: ["여행"],
    url: "https://ssl.gstatic.com/trends_nrtr/2402_RC03/embed_loader.js",
    time: "today 12-m",
    geo: "KR",
};

GoogleTrends.propTypes = {
    target: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    keyword: PropTypes.array.isRequired,
    url: PropTypes.string,
    time: PropTypes.string,
    geo: PropTypes.string,
};
export default GoogleTrends;
