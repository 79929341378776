import axios from "axios";
import { logout } from "../modules/utils";

export function getStoreNoticeList({ _category, _size, _pageNo }) {
    return axios
        .post("/apis/store-notice/get-notice-list", {
            category: _category,
            size: _size,
            pageNo: _pageNo,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}

export function insertNotice({ _noticeTitle, _noticeContents, _noticePopup, _noticeType }) {
    return axios
        .post("/apis/store-notice/insert-notice", {
            noticeTitle: _noticeTitle,
            noticeContents: _noticeContents,
            noticePopup: _noticePopup,
            noticeType: _noticeType,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
export function deleteNotice({ _noticeIdx }) {
    return axios
        .post("/apis/store-notice/delete-notice", {
            noticeIdx: _noticeIdx,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}

export function modifyNotice({ _noticeIdx, _noticeTitle, _noticeContents, _noticePopup, _noticeType }) {
    return axios
        .post("/apis/store-notice/modify-notice", {
            noticeIdx: _noticeIdx,
            noticeTitle: _noticeTitle,
            noticeContents: _noticeContents,
            noticePopup: _noticePopup,
            noticeType: _noticeType,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
