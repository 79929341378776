import React from "react";
import { Link } from "react-router-dom";
import { History } from "../../modules/utils.js";
// reactstrap components
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Navbar, Nav, Container, Media } from "reactstrap";
import Logo from "../../assets/img/brand/logo-background.png";
import { managerLogout } from "../../lib/manager";
import { BsPerson } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";

class AdminNavbar extends React.Component {
    render() {
        return (
            <>
                <Navbar className="navbar-top navbar-dark float-right" expand="md" id="navbar-main">
                    <Container fluid>
                        <Link className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block" to="/">
                            {this.props.brandText}
                        </Link>
                        <div className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"></div>
                        <Nav className="align-items-center d-none d-md-flex" navbar>
                            <UncontrolledDropdown nav>
                                <DropdownToggle className="pr-0" nav>
                                    <Media className="align-items-center ">
                                        <span className="avatar avatar-sm rounded-circle">
                                            <img alt="..." src={Logo} />
                                        </span>
                                        <Media className="ml-2 d-none d-lg-block">
                                            <span className="mb-0 text-sm font-weight-bold">{this.props.managerInfo.userName}님</span>
                                        </Media>
                                    </Media>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                    <DropdownItem className="noti-title" header tag="div">
                                        <h6 className="text-overflow m-0">안녕하세요</h6>
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={(e) => {
                                            e.preventDefault();
                                            History.replace("/user/profile", { params: { getContentsItem: this.props.managerInfo } });
                                            window.location.reload();
                                        }}>
                                        <BsPerson />
                                        <span>내 정보</span>
                                    </DropdownItem>

                                    <DropdownItem divider />
                                    <DropdownItem
                                        href="#pablo"
                                        onClick={(e) => {
                                            managerLogout().then(() => {
                                                History.replace("/auth/login");
                                                window.location.reload();
                                            });
                                        }}>
                                        <FiLogOut />
                                        <span>로그아웃</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Container>
                </Navbar>
            </>
        );
    }
}

export default AdminNavbar;
