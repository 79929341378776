import axios from "axios";
import { logout } from "../modules/utils";

export function insertEvent({
    _eventTitle,
    _eventMainImage,
    _eventSubImage,
    _eventDesc,
    _eventButton,
    _eventButtonContents,
    _eventDate,
    _eventState,
    _eventEndDate,
    _forwardType,
    _forwardUrl,
    _navigationCategory,
    _navigationSeq,
    _navigationCode,
}) {
    let eventData = new FormData();

    eventData.append("file", _eventMainImage);
    eventData.append("file", _eventSubImage);

    eventData.append("eventTitle", _eventTitle);
    eventData.append("eventDesc", _eventDesc);
    eventData.append("eventButton", _eventButton);
    eventData.append("eventButtonContents", _eventButtonContents);
    eventData.append("eventDate", _eventDate);
    eventData.append("eventState", _eventState);
    eventData.append("eventEndDate", _eventEndDate);

    eventData.append("forwardType", _forwardType);
    eventData.append("forwardUrl", _forwardUrl);
    eventData.append("navigationCategory", _navigationCategory);
    eventData.append("navigationSeq", _navigationSeq);
    eventData.append("navigationCode", _navigationCode);
    return axios
        .post("/apis/event/insert-event", eventData, {
            headers: {
                "content-type": "multipart/form-data; charset=utf-8;",
            },
        })
        .then((result) => {
            return result;
        })
        .catch((e) => {
            return null;
        });
}
export function modifyEvent({
    _eventIdx,
    _eventTitle,
    _eventDesc,
    _eventButton,
    _eventButtonContents,
    _eventDate,
    _eventState,
    _eventEndDate,
    _forwardType,
    _forwardUrl,
    _navigationCategory,
    _navigationSeq,
    _navigationCode,
}) {
    return axios
        .post("/apis/event/modify-event", {
            eventIdx: _eventIdx,
            eventTitle: _eventTitle,
            eventDesc: _eventDesc,
            eventButton: _eventButton,
            eventButtonContents: _eventButtonContents,
            eventDate: _eventDate,
            eventState: _eventState,
            eventEndDate: _eventEndDate,
            forwardType: _forwardType,
            forwardUrl: _forwardUrl,
            navigationCategory: _navigationCategory,
            navigationSeq: _navigationSeq,
            navigationCode: _navigationCode,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
export function deleteEvent({ _eventIdx }) {
    return axios
        .post("/apis/event/delete-event", {
            eventIdx: _eventIdx,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}

export function getEventData({ _category, _size, _pageNo }) {
    return axios
        .post("/apis/event/get-event-list", {
            category: _category,
            size: _size,
            pageNo: _pageNo,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
