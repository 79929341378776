import React from "react";
import { History, convertDate, numberWithComma, convertFormat } from "../../modules/utils.js";
import { Card, Col, Container, Input, Label, Row, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Collapse } from "reactstrap";
import ToggleSwitch from "../../modules/ToggleSwitch.js";
import lodash from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import * as Bi from "react-icons/bi";

import { PostcodeDialog } from "../../modules/modal";
import { paymentStatus } from "./Payment.js";
import { toast } from "react-toastify";
import JSZip from "jszip";
import FileSaver from "file-saver";
import { CircularProgress, Backdrop, ListItem, ListItemText, List } from "@material-ui/core";

import { makeStickerSVG } from "../../modules/svg/makeStickerSVG.js";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { modifyPaymentInfo } from "../../lib/payment.js";
import { PhotologDialog } from "../../modules/PhotologDialog.js";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

class RenderSVGSticker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            svg: null,
        };
    }
    readFile(file) {
        let fr = new FileReader();
        fr.readAsDataURL(file.svg);
        fr.onloadend = (r) => {
            this.setState({ svg: r.target.result.replaceAll("data:text/plain;charset=utf-8;base64,", "") });
        };
    }
    componentDidMount() {
        this.readFile(this.props.svg);
    }
    componentWillUnmount() {}
    componentDidUpdate(prevProps) {}
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.svg !== nextState.svg) {
            return true;
        }
        return false;
    }

    render() {
        const { index } = this.props;

        if (this.state.svg === null) {
            return <div />;
        } else {
            return <img key={`image-${index}`} src={`data:image/svg+xml;base64,${encodeURIComponent(this.state.svg)}`} alt="" style={this.props.imageStyle} className={"border border-2"} />;
        }
    }
}

class RenderStickerList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            stickerSVGFiles: [],
        };
    }
    componentDidMount() {}
    componentWillUnmount() {}
    componentDidUpdate(prevProps) {}
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.stickerSVGFiles !== nextState.stickerSVGFiles) {
            return true;
        }
        if (this.state.isOpen !== nextState.isOpen) {
            return true;
        }
        return false;
    }

    svgMaker() {
        const { orderInfo, userInfo } = this.props;
        let stickersFromOrderInfo = lodash.groupBy(orderInfo, "product_code").loginmap_sticker;
        let stickersItem = [];
        lodash.uniqBy(stickersFromOrderInfo, "map_url").map((uniqMapOrderItem, uniqMapOrderIndex) => {
            makeStickerSVG({
                url: uniqMapOrderItem.map_url,
                codeArr: lodash.groupBy(stickersFromOrderInfo, "map_url")[uniqMapOrderItem.map_url].map((orderItem) => {
                    return orderItem.region_code;
                }),
                name: userInfo.user_real_name,
                phoneNum: userInfo.user_phone,
            })
                .then((makeStickerSVGResult) => {
                    if (makeStickerSVGResult.length === lodash.groupBy(stickersFromOrderInfo, "map_url")[uniqMapOrderItem.map_url].length) {
                        stickersItem.push(
                            makeStickerSVGResult.map((svgFile, index) => {
                                return {
                                    svg: new File([`${svgFile.svgString}`], `${svgFile.fileName}`, {
                                        type: "text/plain;charset=utf-8",
                                    }),
                                    paymentCode: lodash.groupBy(stickersFromOrderInfo, "map_url")[uniqMapOrderItem.map_url][index].payment_code,
                                    orderCode: lodash.groupBy(stickersFromOrderInfo, "map_url")[uniqMapOrderItem.map_url][index].order_code,
                                    codeName: lodash.groupBy(stickersFromOrderInfo, "map_url")[uniqMapOrderItem.map_url][index].region_name,
                                    fileName: svgFile.fileName,
                                };
                            }),
                        );

                        if (lodash.flatMapDeep(stickersItem).length === stickersFromOrderInfo.length) {
                            this.setState({ stickerSVGFiles: lodash.flatMapDeep(stickersItem) });
                        }
                    }
                })
                .catch((err) => {});
        });
    }

    render() {
        if (this.state.stickerSVGFiles.length !== 0) {
            let stickerGroupByList = Object.entries(lodash.groupBy(this.state.stickerSVGFiles, "orderCode")).map(([k, v]) => {
                return { orderCode: k, items: v };
            });
            let renderList = stickerGroupByList.map((orderList, index) => {
                let renderStickers = orderList.items.map((item, index) => {
                    return (
                        <Col className={"col-md-4 col-sm-6 col-12 m-0"} key={`sticker-images-${index}`}>
                            <RenderSVGSticker svg={item} index={index} imageStyle={{ width: "100%", height: 200, background: "white" }} />
                            <Button
                                style={{ position: "absolute", top: 10, right: 20 }}
                                className="btn btn-danger"
                                size="sm"
                                onClick={() => {
                                    FileSaver.saveAs(item.svg, `${item.fileName}`);
                                }}>
                                <Bi.BiDownload />
                            </Button>
                            <div className={"d-flex justify-content-center align-items-center"}>{item.codeName}</div>
                        </Col>
                    );
                });

                return (
                    <div className={"mt-3 mb-3"} style={{ background: "white" }}>
                        <ListItem key={`sticker-images-header-${index}`}>
                            <ListItemText primary={`${orderList.orderCode}`} />
                        </ListItem>
                        <div className={"d-flex justify-content-center align-items-center mx-3"}>
                            <div className={"w-100"}>
                                <Row>{renderStickers}</Row>
                            </div>
                        </div>
                    </div>
                );
            });
            return (
                <div className={"d-flex w-100"}>
                    <List component="nav" style={{ width: "100%" }} className={"rounded"}>
                        {renderList}
                    </List>
                </div>
            );
        } else {
            this.svgMaker();
            return (
                <div className={"d-flex justify-content-center align-items-center"} style={{ height: 250 }}>
                    <CircularProgress color="secondary" />
                </div>
            );
        }
    }
}

const PaymentDetail = (props) => {
    const [showItem, setShowItem] = React.useState(null);
    const [addMode, setAddMode] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);

    const [showMessage, setShowMessage] = React.useState(false);
    const [showPostcodeDialog, setShowPostcodeDialog] = React.useState(false);
    const [showBackdrop, setShowBackdrop] = React.useState(false);
    const [openOrderState, setOpenOrderState] = React.useState(false);

    const [openUserInfo, setOpenUserInfo] = React.useState(true);
    const [openOrderInfo, setOpenOrderInfo] = React.useState(true);
    const [openPaymentInfo, setOpenPaymentInfo] = React.useState(false);

    const [openStickerImages, setOpenStickerImages] = React.useState(false);
    const [stickerSVGFiles, setStickerSVGFiles] = React.useState([]);

    const message = React.useRef({
        tag: "",
        title: "",
        message: "",
        body: null,
        button: [],
        showCenter: false,
        showHeader: false,
        large: false,
    });

    const classes = useStyles();

    React.useEffect(() => {
        if (props === undefined) {
            History.replace("/admin/payment");
            window.location.reload();
            return;
        } else {
            if (showItem === null) {
                if (props.mode === "add") {
                } else {
                    setShowItem({
                        contents: props.getContentsItem,
                        details: props.getContentsItemDetail,
                    });
                }
            } else {
            }
        }
    }, [showItem, props]);

    const doValidation = () => {
        if (editMode) {
            if (lodash.isEqual(props.getContentsItem, showItem.contents) && lodash.isEqual(props.getContentsItemDetail, showItem.details)) {
                return {
                    errCode: -1,
                    errMsg: "바뀐값이 없음",
                };
            }
        }
        return {
            errCode: 0,
            errMsg: "문제 발견되지 않음",
        };
    };

    React.useEffect(() => {
        if (openStickerImages && stickerSVGFiles.length === 0) {
            makeSVGFile();
        }
    }, [openStickerImages]);

    const makeSVGFile = () => {
        if (openStickerImages && stickerSVGFiles.length === 0) {
            let stickersFromOrderInfo = lodash.groupBy(showItem.details.orderInfo, "product_code").loginmap_sticker;
            let stickersItem = [];
            lodash.uniqBy(stickersFromOrderInfo, "map_url").map(async (uniqMapOrderItem, uniqMapOrderIndex) => {
                await makeStickerSVG({
                    url: uniqMapOrderItem.map_url,
                    codeArr: lodash.groupBy(stickersFromOrderInfo, "map_url")[uniqMapOrderItem.map_url].map((orderItem) => {
                        return orderItem.region_code;
                    }),
                    name: showItem.contents.user_real_name,
                    phoneNum: showItem.contents.user_phone,
                })
                    .then((makeStickerSVGResult) => {
                        makeStickerSVGResult.map((svgFile, index) => {
                            for (let i = 0; i < Number(lodash.groupBy(stickersFromOrderInfo, "map_url")[uniqMapOrderItem.map_url][0].order_count); i++) {
                                let zeroPadIndex = index < 10 ? `00${index}` : index < 100 ? `0${index}` : `${index}`;
                                stickersItem.push({
                                    svg: new File([`${svgFile.svgString}`], `${svgFile.fileName}`, {
                                        type: "text/plain;charset=utf-8",
                                    }),
                                    paymentCode: lodash.groupBy(stickersFromOrderInfo, "map_url")[uniqMapOrderItem.map_url][index].payment_code,
                                    orderCode: lodash.groupBy(stickersFromOrderInfo, "map_url")[uniqMapOrderItem.map_url][index].order_code,
                                    codeName: lodash.groupBy(stickersFromOrderInfo, "map_url")[uniqMapOrderItem.map_url][index].region_name,
                                    fileName: `${zeroPadIndex}-${svgFile.fileName}`,
                                });
                            }
                        });
                        if (lodash.flatMapDeep(stickersItem).length === lodash.sumBy(stickersFromOrderInfo, "order_count")) {
                            setStickerSVGFiles(lodash.flatMapDeep(stickersItem));
                        }
                    })
                    .catch((err) => {});
            });
        }
    };

    const renderImages = () => {
        if (stickerSVGFiles.length !== 0) {
            let stickerGroupByList = Object.entries(lodash.groupBy(stickerSVGFiles, "orderCode")).map(([k, v]) => {
                return { orderCode: k, items: v };
            });
            let renderList = stickerGroupByList.map((orderList) => {
                let renderStickers = orderList.items.map((item, index) => {
                    return (
                        <Col className={"col-md-4 col-sm-6 col-12 m-0"} key={`sticker-images-${index}`}>
                            <RenderSVGSticker svg={item} index={index} imageStyle={{ width: "100%", height: 200, background: "white" }} />
                            <Button
                                style={{ position: "absolute", top: 10, right: 20 }}
                                className="btn btn-danger"
                                size="sm"
                                onClick={() => {
                                    FileSaver.saveAs(item.svg, `${item.fileName}`);
                                }}>
                                <Bi.BiDownload />
                            </Button>
                            <div className={"d-flex justify-content-center align-items-center"}>{item.codeName}</div>
                        </Col>
                    );
                });

                return (
                    <Collapse isOpen={true}>
                        <List component="nav" style={{ width: "100%", background: "#f5f5f5" }} className={"rounded"}>
                            <ListItem>
                                <ListItemText primary={`${orderList.orderCode}`} />
                                <MdExpandLess />
                            </ListItem>
                            <Row>{renderStickers}</Row>
                        </List>
                    </Collapse>
                );
            });
            return renderList;
        } else {
            makeSVGFile();
            return (
                <div className={"d-flex justify-content-center align-items-center"} style={{ height: 250 }}>
                    <CircularProgress color="secondary" />
                </div>
            );
        }

        // return <div></div>;
    };

    if (showItem === null) {
        return <div></div>;
    } else {
        return (
            <>
                <Container className="mt--7">
                    {addMode ? (
                        <Row>
                            <Col className={"col-12 mx-auto mt-6 m-3"}></Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col className={"col-12 mx-auto mt-6 m-3"}>
                                <Label for="contents_editMode" style={{ color: "black" }}>
                                    수정모드
                                </Label>
                                <Row className={"ml-0"}>
                                    <ToggleSwitch
                                        id="contents_editMode"
                                        checked={editMode}
                                        onChange={(v) => {
                                            if (editMode === true) {
                                                // 수정모드 가능->불가능
                                                setShowItem({
                                                    contents: props.getContentsItem,
                                                    details: props.getContentsItemDetail,
                                                });
                                            }
                                            setEditMode(v);
                                        }}
                                        optionLabels={["가능", "불가"]}
                                    />
                                </Row>
                            </Col>
                        </Row>
                    )}

                    <Card className="shadow border-0 pb-4">
                        <div className={"ml-3 mr-3 mt-3 shadow-lg p-3  bg-body rounded border"}>
                            <Row onClick={() => setOpenUserInfo((prev) => !prev)}>
                                <Col className={"col-10"}>
                                    <span>주문자 정보</span>
                                </Col>
                                <Col className={"col-2"}>
                                    <span className={"float-right"}>{openUserInfo ? <MdExpandLess /> : <MdExpandMore />}</span>
                                </Col>
                            </Row>
                            <Collapse isOpen={openUserInfo} className={"mt-3"}>
                                <Row className={""}>
                                    <Col className={"col-lg-4 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                        <Label for="contents_user_real_name">주문자명</Label>
                                        <Input type="text" name="user_real_name" id="contents_user_real_name" value={showItem.contents.user_real_name} disabled />
                                    </Col>
                                    <Col className={"col-lg-4 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                        <Label for="contents_order_date">주문일시</Label>
                                        <Input
                                            type="text"
                                            name="order_date"
                                            id="contents_order_date"
                                            value={convertDate({ _originDate: showItem.contents.order_date, _convertFormat: "YY.MM.DD HH:mm" })}
                                            disabled
                                        />
                                    </Col>
                                    <Col className={"col-lg-4 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                        <Label for="contents_order_state">주문상태</Label>
                                        {!editMode && (
                                            <Input
                                                type="text"
                                                name="order_state"
                                                id="contents_order_state"
                                                value={lodash.find(paymentStatus, { value: showItem.contents.order_state }).label}
                                                disabled={!editMode}
                                            />
                                        )}
                                        {editMode && (
                                            <div>
                                                <Dropdown size="lg" isOpen={openOrderState} toggle={() => setOpenOrderState((prev) => !prev)} className={"pl-2"} disabled={addMode ? false : !editMode}>
                                                    <DropdownToggle caret disabled={addMode ? false : !editMode}>
                                                        {lodash.find(paymentStatus, { value: showItem.contents.order_state }).label}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {paymentStatus
                                                            .filter((item) => item.value !== "no_status")
                                                            .map((option) => {
                                                                return (
                                                                    <DropdownItem
                                                                        key={`filter-details-items-${option.statusCode}`}
                                                                        onClick={() => {
                                                                            setShowItem({
                                                                                contents: {
                                                                                    ...showItem.contents,
                                                                                    order_state: option.value,
                                                                                },
                                                                                details: { ...showItem.details },
                                                                            });
                                                                        }}>
                                                                        {option.label}
                                                                    </DropdownItem>
                                                                );
                                                            })}
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>
                                        )}
                                    </Col>
                                    <Col className={"col-lg-4 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                        <Label for="contents_user_id">ID</Label>
                                        <Input type="text" name="user_id" id="contents_user_id" value={showItem.contents.user_id} disabled />
                                    </Col>
                                    <Col className={"col-lg-4 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                        <Label for="contents_user_phone">전화번호</Label>
                                        <Input type="text" name="user_phone" id="contents_user_phone" value={convertFormat("phone", showItem.contents.user_phone)} disabled />
                                    </Col>
                                    <Col className={"col-lg-4 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                        <Label for="contents_user_mail">이메일</Label>
                                        <Input type="text" name="user_mail" id="contents_user_mail" value={showItem.contents.user_mail} disabled />
                                    </Col>
                                    <Col className={"col-12 mx-auto mt-3"}>
                                        <Label for="contents_shipping_number">
                                            운송장 번호
                                            {showItem.contents.shipping_number !== null && showItem.contents.shipping_number.length >= 13 ? (
                                                <span className={"ml-2"}>
                                                    (
                                                    <a
                                                        href={`https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?displayHeader=N&sid1=${showItem.contents.shipping_number}`}
                                                        target="_blank"
                                                        rel="noreferrer">
                                                        우체국 조회 <Bi.BiWindows />
                                                    </a>
                                                    )
                                                </span>
                                            ) : (
                                                <span className={"ml-2"}>
                                                    (
                                                    <a
                                                        href={`https://www.cupost.co.kr/postbox/delivery/localResult.cupost?invoice_no=${showItem.contents.shipping_number}`}
                                                        target="_blank"
                                                        rel="noreferrer">
                                                        {"CU POST"} 조회 <Bi.BiWindows />
                                                    </a>
                                                    )
                                                </span>
                                            )}
                                        </Label>
                                        <Input
                                            type="text"
                                            name="shipping_number"
                                            id="contents_shipping_number"
                                            value={showItem.contents.shipping_number}
                                            disabled={!editMode}
                                            onChange={(e) => {
                                                setShowItem({
                                                    contents: {
                                                        ...showItem.contents,
                                                        shipping_number: e.target.value === "" ? null : e.target.value,
                                                    },
                                                    details: { ...showItem.details },
                                                });
                                            }}
                                        />
                                    </Col>
                                    <Col className={"col-12 mx-auto mt-3"}>
                                        <Label for="contents_user_address">
                                            배송지 주소
                                            {editMode && (
                                                <span
                                                    className={"ml-2"}
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setShowPostcodeDialog(true);
                                                    }}>
                                                    ( 수정
                                                    <Bi.BiWindows /> )
                                                </span>
                                            )}
                                        </Label>

                                        <Input type="text" name="user_address" id="contents_user_address" value={showItem.contents.user_address} disabled />
                                    </Col>
                                </Row>
                            </Collapse>
                        </div>
                        <div className={"ml-3 mr-3 mt-3 shadow-lg p-3 bg-body rounded border"}>
                            <Row onClick={() => setOpenOrderInfo((prev) => !prev)}>
                                <Col className={"col-10"}>
                                    <span>주문상품 정보</span>
                                </Col>
                                <Col className={"col-2"}>
                                    <span className={"float-right"}>{openOrderInfo ? <MdExpandLess /> : <MdExpandMore />}</span>
                                </Col>
                            </Row>
                            <Collapse isOpen={openOrderInfo} className={"mt-3"}>
                                <div className={"d-flex justify-content-center align-items-center"}>
                                    <List component="nav" style={{ width: "100%", background: "#f5f5f5" }} className={"rounded"}>
                                        {showItem.contents.logcard_count !== 0 && (
                                            <ListItem>
                                                <ListItemText
                                                    primary={`로그카드 ${showItem.contents.logcard_count}장 - ${lodash.groupBy(showItem.details.orderInfo, "product_code").logcard[0].order_count}팩`}
                                                />
                                            </ListItem>
                                        )}
                                        {showItem.contents.loginmap_count !== 0 && (
                                            <ListItem>
                                                <ListItemText primary={`로그인맵 ${showItem.contents.loginmap_count}장`} />
                                            </ListItem>
                                        )}
                                        {showItem.contents.sticker_count !== 0 && (
                                            <ListItem onClick={() => setOpenStickerImages(!openStickerImages)}>
                                                <ListItemText
                                                    primary={`로그인맵 스티커 ${showItem.contents.sticker_count}장 - ${
                                                        lodash.groupBy(showItem.details.orderInfo, "product_code").loginmap_sticker[0].order_count
                                                    }팩`}
                                                />
                                                {openStickerImages ? <MdExpandLess /> : <MdExpandMore />}
                                            </ListItem>
                                        )}
                                        {showItem.contents.sticker_count !== 0 && (
                                            <Collapse isOpen={openStickerImages}>
                                                <div className={"d-flex justify-content-end align-items-center mr-3 mb-3"}>
                                                    <Button
                                                        className={"btn btn-danger"}
                                                        size="sm"
                                                        onClick={() => {
                                                            let zip = new JSZip();
                                                            stickerSVGFiles.map((stickerItem, stickerIndex) => {
                                                                zip.file(`${stickerItem.fileName}`, stickerItem.svg);
                                                            });
                                                            zip.generateAsync({ type: "blob" }).then(function (content) {
                                                                FileSaver.saveAs(content, `${showItem.contents.payment_code}.zip`);
                                                            });
                                                        }}>
                                                        일괄 다운로드
                                                    </Button>
                                                </div>
                                                <div className={"d-flex justify-content-center align-items-center mx-3"}>
                                                    <RenderStickerList orderInfo={showItem.details.orderInfo} userInfo={showItem.contents} />
                                                </div>
                                            </Collapse>
                                        )}
                                    </List>
                                </div>
                            </Collapse>
                        </div>
                        <div className={"ml-3 mr-3 mt-3 shadow-lg p-3  bg-body rounded border"}>
                            <Row onClick={() => setOpenPaymentInfo((prev) => !prev)}>
                                <Col className={"col-10"}>
                                    <span>결제 정보</span>
                                </Col>
                                <Col className={"col-2"}>
                                    <span className={"float-right"}>{openPaymentInfo ? <MdExpandLess /> : <MdExpandMore />}</span>
                                </Col>
                            </Row>
                            <Collapse isOpen={openPaymentInfo} className={"mt-3"}>
                                <Row className={""}>
                                    <Col className={"col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                        <Label for="contents_total_price">결제금액</Label>
                                        <Input type="text" name="total_price" id="contents_total_price" value={numberWithComma(showItem.contents.total_price) + `원`} disabled />
                                    </Col>
                                    <Col className={"col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                        <Label for="contents_payment_date">결제일시</Label>
                                        <Input
                                            type="text"
                                            name="payment_date"
                                            id="contents_payment_date"
                                            value={convertDate({ _originDate: showItem.contents.order_date, _convertFormat: "YY.MM.DD HH:mm" })}
                                            disabled
                                        />
                                    </Col>
                                    <Col className={"col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                        <Label for="contents_payment_code">결제코드</Label>
                                        <Input type="text" name="payment_code" id="contents_payment_code" value={showItem.contents.payment_code} disabled />
                                    </Col>
                                    <Col className={"col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                        <Label for="contents_payment_coupon_used">쿠폰사용</Label>
                                        <Input
                                            type="text"
                                            name="payment_coupon_used"
                                            id="contents_payment_coupon_used"
                                            value={showItem.contents.payment_coupon_used === 0 ? "사용안함" : "사용"}
                                            disabled
                                        />
                                    </Col>
                                    <Col className={"col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                        <Label for="contents_coupon_used_count">쿠폰사용수</Label>
                                        <Input
                                            type="text"
                                            name="coupon_used_count"
                                            id="contents_coupon_used_count"
                                            value={numberWithComma(showItem.contents.coupon_used_count === null ? 0 : showItem.contents.coupon_used_count)}
                                            disabled
                                        />
                                    </Col>
                                    <Col className={"col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                        <Label for="contents_coupon_code">쿠폰코드</Label>
                                        <Input type="text" name="coupon_code" id="contents_coupon_code" value={showItem.contents.coupon_code} disabled />
                                    </Col>
                                </Row>
                            </Collapse>
                        </div>

                        {editMode && (
                            <Row className={"mt-8"}>
                                <Col className={"col-11 mx-auto"}>
                                    <div className={"float-right"}>
                                        <Button
                                            className={"btn btn-default btn-lg"}
                                            style={{ width: 100 }}
                                            onClick={() => {
                                                const validationResult = doValidation();
                                                if (validationResult.errCode === 0) {
                                                    message.current.title = "알림";
                                                    message.current.message = "수정하시겠습니까?";
                                                    message.current.body = null;
                                                    message.current.button = [
                                                        {
                                                            title: "수정",
                                                            action: () => {
                                                                setShowMessage(false);

                                                                modifyPaymentInfo({
                                                                    _paymentCode: showItem.contents.payment_code,
                                                                    _orderState: showItem.contents.order_state,
                                                                    _shippingNumber: showItem.contents.shipping_number,
                                                                    _userAddress: showItem.contents.user_address,
                                                                }).then((modifyPaymentInfoResult) => {
                                                                    if (modifyPaymentInfoResult.data.res_code === 2) {
                                                                        props.onResult({
                                                                            actionCode: modifyPaymentInfoResult.data.res_code,
                                                                            actionName: "edit",
                                                                            changedData: {
                                                                                ...showItem,
                                                                            },
                                                                        });
                                                                    }
                                                                });
                                                            },
                                                            background: "red",
                                                            textColor: "white",
                                                        },
                                                        {
                                                            title: "닫기",
                                                            action: () => {
                                                                setShowMessage(false);
                                                            },
                                                            background: "purple",
                                                            textColor: "white",
                                                        },
                                                    ];
                                                    message.current.body = null;
                                                    message.current.showFooter = true;
                                                    message.current.keyboard = true;
                                                    message.current.renderFooter = null;
                                                    setShowMessage(true);
                                                } else {
                                                    message.current.title = "알림";
                                                    message.current.message = `${validationResult.errMsg}`;
                                                    message.current.button = [
                                                        {
                                                            title: "확인",
                                                            action: () => {
                                                                setShowMessage(false);
                                                            },
                                                            background: "purple",
                                                            textColor: "white",
                                                        },
                                                    ];
                                                    message.current.body = null;
                                                    message.current.showFooter = true;
                                                    message.current.keyboard = true;
                                                    message.current.renderFooter = null;
                                                    setShowMessage(true);
                                                }
                                            }}>
                                            수정
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Card>
                </Container>
                <PhotologDialog
                    isOpen={showMessage}
                    toggle={() => setShowMessage(!showMessage)}
                    title={message.current.title}
                    message={message.current.message}
                    body={message.current.message !== null ? null : message.current.body}
                    button={message.current.button}
                    backdrop={"static"}
                    keyboard={message.current.keyboard}
                    showCenter
                    showFooter={message.current.showFooter}
                    renderFooter={message.current.renderFooter}
                />
                <PostcodeDialog
                    isOpen={showPostcodeDialog}
                    toggle={() => setShowPostcodeDialog(!showPostcodeDialog)}
                    showCenter={true}
                    onEditAction={(data) => {
                        setShowItem({
                            contents: {
                                ...showItem.contents,
                                user_address: data,
                            },
                            details: { ...showItem.details },
                        });
                        toast.success("🎉 수정되었습니다");
                        setShowPostcodeDialog(false);
                    }}
                />

                <Backdrop
                    className={classes.backdrop}
                    open={showBackdrop}
                    // invisible={true}
                    onClick={() => {}}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        );
    }
};

export default PaymentDetail;
