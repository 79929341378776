import React, { Fragment } from "react";
import { History, convertDate, numberWithComma, convertFormat } from "../../modules/utils.js";
import { Button, Card, CardImg, Col, Container, Input, InputGroup, Label, Row } from "reactstrap";
import ToggleSwitch from "../../modules/ToggleSwitch.js";
import lodash from "lodash";
import { makeStyles } from "@material-ui/core/styles";

import { PostcodeDialog } from "../../modules/modal";
import { toast } from "react-toastify";
import { CircularProgress, Backdrop, FormControlLabel, Checkbox } from "@material-ui/core";
import { convertNumberToKorean, checkNumberOnly } from "../../modules/utils";
import { storeStateList, storeTypeList } from "./StoreList.js";
import { StoreTimeSelector } from "../../modules/PhotologSelector";
import { PhotologDialog } from "../../modules/PhotologDialog.js";
import { getStoreDetail, publishStoreInfo, rejectStoreInfo, setStoreState } from "../../lib/storeInfo.js";
import { DropdownList } from "../../modules/PhotologLib.js";
const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const StoreListDetail = (props) => {
    const [showItem, setShowItem] = React.useState(null);
    const [showItemOrigin, setShowItemOrigin] = React.useState(null);
    const [addMode, setAddMode] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);

    const [showMessage, setShowMessage] = React.useState(false);
    const [showPostcodeDialog, setShowPostcodeDialog] = React.useState(false);
    const [showBackdrop, setShowBackdrop] = React.useState(false);

    const [publicState, setPublicState] = React.useState("create");

    const message = React.useRef({
        tag: "",
        title: "",
        message: "",
        body: null,
        button: [],
        showCenter: false,
        showHeader: false,
        large: false,
    });

    const classes = useStyles();

    React.useEffect(() => {
        if (props === undefined) {
            History.replace("/admin/payment");
            window.location.reload();
            return;
        } else {
            if (showItem === null) {
                if (props.mode === "add") {
                } else {
                    getStoreDetail({ _storeId: props.getContentsItem.store_id }).then((result) => {
                        if (result !== null) {
                            switch (result.data.res_code) {
                                case 2:
                                    let userStoreInfo = result.data.result;
                                    setShowItem({
                                        contents: props.getContentsItem,
                                        detail: {
                                            ...userStoreInfo,
                                            imageList:
                                                userStoreInfo.imageList === undefined
                                                    ? []
                                                    : userStoreInfo.imageList.map((item) => {
                                                          return {
                                                              imagePath: item.store_image_path,
                                                              imageOrder: item.store_image_order,
                                                              ...item,
                                                          };
                                                      }),
                                            hoursList:
                                                Boolean(props.getContentsItem.store_hours_showing) && props.getContentsItem.store_type !== "accommodation"
                                                    ? {
                                                          hourMon:
                                                              userStoreInfo.hoursList !== undefined
                                                                  ? userStoreInfo.hoursList[0].hours_mon !== "off"
                                                                      ? { open: userStoreInfo.hoursList[0].hours_mon.split(",")[0], close: userStoreInfo.hoursList[0].hours_mon.split(",")[1] }
                                                                      : "off"
                                                                  : "off",
                                                          hourTue:
                                                              userStoreInfo.hoursList !== undefined
                                                                  ? userStoreInfo.hoursList[0].hours_tue !== "off"
                                                                      ? { open: userStoreInfo.hoursList[0].hours_tue.split(",")[0], close: userStoreInfo.hoursList[0].hours_tue.split(",")[1] }
                                                                      : "off"
                                                                  : "off",
                                                          hourWed:
                                                              userStoreInfo.hoursList !== undefined
                                                                  ? userStoreInfo.hoursList[0].hours_wed !== "off"
                                                                      ? { open: userStoreInfo.hoursList[0].hours_wed.split(",")[0], close: userStoreInfo.hoursList[0].hours_wed.split(",")[1] }
                                                                      : "off"
                                                                  : "off",
                                                          hourThu:
                                                              userStoreInfo.hoursList !== undefined
                                                                  ? userStoreInfo.hoursList[0].hours_thu !== "off"
                                                                      ? { open: userStoreInfo.hoursList[0].hours_thu.split(",")[0], close: userStoreInfo.hoursList[0].hours_thu.split(",")[1] }
                                                                      : "off"
                                                                  : "off",
                                                          hourFri:
                                                              userStoreInfo.hoursList !== undefined
                                                                  ? userStoreInfo.hoursList[0].hours_fri !== "off"
                                                                      ? { open: userStoreInfo.hoursList[0].hours_fri.split(",")[0], close: userStoreInfo.hoursList[0].hours_fri.split(",")[1] }
                                                                      : "off"
                                                                  : "off",
                                                          hourSat:
                                                              userStoreInfo.hoursList !== undefined
                                                                  ? userStoreInfo.hoursList[0].hours_sat !== "off"
                                                                      ? { open: userStoreInfo.hoursList[0].hours_sat.split(",")[0], close: userStoreInfo.hoursList[0].hours_sat.split(",")[1] }
                                                                      : "off"
                                                                  : "off",
                                                          hourSun:
                                                              userStoreInfo.hoursList !== undefined
                                                                  ? userStoreInfo.hoursList[0].hours_sun !== "off"
                                                                      ? { open: userStoreInfo.hoursList[0].hours_sun.split(",")[0], close: userStoreInfo.hoursList[0].hours_sun.split(",")[1] }
                                                                      : "off"
                                                                  : "off",
                                                          hourHoliday:
                                                              userStoreInfo.hoursList !== undefined
                                                                  ? userStoreInfo.hoursList[0].hours_holiday !== "off"
                                                                      ? { open: userStoreInfo.hoursList[0].hours_holiday.split(",")[0], close: userStoreInfo.hoursList[0].hours_holiday.split(",")[1] }
                                                                      : "off"
                                                                  : "off",
                                                      }
                                                    : {
                                                          hourMon: { open: "00:00", close: "00:00" },
                                                          hourTue: { open: "00:00", close: "00:00" },
                                                          hourWed: { open: "00:00", close: "00:00" },
                                                          hourThu: { open: "00:00", close: "00:00" },
                                                          hourFri: { open: "00:00", close: "00:00" },
                                                          hourSat: { open: "00:00", close: "00:00" },
                                                          hourSun: { open: "00:00", close: "00:00" },
                                                          hourHoliday: { open: "00:00", close: "00:00" },
                                                      },
                                            menuList:
                                                userStoreInfo.menuList !== undefined && userStoreInfo.menuList.length === 0
                                                    ? []
                                                    : userStoreInfo.menuList.map((item, index) => {
                                                          return {
                                                              menuIndex: index,
                                                              menuName: item.menu_name,
                                                              menuPrice: item.menu_price,
                                                              menuImage: item.menu_image,
                                                          };
                                                      }),
                                        },
                                    });
                                    setShowItemOrigin({
                                        contents: props.getContentsItem,
                                        detail: result.data.result,
                                    });

                                    setPublicState(props.getContentsItem.store_publish_state);
                                    break;
                                default:
                                    break;
                            }
                        } else {
                            // console.log(" error");
                        }
                    });
                }
            } else {
            }
        }
    }, [showItem, props]);

    if (showItem === null) {
        return <div></div>;
    } else {
        return (
            <Fragment>
                <Container className="mt--7">
                    {addMode ? (
                        <Row>
                            <Col className={"col-12 mx-auto mt-6 m-3"}></Col>
                        </Row>
                    ) : (
                        <Row className="d-flex">
                            <Col className={"col-12 mt-6 mb-3"}>
                                <Row className="d-flex justify-content-end mr-2">
                                    <Button
                                        color="primary"
                                        type="button"
                                        size="md"
                                        onClick={(v) => {
                                            message.current.title = "알림";
                                            // message.current.message = `${selectedRows.length}개의 상태를 변경합니다`;
                                            message.current.message = null;
                                            message.current.showCenter = true;
                                            message.current.body = (
                                                <PublicStateChanger
                                                    defaultValue={{ state: publicState, rejectComment: showItem.contents.store_reject_comment }}
                                                    dataList={storeStateList.filter((state) => state.value !== "create").filter((state) => state.value !== "modify")}
                                                    storeId={showItem.contents.store_id}
                                                    storeType={showItem.contents.store_type}
                                                    onChange={(v) => {
                                                        setShowMessage(false);
                                                        window.location.reload();
                                                    }}
                                                />
                                            );
                                            message.current.button = null;

                                            message.current.showFooter = false;
                                            message.current.keyboard = true;
                                            message.current.renderFooter = null;
                                            setTimeout(() => {
                                                setShowMessage(true);
                                            }, 500);
                                        }}>
                                        상태확인 및 변경
                                    </Button>
                                </Row>
                            </Col>
                        </Row>
                    )}

                    <Card className="shadow border-0 pb-4">
                        <Row className={"mt-3 mx-2"}>
                            <Col className={"col-md-3 col-12"}>
                                <Label for="contents_store_id">상점ID</Label>
                                <Input type="text" name="store_id" id="contents_store_id" value={showItem.contents.store_id} disabled={addMode ? false : !editMode} onChange={(e) => {}} />
                            </Col>
                            <Col className={"col-md-3 col-12"}>
                                <Label for="contents_user_id">유저ID</Label>
                                <Input type="text" name="user_id" id="contents_user_id" value={showItem.contents.user_id} disabled={addMode ? false : !editMode} onChange={(e) => {}} />
                            </Col>
                            <Col className={"col-md-3 col-12"}>
                                <Label for="contents_store_publish_state">상점상태</Label>
                                <Input
                                    type="text"
                                    name="store_publish_state"
                                    id="contents_store_publish_state"
                                    value={lodash.find(storeStateList, { value: showItem.contents.store_publish_state }).name}
                                    disabled={addMode ? false : !editMode}
                                    onChange={(e) => {}}
                                />
                            </Col>
                            <Col className={"col-md-3 col-12"}>
                                <Label for="contents_store_type">상점종류</Label>
                                <Input
                                    type="text"
                                    name="store_type"
                                    id="contents_store_type"
                                    value={lodash.find(storeTypeList, { value: showItem.contents.store_type }).name}
                                    disabled={addMode ? false : !editMode}
                                    onChange={(e) => {}}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-3 mx-2"}>
                            <Col className={"col-md-6 col-12"}>
                                <Label for="contents_store_name">상점명</Label>
                                <Input type="text" name="store_name" id="contents_store_name" value={showItem.contents.store_name} disabled={addMode ? false : !editMode} onChange={(e) => {}} />
                            </Col>
                            <Col className={"col-md-6 col-12"}>
                                <Label for="contents_store_phone">전화번호</Label>
                                <Input type="text" name="store_phone" id="contents_store_phone" value={showItem.contents.store_phone} disabled={addMode ? false : !editMode} onChange={(e) => {}} />
                            </Col>
                        </Row>
                        <Row className={"mt-3 mx-2"}>
                            <Col className={"col-md-2 col-12"}>
                                <Label for="contents_store_region">지역</Label>
                                <Input
                                    type="text"
                                    name="store_region"
                                    id="contents_store_region"
                                    value={lodash.find(props.regionList, ["standard_map_code", showItem.contents.store_region]).standard_region_name}
                                    disabled={addMode ? false : !editMode}
                                    onChange={(e) => {}}
                                />
                            </Col>

                            <Col className={"col-md-10 col-12"}>
                                <Label for="contents_store_post_code">상점주소</Label>
                                <Input
                                    type="text"
                                    name="store_post_code"
                                    id="contents_store_post_code"
                                    value={`(${showItem.contents.store_post_code}) ${showItem.contents.store_address}${
                                        showItem.contents.store_address_detail === null ? "" : `, ${showItem.contents.store_address_detail}`
                                    }`}
                                    disabled={addMode ? false : !editMode}
                                    onChange={(e) => {}}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-3 mx-2"}>
                            <Col className={"col-12"}>
                                <Label for="contents_store_desc">간단설명</Label>
                                <Input type="text" name="store_desc" id="contents_store_desc" value={showItem.contents.store_desc} disabled={addMode ? false : !editMode} onChange={(e) => {}} />
                            </Col>
                        </Row>
                        <Row className={"mt-3 mx-2"}>
                            <Col className={"col-12"}>
                                <Label for="contents_store_desc_detail">설명</Label>
                                <Input
                                    type="textarea"
                                    name="store_desc_detail"
                                    id="contents_store_desc_detail"
                                    value={showItem.contents.store_desc_detail}
                                    disabled={addMode ? false : !editMode}
                                    style={{ minHeight: 150 }}
                                    onChange={(e) => {}}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-3 mx-2"}>
                            <Col className={"col-12"}>
                                <Label for="contents_store_main_image">상점대표사진</Label>

                                {showItem.contents.store_main_image !== null && (
                                    <Row className="mb-2">
                                        <StoreImageItem
                                            className={"col-12  d-flex justify-content-center align-content-center"}
                                            type={"thumbnail"}
                                            item={showItem.contents.store_main_image}
                                            viewMode={"view"}
                                            onDelete={(path) => {}}
                                        />
                                    </Row>
                                )}
                            </Col>
                        </Row>
                        <Row className={"mt-3 mx-2"}>
                            <Col className={"col-12"}>
                                <Label for="contents_imageList">사업장 사진</Label>

                                <Row>
                                    {showItem.detail.imageList.length > 0 &&
                                        showItem.detail.imageList.map((item, index) => {
                                            return <StoreImageItem key={`store-info-image-list-item-${index}`} className={"col-lg-4 col-md-6 col-12"} index={index} item={item} viewMode={"view"} />;
                                        })}
                                </Row>
                            </Col>
                        </Row>

                        <Row className={"mt-3 mx-4"}>
                            <FormControlLabel
                                value={showItem.contents.store_menu_showing}
                                control={
                                    <Checkbox
                                        checked={Boolean(showItem.contents.store_menu_showing)}
                                        onChange={(e) => {
                                            // if (this.props.viewMode !== "view") this.setState({ storeInfo: { ...this.state.storeInfo, storeMenuShowing: !this.state.storeInfo.storeMenuShowing } });
                                        }}
                                    />
                                }
                                label={showItem.contents.store_type === "accommodation" ? "객실정보를 공개합니다" : "주요상품 또는 메뉴를 공개합니다"}
                            />
                        </Row>
                        {Boolean(showItem.contents.store_menu_showing) && (
                            <Row className={"mx-2"}>
                                <Col className={"col-12"}>
                                    <Label for="contents_popup_title">{showItem.contents.store_type === "accommodation" ? "객실정보" : "주요상품 또는 메뉴"}</Label>

                                    <Row id={"store_product"}>
                                        {showItem.detail.menuList.length > 0 &&
                                            showItem.detail.menuList.map((item, index) => {
                                                return (
                                                    <ProductItem
                                                        key={`store_product-${item.menuIndex}`}
                                                        className={"col-md-4 col-12"}
                                                        index={item.menuIndex}
                                                        item={item}
                                                        type={showItem.contents.store_type}
                                                        viewMode={"view"}
                                                    />
                                                );
                                            })}
                                    </Row>
                                </Col>
                            </Row>
                        )}

                        <Row className={"mt-3 mx-4"}>
                            <FormControlLabel
                                value={showItem.contents.store_hours_showing}
                                control={
                                    <Checkbox
                                        checked={Boolean(showItem.contents.store_hours_showing)}
                                        onChange={(e) => {
                                            // if (this.props.viewMode !== "view") this.setState({ storeInfo: { ...this.state.storeInfo, storeMenuShowing: !this.state.storeInfo.storeMenuShowing } });
                                        }}
                                    />
                                }
                                label={"영업시간을 공개합니다"}
                            />
                        </Row>
                        <Row className={"mx-2"}>
                            <StoreTimeSelector
                                storeType={showItem.contents.store_type}
                                storeHoursType={showItem.contents.store_hours_type}
                                viewMode={"view"}
                                show={Boolean(showItem.contents.store_hours_showing)}
                                value={
                                    showItem.contents.store_type === "accommodation"
                                        ? showItem.detail.checkTime === null
                                            ? { checkIn: null, checkOut: null }
                                            : { checkIn: showItem.detail.checkTime[0].check_in_time, checkOut: showItem.detail.checkTime[0].check_out_time }
                                        : showItem.detail.hoursList
                                }
                            />
                        </Row>
                        <Row className={"mt-3 mx-4"}>
                            <FormControlLabel
                                value={showItem.contents.store_url_link_showing}
                                control={
                                    <Checkbox
                                        checked={Boolean(showItem.contents.store_url_link_showing)}
                                        onChange={(e) => {
                                            // if (this.props.viewMode !== "view") this.setState({ storeInfo: { ...this.state.storeInfo, storeMenuShowing: !this.state.storeInfo.storeMenuShowing } });
                                        }}
                                    />
                                }
                                label={"소개페이지 URL을 공개합니다"}
                            />
                        </Row>
                        {Boolean(showItem.contents.store_url_link_showing) && (
                            <Row className={"mx-2"}>
                                <Col className={"col-12"}>
                                    <Label for="contents_store_url_link">소개페이지 URL</Label>
                                    <Input
                                        type="text"
                                        name="store_url_link"
                                        id="contents_store_url_link"
                                        value={showItem.contents.store_url_link}
                                        disabled={addMode ? false : !editMode}
                                        onChange={(e) => {}}
                                    />
                                </Col>
                            </Row>
                        )}
                    </Card>
                </Container>
                <PhotologDialog
                    isOpen={showMessage}
                    toggle={() => setShowMessage(!showMessage)}
                    title={message.current.title}
                    message={message.current.message}
                    body={message.current.message !== null ? null : message.current.body}
                    button={message.current.button}
                    backdrop={"static"}
                    keyboard={message.current.keyboard}
                    showCenter
                    showFooter={message.current.showFooter}
                    renderFooter={message.current.renderFooter}
                />
                <PostcodeDialog
                    isOpen={showPostcodeDialog}
                    toggle={() => setShowPostcodeDialog(!showPostcodeDialog)}
                    showCenter={true}
                    onEditAction={(data) => {
                        setShowItem({
                            contents: {
                                ...showItem.contents,
                                user_address: data,
                            },
                            details: { ...showItem.details },
                        });
                        toast.success("🎉 수정되었습니다");
                        setShowPostcodeDialog(false);
                    }}
                />

                <Backdrop
                    className={classes.backdrop}
                    open={showBackdrop}
                    // invisible={true}
                    onClick={() => {}}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Fragment>
        );
    }
};

export default StoreListDetail;

class ProductItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            priceKorean: "",
        };
    }

    componentDidMount() {
        if (this.props.item.menuPrice !== null && String(this.props.item.menuPrice).length > 0) {
            this.setState({ priceKorean: convertNumberToKorean(this.props.item.menuPrice) });
        }
    }
    componentWillUnmount() {}
    componentDidUpdate(prevProps) {
        if (this.props.item.menuPrice !== null && String(this.props.item.menuPrice).length > 0) {
            this.setState({ priceKorean: convertNumberToKorean(this.props.item.menuPrice) });
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.type !== nextProps.type) {
            return true;
        }
        if (this.props.item !== nextProps.item) {
            return true;
        }
        if (this.props.viewMode !== nextProps.viewMode) {
            return true;
        }
        if (this.state.priceKorean !== nextState.priceKorean) {
            return true;
        }
        return false;
    }
    render() {
        return (
            <Col className={`${this.props.className} my-2 `} key={`ProductItem-${this.props.viewMode}`}>
                <Card className={"mx-auto py-2"}>
                    <div className={"mx-auto mx-2 my-2"} style={{ width: "90%", height: 200 }}>
                        <div className={"mx-auto py-2"} style={{ width: "90%", height: 200 }}>
                            {this.props.item.menuImage !== null && this.props.item.menuImage !== "null" ? (
                                <div>
                                    <img
                                        src={this.props.item.menuImage}
                                        style={{
                                            flex: 1,
                                            width: "100%",
                                            height: 200,
                                            overflow: "hidden",
                                            objectFit: "cover",
                                            borderRadius: 8,
                                        }}
                                        alt={""}
                                    />
                                </div>
                            ) : (
                                <Card
                                    className="d-flex"
                                    style={{
                                        width: "100%",
                                        height: 200,
                                        overflow: "hidden",
                                        backgroundColor: "#fafafa",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                    <div className="text-center text-muted">
                                        <span style={{ fontSize: 12 }}>
                                            등록된 사진이
                                            <br />
                                            없습니다
                                        </span>
                                    </div>
                                </Card>
                            )}
                        </div>
                    </div>

                    <InputGroup className="mx-auto input-group-alternative m-2 " style={{ width: "85%" }}>
                        <Input
                            placeholder={this.props.type === "accommodation" ? "객실이름" : "상품명 또는 메뉴명"}
                            type="text"
                            value={this.props.item.menuName}
                            maxLength={20}
                            onChange={(e) => {
                                if (this.props.viewMode !== "view") this.props.onChangeValue({ itemKey: "menuName", value: e.target.value });
                            }}
                        />
                    </InputGroup>
                    <InputGroup className="mx-auto input-group-alternative m-2 " style={{ width: "85%", background: "white !important" }}>
                        <Input
                            placeholder="금액"
                            type={this.props.viewMode === "view" ? "text" : "number"}
                            value={this.props.viewMode === "view" ? `${numberWithComma(this.props.item.menuPrice)}원` : this.props.item.menuPrice}
                            onChange={(e) => {
                                if (this.props.viewMode !== "view" && checkNumberOnly(e.target.value) && e.target.value < 10000000) {
                                    this.props.onChangeValue({ itemKey: "menuPrice", value: e.target.value });
                                    this.setState({ priceKorean: convertNumberToKorean(e.target.value) });
                                }
                            }}
                        />
                    </InputGroup>
                    {this.props.viewMode !== "view" && (
                        <div className="d-flex justify-content-end mr-4">
                            <span className="text-muted text-sm">{this.state.priceKorean} 원</span>
                        </div>
                    )}

                    {this.props.viewMode === "view" ? null : (
                        <div className="d-flex justify-content-end align-items-center mr-4 py-2">
                            <Button
                                color="primary"
                                type="button"
                                size="sm"
                                outline
                                onClick={() => {
                                    this.props.onChangeValue({ itemKey: "menuImage", value: null });
                                }}>
                                사진제거
                            </Button>
                            <Button
                                color="danger"
                                type="button"
                                size="sm"
                                outline
                                onClick={() => {
                                    this.props.onDeleteValue(this.props.index);
                                }}>
                                상품삭제
                            </Button>
                        </div>
                    )}
                </Card>
            </Col>
        );
    }
}
class StoreImageItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            storeImage: [],
            previews: [],
        };
    }

    componentDidMount() {}
    componentWillUnmount() {}
    componentDidUpdate(prevProps) {}
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.item !== nextProps.item) {
            return true;
        }
        if (this.props.viewMode !== nextProps.viewMode) {
            return true;
        }
        return false;
    }
    render() {
        if (this.props.type === "thumbnail") {
            return (
                <Col className={`${this.props.className} my-2`} style={{ width: "100%", height: "auto", maxHeight: 400 }}>
                    <Card style={{ width: "100%", height: "auto", maxHeight: 400 }}>
                        <CardImg
                            top
                            src={this.props.item}
                            className="rounded"
                            alt=""
                            style={{
                                flex: 1,
                                width: "100%",
                                height: 400,
                                overflow: "hidden",
                                borderWidth: 1,
                                objectFit: "cover",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        />
                        {this.props.viewMode === "view" ? null : (
                            <div className="d-flex justify-content-end align-items-center py-2 mr-2">
                                <Button
                                    color="primary"
                                    type="button"
                                    size="sm"
                                    outline
                                    onClick={() => {
                                        this.props.onDelete(this.props.item);
                                    }}>
                                    사진제거
                                </Button>
                            </div>
                        )}
                    </Card>
                </Col>
            );
        }

        return (
            <Col className={`${this.props.className} my-2`}>
                <Card style={{ minHeight: 300, maxHeight: 300 }}>
                    <CardImg
                        top
                        src={this.props.item.imagePath}
                        alt=""
                        style={{
                            flex: 1,
                            width: "100%",
                            height: 300,
                            overflow: "hidden",
                            objectFit: "cover",
                            borderRadius: 8,
                        }}
                    />
                    {this.props.viewMode === "view" ? null : (
                        <div className="d-flex justify-content-end align-items-center py-2 mr-1">
                            <Button
                                color="primary"
                                type="button"
                                size="sm"
                                outline
                                onClick={() => {
                                    this.props.onDelete(this.props.item.imagePath);
                                }}>
                                사진제거
                            </Button>
                        </div>
                    )}
                </Card>
            </Col>
        );
    }
}

StoreImageItem.defaultProps = {
    type: "default",
};

class PublicStateChanger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            publicState: null,
            dataList: [],
            rejectComment: null,
        };
    }
    componentDidMount() {
        this.setState({ publicState: this.props.defaultValue.state, rejectComment: this.props.defaultValue.rejectComment, dataList: this.props.dataList });
    }
    componentWillUnmount() {}
    componentDidUpdate(prevProps) {}
    // shouldComponentUpdate(nextProps, nextState) {
    //  return false;
    // }

    doChangePublicState() {
        if (this.state.publicState === "reject") {
            rejectStoreInfo({ _rejectComment: this.state.rejectComment, _storeId: this.props.storeId }).then((result) => {
                // console.log("rejectStoreInfo result=>", result);
                if (result !== null) {
                    switch (result.data.res_code) {
                        case 2:
                            this.props.onChange();
                            break;
                        default:
                            break;
                    }
                } else {
                    // console.log("rejectStoreInfo error");
                }
            });
        }
        // 검토중
        else if (this.state.publicState === "proceeding") {
            setStoreState({ _storeId: this.props.storeId }).then((result) => {
                // console.log("setStoreState result=>", result);
                if (result !== null) {
                    switch (result.data.res_code) {
                        case 2:
                            this.props.onChange();
                            break;
                        default:
                            break;
                    }
                } else {
                    // console.log("publishStoreInfo error");
                }
            });
        } else if (this.state.publicState === "publish") {
            publishStoreInfo({ _storeId: this.props.storeId, _storeType: this.props.storeType }).then((result) => {
                // console.log("publishStoreInfo result=>", result);
                if (result !== null) {
                    switch (result.data.res_code) {
                        case 2:
                            this.props.onChange();
                            break;
                        default:
                            break;
                    }
                } else {
                    // console.log("publishStoreInfo error");
                }
            });
        }
    }

    render() {
        return (
            <div>
                <Row className="d-flex justify-content-center mb-4">
                    <Col>다음의 상태로 변경합니다</Col>
                </Row>
                <Row>
                    <Col className={"ml--2 mt-2"}>
                        <Label for="contents_change_public_state">상점상태</Label>
                        <DropdownList
                            id="contents_change_public_state"
                            className="col-12"
                            dataList={this.state.dataList}
                            defaultValue={this.state.publicState}
                            defaultTitle={"상태"}
                            onChange={(selectItem) => {
                                this.setState({ publicState: selectItem.value });
                            }}
                        />
                    </Col>
                </Row>

                {this.state.publicState === "reject" && (
                    <Row className="mt-2">
                        <Col className={"ml--2 mt-2"}>
                            <Label for="contents_reject_comment">반려사유</Label>
                            <Input
                                className=""
                                id="reject_comment"
                                type="textarea"
                                value={this.state.rejectComment}
                                style={{ minHeight: 150 }}
                                onChange={(e) => {
                                    this.setState({ rejectComment: e.target.value });
                                }}
                            />
                        </Col>
                    </Row>
                )}

                <div className="modal-footer">
                    <Button key={`modal-button-1`} style={{ background: "purple", color: "white" }} onClick={() => this.doChangePublicState()}>
                        변경
                    </Button>
                </div>
            </div>
        );
    }
}
