import React, { Fragment } from "react";
import { customFilter } from "react-bootstrap-table2-filter";

import { Button, Card, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import lodash from "lodash";
import NormalHeader from "../../components/Headers/NormalHeader.js";
import PaymentDetail from "./PaymentDetail";

import { convertDate, convertFormat, numberWithComma } from "../../modules/utils.js";
import { PhotologDialog } from "../../modules/PhotologDialog.js";

import { ToastContainer, toast } from "react-toastify";
import * as Bi from "react-icons/bi";
import { makeStickerSVG } from "../../modules/svg/makeStickerSVG.js";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { getOrderList, getPaymentData, modifyOrderState } from "../../lib/payment.js";
import { getProductList } from "../../lib/basic.js";
import XLSX from "xlsx";
import JSZip from "jszip";
import FileSaver from "file-saver";

import PhotologTable from "../../modules/PhotologTable.js";
export function makeZipFile({ orderList = null, userName = null, userPhone = null, multi = null, zip = new JSZip() }) {
    let toastId = null;

    function makeFile(svg, count) {
        return Array(count)
            .fill(0)
            .map((item, index) => {
                let zeroPadIndex = index < 10 ? `00${index}` : index < 100 ? `0${index}` : `${index}`;
                let fileName = `${zeroPadIndex}-${String(svg.fileName).replace(" ", "")}`;
                return zip.file(
                    fileName,
                    new File([`${svg.svgString}`], fileName, {
                        type: "text/plain;charset=utf-8",
                    }),
                );
            });
    }

    function makeZip(zipName = convertDate({ _originDate: new Date(), _convertFormat: "YYYYMMDDHHmmss" })) {
        zip.generateAsync({ type: "blob" }).then(function (content) {
            FileSaver.saveAs(content, `${zipName}.zip`);
            toast.update(toastId, {
                progress: 0.999,
                render: "🎉 변환이 완료되었습니다",
            });

            setTimeout(() => {
                toast.done(toastId);
                toastId = null;
            }, 2000);
        });
    }

    if (multi === null && (orderList === null || userName === null || userPhone === null)) {
        toastId = toast("🤔 에러가 발생되었습니다.", {
            progress: 0,
            autoClose: false,
            closeButton: false,
            type: toast.TYPE.ERROR,
        });

        setTimeout(() => {
            toast.done(toastId);
            toastId = null;
        }, 2000);
    } else {
        if (toastId === null) {
            toastId = toast("🛠 변환중...", {
                progress: 0,
                autoClose: false,
                closeButton: false,
            });
        }
        let svgFiles = [];
        if (multi === null) {
            let loginmapList = orderList.filter((item) => item.product_code !== "loginmap_map");
            lodash.uniqBy(loginmapList, "map_url").map(async (uniqMapOrderItem, uniqMapOrderIndex) => {
                if (uniqMapOrderItem.map_url !== null) {
                    await makeStickerSVG({
                        url: uniqMapOrderItem.map_url,
                        codeArr: lodash.groupBy(loginmapList, "map_url")[uniqMapOrderItem.map_url].map((orderItem) => {
                            return orderItem.region_code;
                        }),
                        name: userName,
                        phoneNum: userPhone,
                    })
                        .then((makeStickerSVGResult) => {
                            svgFiles.push(
                                makeStickerSVGResult.map((SVGItem) => {
                                    return makeFile(SVGItem, uniqMapOrderItem.order_count);
                                }),
                            );
                            if (lodash.flatMapDeep(svgFiles).length === lodash.sumBy(loginmapList, "order_count")) {
                                makeZip(uniqMapOrderItem.payment_code);
                            } else {
                                toast.update(toastId, {
                                    progress: lodash.flatMapDeep(svgFiles).length / lodash.sumBy(loginmapList, "order_count"),
                                });
                            }
                        })
                        .catch((err) => {
                            toast.update(toastId, {
                                progress: 0,
                                type: toast.TYPE.ERROR,
                                render: "🤔 에러가 발생되었습니다",
                            });

                            setTimeout(() => {
                                toast.done(toastId);
                                toastId = null;
                            }, 2000);
                        });
                }
            });
        } else {
            let orderCount = lodash.sum(
                multi.map((multiItem) => {
                    let loginmapList = multiItem.orderList.filter((item) => item.product_code !== "loginmap_map");
                    return lodash.sumBy(loginmapList, "order_count");
                }),
            );

            multi.map((multiItem) => {
                let loginmapList = multiItem.orderList.filter((item) => item.product_code !== "loginmap_map");

                lodash.uniqBy(loginmapList, "map_url").map((uniqMapOrderItem, uniqMapOrderIndex) => {
                    makeStickerSVG({
                        url: uniqMapOrderItem.map_url,
                        codeArr: lodash.groupBy(loginmapList, "map_url")[uniqMapOrderItem.map_url].map((orderItem) => {
                            return orderItem.region_code;
                        }),
                        name: multiItem.userName,
                        phoneNum: multiItem.userPhone,
                    })
                        .then((makeStickerSVGResult) => {
                            svgFiles.push(
                                makeStickerSVGResult.map((SVGItem) => {
                                    return makeFile(SVGItem, uniqMapOrderItem.order_count);
                                }),
                            );
                            if (lodash.flatMapDeep(svgFiles).length === orderCount) {
                                makeZip();
                            } else {
                                toast.update(toastId, {
                                    progress: lodash.flatMapDeep(svgFiles).length / orderCount,
                                });
                            }
                        })
                        .catch((err) => {
                            toast.update(toastId, {
                                progress: 0,
                                type: toast.TYPE.ERROR,
                                render: "🤔 에러가 발생되었습니다",
                            });

                            setTimeout(() => {
                                toast.done(toastId);
                                toastId = null;
                            }, 2000);
                        });
                });
            });
        }
    }
}

async function makeXlsx(data) {
    let xlsxData = await data
        .filter((item) => item.order_state === "product_confirm")
        .map((item) => {
            let purchasedItemText = "";
            if (item.logcard_count !== 0) {
                purchasedItemText += `로그카드 ${item.logcard_count}장`;
            }
            if (item.loginmap_count !== 0) {
                if (purchasedItemText !== "") purchasedItemText += ` + `;
                purchasedItemText += `지도 ${item.loginmap_count}장`;
            }
            if (item.sticker_count !== 0) {
                if (purchasedItemText !== "") purchasedItemText += ` + `;
                purchasedItemText += `스티커 ${item.sticker_count}장`;
            }

            // return {
            //     받는분성명: item.user_real_name,
            //     받는분전화번호: convertFormat("phone", item.user_phone),
            //     받는분기타연락처: "",
            //     받는분주소: item.user_address.replaceAll(item.user_address.substring(0, 8), "").trim(),
            //     품목명: purchasedItemText,
            //     배송메세지1: "",
            // };
            // CJ 편의점택배 접수용
            return {
                name: item.user_real_name,
                postcode: item.user_address.substring(1, 6),
                address1: item.user_address.replaceAll(item.user_address.substring(0, 8), "").split(",")[0].trim(),
                address2: item.user_address.replaceAll(item.user_address.substring(0, 8), "").split(",")[1].trim(),
                phone: convertFormat("phone", item.user_phone),
                morePhone: "",
                requestMsg: "",
                priceType: "선불",
            };
        });

    var ws = XLSX.utils.json_to_sheet(xlsxData, { skipHeader: false });
    // 셀의 가로길이 지정
    ws["!cols"] = [{ wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 400 }, { wpx: 200 }, { wpx: 100 }];
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "송장접수용");
    ["수취인명", "우편번호", "주소1", "주소2", "전화번호", "추가전화번호", "배송요청사항", "지불방법"].forEach((x, idx) => {
        const cellAdd = XLSX.utils.encode_cell({ c: idx, r: 0 });
        ws[cellAdd].v = x;
    });
    await XLSX.writeFile(wb, `[송장접수용]_${convertDate({ _originDate: new Date(), _convertFormat: "YYYYMMDD_HHmmss" })}.xlsx`);
}
class OrderStateFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            selectedFilterValue: -2,
        };
    }
    render() {
        return (
            <Dropdown size="sm" isOpen={this.state.dropdownOpen} toggle={() => this.setState({ dropdownOpen: !this.state.dropdownOpen })}>
                <DropdownToggle caret> {lodash.find(this.props.filters, { statusCode: this.state.selectedFilterValue }).label} </DropdownToggle>
                <DropdownMenu>
                    {this.props.filters.map((option) => {
                        return (
                            <DropdownItem
                                key={`filter-items-${option.value}`}
                                onClick={() => {
                                    if (this.props.onFilter !== undefined) {
                                        this.props.onFilter(option.value);
                                    } else if (this.props.onChangeState !== undefined) {
                                        this.props.onChangeState(option.value);
                                    }
                                    this.setState({ selectedFilterValue: option.statusCode });
                                }}>
                                <span className={"badge rounded-pill"} style={{ background: option.bgColor, color: option.textColor }}>
                                    {option.label}
                                </span>
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </Dropdown>
        );
    }
}

const Payment = () => {
    const [productList, setProductList] = React.useState(null);
    const [showMessage, setShowMessage] = React.useState(false);

    const selectedOrderStateRef = React.useRef(null);
    const message = React.useRef({
        tag: "",
        title: "",
        message: "",
        body: null,
        button: [],
        showCenter: false,
        showHeader: false,
        large: false,
    });
    const columns = [
        // {
        //     dataField: "payment_code",
        //     text: "주문코드",
        //     headerStyle: { width: "8%" },
        //     style: (cell, row, rowIndex, colIndex) => {
        //         return {
        //             whiteSpace: "nowrap",
        //             textOverflow: "ellipsis",
        //             overflow: "hidden",
        //         };
        //     },
        //     classes: "d-none d-lg-table-cell",
        //     headerClasses: "d-none d-lg-table-cell",
        // },
        {
            dataField: "user_real_name",
            text: "주문자",
            headerStyle: { width: "15%" },
            headerAlign: (column, colIndex) => "left",
            align: (cell, row, rowIndex, colIndex) => {
                return "left";
            },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
            formatter: (cell, row) => {
                return (
                    <div>
                        <Row>
                            {row.user_real_name !== null && (
                                <Col style={{ fontSize: 12 }}>
                                    {row.user_real_name}{" "}
                                    {row.coupon_code !== null && (
                                        <span style={{ fontSize: 12, color: "blue" }}>
                                            {String(row.coupon_code).startsWith("INFC") ? "(인플루언서 확인 필요)" : String(row.coupon_code).startsWith("SPCL") ? "(스마트스토어 확인 필요)" : null}
                                        </span>
                                    )}
                                </Col>
                            )}
                        </Row>
                        <Row>{row.payment_code !== null && <Col style={{ fontSize: 12, color: "red" }}>{row.payment_code}</Col>}</Row>
                    </div>
                );
            },
        },

        {
            dataField: "product_code",
            text: "주문상품",
            headerStyle: { width: "15%" },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
            formatter: (cell, row) => {
                return (
                    <div>
                        <span>
                            {lodash.find(productList, { product_code: cell }).product_name} {row.order_list_count === 1 ? "" : `외 ${row.order_list_count - 1}종`}
                        </span>

                        <Row>{row.logcard_count !== 0 && <Col style={{ fontSize: 12, color: "red" }}>카드: {row.logcard_count}장</Col>}</Row>
                        <Row>{row.sticker_count !== 0 && <Col style={{ fontSize: 12, color: "red" }}>스티커: {row.sticker_count}장</Col>}</Row>
                        <Row>{row.loginmap_count !== 0 && <Col style={{ fontSize: 12, color: "red" }}>지도: {row.loginmap_count}장</Col>}</Row>
                    </div>
                );
            },
        },
        {
            dataField: "total_price",
            text: "결제정보",
            headerStyle: { width: "15%" },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
            formatter: (cell, row) => {
                return (
                    <div>
                        <Row>{row.coupon_code !== null && <Col style={{ fontSize: 12 }}>{row.coupon_code}</Col>}</Row>
                        <Row>{<Col style={{ fontSize: 12, color: "red" }}>{numberWithComma(row.total_price)}원</Col>}</Row>
                    </div>
                );
            },
        },
        {
            dataField: "order_state",
            text: "",
            headerStyle: { width: "10%" },
            headerAlign: (column, colIndex) => "left",
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
            formatter: (cell, row) => {
                return (
                    <span className={"badge rounded-pill"} style={{ background: lodash.find(paymentStatus, { value: cell }).bgColor, color: lodash.find(paymentStatus, { value: cell }).textColor }}>
                        {lodash.find(paymentStatus, { value: cell }).label}
                    </span>
                );
            },
            filter: customFilter(),
            filterRenderer: (onFilter, column) => <OrderStateFilter onFilter={onFilter} column={column} filters={paymentStatus} />,
        },
        {
            dataField: "",
            text: "다운로드",
            headerStyle: { width: "8%" },
            headerAlign: (column, colIndex) => "center",
            align: (cell, row, rowIndex, colIndex) => {
                return "center";
            },
            classes: "d-none d-lg-table-cell",
            headerClasses: "d-none d-lg-table-cell",
            formatter: (cell, row) => {
                if (row.has_loginmap_sticker === 1 && paymentItemStatus(row.order_state).statusCode >= 0 && paymentItemStatus(row.order_state).statusCode !== 5) {
                    return (
                        <div>
                            <Button
                                className="btn btn-danger"
                                size="sm"
                                onClick={(e) => {
                                    e.stopPropagation(); // rowEvent를 막기위한 액션
                                    getOrderList({ _paymentCode: row.payment_code }).then((getOrderListResult) => {
                                        if (getOrderListResult.data.res_code === 2) {
                                            makeZipFile({ orderList: getOrderListResult.data.result, userName: row.user_real_name, userPhone: row.user_phone });
                                        }
                                    });
                                }}>
                                <Bi.BiDownload />
                            </Button>
                        </div>
                    );
                } else return <div />;
            },
        },
    ];

    React.useEffect(() => {
        getProductList().then((getProductListResult) => {
            if (getProductListResult !== null && getProductListResult.data.res_code === 2) {
                setTimeout(() => {
                    setProductList(getProductListResult.data.result);
                }, 1000);
            }
        });
    }, []);

    return (
        <div>
            <NormalHeader />
            <Container className="mt--7 pb-7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow border-0 pt-4 pb-4" style={{ minHeight: 300 }}>
                            {productList !== null ? (
                                <PhotologTable
                                    category={7}
                                    keyField={"payment_code"}
                                    columns={columns}
                                    rowClickEvent={(e, row, rowIndex) => {
                                        return getOrderList({ _paymentCode: row.payment_code });
                                    }}
                                    detailPage={PaymentDetail}
                                    getData={({ _category, _size, _pageNo }) => {
                                        return getPaymentData({ _category: _category, _size: _size, _pageNo: _pageNo });
                                    }}
                                    enableSelectRow
                                    bottomButton={(selectedRows) => {
                                        return (
                                            <Fragment>
                                                <Button
                                                    className="btn btn-danger"
                                                    size="sm"
                                                    onClick={() => {
                                                        const filteredSelectedRows = selectedRows.filter(
                                                            (item) =>
                                                                paymentItemStatus(item.order_state).statusCode >= 0 &&
                                                                paymentItemStatus(item.order_state).statusCode !== 5 &&
                                                                item.has_loginmap_sticker === 1,
                                                        );
                                                        let orderItems = [];
                                                        filteredSelectedRows.map(async (filteredItem, filteredItemIndex) => {
                                                            getOrderList({ _paymentCode: filteredItem.payment_code })
                                                                .then((getOrderListResult) => {
                                                                    if (getOrderListResult.data.res_code === 2) {
                                                                        return {
                                                                            orderList: getOrderListResult.data.result,
                                                                            userName: filteredItem.user_real_name,
                                                                            userPhone: filteredItem.user_phone,
                                                                        };
                                                                    }
                                                                })
                                                                .then((orderItem) => {
                                                                    orderItems.push(orderItem);
                                                                    if (orderItems.length === filteredSelectedRows.length) {
                                                                        makeZipFile({ multi: orderItems });
                                                                    }
                                                                });
                                                        });
                                                    }}>
                                                    일괄 다운로드
                                                </Button>
                                                <Button
                                                    className="btn btn-danger"
                                                    size="sm"
                                                    onClick={() => {
                                                        if (selectedRows.length === 0) {
                                                            // 선택된 항목 없음
                                                        } else {
                                                            message.current.title = "알림";
                                                            // message.current.message = `${selectedRows.length}개의 상태를 변경합니다`;
                                                            message.current.showCenter = true;
                                                            message.current.message = null;
                                                            message.current.body = (
                                                                <div>
                                                                    <Row>
                                                                        <Col>선택된 항목 주문상태를</Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className={"ml--2 mt-2"}>
                                                                            <OrderStateFilter
                                                                                onChangeState={(value) => {
                                                                                    selectedOrderStateRef.current = value;
                                                                                }}
                                                                                filters={paymentStatus}
                                                                            />
                                                                            <span>상태로 변경합니다</span>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            );
                                                            message.current.button = [
                                                                {
                                                                    title: "변경",
                                                                    action: () => {
                                                                        setShowMessage(false);
                                                                        modifyOrderState({ _orderList: selectedRows, _orderState: selectedOrderStateRef.current }).then((modifyOrderStateResult) => {
                                                                            if (modifyOrderStateResult.data.res_code === 2) {
                                                                                toast.success("🎉 수정되었습니다");
                                                                                setTimeout(() => {
                                                                                    window.location.reload();
                                                                                }, 1500);
                                                                            }
                                                                        });
                                                                    },
                                                                    background: "red",
                                                                    textColor: "white",
                                                                },
                                                                {
                                                                    title: "닫기",
                                                                    action: () => {
                                                                        setShowMessage(false);
                                                                    },
                                                                    background: "purple",
                                                                    textColor: "white",
                                                                },
                                                            ];
                                                            selectedOrderStateRef.current = null;

                                                            message.current.showFooter = true;
                                                            message.current.keyboard = true;
                                                            message.current.renderFooter = null;
                                                            setShowMessage(true);
                                                        }
                                                    }}>
                                                    상태 일괄변경
                                                </Button>
                                                <Button
                                                    className="btn btn-success"
                                                    size="sm"
                                                    onClick={() => {
                                                        try {
                                                            if (selectedRows.length !== 0) {
                                                                makeXlsx(selectedRows);
                                                            }
                                                        } catch (e) {}
                                                    }}>
                                                    송장접수용
                                                </Button>
                                            </Fragment>
                                        );
                                    }}
                                />
                            ) : (
                                <div className={"d-flex justify-content-center align-items-center"} style={{ minHeight: 300 }}>
                                    <CircularProgress color="inherit" />
                                </div>
                            )}
                        </Card>
                    </div>
                </Row>
            </Container>
            <PhotologDialog
                isOpen={showMessage}
                toggle={() => setShowMessage(!showMessage)}
                title={message.current.title}
                body={message.current.body}
                button={message.current.button}
                showCenter={message.current.showCenter}
                showHeader={message.current.showHeader}
                large={message.current.large}
                backdrop={"static"}
            />

            <ToastContainer autoClose={3000} draggable={false} limit={1} hideProgressBar={false} newestOnTop={false} closeOnClick={true} pauseOnHover={false} position={toast.POSITION.BOTTOM_RIGHT} />
        </div>
    );
};

export const paymentItemStatus = (status) => {
    return lodash.find(paymentStatus, { value: status });
};

export const paymentStatus = [
    { value: "no_status", label: "상태", statusCode: -2 },
    // { value: "prev_paid", label: "결제 진행 전", statusCode: -1 },
    { value: "product_checking", label: "주문 완료", statusCode: 0, bgColor: "#142d4c", textColor: "white" },
    { value: "product_confirm", label: "제품 제작 중", statusCode: 1, bgColor: "#ff9292", textColor: "white" },
    { value: "shipping_prepare", label: "배송 준비 중", statusCode: 2, bgColor: "#ff9292", textColor: "white" },
    { value: "shipping_proceeding", label: "배송중", statusCode: 3, bgColor: "#ff9292", textColor: "white" },
    { value: "shipping_complete", label: "배송 완료", statusCode: 4, bgColor: "#949cdf", textColor: "white" },
    { value: "cancel_order", label: "주문 취소", statusCode: 5, bgColor: "#a3d2ca", textColor: "white" },
];

export default Payment;
