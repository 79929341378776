import React from "react";
import NormalHeader from "../../components/Headers/NormalHeader.js";
import ManagerDetail from "./ManagerDetail";
import { Card, Container, Row } from "reactstrap";
import { getManagerData, getUsersGrant } from "../../lib/manager.js";
import { convertDate } from "../../modules/utils.js";
import PhotologTable, { SortIcon } from "../../modules/PhotologTable.js";

const Manager = () => {
    const columns = [
        {
            dataField: "user_id",
            text: "ID",
            headerStyle: { width: "25%" },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
        },
        {
            dataField: "user_name",
            text: "이름",
            headerStyle: { width: "25%", minWidth: 100 },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
        },
        {
            dataField: "user_birthday",
            text: "생년월일",
            sort: true,
            headerStyle: { width: "25%" },
            headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
                return (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {column.text}
                        <SortIcon order={sortElement.props.order} />
                    </div>
                );
            },
            formatter: (cell, row) => {
                return <span>{convertDate({ _originDate: cell, _convertFormat: "YYYY.MM.DD." })}</span>;
            },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
        },
        {
            dataField: "user_regist_date",
            text: "등록일시",
            sort: true,
            headerStyle: { width: "25%" },
            headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
                return (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {column.text}
                        <SortIcon order={sortElement.props.order} />
                    </div>
                );
            },
            formatter: (cell, row) => {
                return <span>{convertDate({ _originDate: cell, _convertFormat: "YYYY-MM-DD HH:mm:ss" })}</span>;
            },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
        },
    ];

    React.useEffect(() => {
        // doGetContents({ _category: 7, _size: 10, _pageNo: 1 });
    }, []);

    return (
        <>
            <NormalHeader />
            <Container className="mt--7 pb-7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow border-0 pt-4 pb-4">
                            <PhotologTable
                                category={7}
                                keyField={"user_id"}
                                columns={columns}
                                detailPage={ManagerDetail}
                                getData={({ _category, _size, _pageNo }) => {
                                    return getManagerData({ _category: _category, _size: _size, _pageNo: _pageNo });
                                }}
                            />
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Manager;
