import React from "react";
import { Badge, Card, Container, Row } from "reactstrap";
import NormalHeader from "../../components/Headers/NormalHeader.js";
import { convertDate } from "../../modules/utils.js";
import StoreListDetail from "./StoreListDetail";
import PhotologTable, { SortIcon } from "../../modules/PhotologTable.js";
import lodash from "lodash";
import { getStoreList } from "../../lib/storeInfo.js";
import { getRegionNameByCode } from "../../lib/basic.js";

export const storeStateList = [
    {
        value: "create",
        name: "검토 대기중",
        color: "info",
    },
    {
        value: "modify",
        name: "검토 대기중",
        color: "info",
    },
    {
        value: "proceeding",
        name: "검토중",
        color: "info",
    },
    {
        value: "publish",
        name: "활성화됨",
        color: "success",
    },
    {
        value: "reject",
        name: "반려",
        color: "danger",
    },
];

export const storeTypeList = [
    { name: "음식점", value: "food", color: "info" },
    { name: "숙박", value: "accommodation", color: "warning" },
    { name: "카페", value: "cafe", color: "primary" },
    { name: "액티비티", value: "activity", color: "success" },
];
const StoreList = () => {
    const [regionList, setRegionList] = React.useState(null);
    const columns = [
        {
            dataField: "store_name",
            text: "상점명",
            headerStyle: { minWidth: 100 },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
        },
        {
            dataField: "store_type",
            text: "업종",
            headerStyle: { width: "15%", minWidth: 100 },
            headerAlign: (column, colIndex) => "center",
            align: (cell, row, rowIndex, colIndex) => {
                return "center";
            },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
            formatter: (cell, row) => {
                return (
                    <div>
                        <Badge color={lodash.find(storeTypeList, { value: cell }).color}>{lodash.find(storeTypeList, { value: cell }).name}</Badge>
                    </div>
                );
            },
        },
        {
            dataField: "store_publish_state",
            text: "상태",
            headerStyle: { width: "15%", minWidth: 100 },
            headerAlign: (column, colIndex) => "center",
            align: (cell, row, rowIndex, colIndex) => {
                return "center";
            },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
            formatter: (cell, row) => {
                return (
                    <div>
                        <Badge color={lodash.find(storeStateList, { value: cell }).color}>{lodash.find(storeStateList, { value: cell }).name}</Badge>
                    </div>
                );
            },
        },
        {
            dataField: "store_registed_date",
            text: "등록일시",
            sort: true,
            headerStyle: { width: "25%" },
            headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
                return (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {column.text}
                        <SortIcon order={sortElement.props.order} />
                    </div>
                );
            },
            formatter: (cell, row) => {
                return <span>{convertDate({ _originDate: cell, _convertFormat: "YYYY-MM-DD HH:mm" })}</span>;
            },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
        },
    ];

    React.useEffect(() => {
        if (regionList === null) {
            getRegionNameByCode().then((result) => {
                setRegionList(result.data.result);
            });
        }
    }, []);

    return (
        <>
            <NormalHeader />
            <Container className="mt--7 pb-7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow border-0 pt-4 pb-4">
                            <PhotologTable
                                category={6}
                                keyField={"store_id"}
                                columns={columns}
                                detailPage={StoreListDetail}
                                detailPageParams={{ regionList: regionList }}
                                getData={({ _category, _size, _pageNo }) => {
                                    return getStoreList({ _category: _category, _size: _size, _pageNo: _pageNo });
                                }}
                            />
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default StoreList;
