import axios from "axios";
import moment from "moment";
import lodash from "lodash";

export function randomNumber(min = 1, max = 1000) {
    return Math.floor(min + Math.random() * (max - min));
}

export function dateManager(action = "sub", count = 1, type = "d", format = "MM/DD") {
    switch (action) {
        case "add":
            return moment().add(count, type).format(format);

        case "sub":
            return moment().subtract(count, type).format(format);

        default:
            return null;
    }
}

export function getAnalyticsDaily() {
    return new Promise(function (resolve, reject) {
        var data = [];

        for (var i = 0; i < 7; i++) {
            data.push({
                report_date: dateManager("sub", i, "d"), // 금일
                daily_registed_count: randomNumber(),
                unique_visitor_count: randomNumber(),
                today_registed_story: randomNumber(),
                today_print_clicked: randomNumber(),
            });
        }

        resolve(lodash.reverse(data));
    });

    // return axios
    //     .post("/apis/analytics/analytics-data", {

    //     })
    //     .then((result) => {
    //         return result;
    //     })
    //     .catch((e) => {
    //         return null;
    //     });
}

export function getAnalyticsWeekly() {
    return new Promise(function (resolve, reject) {
        var data = [];

        for (var i = 0; i < 4; i++) {
            data.push({
                report_date: dateManager("sub", i, "w"), // 금일
                daily_registed_count: randomNumber(100, 10000),
                unique_visitor_count: randomNumber(100, 10000),
                today_registed_story: randomNumber(100, 10000),
                today_print_clicked: randomNumber(100, 10000),
            });
        }

        resolve(lodash.reverse(data));
    });

    // return axios
    //     .post("/apis/analytics/getAnalyticsWeekly", {

    //     })
    //     .then((result) => {
    //         return result;
    //     })
    //     .catch((e) => {
    //         return null;
    //     });
}
export function getAnalyticsMonthly() {
    return new Promise(function (resolve, reject) {
        var data = [];

        for (var i = 0; i < 3; i++) {
            data.push({
                report_date: dateManager("sub", i, "M", "MM"), // 금일
                daily_registed_count: randomNumber(300, 30000),
                unique_visitor_count: randomNumber(300, 30000),
                today_registed_story: randomNumber(300, 30000),
                today_print_clicked: randomNumber(300, 30000),
            });
        }

        resolve(lodash.reverse(data));
    });

    // return axios
    //     .post("/apis/analytics/getAnalyticsMonthly", {

    //     })
    //     .then((result) => {
    //         return result;
    //     })
    //     .catch((e) => {
    //         return null;
    //     });
}

export function getAnalytics() {
    return axios
        .post("/apis/analytics/analytics-data", {})
        .then((result) => {
            return result;
        })
        .catch((e) => {
            return null;
        });
}
