import axios from "axios";
import { logout } from "../modules/utils";

export function deleteUser({ _userId }) {
    return axios
        .post("/apis/user/delete-user", {
            userId: _userId,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
export function getUserData({ _category, _size, _pageNo }) {
    return axios
        .post("/apis/user/get-app-user-info", {
            category: _category,
            size: _size,
            pageNo: _pageNo,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
