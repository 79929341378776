import React from "react";
import { Card, Container, Row } from "reactstrap";
import lodash from "lodash";
import NormalHeader from "../../components/Headers/NormalHeader.js";
import { getContentsData, getContentsDetail } from "../../lib/contents.js";
import { convertDate } from "../../modules/utils.js";
import ContentsDetail from "./ContentsDetail";
import { getRegionNameByCode } from "../../lib/basic.js";
import PhotologTable, { SortIcon } from "../../modules/PhotologTable.js";

const Contents = () => {
    const [regionNameList, setRegionNameList] = React.useState([]);

    const columns = [
        {
            dataField: "seq",
            text: "seq",
            headerStyle: { width: "17%" },
            sort: true,
            headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
                return <div style={{ display: "flex", flexDirection: "row" }}>{column.text}</div>;
            },
            classes: "d-none d-lg-table-cell",
            headerClasses: "d-none d-lg-table-cell",
        },
        {
            dataField: "user_id",
            text: "사용자",
            headerStyle: { width: "20%" },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
        },
        {
            dataField: "trip_idx",
            text: "여행지역",
            headerStyle: { width: "13%" },
            classes: "d-none d-lg-table-cell",
            headerClasses: "d-none d-lg-table-cell",
            formatter: (cell, row) => {
                if (lodash.find(regionNameList, ["standard_map_code", cell]) === undefined) {
                    return <span>{cell}</span>;
                } else return <span>{lodash.find(regionNameList, ["standard_map_code", cell]).standard_region_name}</span>;
            },
        },

        {
            dataField: "trip_place",
            text: "여행장소",
            headerStyle: { width: "25%" },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
            formatter: (cell, row) => {
                return <span>{cell}</span>;
            },
        },
        {
            dataField: "registed_date",
            text: "등록일시",
            sort: true,
            headerStyle: { width: "25%" },
            headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
                return (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {column.text}
                        <SortIcon order={sortElement.props.order} />
                    </div>
                );
            },
            formatter: (cell, row) => {
                return <span>{convertDate({ _originDate: cell, _convertFormat: "YYYY-MM-DD HH:mm" })}</span>;
            },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
        },
    ];

    React.useEffect(() => {
        if (regionNameList.length === 0) {
            getRegionNameByCode().then((result) => {
                setRegionNameList(result.data.result);
                // if (contentsData.length === 0) doGetContents({ _category: 2, _size: 10, _pageNo: 1 });
            });
        }
    }, []);

    return (
        <>
            <NormalHeader />
            <Container className="mt--7 pb-7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow border-0 pt-4 pb-4">
                            <PhotologTable
                                category={2}
                                keyField={"seq"}
                                columns={columns}
                                rowClickEvent={(e, row, rowIndex) => {
                                    return getContentsDetail({ _seq: row.seq });
                                }}
                                detailPage={ContentsDetail}
                                getData={({ _category, _size, _pageNo }) => {
                                    return getContentsData({ _category: _category, _size: _size, _pageNo: _pageNo });
                                }}
                            />
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Contents;
