import React, { Component } from "react";

import { convertDate, History } from "../../modules/utils.js";
import NormalHeader from "../../components/Headers/NormalHeader.js";
import { Card, Col, Container, Input, Label, Row, Button } from "reactstrap";
import ToggleSwitch from "../../modules/ToggleSwitch.js";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import lodash from "lodash";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/ko";
import { PhotologDialog } from "../../modules/PhotologDialog.js";
import { getUsersGrant, me, modifyUserInfo } from "../../lib/manager.js";
import { Radio, RadioGroup } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const MyProfile = (props) => {
    const [showItem, setShowItem] = React.useState(null);
    const [editMode, setEditMode] = React.useState(false);

    const [openCal, setOpenCal] = React.useState(false);
    const [openCalTag, setOpenCalTag] = React.useState("birthday");

    const [showMessage, setShowMessage] = React.useState(false);
    const [showBackdrop, setShowBackdrop] = React.useState(false);

    const message = React.useRef({
        tag: "",
        title: "",
        message: null,
        body: null,
        button: [],
        keyboard: true,
        toggle: true,
        renderFooter: null,
        showFooter: true,
    });

    const classes = useStyles();
    React.useEffect(() => {
        if (showItem === null) {
            me()
                .then((meResult) => {
                    setShowItem({
                        contents: {
                            ...meResult.data.result,
                            userPass: null,
                        },
                    });
                    getUsersGrant({ _userId: meResult.data.result.userId }).then((result) => {});
                })
                .catch(() => {
                    History.replace("/admin/");
                    window.location.reload();
                });
        } else {
        }
    }, []);

    const doValidation = () => {
        if (lodash.isEqual(props.location.state.params.getContentsItem, showItem.contents) && lodash.isEqual(props.location.state.params.getContentsItemDetail, showItem.details)) {
            return {
                errCode: -1,
                errMsg: "바뀐값이 없음",
            };
        } else {
            if (showItem.contents.userBirthday === null || showItem.contents.userBirthday.length === 0) {
                // 값이 없을때
                return {
                    errCode: 1,
                    errMsg: "생년월일 입력란을 확인하세요",
                };
            }
            if (showItem.contents.userGender === null || showItem.contents.userGender.length === 0) {
                // 값이 없을때
                return {
                    errCode: 1,
                    errMsg: "성별 입력란을 확인하세요",
                };
            }
            if (showItem.contents.userId === null || showItem.contents.userId.length === 0) {
                // 값이 없을때
                return {
                    errCode: 1,
                    errMsg: "ID 입력란을 확인하세요",
                };
            }
            if (showItem.contents.userName === null || showItem.contents.userName.length === 0) {
                // 값이 없을때
                return {
                    errCode: 1,
                    errMsg: "이름 입력란을 확인하세요",
                };
            }
            if (showItem.contents.userPhone === null || showItem.contents.userPhone.length === 0) {
                // 값이 없을때
                return {
                    errCode: 1,
                    errMsg: "휴대전화 입력란을 확인하세요",
                };
            }
            if (showItem.contents.userRegistDate === null || showItem.contents.userRegistDate.length === 0) {
                // 값이 없을때
                return {
                    errCode: 1,
                    errMsg: "가입일 입력란을 확인하세요",
                };
            }
            if (showItem.contents.userPass === null || showItem.contents.userPass.length === 0) {
                // 값이 없을때
                return {
                    errCode: 1,
                    errMsg: "비밀번호 입력란을 확인하세요",
                };
            }
        }

        return {
            errCode: 0,
            errMsg: "문제 발견되지 않음",
        };
    };

    if (showItem === null) {
        return <div></div>;
    } else {
        return (
            <>
                <NormalHeader />
                <Container className="mt--7 pb-7" fluid>
                    <Row>
                        <Col className={"col-12 mx-auto mt--4 m-3"}>
                            <Label for="contents_editMode" style={{ color: "white" }}>
                                수정모드
                            </Label>
                            <Row className={"ml-0"}>
                                <ToggleSwitch
                                    id="contents_editMode"
                                    checked={editMode}
                                    onChange={(v) => {
                                        if (editMode === true) {
                                            // 수정모드 가능->불가능
                                            me()
                                                .then((meResult) => {
                                                    setShowItem({
                                                        contents: {
                                                            ...meResult.data.result,
                                                            userPass: null,
                                                        },
                                                    });
                                                })
                                                .catch(() => {
                                                    History.replace("/admin/");
                                                    window.location.reload();
                                                });
                                        }
                                        setEditMode(v);
                                    }}
                                    optionLabels={["가능", "불가"]}
                                />
                            </Row>
                        </Col>
                    </Row>
                    <Card className="shadow border-0 pb-4">
                        <Row className={"ml-3 mr-3 mt-3"}>
                            <Col className={"col-lg-4 col-md-4 col-sm-12 col-12 mx-auto mt-3"}>
                                <Label for="contents_userId">ID</Label>
                                <Input type="text" name="userId" id="contents_userId" defaultValue={showItem.contents.userId} disabled />
                            </Col>
                            <Col className={"col-lg-4 col-md-4 col-sm-12 col-12 mx-auto mt-3"}>
                                <Label for="contents_userName">이름</Label>
                                <Input
                                    type="text"
                                    name="userName"
                                    id="contents_userName"
                                    defaultValue={showItem.contents.userName}
                                    value={showItem.contents.userName}
                                    disabled={!editMode}
                                    onChange={(e) => {
                                        setShowItem({
                                            contents: {
                                                ...showItem.contents,
                                                userName: e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </Col>

                            <Col className={"col-lg-4 col-md-4 col-sm-12 col-12 mx-auto mt-3"}>
                                <Label for="contents_userBirthday">생년월일</Label>
                                <Input
                                    type="text"
                                    name="userBirthday"
                                    id="contents_userBirthday"
                                    defaultValue={convertDate({ _originDate: showItem.contents.userBirthday, _convertFormat: "YYYY.MM.DD" })}
                                    value={convertDate({ _originDate: showItem.contents.userBirthday, _convertFormat: "YYYY.MM.DD" })}
                                    disabled={!editMode}
                                    onClick={() => {
                                        // setOpenCal({ status: true, tag: "userBirthday" });
                                        setOpenCalTag("userBirthday");
                                        setTimeout(() => {
                                            setOpenCal(true);
                                        }, 300);
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className={"ml-3 mr-3 mt-3"}>
                            <Col className={"col-lg-4 col-md-4 col-sm-12 col-12 mx-auto mt-3"}>
                                <Label for="contents_userPhone">휴대전화</Label>
                                <Input type="text" name="userPhone" id="contents_userPhone" defaultValue={showItem.contents.userPhone} disabled={!editMode} />
                            </Col>
                            <Col className={"col-lg-4 col-md-4 col-sm-12 col-12 mx-auto mt-3"}>
                                <Label for="contents_userPass">비밀번호</Label>
                                <Input
                                    type="password"
                                    name="userPass"
                                    id="contents_userPass"
                                    disabled={!editMode}
                                    onChange={(e) => {
                                        setShowItem({
                                            contents: {
                                                ...showItem.contents,
                                                userPass: String(e.target.value),
                                            },
                                        });
                                    }}
                                />
                            </Col>
                            <Col className={"col-lg-4 col-md-4 col-sm-12 col-12 mx-auto mt-3"}>
                                <Label for="contents_userGender">성별</Label>
                                <RadioGroup row className={"custom-control-inline"} id="contents_userGender" name="userGender" value={showItem.contents.userGender}>
                                    <FormControlLabel
                                        checked={String(showItem.contents.userGender) === "M"}
                                        key={`userGender_M`}
                                        control={
                                            <Radio
                                                name={"userGender"}
                                                value={"M"}
                                                onChange={(e) => {
                                                    setShowItem({
                                                        contents: {
                                                            ...showItem.contents,
                                                            userGender: e.target.value,
                                                        },
                                                    });
                                                }}
                                            />
                                        }
                                        label={
                                            <div>
                                                <span style={{ fontSize: 16 }}>{` 남 `}</span>
                                            </div>
                                        }
                                        disabled={!editMode}
                                    />
                                    <FormControlLabel
                                        checked={String(showItem.contents.userGender) === "F"}
                                        key={`userGender_F`}
                                        control={
                                            <Radio
                                                name={"userGender"}
                                                value={"F"}
                                                onChange={(e) => {
                                                    setShowItem({
                                                        contents: {
                                                            ...showItem.contents,
                                                            userGender: e.target.value,
                                                        },
                                                    });
                                                }}
                                            />
                                        }
                                        label={
                                            <div>
                                                <span style={{ fontSize: 16 }}>{` 여 `}</span>
                                            </div>
                                        }
                                        disabled={!editMode}
                                    />
                                </RadioGroup>
                            </Col>
                        </Row>

                        <Row className={"mt-6"}>
                            <Col className={"col-11 mx-auto"}>
                                <div className={"float-right"}>
                                    <Button
                                        className={"btn btn-default btn-lg"}
                                        style={{ width: 100 }}
                                        onClick={() => {
                                            message.current.title = "알림";
                                            message.current.message = "수정하시겠습니까?";
                                            message.current.button = [
                                                {
                                                    title: "수정",
                                                    action: () => {
                                                        setShowMessage(false);
                                                        const validationResult = doValidation();
                                                        setShowBackdrop(true);
                                                        if (validationResult.errCode === 0) {
                                                            modifyUserInfo({
                                                                _userName: showItem.contents.userName,
                                                                _userPhone: showItem.contents.userPhone,
                                                                _userGender: showItem.contents.userGender,
                                                                _userBirthday: showItem.contents.userBirthday,
                                                                _userPass: showItem.contents.userPass,
                                                            })
                                                                .then((modifyUserInfoResult) => {
                                                                    setShowBackdrop(false);
                                                                    if (modifyUserInfoResult.data.res_code === 2) {
                                                                        // window.location.reload();
                                                                        toast.success("🎉 수정되었습니다");
                                                                        setShowItem({
                                                                            contents: {
                                                                                ...showItem.contents,
                                                                                userPass: null,
                                                                            },
                                                                        });
                                                                        setEditMode(false);
                                                                    } else {
                                                                        message.current.title = "알림";
                                                                        message.current.message = `오류가 발생되었습니다\n잠시 후 다시 시도하시기 바랍니다`;
                                                                        message.current.button = [
                                                                            {
                                                                                title: "확인",
                                                                                action: () => {
                                                                                    setShowMessage(false);
                                                                                },
                                                                                background: "purple",
                                                                                textColor: "white",
                                                                            },
                                                                        ];
                                                                        message.current.body = null;
                                                                        message.current.showFooter = true;
                                                                        message.current.keyboard = true;
                                                                        message.current.renderFooter = null;
                                                                        setTimeout(() => {
                                                                            setShowMessage(true);
                                                                        }, 500);
                                                                    }
                                                                })
                                                                .catch((err) => {
                                                                    setShowBackdrop(false);
                                                                });
                                                        } else {
                                                            setShowBackdrop(false);
                                                            message.current.title = "알림";
                                                            message.current.message = `${validationResult.errMsg}`;
                                                            message.current.button = [
                                                                {
                                                                    title: "확인",
                                                                    action: () => {
                                                                        setShowMessage(false);
                                                                    },
                                                                    background: "purple",
                                                                    textColor: "white",
                                                                },
                                                            ];
                                                            message.current.body = null;
                                                            message.current.showFooter = true;
                                                            message.current.keyboard = true;
                                                            message.current.renderFooter = null;
                                                            setTimeout(() => {
                                                                setShowMessage(true);
                                                            }, 500);
                                                        }
                                                    },
                                                    background: "red",
                                                    textColor: "white",
                                                },
                                                {
                                                    title: "닫기",
                                                    action: () => {
                                                        setShowMessage(false);
                                                    },
                                                    background: "purple",
                                                    textColor: "white",
                                                },
                                            ];
                                            message.current.body = null;
                                            message.current.showFooter = true;
                                            message.current.keyboard = true;
                                            message.current.renderFooter = null;
                                            setShowMessage(true);
                                        }}>
                                        수정
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Container>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        // disableToolbar
                        okLabel="확인"
                        cancelLabel="취소"
                        style={{ display: "none" }}
                        value={openCalTag === "userBirthday" ? showItem.contents.userBirthday : showItem.contents.userRegistDate}
                        onChange={(v) => {
                            if (openCalTag === "userBirthday") {
                                setShowItem({
                                    contents: {
                                        ...showItem.contents,
                                        userBirthday: convertDate({ _originDate: v, _convertFormat: "YYYY-MM-DD HH:mm" }),
                                    },
                                });
                            } else {
                                setShowItem({
                                    contents: {
                                        ...showItem.contents,
                                        userRegistDate: convertDate({ _originDate: v, _convertFormat: "YYYY-MM-DD HH:mm" }),
                                    },
                                });
                            }
                        }}
                        open={openCal}
                        // onOpen={() =>
                        //     setOpenCal({
                        //         ...openCalTag,
                        //     })
                        // }
                        onClose={() => setOpenCal(false)}
                        ampm={false}
                        disabled={!editMode}
                        disableFuture
                    />
                </MuiPickersUtilsProvider>
                <PhotologDialog
                    isOpen={showMessage}
                    toggle={() => setShowMessage(!showMessage)}
                    title={message.current.title}
                    message={message.current.message}
                    body={message.current.message !== null ? null : message.current.body}
                    button={message.current.button}
                    backdrop={"static"}
                    keyboard={message.current.keyboard}
                    showCenter
                    showFooter={message.current.showFooter}
                    renderFooter={message.current.renderFooter}
                />
                <Backdrop
                    className={classes.backdrop}
                    open={showBackdrop}
                    // invisible={true}
                    onClick={() => {}}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <ToastContainer
                    autoClose={3000}
                    draggable={false}
                    limit={1}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={true}
                    pauseOnHover={false}
                    position={toast.POSITION.BOTTOM_RIGHT}
                />
            </>
        );
    }
};

export default MyProfile;
