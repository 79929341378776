import axios from "axios";
import { logout } from "../modules/utils";

export function insertNewPush({
    _pushTitle,
    _pushSubTitle,
    _pushMessage,
    _pushImage,
    _pushUrl,
    _pushType,
    _pushTag,
    _pushSendTime,
    _pushNavigationType,
    _pushNavigationCategory,
    _pushDataSeq,
    _pushDataCode,
}) {
    return axios
        .post("/apis/push-internal/insert-new-push", {
            pushTitle: _pushTitle,
            pushSubTitle: _pushSubTitle,
            pushMessage: _pushMessage,
            pushImage: _pushImage,
            pushUrl: _pushUrl,
            pushType: _pushType,
            pushTag: _pushTag,
            pushSendTime: _pushSendTime,
            pushNavigationType: _pushNavigationType,
            pushNavigationCategory: _pushNavigationCategory,
            pushDataSeq: _pushDataSeq,
            pushDataCode: _pushDataCode,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}

export function getPushData({ _category, _size, _pageNo }) {
    return axios
        .post("/apis/push-internal/get-push-history", {
            category: _category,
            size: _size,
            pageNo: _pageNo,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
