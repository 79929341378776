import React from "react";
import { createBrowserHistory } from "history";
import moment from "moment";
import "moment-timezone";
import "moment/locale/ko";
import * as FontAwesome from "react-icons/fa";
import * as HeroIcon from "react-icons/hi";
import * as IonicIcon from "react-icons/io";
import { managerLogout } from "../lib/manager";
import lodash from "lodash";
import { ageGradeList } from "../views/admin/Users";

moment.tz.setDefault("Asia/Seoul");
export const PLACETYPE = [
    {
        iconNumber: 1,
        iconName: "mosque",
        iconType: "FontAwesome5",
        icon: (style) => {
            return <FontAwesome.FaMosque style={{ ...style }} />;
        },
        iconDescription: "관광지",
        iconActiveColor: "#3889AE",
        iconInactiveColor: "#ffffff",
        fontSize: 18,
    },
    {
        iconNumber: 2,
        iconName: "utensils",
        iconType: "FontAwesome5",
        icon: (style) => {
            return <FontAwesome.FaUtensils style={{ ...style }} />;
        },

        iconDescription: "맛집",
        iconActiveColor: "#E48786",
        iconInactiveColor: "#ffffff",
        fontSize: 20,
    },
    {
        iconNumber: 3,
        iconName: "mug-hot",
        iconType: "FontAwesome5",
        icon: (style) => {
            return <FontAwesome.FaMugHot style={{ ...style }} />;
        },
        iconDescription: "카페",
        iconActiveColor: "#B58D3D",
        iconInactiveColor: "#ffffff",
        fontSize: 20,
    },
    {
        iconNumber: 4,
        iconName: "ios-bed",
        iconType: "FontIonicons",
        icon: (style) => {
            return <IonicIcon.IoIosBed style={{ ...style }} />;
        },
        iconDescription: "숙소",
        iconActiveColor: "#9ACD32",
        iconInactiveColor: "#ffffff",
        fontSize: 20,
    },
    {
        iconNumber: 5,
        iconName: "puzzle",
        iconType: "MaterialCommunityIcons",
        icon: (style) => {
            return <HeroIcon.HiPuzzle style={{ ...style }} />;
        },
        iconDescription: "액티비티",
        iconActiveColor: "#F6CD8B",
        iconInactiveColor: "#ffffff",
        fontSize: 20,
    },
];
export const CONVENIENCE = [
    {
        iconNumber: 1,
        iconName: "restroom",
        iconType: "FontAwesome5",
        icon: (style) => {
            return <FontAwesome.FaRestroom style={{ ...style }} />;
        },
        iconDescription: "화장실 편리",
        iconActiveColor: "#003c6a",
        iconInactiveColor: "#ffffff",
        fontSize: 18,
    },
    {
        iconNumber: 2,
        iconName: "wheelchair",
        iconType: "FontAwesome5",
        icon: (style) => {
            return <FontAwesome.FaWheelchair style={{ ...style }} />;
        },
        iconDescription: "장애인 친화",
        iconActiveColor: "#9999ff",
        iconInactiveColor: "#ffffff",
        fontSize: 18,
    },
    {
        iconNumber: 3,
        iconName: "dog",
        iconType: "FontAwesome5",
        icon: (style) => {
            return <FontAwesome.FaDog style={{ ...style }} />;
        },
        iconDescription: "반려동물 동반",
        iconActiveColor: "#884dff",
        iconInactiveColor: "#ffffff",
        fontSize: 20,
    },
    {
        iconNumber: 4,
        iconName: "car",
        iconType: "FontAwesome5",
        icon: (style) => {
            return <FontAwesome.FaCar style={{ ...style }} />;
        },
        iconDescription: "주차 가능",
        iconActiveColor: "#F3B54A",
        iconInactiveColor: "#ffffff",
        fontSize: 20,
    },
];
export const History = createBrowserHistory();

export function convertDate({ _originDate, _originFormat = "YYYY-MM-DD HH:mm:ss", _convertFormat = "YYYY.MM.DD HH:mm" }) {
    const newDate = moment(_originDate, _originFormat);

    if (newDate.isValid()) {
        // return moment(moment(_originDate).tz("Asia/Seoul"), _originFormat).tz("Asia/Seoul").format(_convertFormat);
        return moment(_originDate, _originFormat).tz("Asia/Seoul").format(_convertFormat);
    } else {
        // return moment(new Date()).tz("Asia/Seoul").format(_convertFormat);
        return moment(new Date(), _originFormat).tz("Asia/Seoul").format(_convertFormat);
    }
}

export function randomInteger(_minValue = 10000000, _maxValue = 99999999) {
    const minValue = _minValue;
    const maxValue = _maxValue;
    return Math.floor(minValue + Math.random() * (maxValue - minValue));
}

export function numberWithComma(number) {
    if (number === undefined) return 0;
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function numberFormatter(num, digits) {
    var si = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}
export function makeRandomCode(length) {
    var result = "";
    var baseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for (var i = 0; i < length; i++) {
        result += baseChars.charAt(Math.floor(Math.random() * baseChars.length));
    }
    return result;
}

export function getFileNameFromPath(asValue) {
    var regExp = /(\w+)(\.\w+)+(?!.*(\w+)(\.\w+)+)/;
    return regExp.exec(asValue)[0];
}

export function convertFormat(type, value) {
    var regExp = null;
    switch (type) {
        case "phone": {
            regExp = /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/;
            return `${regExp.exec(value)[1]}-${regExp.exec(value)[2]}-${regExp.exec(value)[3]}`;
        }
        default: {
            return null;
        }
    }
}

export function logout() {
    if (History.location.pathname !== "/auth/login") {
        managerLogout().then(() => {
            History.replace("/auth/login");
            window.location.reload();
        });
    }
}

export function getAgeGrade(birthday, onlyAge = false) {
    let age = moment().diff(moment(birthday).format("YYYY"), "years") + 1;
    let ageInfo = null;
    let ageGrade = Math.floor(age / 10) * 10 === 0 ? -1 : Math.floor(age / 10) * 10;
    if (isNaN(age)) {
        ageInfo = lodash.find(ageGradeList, { grade: -1 });
        return onlyAge ? { age: -1, grade: -1 } : { age: -1, ...ageInfo };
    } else {
        if (lodash.find(ageGradeList, { grade: ageGrade }) === undefined || ageGrade === 0) {
            ageInfo = lodash.find(ageGradeList, { grade: -1 });
        } else {
            ageInfo = lodash.find(ageGradeList, { grade: ageGrade });
        }
        return onlyAge ? { age: age, grade: ageGrade } : { age: age, ...ageInfo };
    }
}

/* ####################### 정규식 ####################### */
// 이메일 정규식 확인
export function checkEmail(asValue) {
    var regExp = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
    return regExp.test(asValue);
}
// 전화번호 정규식 확인
export function checkPhoneNumber(asValue) {
    var regExp = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;

    return regExp.test(asValue);
}
// 영어만
export function checkEnglishOnly(asValue) {
    var regExp = /^[a-zA-Z]*$/;
    return regExp.test(asValue);
}
// 숫자만
export function checkNumberOnly(asValue) {
    var regExp = /^[0-9]*$/;
    return regExp.test(asValue);
}
// 영어, 숫자만
export function checkEnglishNumberOnly(asValue) {
    var regExp = /^[a-zA-Z0-9]*$/;
    return regExp.test(asValue);
}
// 한국어만
export function checkKoreanOnly(asValue) {
    var regExp = /^[가-힣]*$/;
    return regExp.test(asValue);
}
// 문자열 자르기
export function splitString(str, length) {
    return str.match(new RegExp(".{1," + length + "}", "g"));
}
/* ####################### 정규식 ####################### */

export function diffDate({ _date1, _date1Format = "YYYY-MM-DD HH:mm:ss", _date2, _date2Format = "YYYY-MM-DD HH:mm:ss" }) {
    // moment.locale("ko");
    const date1 = moment(_date1).tz("Asia/Seoul");
    const date2 = moment(_date2).tz("Asia/Seoul");

    let days = moment.duration(date2.diff(date1)).days();
    let hours = moment.duration(date2.diff(date1)).hours();
    let minutes = moment.duration(date2.diff(date1)).minutes();
    let seconds = moment.duration(date2.diff(date1)).seconds();

    return {
        d: days,
        h: hours,
        m: minutes,
        s: seconds,
    };
}

export function convertNumberToKorean(num) {
    let newNum = String(num);
    let hanA = ["", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구", "십"];
    let danA = ["", "십", "백", "천", "", "십", "백", "천", "", "십", "백", "천", "", "십", "백", "천"];
    let result = "";
    for (let i = 0; i < newNum.length; i++) {
        let str = "";
        let han = hanA[newNum.charAt(newNum.length - (i + 1))];
        if (han !== "") str += han + danA[i];
        if (i === 4) str += "만";
        if (i === 8) str += "억";
        if (i === 12) str += "조";
        result = str + result;
    }
    // if (num !== 0) result = result + "원";
    return result;
}

export function getOS(windowNavigator) {
    var userAgent = windowNavigator.userAgent,
        platform = windowNavigator.platform,
        macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
        windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
        iosPlatforms = ["iPhone", "iPad", "iPod"],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = "Mac";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = "Windows";
    } else if (/Android/.test(userAgent)) {
        os = "Android";
    } else if (!os && /Linux/.test(platform)) {
        os = "Linux";
    }

    return os;
}
