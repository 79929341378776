import React, { Component } from "react";

import { convertDate, History, makeRandomCode, numberWithComma, checkEnglishNumberOnly, splitString, getOS } from "../../modules/utils.js";
import NormalHeader from "../../components/Headers/NormalHeader.js";
import { ButtonDropdown, Card, Col, Container, Form, FormGroup, Input, Label, Row, Button } from "reactstrap";
import ToggleSwitch from "../../modules/ToggleSwitch.js";
// import Icon from "../../modules/VectorIcons"

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import lodash from "lodash";
import { MuiPickersUtilsProvider, TimePicker, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/ko";
import { PhotologDialog } from "../../modules/PhotologDialog.js";
import { deleteCoupon, insertCoupon, modifyCoupon } from "../../lib/coupon.js";
import { couponTypeList } from "./Coupon.js";
import FileSaver from "file-saver";
import { toast } from "react-toastify";
import iconv from "iconv-lite";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const CouponDetail = (props) => {
    const [showItem, setShowItem] = React.useState(null);
    const [addMode, setAddMode] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [openCal, setOpenCal] = React.useState(false);
    const [openCalTag, setOpenCalTag] = React.useState("start_date");

    const [showMessage, setShowMessage] = React.useState(false);
    const [showBackdrop, setShowBackdrop] = React.useState(false);
    const [hasMinPriceLimit, setHasMinPriceLimit] = React.useState(false);
    const [hasMaxPriceLimit, setHasMaxPriceLimit] = React.useState(false);
    const [couponContents, setCouponContents] = React.useState(false);
    // const couponContents = React.useRef(null);
    const message = React.useRef({
        tag: "",
        title: "",
        message: null,
        body: null,
        button: [],
        keyboard: true,
        toggle: true,
        renderFooter: null,
        showFooter: true,
    });

    const classes = useStyles();
    React.useEffect(() => {
        if (props === undefined) {
            History.replace("/admin/coupon");
            window.location.reload();
            return;
        } else {
            if (showItem === null) {
                if (props.mode === "add") {
                    setShowItem({
                        contents: {
                            coupon_code: null,
                            coupon_contents: "",
                            coupon_count: 0,
                            coupon_expired_date: convertDate({ _originDate: new Date(), _convertFormat: "YYYY-MM-DD HH:mm:ss" }),
                            coupon_percent: 0,
                            coupon_price: 0,
                            coupon_publish_date: convertDate({ _originDate: new Date(), _convertFormat: "YYYY-MM-DD HH:mm:ss" }),
                            coupon_title: null,
                            coupon_type: 0,
                            coupon_max_price: -1,
                            coupon_min_price: -1,
                            coupon_free_amount: null,
                            coupon_remain_amount: null,
                            coupon_specific_product: null,
                            coupon_used_yn: false,
                        },
                    });

                    setHasMinPriceLimit(false);
                    setHasMaxPriceLimit(false);
                    setCouponContents("");
                    setAddMode(true);
                } else {
                    setShowItem({
                        contents: props.getContentsItem,
                    });

                    setHasMinPriceLimit(props.getContentsItem.coupon_min_price !== -1);
                    setHasMaxPriceLimit(props.getContentsItem.coupon_max_price !== -1);
                    setCouponContents(props.getContentsItem.coupon_contents);
                }
            }
        }
    }, [showItem, props]);

    const couponSpecificProductList = [
        {
            coupon_specific_product: "all",
            coupon_specific_product_name: "전체",
        },
        {
            coupon_specific_product: "logcard",
            coupon_specific_product_name: "로그카드",
        },
        {
            coupon_specific_product: "loginmap_map",
            coupon_specific_product_name: "로그인맵 지도",
        },
        {
            coupon_specific_product: "loginmap_sticker",
            coupon_specific_product_name: "로그인맵 스티커",
        },
    ];

    const doValidation = () => {
        if (editMode) {
            if (lodash.isEqual(props.getContentsItem, showItem.contents) && lodash.isEqual(props.getContentsItem.coupon_contents, showItem.contents)) {
                return {
                    errCode: -1,
                    errMsg: "바뀐값이 없음",
                };
            }
        }
        // 쿠폰코드 (필수)
        // 수정할때만 코드가 있는지 확인함, 추가시에는 필요없음 (코드서버생성)
        if (!addMode) {
            if (showItem.contents.coupon_code === null || showItem.contents.coupon_code.length === 0) {
                return {
                    errCode: 1,
                    errMsg: "쿠폰코드 입력란을 확인하세요",
                };
            }
        } else {
            if (showItem.contents.coupon_identifier_code === null || showItem.contents.coupon_identifier_code.length === 0) {
                return {
                    errCode: 1,
                    errMsg: "고정코드 입력란을 확인하세요",
                };
            }
            if (showItem.contents.coupon_publish_count === null || showItem.contents.coupon_publish_count.length === 0) {
                return {
                    errCode: 1,
                    errMsg: "쿠폰발행수 입력란을 확인하세요",
                };
            }
        }
        // 쿠폰 제목
        if (showItem.contents.coupon_title === null || showItem.contents.coupon_title.length === 0) {
            return {
                errCode: 1,
                errMsg: "쿠폰제목 입력란을 확인하세요",
            };
        }

        if (hasMinPriceLimit) {
            if (showItem.contents.coupon_min_price === null || showItem.contents.coupon_min_price.length === 0 || showItem.contents.coupon_min_price === -1) {
                return {
                    errCode: 1,
                    errMsg: "최소결제금액 입력란을 확인하세요",
                };
            }
        }
        if (hasMaxPriceLimit) {
            if (showItem.contents.coupon_max_price === null || showItem.contents.coupon_max_price.length === 0 || showItem.contents.coupon_max_price === -1) {
                return {
                    errCode: 1,
                    errMsg: "최대할인금액 입력란을 확인하세요",
                };
            }
        }

        // 쿠폰사용 시작일시
        if (showItem.contents.coupon_publish_date === null || showItem.contents.coupon_publish_date.length === 0) {
            return {
                errCode: 1,
                errMsg: "쿠폰사용 시작일시 입력란을 확인하세요",
            };
        }
        // 쿠폰사용 종료일시
        if (showItem.contents.coupon_expired_date === null || showItem.contents.coupon_expired_date.length === 0) {
            return {
                errCode: 1,
                errMsg: "쿠폰사용 종료일시 입력란을 확인하세요",
            };
        }
        // 쿠폰사용 시작일시와 종료일시가 동일한지 확인
        if (lodash.isEqual(showItem.contents.coupon_publish_date, showItem.contents.coupon_expired_date)) {
            return {
                errCode: 2,
                errMsg: "쿠폰사용 시작/종료일시가 같습니다",
            };
        }

        // 쿠폰형식
        if (showItem.contents.coupon_type === null || showItem.contents.coupon_type.length === 0) {
            return {
                errCode: 1,
                errMsg: "쿠폰형식 입력란을 확인하세요",
            };
        } else {
            switch (showItem.contents.coupon_type) {
                case "amount":
                case "promotion":
                    if (showItem.contents.coupon_free_amount === null || showItem.contents.coupon_free_amount.length === 0 || showItem.contents.coupon_free_amount === 0) {
                        return {
                            errCode: 2,
                            errMsg: "무료수량 입력란을 확인하세요",
                        };
                    }
                    if (showItem.contents.coupon_remain_amount === null || showItem.contents.coupon_remain_amount.length === 0) {
                        return {
                            errCode: 2,
                            errMsg: "잔여수량 입력란을 확인하세요",
                        };
                    }
                    break;
                case "price":
                    if (showItem.contents.coupon_price === null || showItem.contents.coupon_price.length === 0 || showItem.contents.coupon_price === 0) {
                        return {
                            errCode: 2,
                            errMsg: "할인 금액 입력란을 확인하세요",
                        };
                    }
                    break;

                case "percent":
                    if (showItem.contents.coupon_percent === null || showItem.contents.coupon_percent.length === 0 || showItem.contents.coupon_percent === 0) {
                        return {
                            errCode: 2,
                            errMsg: "할인 비율 입력란을 확인하세요",
                        };
                    }
                    break;

                default:
                    break;
            }
        }

        if (showItem.contents.coupon_specific_product === null || showItem.contents.coupon_specific_product.length === 0) {
            return {
                errCode: 1,
                errMsg: "적용대상 입력란을 확인하세요",
            };
        }
        // 쿠폰내용 (필수)
        // if (showItem.contents.coupon_contents === null || showItem.contents.coupon_contents.length === 0) {
        //     return {
        //         errCode: 1,
        //         errMsg: "쿠폰내용 입력란을 확인하세요",
        //     };
        // }
        return {
            errCode: 0,
            errMsg: "문제 발견되지 않음",
        };
    };

    const makeCSV = async (codeArr) => {
        let csvStr = `"code"`;

        await codeArr.map((item) => {
            let splitStrArr = splitString(item, 4);
            csvStr += `\n"${splitStrArr.join("-")} (${showItem.contents.coupon_free_amount}장)"`;
        });
        let fileName = `${convertDate({ _originDate: new Date(), _convertFormat: "YYYYMMDD_HHmmss" })}.csv`;
        let csvFile = new File([iconv.encode(csvStr, getOS(window.navigator) === "Mac" ? "UTF-8" : "EUC-KR")], fileName, { type: "text/csv" });
        return FileSaver.saveAs(csvFile, fileName);
    };

    if (showItem === null) {
        return <div></div>;
    } else {
        return (
            <>
                <Container className="mt--7">
                    {addMode ? (
                        <Row>
                            <Col className={"col-12 mx-auto mt-6 m-3"}></Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col className={"col-12 mx-auto mt-6 m-3"}>
                                <Label for="contents_editMode" style={{ color: "black" }}>
                                    수정모드
                                </Label>
                                <Row className={"ml-0"}>
                                    <ToggleSwitch
                                        id="contents_editMode"
                                        checked={editMode}
                                        onChange={(v) => {
                                            if (editMode === true) {
                                                // 수정모드 가능->불가능
                                                setShowItem({
                                                    contents: props.getContentsItem,
                                                });
                                            }
                                            setEditMode(v);
                                        }}
                                        optionLabels={["가능", "불가"]}
                                    />
                                </Row>
                            </Col>
                        </Row>
                    )}

                    <Card className="shadow border-0 pb-4">
                        <Row className={"ml-3 mr-3 mt-3"} hidden={addMode}>
                            <Col className={"col-lg-3 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                <Label for="contents_coupon_used_yn">쿠폰사용유무</Label>
                                <div className={"d-flex justify-content-start align-items-center mt-2"}>
                                    <ToggleSwitch
                                        id="contents_coupon_used_yn"
                                        checked={showItem.contents.coupon_used_yn}
                                        disabled={addMode ? false : !editMode}
                                        onChange={(v) => {
                                            if (!v) {
                                                // disabled
                                                setShowItem({
                                                    contents: {
                                                        ...showItem.contents,
                                                        coupon_used_yn: false,
                                                    },
                                                });
                                            } else {
                                                // enabled
                                                setShowItem({
                                                    contents: {
                                                        ...showItem.contents,
                                                        coupon_used_yn: true,
                                                    },
                                                });
                                            }
                                        }}
                                        optionLabels={["사용", "안함"]}
                                    />
                                </div>
                            </Col>
                            <Col className={"col-lg-3 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                <Label for="contents_coupon_registed_yn">쿠폰등록유무</Label>
                                <div className={"d-flex justify-content-start align-items-center mt-2"}>
                                    <ToggleSwitch
                                        id="contents_coupon_registed_yn"
                                        checked={showItem.contents.coupon_registed_yn}
                                        disabled={addMode ? false : !editMode}
                                        onChange={(v) => {
                                            if (!v) {
                                                // disabled
                                                setShowItem({
                                                    contents: {
                                                        ...showItem.contents,
                                                        coupon_registed_yn: false,
                                                        coupon_registed_date: null,
                                                    },
                                                });
                                            } else {
                                                // enabled
                                                setShowItem({
                                                    contents: {
                                                        ...showItem.contents,
                                                        coupon_registed_yn: true,
                                                        coupon_registed_date:
                                                            props.getContentsItem.coupon_registed_date === null
                                                                ? convertDate({ _originDate: new Date(), _convertFormat: "YYYY-MM-DD HH:mm:ss" })
                                                                : props.getContentsItem.coupon_registed_date,
                                                    },
                                                });
                                            }
                                        }}
                                        optionLabels={["등록", "안함"]}
                                    />
                                </div>
                            </Col>
                            <Col className={"col-lg-6 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                <Label for="contents_coupon_registed_date">쿠폰등록일</Label>
                                <Input
                                    type="text"
                                    name="coupon_registed_date"
                                    id="contents_coupon_registed_date"
                                    value={
                                        showItem.contents.coupon_registed_date === null
                                            ? ""
                                            : convertDate({ _originDate: showItem.contents.coupon_registed_date, _convertFormat: "YYYY-MM-DD HH:mm:ss" })
                                    }
                                    disabled={true}
                                    onChange={(e) => {
                                        setShowItem({
                                            contents: {
                                                ...showItem.contents,
                                                coupon_registed_date: e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className={"m-3"}>
                            <Col className={"col-lg-6 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                <Label for="contents_coupon_title">쿠폰제목</Label>
                                <Input
                                    type="text"
                                    name="coupon_title"
                                    id="contents_coupon_title"
                                    value={showItem.contents.coupon_title}
                                    disabled={addMode ? false : !editMode}
                                    onChange={(e) => {
                                        setShowItem({
                                            contents: {
                                                ...showItem.contents,
                                                coupon_title: e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                            <Col className={addMode ? "col-lg-3 col-md-6 col-sm-6 col-12 mx-auto mt-3" : "d-none"}>
                                <Label for="contents_coupon_identifier_code">고정코드(4자)</Label>
                                <Input
                                    className={"text-uppercase"}
                                    type="text"
                                    name="coupon_identifier_code"
                                    id="contents_coupon_identifier_code"
                                    value={showItem.contents.coupon_identifier_code}
                                    maxLength={4}
                                    disabled={addMode ? false : !editMode}
                                    onChange={(e) => {
                                        let inputText = e.target.value;
                                        if (checkEnglishNumberOnly(inputText)) {
                                            setShowItem({
                                                contents: {
                                                    ...showItem.contents,
                                                    coupon_identifier_code: inputText,
                                                },
                                            });
                                        }
                                    }}
                                />
                            </Col>
                            <Col className={"col-lg-3 col-md-6 col-sm-6 col-12 mx-auto mt-3"} hidden={!addMode}>
                                <Label for="contents_coupon_publish_count">쿠폰발행수</Label>

                                <Input
                                    type="number"
                                    min={1}
                                    name="coupon_publish_count"
                                    id="contents_coupon_publish_count"
                                    value={showItem.contents.coupon_publish_count}
                                    onChange={(e) => {
                                        if (Number(e.target.value) <= 0) {
                                            // do nothing
                                        } else {
                                            setShowItem({
                                                contents: {
                                                    ...showItem.contents,
                                                    coupon_publish_count: e.target.value,
                                                },
                                            });
                                        }
                                    }}
                                />
                            </Col>
                            <Col className={"col-lg-6 col-md-12 col-sm-12 col-12 mx-auto mt-3"} hidden={addMode}>
                                <Label for="contents_coupon_code">쿠폰코드</Label>
                                <Input
                                    type="text"
                                    name="coupon_code"
                                    id="contents_coupon_code"
                                    value={showItem.contents.coupon_code}
                                    disabled={addMode ? false : !editMode}
                                    onChange={(e) => {
                                        setShowItem({
                                            contents: {
                                                ...showItem.contents,
                                                coupon_code: e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className={"ml-3 mr-3 mt-3"}>
                            <Col className={"col-lg-3 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                <Label for="contents_has_coupon_min_price">최소결제금액 설정</Label>
                                <div className={"d-flex justify-content-start align-items-center mt-2"}>
                                    <ToggleSwitch
                                        id="contents_has_coupon_min_price"
                                        checked={hasMinPriceLimit}
                                        disabled={addMode ? false : !editMode}
                                        onChange={(v) => {
                                            if (!v) {
                                                // 제한있음 -> 없음
                                                setShowItem({
                                                    contents: {
                                                        ...showItem.contents,
                                                        coupon_min_price: addMode ? -1 : props.getContentsItem.coupon_min_price,
                                                    },
                                                });
                                            } else {
                                                // 제한없음 -> 있음
                                                setShowItem({
                                                    contents: {
                                                        ...showItem.contents,
                                                        coupon_min_price: "",
                                                    },
                                                });
                                            }
                                            setHasMinPriceLimit(v);
                                        }}
                                        optionLabels={["제한", "없음"]}
                                    />
                                </div>
                            </Col>
                            <Col className={"col-lg-3 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                <Label for="contents_coupon_min_price">최소결제금액</Label>
                                <Input
                                    type="text"
                                    name="coupon_min_price"
                                    id="contents_coupon_min_price"
                                    value={showItem.contents.coupon_min_price}
                                    disabled={hasMinPriceLimit ? false : true}
                                    onChange={(e) => {
                                        setShowItem({
                                            contents: {
                                                ...showItem.contents,
                                                coupon_min_price: e.target.value,
                                            },
                                        });
                                    }}
                                />
                                {showItem.contents.coupon_min_price >= 0 && (
                                    <span className={"d-flex justify-content-end align-items-center mt-2 fs-6"}>{`${numberWithComma(showItem.contents.coupon_min_price)}원`}</span>
                                )}
                            </Col>
                            <Col className={"col-lg-3 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                <Label for="contents_has_coupon_max_price">최대할인금액 설정</Label>
                                <div className={"d-flex justify-content-start align-items-center mt-2"}>
                                    <ToggleSwitch
                                        id="contents_has_coupon_max_price"
                                        checked={hasMaxPriceLimit}
                                        disabled={addMode ? false : !editMode}
                                        onChange={(v) => {
                                            // 제한있음 = true, 제한없음 = false
                                            if (!v) {
                                                // 제한있음 -> 없음
                                                setShowItem({
                                                    contents: {
                                                        ...showItem.contents,
                                                        coupon_max_price: addMode ? -1 : props.getContentsItem.coupon_max_price,
                                                    },
                                                });
                                            } else {
                                                // 제한없음 -> 있음
                                                setShowItem({
                                                    contents: {
                                                        ...showItem.contents,
                                                        coupon_max_price: "",
                                                    },
                                                });
                                            }
                                            setHasMaxPriceLimit(v);
                                        }}
                                        optionLabels={["제한", "없음"]}
                                    />
                                </div>
                            </Col>
                            <Col className={"col-lg-3 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                <Label for="contents_coupon_max_price">최대할인금액</Label>
                                <Input
                                    type="text"
                                    name="coupon_max_price"
                                    id="contents_coupon_max_price"
                                    value={showItem.contents.coupon_max_price}
                                    disabled={hasMaxPriceLimit ? false : true}
                                    onChange={(e) => {
                                        setShowItem({
                                            contents: {
                                                ...showItem.contents,
                                                coupon_max_price: e.target.value,
                                            },
                                        });
                                    }}
                                />
                                {showItem.contents.coupon_max_price >= 0 && (
                                    <span className={"d-flex justify-content-end align-items-center mt-2 fs-6"}>{`${numberWithComma(showItem.contents.coupon_max_price)}원`}</span>
                                )}
                            </Col>
                        </Row>
                        <Row className={"ml-3 mr-3 mt-3"}>
                            <Col className={"col-lg-6 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                <Label for="contents_coupon_publish_date">사용가능 시작일시</Label>
                                <Input
                                    type="text"
                                    name="coupon_publish_date"
                                    id="contents_coupon_publish_date"
                                    value={convertDate({ _originDate: showItem.contents.coupon_publish_date, _convertFormat: "YYYY-MM-DD HH:mm" })}
                                    disabled={addMode ? false : !editMode}
                                    onClick={() => {
                                        // setOpenCal({ status: true, tag: "start_date" });
                                        setOpenCalTag("start_date");
                                        setTimeout(() => {
                                            setOpenCal(true);
                                        }, 300);
                                    }}
                                />
                            </Col>
                            <Col className={"col-lg-6 col-md-6 col-sm-6 col-12 mx-auto mt-3"}>
                                <Label for="contents_coupon_expired_date">사용가능 종료일시</Label>
                                <Input
                                    type="text"
                                    name="coupon_expired_date"
                                    id="contents_coupon_expired_date"
                                    value={convertDate({ _originDate: showItem.contents.coupon_expired_date, _convertFormat: "YYYY-MM-DD HH:mm" })}
                                    disabled={addMode ? false : !editMode}
                                    onClick={() => {
                                        // setOpenCal({ status: true, tag: "end_date" });
                                        setOpenCalTag("end_date");
                                        setTimeout(() => {
                                            setOpenCal(true);
                                        }, 300);
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className={"ml-3 mr-3 mt-3"}>
                            <Col className={"col-12 mx-auto mt-3"}>
                                <Label for="contents_coupon_specific_product">적용 대상</Label>

                                <RadioGroup
                                    row
                                    className={"custom-control-inline"}
                                    id="contents_coupon_specific_product"
                                    name="coupon_specific_product"
                                    value={showItem.contents.coupon_specific_product}>
                                    {couponSpecificProductList.map((item, index) => {
                                        return (
                                            <FormControlLabel
                                                checked={showItem.contents.coupon_specific_product === item.coupon_specific_product}
                                                key={`coupon_specific_product_${index}`}
                                                control={
                                                    <Radio
                                                        name={item.coupon_specific_product_name}
                                                        value={item.coupon_specific_product}
                                                        onChange={(e) => {
                                                            setShowItem({
                                                                contents: {
                                                                    ...showItem.contents,
                                                                    coupon_specific_product: e.target.value,
                                                                },
                                                            });
                                                        }}
                                                    />
                                                }
                                                label={<span style={{ fontSize: 16 }}>{` ${item.coupon_specific_product_name} `}</span>}
                                                disabled={addMode ? false : !editMode}
                                            />
                                        );
                                    })}
                                </RadioGroup>
                            </Col>
                        </Row>
                        <Row className={"ml-3 mr-3 mt-3"}>
                            <Col className={"col-12 mx-auto "}>
                                <Label for="contents_coupon_type">쿠폰 형식</Label>

                                <RadioGroup row className={"custom-control-inline"} id="contents_coupon_type" name="coupon_type" value={showItem.contents.coupon_type}>
                                    {couponTypeList.map((item, index) => {
                                        return (
                                            <FormControlLabel
                                                checked={showItem.contents.coupon_type === item.coupon_type}
                                                key={`coupon_type_${index}`}
                                                control={
                                                    <Radio
                                                        name={item.coupon_type_name}
                                                        value={item.coupon_type}
                                                        onChange={(e) => {
                                                            setShowItem({
                                                                contents: {
                                                                    ...showItem.contents,
                                                                    coupon_type: e.target.value,
                                                                    coupon_percent: addMode ? "" : props.getContentsItem.coupon_percent,
                                                                    coupon_price: addMode ? "" : props.getContentsItem.coupon_price,
                                                                    coupon_free_amount: addMode ? "" : props.getContentsItem.coupon_free_amount,
                                                                    coupon_remain_amount: addMode ? "" : props.getContentsItem.coupon_remain_amount,
                                                                },
                                                            });
                                                        }}
                                                    />
                                                }
                                                label={<span style={{ fontSize: 16 }}>{` ${item.coupon_type_name} `}</span>}
                                                disabled={addMode ? false : !editMode}
                                            />
                                        );
                                    })}
                                </RadioGroup>
                            </Col>
                        </Row>

                        {showItem.contents.coupon_type === 1 && (
                            <Row className={"ml-3 mr-3 mt-3"}>
                                <Col className={"col-12 mx-auto"}>
                                    <Label for="contents_coupon_price">할인 금액</Label>
                                    <Input
                                        type="number"
                                        name="coupon_price"
                                        id="contents_coupon_price"
                                        value={addMode ? null : showItem.contents.coupon_price}
                                        disabled={addMode ? false : !editMode}
                                        onChange={(e) => {
                                            setShowItem({
                                                contents: {
                                                    ...showItem.contents,
                                                    coupon_price: Number(e.target.value.replaceAll(",", "")),
                                                },
                                            });
                                        }}
                                    />
                                </Col>
                            </Row>
                        )}
                        {(showItem.contents.coupon_type === "amount" || showItem.contents.coupon_type === "promotion" || showItem.contents.coupon_type === "influencer") && (
                            <Row className={"ml-3 mr-3 mt-3"}>
                                <Col className={"col-sm-6 col-12 mx-auto"}>
                                    <Label for="contents_coupon_free_amount">무료수량</Label>
                                    <Input
                                        type="number"
                                        name="coupon_free_amount"
                                        id="contents_coupon_free_amount"
                                        value={showItem.contents.coupon_free_amount}
                                        disabled={addMode ? false : !editMode}
                                        onChange={(e) => {
                                            if (addMode) {
                                                setShowItem({
                                                    contents: {
                                                        ...showItem.contents,
                                                        coupon_free_amount: e.target.value,
                                                        coupon_remain_amount: e.target.value,
                                                    },
                                                });
                                            } else {
                                                setShowItem({
                                                    contents: {
                                                        ...showItem.contents,
                                                        coupon_free_amount: e.target.value,
                                                    },
                                                });
                                            }
                                        }}
                                    />
                                </Col>
                                <Col className={"col-sm-6 col-12 mx-auto"}>
                                    <Label for="contents_coupon_remain_amount">잔여수량</Label>
                                    <Input
                                        type="number"
                                        name="coupon_remain_amount"
                                        id="contents_coupon_remain_amount"
                                        value={showItem.contents.coupon_remain_amount}
                                        disabled={addMode ? true : !editMode}
                                        onChange={(e) => {
                                            setShowItem({
                                                contents: {
                                                    ...showItem.contents,
                                                    coupon_remain_amount: e.target.value,
                                                },
                                            });
                                        }}
                                    />
                                </Col>
                            </Row>
                        )}
                        {showItem.contents.coupon_type === "price" && (
                            <Row className={"ml-3 mr-3 mt-3"}>
                                <Col className={"col-12 mx-auto"}>
                                    <Label for="contents_coupon_price">할인 금액(원)</Label>
                                    <Input
                                        type="number"
                                        name="coupon_price"
                                        id="contents_coupon_price"
                                        value={addMode ? null : showItem.contents.coupon_price}
                                        disabled={addMode ? false : !editMode}
                                        onChange={(e) => {
                                            setShowItem({
                                                contents: {
                                                    ...showItem.contents,
                                                    coupon_price: e.target.value,
                                                },
                                            });
                                        }}
                                    />
                                </Col>
                            </Row>
                        )}
                        {showItem.contents.coupon_type === "percent" && (
                            <Row className={"ml-3 mr-3 mt-3"}>
                                <Col className={"col-12 mx-auto"}>
                                    <Label for="contents_coupon_percent">할인 비율(%)</Label>
                                    <Input
                                        type="number"
                                        name="coupon_percent"
                                        id="contents_coupon_percent"
                                        value={addMode ? null : showItem.contents.coupon_percent}
                                        disabled={addMode ? false : !editMode}
                                        onChange={(e) => {
                                            setShowItem({
                                                contents: {
                                                    ...showItem.contents,
                                                    coupon_percent: e.target.value,
                                                },
                                            });
                                        }}
                                    />
                                </Col>
                            </Row>
                        )}

                        <Row className={"ml-3 mr-3 mt-3"}>
                            <Col className={"col-12 mx-auto"}>
                                <Label for="contents_coupon_notice">쿠폰 유의사항</Label>
                                <Input
                                    type="textarea"
                                    name="coupon_percent"
                                    id="contents_coupon_notice"
                                    value={showItem.contents.coupon_notice}
                                    disabled={addMode ? false : !editMode}
                                    onChange={(e) => {
                                        setShowItem({
                                            contents: {
                                                ...showItem.contents,
                                                coupon_notice: e.target.value,
                                            },
                                        });
                                    }}
                                    style={{ minHeight: 100 }}
                                />
                            </Col>
                        </Row>
                        <Row className={"ml-3 mr-3 mt-3"}>
                            <Col className={"col-12 mx-auto"}>
                                <Label for="contents_coupon_contents">쿠폰 설명</Label>
                                <Input
                                    type="textarea"
                                    name="coupon_percent"
                                    id="contents_coupon_contents"
                                    value={showItem.contents.coupon_contents}
                                    disabled={addMode ? false : !editMode}
                                    onChange={(e) => {
                                        setShowItem({
                                            contents: {
                                                ...showItem.contents,
                                                coupon_contents: e.target.value,
                                            },
                                        });
                                    }}
                                    style={{ minHeight: 100 }}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-8"}>
                            <Col className={"col-11 mx-auto"}>
                                <div className={"float-right"}>
                                    {addMode ? (
                                        <div>
                                            <Button
                                                className={"btn btn-default btn-lg"}
                                                style={{ width: 100 }}
                                                onClick={() => {
                                                    const validationResult = doValidation();
                                                    if (validationResult.errCode === 0) {
                                                        message.current.title = "알림";
                                                        message.current.message = "추가하시겠습니까?";
                                                        message.current.button = [
                                                            {
                                                                title: "추가",
                                                                action: () => {
                                                                    setShowMessage(false);
                                                                    insertCoupon({
                                                                        _coupon_contents: showItem.contents.coupon_contents,
                                                                        _coupon_publish_date: showItem.contents.coupon_publish_date,
                                                                        _coupon_expired_date: showItem.contents.coupon_expired_date,
                                                                        _coupon_percent: showItem.contents.coupon_percent,
                                                                        _coupon_price: showItem.contents.coupon_price,
                                                                        _coupon_title: showItem.contents.coupon_title,
                                                                        _coupon_type: showItem.contents.coupon_type,
                                                                        _coupon_max_price: hasMaxPriceLimit ? showItem.contents.coupon_max_price : -1,
                                                                        _coupon_min_price: hasMinPriceLimit ? showItem.contents.coupon_min_price : -1,
                                                                        _coupon_free_amount: showItem.contents.coupon_free_amount,
                                                                        _coupon_remain_amount: showItem.contents.coupon_free_amount,
                                                                        _coupon_specific_product: showItem.contents.coupon_specific_product,
                                                                        _coupon_identifier_code: showItem.contents.coupon_identifier_code,
                                                                        _coupon_publish_count: showItem.contents.coupon_publish_count,
                                                                        _coupon_notice: showItem.contents.coupon_notice,
                                                                    }).then((insertCouponResult) => {
                                                                        if (insertCouponResult.data.res_code === 2) {
                                                                            message.current.title = "알림";
                                                                            message.current.message = `CSV파일을 추출하시겠습니까?`;
                                                                            message.current.button = [
                                                                                {
                                                                                    title: "Export CSV",
                                                                                    action: () => {
                                                                                        setShowMessage(false);
                                                                                        makeCSV(insertCouponResult.data.result.coupon_code_list).then((result) => {
                                                                                            toast.success("🎉 완료되었습니다");
                                                                                            setTimeout(() => {
                                                                                                window.location.reload();
                                                                                            }, 2000);
                                                                                        });
                                                                                    },
                                                                                    background: "red",
                                                                                    textColor: "white",
                                                                                },
                                                                                {
                                                                                    title: "닫기",
                                                                                    action: () => {
                                                                                        setShowMessage(false);
                                                                                        toast.success("🎉 완료되었습니다");
                                                                                        setTimeout(() => {
                                                                                            window.location.reload();
                                                                                        }, 2000);
                                                                                    },
                                                                                    background: "purple",
                                                                                    textColor: "white",
                                                                                },
                                                                            ];
                                                                            message.current.body = null;
                                                                            message.current.showFooter = true;
                                                                            message.current.keyboard = true;
                                                                            message.current.renderFooter = null;
                                                                            setTimeout(() => {
                                                                                setShowMessage(true);
                                                                            }, 500);
                                                                        } else {
                                                                            props.onResult({ actionCode: insertCouponResult.data.res_code });
                                                                        }
                                                                    });
                                                                },
                                                                background: "red",
                                                                textColor: "white",
                                                            },
                                                            {
                                                                title: "닫기",
                                                                action: () => {
                                                                    setShowMessage(false);
                                                                },
                                                                background: "purple",
                                                                textColor: "white",
                                                            },
                                                        ];
                                                        message.current.body = null;
                                                        message.current.showFooter = true;
                                                        message.current.keyboard = true;
                                                        message.current.renderFooter = null;
                                                        setShowMessage(true);
                                                    } else {
                                                        message.current.title = "알림";
                                                        message.current.message = `${validationResult.errMsg}`;
                                                        message.current.button = [
                                                            {
                                                                title: "확인",
                                                                action: () => {
                                                                    setShowMessage(false);
                                                                },
                                                                background: "purple",
                                                                textColor: "white",
                                                            },
                                                        ];
                                                        message.current.body = null;
                                                        message.current.showFooter = true;
                                                        message.current.keyboard = true;
                                                        message.current.renderFooter = null;
                                                        setShowMessage(true);
                                                    }
                                                }}>
                                                추가
                                            </Button>
                                        </div>
                                    ) : (
                                        <div>
                                            <Button
                                                className={"btn btn-default btn-lg"}
                                                style={{ width: 100 }}
                                                onClick={() => {
                                                    message.current.title = "알림";
                                                    message.current.message = "수정하시겠습니까?";
                                                    message.current.button = [
                                                        {
                                                            title: "수정",
                                                            action: () => {
                                                                setShowMessage(false);
                                                                // setShowBackdrop(true);

                                                                const validationResult = doValidation();

                                                                if (validationResult.errCode === 0) {
                                                                    modifyCoupon({
                                                                        _coupon_code: showItem.contents.coupon_code,
                                                                        _coupon_contents: couponContents,
                                                                        _coupon_publish_date: showItem.contents.coupon_publish_date,
                                                                        _coupon_expired_date: showItem.contents.coupon_expired_date,
                                                                        _coupon_percent: showItem.contents.coupon_percent,
                                                                        _coupon_price: showItem.contents.coupon_price,
                                                                        _coupon_title: showItem.contents.coupon_title,
                                                                        _coupon_type: showItem.contents.coupon_type,
                                                                        _coupon_max_price: hasMaxPriceLimit ? showItem.contents.coupon_max_price : -1,
                                                                        _coupon_min_price: hasMinPriceLimit ? showItem.contents.coupon_min_price : -1,
                                                                        _coupon_free_amount: showItem.contents.coupon_free_amount,
                                                                        _coupon_remain_amount: showItem.contents.coupon_remain_amount,
                                                                        _coupon_specific_product: showItem.contents.coupon_specific_product,
                                                                        _coupon_notice: showItem.contents.coupon_notice,
                                                                        _coupon_used_yn: showItem.contents.coupon_used_yn,
                                                                        _coupon_registed_yn: showItem.contents.coupon_registed_yn,
                                                                        _coupon_registed_date: showItem.contents.coupon_registed_date,
                                                                    }).then((modifyCouponResult) => {
                                                                        if (modifyCouponResult.data.res_code === 2) {
                                                                            props.onResult({
                                                                                actionCode: modifyCouponResult.data.res_code,
                                                                                actionName: "edit",
                                                                                changedData: {
                                                                                    ...showItem,
                                                                                },
                                                                            });
                                                                        } else {
                                                                            // props.onResult({ actionCode: modifyCouponResult.data.res_code });
                                                                            toast.error("🤔 에러가 발생되었습니다");
                                                                        }
                                                                    });
                                                                } else {
                                                                    message.current.title = "알림";
                                                                    message.current.message = `${validationResult.errMsg}`;
                                                                    message.current.button = [
                                                                        {
                                                                            title: "확인",
                                                                            action: () => {
                                                                                setShowMessage(false);
                                                                            },
                                                                            background: "purple",
                                                                            textColor: "white",
                                                                        },
                                                                    ];
                                                                    message.current.body = null;
                                                                    message.current.showFooter = true;
                                                                    message.current.keyboard = true;
                                                                    message.current.renderFooter = null;
                                                                    setTimeout(() => {
                                                                        setShowMessage(true);
                                                                    }, 500);
                                                                }
                                                            },
                                                            background: "red",
                                                            textColor: "white",
                                                        },
                                                        {
                                                            title: "닫기",
                                                            action: () => {
                                                                setShowMessage(false);
                                                            },
                                                            background: "purple",
                                                            textColor: "white",
                                                        },
                                                    ];
                                                    message.current.body = null;
                                                    message.current.showFooter = true;
                                                    message.current.keyboard = true;
                                                    message.current.renderFooter = null;
                                                    setShowMessage(true);
                                                }}>
                                                수정
                                            </Button>
                                            <Button
                                                className={"btn btn-danger btn-lg"}
                                                style={{ width: 100 }}
                                                onClick={() => {
                                                    message.current.title = "알림";
                                                    message.current.message = "삭제하시겠습니까?";
                                                    message.current.button = [
                                                        {
                                                            title: "삭제",
                                                            action: () => {
                                                                setShowMessage(false);
                                                                deleteCoupon({ _coupon_code: showItem.contents.coupon_code }).then((deleteCouponResult) => {
                                                                    if (deleteCouponResult.data.res_code === 2) {
                                                                        // 삭제완료
                                                                        props.onResult({
                                                                            actionCode: deleteCouponResult.data.res_code,
                                                                            actionName: "del",
                                                                            changedData: {
                                                                                ...showItem,
                                                                            },
                                                                        });
                                                                    } else {
                                                                        props.onResult({ actionCode: deleteCouponResult.data.res_code });
                                                                    }
                                                                });
                                                            },
                                                            background: "red",
                                                            textColor: "white",
                                                        },
                                                        {
                                                            title: "닫기",
                                                            action: () => {
                                                                setShowMessage(false);
                                                            },
                                                            background: "purple",
                                                            textColor: "white",
                                                        },
                                                    ];
                                                    message.current.body = null;
                                                    message.current.showFooter = true;
                                                    message.current.keyboard = true;
                                                    message.current.renderFooter = null;
                                                    setShowMessage(true);
                                                }}>
                                                삭제
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Container>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                        margin="normal"
                        id="date-picker-dialog"
                        name="coupon_publish_date-picker"
                        // disableToolbar
                        okLabel="확인"
                        cancelLabel="취소"
                        style={{ display: "none" }}
                        value={openCalTag === "start_date" ? showItem.contents.coupon_publish_date : showItem.contents.coupon_expired_date}
                        onChange={(v) => {
                            if (openCalTag === "start_date") {
                                setShowItem({
                                    contents: {
                                        ...showItem.contents,
                                        coupon_publish_date: convertDate({ _originDate: v, _convertFormat: "YYYY-MM-DD HH:mm" }),
                                    },
                                });
                            } else {
                                setShowItem({
                                    contents: {
                                        ...showItem.contents,
                                        coupon_expired_date: convertDate({ _originDate: v, _convertFormat: "YYYY-MM-DD HH:mm" }),
                                    },
                                });
                            }
                        }}
                        open={openCal}
                        // onOpen={() =>
                        //     setOpenCal({
                        //         ...openCalTag,
                        //     })
                        // }
                        onClose={() => setOpenCal(false)}
                        ampm={false}
                        disabled={!editMode}
                    />
                </MuiPickersUtilsProvider>
                <PhotologDialog
                    isOpen={showMessage}
                    toggle={() => setShowMessage(!showMessage)}
                    title={message.current.title}
                    message={message.current.message}
                    body={message.current.message !== null ? null : message.current.body}
                    button={message.current.button}
                    backdrop={"static"}
                    keyboard={message.current.keyboard}
                    showCenter
                    showFooter={message.current.showFooter}
                    renderFooter={message.current.renderFooter}
                />
                <Backdrop
                    className={classes.backdrop}
                    open={showBackdrop}
                    // invisible={true}
                    onClick={() => {}}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        );
    }
};

export default CouponDetail;
