import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
import { Button, Card, CardHeader, CardBody, NavItem, NavLink, Nav, Progress, Table, Container, Row, Col, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from "reactstrap";

import Header from "../components/Headers/Header.js";
import { getAnalytics } from "../lib/analytics.js";
import lodash from "lodash";
import { Bar, Line, Pie } from "react-chartjs-2";
import GoogleTrends from "../modules/GoogleTrends.js";
import NormalHeader from "../components/Headers/NormalHeader.js";
import { convertDate, numberWithComma } from "../modules/utils.js";
import "chartjs-plugin-labels";
import moment from "moment";
import { BsBarChartFill, BsCalendar, BsPieChartFill, BsTable } from "react-icons/bs";
import { MdShowChart } from "react-icons/md";
import { PhotologDialog } from "../modules/PhotologDialog";
import { DatePicker, DateTimePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
moment.tz.setDefault("Asia/Seoul");

let ageGradeList = [
    { label: "그외", grade: -1, bgColor: "#f14668", textColor: "white" },
    { label: "10대", grade: 10, bgColor: "#587850", textColor: "white" },
    { label: "20대", grade: 20, bgColor: "#a3d2ca", textColor: "white" },
    { label: "30대", grade: 30, bgColor: "#fbc687", textColor: "white" },
    { label: "40대", grade: 40, bgColor: "#ea907a", textColor: "white" },
];

class ChangeAnalyticsDate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
        };
    }

    render() {
        const { data, onChange, startDate, endDate } = this.props;

        if (this.state.startDate === null) {
            this.setState({ startDate: startDate });
            this.setState({ endDate: endDate });
        }
        return (
            <div>
                <Row>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Col className={"col-sm-6 col-12 mx-auto mt-3"}>
                            <KeyboardDatePicker
                                name="analytics_start_date"
                                margin="normal"
                                format="YYYY/MM/DD"
                                okLabel="확인"
                                cancelLabel="취소"
                                style={{}}
                                value={this.state.startDate === null ? startDate : this.state.startDate}
                                onChange={(v) => {
                                    this.setState({ startDate: v });
                                    onChange(v, this.state.endDate === null ? endDate : this.state.endDate);
                                }}
                                label="시작일"
                                minDate={startDate}
                                maxDate={endDate}
                                minDateMessage={"선택하신 날짜를 확인하세요"}
                                maxDateMessage={"선택하신 날짜를 확인하세요"}
                                disableFuture
                                disableToolbar
                            />
                        </Col>
                        <Col className={"col-sm-6 col-12  mx-auto mt-3"}>
                            <KeyboardDatePicker
                                name="analytics_end_date"
                                margin="normal"
                                format="YYYY/MM/DD"
                                okLabel="확인"
                                cancelLabel="취소"
                                style={{}}
                                value={this.state.endDate === null ? endDate : this.state.endDate}
                                onChange={(v) => {
                                    this.setState({ endDate: v });
                                    onChange(this.state.startDate === null ? startDate : this.state.startDate, v);
                                }}
                                label="종료일"
                                minDate={startDate}
                                maxDate={endDate}
                                minDateMessage={"선택하신 날짜를 확인하세요"}
                                maxDateMessage={"선택하신 날짜를 확인하세요"}
                                disableFuture
                                disableToolbar
                            />
                        </Col>
                    </MuiPickersUtilsProvider>
                </Row>
            </div>
        );
    }
}

function datesGroupByComponent(dates, token) {
    return dates.reduce(function (val, obj) {
        let comp = null;
        switch (token) {
            case "d":
                comp = lodash.floor(moment.duration(moment().diff(moment(obj["analytics_date"], "YYYY-MM-DD"))).asDays());
                break;
            case "w":
                comp = lodash.floor(moment.duration(moment().diff(moment(obj["analytics_date"], "YYYY-MM-DD"))).asWeeks());
                break;
            case "m":
                comp = lodash.floor(moment.duration(moment().diff(moment(obj["analytics_date"], "YYYY-MM-DD"))).asMonths());
                break;
            default:
                comp = lodash.floor(moment.duration(moment().diff(moment(obj["analytics_date"], "YYYY-MM-DD"))).asWeeks());
                break;
        }

        if (obj !== undefined) (val[comp] = val[comp] || []).push(obj);
        return val;
    }, []);
}

function makeAnalyticsData(originAnalyticsData, dateType = "d", dataType = "summary", chartType = "line") {
    let showData = [];
    if (dataType === "summary") {
        if (dateType === "d") {
            showData = datesGroupByComponent(originAnalyticsData, "w")[0];
            return {
                labels: showData.map((item) => {
                    return convertDate({ _originDate: item.analytics_date, _convertFormat: "MM/DD" });
                }),
                datasets: [
                    {
                        label: "가입자수",
                        data: showData.map((item) => {
                            return {
                                x: convertDate({ _originDate: item.analytics_date, _convertFormat: "MM/DD" }),
                                y: item.daily_user_regist,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        backgroundColor: "#ea907a",
                        borderColor: "#ea907a",
                    },
                    {
                        label: "방문자수",
                        data: showData.map((item) => {
                            return {
                                x: convertDate({ _originDate: item.analytics_date, _convertFormat: "MM/DD" }),
                                y: item.daily_user_visitor,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        backgroundColor: "#726a95",
                        borderColor: "#726a95",
                    },
                    {
                        label: "스토리 등록수",
                        data: showData.map((item) => {
                            return {
                                x: convertDate({ _originDate: item.analytics_date, _convertFormat: "MM/DD" }),
                                y: item.daily_regist_stories,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        backgroundColor: "#fbc687",
                        borderColor: "#fbc687",
                    },
                    {
                        label: "지도 등록수",
                        data: showData.map((item) => {
                            return {
                                x: convertDate({ _originDate: item.analytics_date, _convertFormat: "MM/DD" }),
                                y: item.daily_regist_maps,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        backgroundColor: "#a3d2ca",
                        borderColor: "#a3d2ca",
                    },
                ],
            };
        } else if (dateType === "w") {
            let startNum = datesGroupByComponent(originAnalyticsData, "w").length >= 5 ? 5 : datesGroupByComponent(originAnalyticsData, "w").length;
            for (let i = startNum; i >= 0; i--) {
                let weeklyData = datesGroupByComponent(originAnalyticsData, "w")[i];
                let duration = `(${convertDate({ _originDate: weeklyData[0].analytics_date, _convertFormat: "MM/DD" })}-${convertDate({
                    _originDate: weeklyData[weeklyData.length - 1].analytics_date,
                    _convertFormat: "MM/DD",
                })})`;
                showData.push({
                    ...weeklyData,
                    weeks: `${i + 1}주전${duration}`,
                    weekly_daily_user_regist: lodash.sum(lodash.map(weeklyData, "daily_user_regist")),
                    weekly_daily_user_visitor: lodash.sum(lodash.map(weeklyData, "daily_user_visitor")),
                    weekly_daily_regist_stories: lodash.sum(lodash.map(weeklyData, "daily_regist_stories")),
                    weekly_daily_regist_maps: lodash.sum(lodash.map(weeklyData, "daily_regist_maps")),
                });
            }
            return {
                labels: showData.map((item) => {
                    return item.weeks;
                }),
                datasets: [
                    {
                        label: "가입자수",
                        data: showData.map((item) => {
                            return {
                                x: item.weeks,
                                y: item.weekly_daily_user_regist,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        backgroundColor: "#ea907a",
                        borderColor: "#ea907a",
                    },
                    {
                        label: "방문자수",
                        data: showData.map((item) => {
                            return {
                                x: item.weeks,
                                y: item.weekly_daily_user_visitor,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        backgroundColor: "#726a95",
                        borderColor: "#726a95",
                    },
                    {
                        label: "스토리 등록수",
                        data: showData.map((item) => {
                            return {
                                x: item.weeks,
                                y: item.weekly_daily_regist_stories,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        backgroundColor: "#fbc687",
                        borderColor: "#fbc687",
                    },
                    {
                        label: "지도 등록수",
                        data: showData.map((item) => {
                            return {
                                x: item.weeks,
                                y: item.weekly_daily_regist_maps,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        backgroundColor: "#a3d2ca",
                        borderColor: "#a3d2ca",
                    },
                ],
            };
        } else if (dateType === "m") {
            let startNum = datesGroupByComponent(originAnalyticsData, "m").length >= 6 ? 6 : datesGroupByComponent(originAnalyticsData, "m").length;

            for (let i = startNum; i >= 0; i--) {
                let monthlyData = datesGroupByComponent(originAnalyticsData, "m")[i];
                if (monthlyData !== undefined) {
                    let duration = `(${convertDate({ _originDate: monthlyData[0].analytics_date, _convertFormat: "MM/DD" })}-${convertDate({
                        _originDate: monthlyData[monthlyData.length - 1].analytics_date,
                        _convertFormat: "MM/DD",
                    })})`;
                    showData.push({
                        ...monthlyData,
                        months: `${i + 1}개월 전${duration}`,
                        monthly_daily_user_regist: lodash.sum(lodash.map(monthlyData, "daily_user_regist")),
                        monthly_daily_user_visitor: lodash.sum(lodash.map(monthlyData, "daily_user_visitor")),
                        monthly_daily_regist_stories: lodash.sum(lodash.map(monthlyData, "daily_regist_stories")),
                        monthly_daily_regist_maps: lodash.sum(lodash.map(monthlyData, "daily_regist_maps")),
                    });
                }
            }
            return {
                labels: showData.map((item) => {
                    return item.months;
                }),
                datasets: [
                    {
                        label: "가입자수",
                        data: showData.map((item) => {
                            return {
                                x: item.months,
                                y: item.monthly_daily_user_regist,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        backgroundColor: "#ea907a",
                        borderColor: "#ea907a",
                    },
                    {
                        label: "방문자수",
                        data: showData.map((item) => {
                            return {
                                x: item.months,
                                y: item.monthly_daily_user_visitor,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        backgroundColor: "#726a95",
                        borderColor: "#726a95",
                    },
                    {
                        label: "스토리 등록수",
                        data: showData.map((item) => {
                            return {
                                x: item.months,
                                y: item.monthly_daily_regist_stories,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        backgroundColor: "#fbc687",
                        borderColor: "#fbc687",
                    },
                    {
                        label: "지도 등록수",
                        data: showData.map((item) => {
                            return {
                                x: item.months,
                                y: item.monthly_daily_regist_maps,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        backgroundColor: "#a3d2ca",
                        borderColor: "#a3d2ca",
                    },
                ],
            };
        }
    } else if (dataType === "visit") {
        if (dateType === "d") {
            showData = datesGroupByComponent(originAnalyticsData, "w")[0];
            return {
                labels: showData.map((item) => {
                    return convertDate({ _originDate: item.analytics_date, _convertFormat: "MM/DD" });
                }),
                datasets: [
                    {
                        label: "AOS 방문자수",
                        data: showData.map((item) => {
                            return {
                                x: convertDate({ _originDate: item.analytics_date, _convertFormat: "MM/DD" }),
                                y: item.daily_android_visitor,
                            };
                        }),
                        fill: chartType === "line" ? false : true,

                        borderColor: "#ea907a",
                        backgroundColor: "#ea907a",
                    },
                    {
                        label: "iOS 방문자수",
                        data: showData.map((item) => {
                            return {
                                x: convertDate({ _originDate: item.analytics_date, _convertFormat: "MM/DD" }),
                                y: item.daily_ios_visitor,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        borderColor: "#726a95",
                        backgroundColor: "#726a95",
                    },
                ],
            };
        } else if (dateType === "w") {
            let startNum = datesGroupByComponent(originAnalyticsData, "w").length >= 5 ? 5 : datesGroupByComponent(originAnalyticsData, "w").length;
            for (let i = startNum; i >= 0; i--) {
                let weeklyData = datesGroupByComponent(originAnalyticsData, "w")[i];
                let duration = `(${convertDate({ _originDate: weeklyData[0].analytics_date, _convertFormat: "MM/DD" })}-${convertDate({
                    _originDate: weeklyData[weeklyData.length - 1].analytics_date,
                    _convertFormat: "MM/DD",
                })})`;
                showData.push({
                    ...weeklyData,
                    weeks: `${i + 1}주전${duration}`,
                    weekly_daily_android_visitor: lodash.sum(lodash.map(weeklyData, "daily_android_visitor")),
                    weekly_daily_ios_visitor: lodash.sum(lodash.map(weeklyData, "daily_ios_visitor")),
                });
            }
            return {
                labels: showData.map((item) => {
                    return item.weeks;
                }),
                datasets: [
                    {
                        label: "AOS 방문자수",
                        data: showData.map((item) => {
                            return {
                                x: item.weeks,
                                y: item.weekly_daily_android_visitor,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        borderColor: "#ea907a",
                        backgroundColor: "#ea907a",
                    },
                    {
                        label: "iOS 방문자수",
                        data: showData.map((item) => {
                            return {
                                x: item.weeks,
                                y: item.weekly_daily_ios_visitor,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        borderColor: "#726a95",
                        backgroundColor: "#726a95",
                    },
                ],
            };
        } else if (dateType === "m") {
            let startNum = datesGroupByComponent(originAnalyticsData, "m").length >= 6 ? 6 : datesGroupByComponent(originAnalyticsData, "m").length;

            for (let i = startNum; i >= 0; i--) {
                let monthlyData = datesGroupByComponent(originAnalyticsData, "m")[i];
                if (monthlyData !== undefined) {
                    let duration = `(${convertDate({ _originDate: monthlyData[0].analytics_date, _convertFormat: "MM/DD" })}-${convertDate({
                        _originDate: monthlyData[monthlyData.length - 1].analytics_date,
                        _convertFormat: "MM/DD",
                    })})`;
                    showData.push({
                        ...monthlyData,
                        months: `${i + 1}개월 전${duration}`,
                        monthly_daily_android_visitor: lodash.sum(lodash.map(monthlyData, "daily_android_visitor")),
                        monthly_daily_ios_visitor: lodash.sum(lodash.map(monthlyData, "daily_ios_visitor")),
                    });
                }
            }
            return {
                labels: showData.map((item) => {
                    return item.months;
                }),
                datasets: [
                    {
                        label: "AOS 방문자수",
                        data: showData.map((item) => {
                            return {
                                x: item.months,
                                y: item.monthly_daily_android_visitor,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        borderColor: "#ea907a",
                        backgroundColor: "#ea907a",
                    },
                    {
                        label: "iOS 방문자수",
                        data: showData.map((item) => {
                            return {
                                x: item.months,
                                y: item.monthly_daily_ios_visitor,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        borderColor: "#726a95",
                        backgroundColor: "#726a95",
                    },
                ],
            };
        }
    } else if (dataType === "regist") {
        if (dateType === "d") {
            showData = datesGroupByComponent(originAnalyticsData, "w")[0];
            return {
                labels: showData.map((item) => {
                    return convertDate({ _originDate: item.analytics_date, _convertFormat: "MM/DD" });
                }),
                datasets: [
                    {
                        label: "AOS 가입자수",
                        data: showData.map((item) => {
                            return {
                                x: convertDate({ _originDate: item.analytics_date, _convertFormat: "MM/DD" }),
                                y: item.daily_android_regist,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        borderColor: "#fbc687",
                        backgroundColor: "#fbc687",
                    },
                    {
                        label: "iOS 가입자수",
                        data: showData.map((item) => {
                            return {
                                x: convertDate({ _originDate: item.analytics_date, _convertFormat: "MM/DD" }),
                                y: item.daily_ios_regist,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        borderColor: "#a3d2ca",
                        backgroundColor: "#a3d2ca",
                    },
                ],
            };
        } else if (dateType === "w") {
            let startNum = datesGroupByComponent(originAnalyticsData, "w").length >= 5 ? 5 : datesGroupByComponent(originAnalyticsData, "w").length;
            for (let i = startNum; i >= 0; i--) {
                let weeklyData = datesGroupByComponent(originAnalyticsData, "w")[i];
                let duration = `(${convertDate({ _originDate: weeklyData[0].analytics_date, _convertFormat: "MM/DD" })}-${convertDate({
                    _originDate: weeklyData[weeklyData.length - 1].analytics_date,
                    _convertFormat: "MM/DD",
                })})`;
                showData.push({
                    ...weeklyData,
                    weeks: `${i + 1}주전${duration}`,
                    weekly_daily_android_regist: lodash.sum(lodash.map(weeklyData, "daily_android_regist")),
                    weekly_daily_ios_regist: lodash.sum(lodash.map(weeklyData, "daily_ios_regist")),
                });
            }
            return {
                labels: showData.map((item) => {
                    return item.weeks;
                }),
                datasets: [
                    {
                        label: "AOS 가입자수",
                        data: showData.map((item) => {
                            return {
                                x: item.weeks,
                                y: item.weekly_daily_android_regist,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        borderColor: "#fbc687",
                        backgroundColor: "#fbc687",
                    },
                    {
                        label: "iOS 가입자수",
                        data: showData.map((item) => {
                            return {
                                x: item.weeks,
                                y: item.weekly_daily_ios_regist,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        borderColor: "#a3d2ca",
                        backgroundColor: "#a3d2ca",
                    },
                ],
            };
        } else if (dateType === "m") {
            let startNum = datesGroupByComponent(originAnalyticsData, "m").length >= 6 ? 6 : datesGroupByComponent(originAnalyticsData, "m").length;

            for (let i = startNum; i >= 0; i--) {
                let monthlyData = datesGroupByComponent(originAnalyticsData, "m")[i];
                if (monthlyData !== undefined) {
                    let duration = `(${convertDate({ _originDate: monthlyData[0].analytics_date, _convertFormat: "MM/DD" })}-${convertDate({
                        _originDate: monthlyData[monthlyData.length - 1].analytics_date,
                        _convertFormat: "MM/DD",
                    })})`;
                    showData.push({
                        ...monthlyData,
                        months: `${i + 1}개월 전${duration}`,
                        monthly_daily_android_regist: lodash.sum(lodash.map(monthlyData, "daily_android_regist")),
                        monthly_daily_ios_regist: lodash.sum(lodash.map(monthlyData, "daily_ios_regist")),
                    });
                }
            }
            return {
                labels: showData.map((item) => {
                    return item.months;
                }),
                datasets: [
                    {
                        label: "AOS 가입자수",
                        data: showData.map((item) => {
                            return {
                                x: item.months,
                                y: item.monthly_daily_android_regist,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        borderColor: "#fbc687",
                        backgroundColor: "#fbc687",
                    },
                    {
                        label: "iOS 가입자수",
                        data: showData.map((item) => {
                            return {
                                x: item.months,
                                y: item.monthly_daily_ios_regist,
                            };
                        }),
                        fill: chartType === "line" ? false : true,
                        borderColor: "#a3d2ca",
                        backgroundColor: "#a3d2ca",
                    },
                ],
            };
        }
    }
}

function chartMaker(data, type, chartType) {
    let ChartComponent = null;

    switch (String(chartType).toLowerCase()) {
        case "bar":
            ChartComponent = Bar;
            break;
        case "line":
            ChartComponent = Line;
            break;
        case "table":
            ChartComponent = null;
            break;
        default:
            ChartComponent = Bar;
            break;
    }

    return (
        <ChartComponent
            data={data}
            options={{
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 30,
                        bottom: 0,
                    },
                },
                legend: {
                    position: "bottom",
                    labels: {
                        boxWidth: 10,
                    },
                },
                tooltips: {
                    mode: String(chartType).toLowerCase() === "bar" ? "point" : "index",
                    intersect: false,
                },
                plugins: {
                    labels: {
                        render: function (args) {
                            return args.value.y;
                        },
                        arc: false,
                        overlap: false,
                    },
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: false,
                            },
                            gridLines: {
                                drawBorder: false,
                                drawTicks: false,
                                zeroLineWidth: 0,
                            },
                        },
                    ],
                },
            }}
        />
    );
}

const Index = () => {
    const [analyticsData, setAnalyticsData] = React.useState(null);
    const [analyticsDataOrigin, setAnalyticsDataOrigin] = React.useState(null);
    const [analyticsLimitDate, setAnalyticsLimitDate] = React.useState(false);

    const [summaryData, setSummaryData] = React.useState(null);
    const [visitData, setVisitData] = React.useState(null);
    const [registData, setRegistData] = React.useState(null);

    const [summaryChartDateType, setSummaryChartDateType] = React.useState("d");
    const [summaryChartStyle, setSummaryChartStyle] = React.useState("bar");
    const [visitChartDateType, setVisitChartDateType] = React.useState("d");
    const [visitChartStyle, setVisitChartStyle] = React.useState("bar");
    const [registChartDateType, setRegistChartDateType] = React.useState("d");
    const [registChartStyle, setRegistChartStyle] = React.useState("bar");

    const [dateLabel, setDateLabel] = React.useState(null);
    const [stackedData, setStackedData] = React.useState(null);
    const [viewChartSexRatio, setViewChartSexRatio] = React.useState(true);
    const [viewChartAgeRatio, setViewChartAgeRatio] = React.useState(true);
    const [onReady, setOnReady] = React.useState(false);

    const [showMessage, setShowMessage] = React.useState(false);

    const dateInfo = React.useRef({
        startDate: null,
        endDate: null,
    });
    const message = React.useRef({
        tag: "",
        title: "",
        message: null,
        body: null,
        button: [],
        keyboard: true,
        toggle: true,
        renderFooter: null,
        showFooter: true,
    });

    React.useEffect(() => {
        getAnalytics().then((getAnalyticsResult) => {
            if (getAnalyticsResult.data.res_code === 2) {
                let data = lodash.uniqBy(getAnalyticsResult.data.result, "analytics_date");
                setAnalyticsData(data);
                setAnalyticsDataOrigin(data);
                // setAnalyticsStartDate(data[0].analytics_date);
                // setAnalyticsEndDate(data[data.length - 1].analytics_date);
                dateInfo.current.startDate = moment(data[0].analytics_date);
                dateInfo.current.endDate = moment(data[data.length - 1].analytics_date);
                setDateLabel(
                    lodash.map(data, function (v) {
                        return convertDate({ _originDate: v.analytics_date, _convertFormat: "YY/MM/DD" });
                    }),
                );
                setSummaryData(makeAnalyticsData(data, "d", "summary"));
                setVisitData(makeAnalyticsData(data, "d", "visit"));
                setRegistData(makeAnalyticsData(data, "d", "regist"));

                let sumSex = data[data.length - 1].daily_male_regist + data[data.length - 1].daily_female_regist;
                let sumAge =
                    data[data.length - 1].daily_teen_regist +
                    data[data.length - 1].daily_twenty_reigst +
                    data[data.length - 1].daily_thirty_regist +
                    data[data.length - 1].daily_forty_regist +
                    data[data.length - 1].daily_older_regist;

                setStackedData({
                    daily_male_regist: {
                        value: data[data.length - 1].daily_male_regist,
                        percentage: lodash.round((data[data.length - 1].daily_male_regist / sumSex) * 100, 1),
                    },
                    daily_female_regist: {
                        value: data[data.length - 1].daily_female_regist,
                        percentage: lodash.round((data[data.length - 1].daily_female_regist / sumSex) * 100, 1),
                    },
                    daily_teen_regist: {
                        value: data[data.length - 1].daily_teen_regist,
                        percentage: lodash.round((data[data.length - 1].daily_teen_regist / sumAge) * 100, 1),
                    },
                    daily_twenty_reigst: {
                        value: data[data.length - 1].daily_twenty_reigst,
                        percentage: lodash.round((data[data.length - 1].daily_twenty_reigst / sumAge) * 100, 1),
                    },
                    daily_thirty_regist: {
                        value: data[data.length - 1].daily_thirty_regist,
                        percentage: lodash.round((data[data.length - 1].daily_thirty_regist / sumAge) * 100, 1),
                    },
                    daily_forty_regist: {
                        value: data[data.length - 1].daily_forty_regist,
                        percentage: lodash.round((data[data.length - 1].daily_forty_regist / sumAge) * 100, 1),
                    },
                    daily_older_regist: {
                        value: data[data.length - 1].daily_older_regist,
                        percentage: lodash.round((data[data.length - 1].daily_older_regist / sumAge) * 100, 1),
                    },
                });
                setOnReady(true);
            }
        });
    }, []);

    if (!onReady) return <div />;
    else {
        return (
            <>
                <NormalHeader />
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className={"col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">종합</h3>
                                        </div>
                                        <div className="col text-right">
                                            <Button
                                                color={summaryChartDateType === "d" ? "danger" : "primary"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setSummaryChartDateType("d");
                                                    setSummaryData(makeAnalyticsData(analyticsData, "d"));
                                                }}
                                                size="sm">
                                                일
                                            </Button>
                                            <Button
                                                color={summaryChartDateType === "w" ? "danger" : "primary"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setSummaryChartDateType("w");
                                                    setSummaryData(makeAnalyticsData(analyticsData, "w"));
                                                }}
                                                size="sm">
                                                주
                                            </Button>
                                            <Button
                                                color={summaryChartDateType === "m" ? "danger" : "primary"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setSummaryChartDateType("m");
                                                    setSummaryData(makeAnalyticsData(analyticsData, "m"));
                                                }}
                                                size="sm">
                                                월
                                            </Button>
                                            <Button
                                                color={analyticsLimitDate ? "danger" : "primary"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    message.current.title = "알림";
                                                    message.current.showCenter = true;
                                                    if (analyticsLimitDate) {
                                                        message.current.body = null;
                                                        message.current.message = "기간제한을 해제하시겠습니까?";
                                                    } else {
                                                        message.current.message = null;
                                                        message.current.body = (
                                                            <div>
                                                                <Row>
                                                                    <Col>기간조회</Col>
                                                                </Row>
                                                                <ChangeAnalyticsDate
                                                                    data={analyticsDataOrigin}
                                                                    startDate={moment(analyticsDataOrigin[0].analytics_date)}
                                                                    endDate={moment(analyticsDataOrigin[analyticsDataOrigin.length - 1].analytics_date)}
                                                                    onChange={(s, e) => {
                                                                        dateInfo.current.startDate = s;
                                                                        dateInfo.current.endDate = e;
                                                                    }}
                                                                />
                                                            </div>
                                                        );
                                                    }

                                                    message.current.button = [
                                                        {
                                                            title: analyticsLimitDate ? "해제" : "변경",
                                                            action: () => {
                                                                setShowMessage(false);

                                                                if (analyticsLimitDate) {
                                                                    setAnalyticsData(analyticsDataOrigin);
                                                                    setSummaryData(makeAnalyticsData(analyticsDataOrigin, summaryChartDateType));
                                                                    setAnalyticsLimitDate(false);
                                                                } else {
                                                                    let startIndex = lodash.findIndex(dateLabel, function (o) {
                                                                        return o === dateInfo.current.startDate.format("YY/MM/DD");
                                                                    });

                                                                    let endIndex = lodash.findIndex(dateLabel, function (o) {
                                                                        return o === dateInfo.current.endDate.format("YY/MM/DD");
                                                                    });

                                                                    let newData = analyticsDataOrigin.slice(startIndex, endIndex);
                                                                    setAnalyticsData(newData);
                                                                    setSummaryData(makeAnalyticsData(newData, summaryChartDateType));
                                                                    setAnalyticsLimitDate(true);
                                                                }
                                                            },
                                                            background: "red",
                                                            textColor: "white",
                                                        },
                                                        {
                                                            title: "닫기",
                                                            action: () => {
                                                                setShowMessage(false);
                                                            },
                                                            background: "purple",
                                                            textColor: "white",
                                                        },
                                                    ];
                                                    message.current.showFooter = true;
                                                    message.current.keyboard = true;
                                                    message.current.renderFooter = null;
                                                    setShowMessage(true);
                                                }}
                                                size="sm">
                                                <BsCalendar />
                                            </Button>
                                        </div>
                                    </Row>
                                    <Row className="align-items-center mt-3">
                                        <div className="col"></div>
                                        <div className="col text-right">
                                            <Button
                                                color={summaryChartStyle === "bar" ? "danger" : "primary"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setSummaryChartStyle("bar");
                                                }}
                                                size="sm">
                                                <BsBarChartFill />
                                            </Button>
                                            <Button
                                                color={summaryChartStyle === "line" ? "danger" : "primary"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setSummaryChartStyle("line");
                                                }}
                                                size="sm">
                                                <MdShowChart />
                                            </Button>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <div className={"chart"}>{chartMaker(summaryData, "summary", summaryChartStyle)}</div>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={"col-lg-6 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">가입자</h3>
                                        </div>
                                        <div className="col text-right">
                                            <Button
                                                color={registChartDateType === "d" ? "danger" : "primary"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setRegistChartDateType("d");
                                                    setRegistData(makeAnalyticsData(analyticsData, "d", "regist"));
                                                }}
                                                size="sm">
                                                일
                                            </Button>
                                            <Button
                                                color={registChartDateType === "w" ? "danger" : "primary"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setRegistChartDateType("w");
                                                    setRegistData(makeAnalyticsData(analyticsData, "w", "regist"));
                                                }}
                                                size="sm">
                                                주
                                            </Button>
                                            <Button
                                                color={registChartDateType === "m" ? "danger" : "primary"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setRegistChartDateType("m");
                                                    setRegistData(makeAnalyticsData(analyticsData, "m", "regist"));
                                                }}
                                                size="sm">
                                                월
                                            </Button>
                                        </div>
                                    </Row>
                                    <Row className="align-items-center mt-3">
                                        <div className="col"></div>
                                        <div className="col text-right">
                                            <Button
                                                color={registChartStyle === "bar" ? "danger" : "primary"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setRegistChartStyle("bar");
                                                }}
                                                size="sm">
                                                <BsBarChartFill />
                                            </Button>
                                            <Button
                                                color={registChartStyle === "line" ? "danger" : "primary"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setRegistChartStyle("line");
                                                }}
                                                size="sm">
                                                <MdShowChart />
                                            </Button>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <div className={"chart"}>{chartMaker(registData, "regist", registChartStyle)}</div>
                            </Card>
                        </Col>
                        <Col className={"col-lg-6 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">방문자</h3>
                                        </div>
                                        <div className="col text-right">
                                            <Button
                                                color={visitChartDateType === "d" ? "danger" : "primary"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setVisitChartDateType("d");
                                                    setVisitData(makeAnalyticsData(analyticsData, "d", "visit"));
                                                }}
                                                size="sm">
                                                일
                                            </Button>
                                            <Button
                                                color={visitChartDateType === "w" ? "danger" : "primary"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setVisitChartDateType("w");
                                                    setVisitData(makeAnalyticsData(analyticsData, "w", "visit"));
                                                }}
                                                size="sm">
                                                주
                                            </Button>
                                            <Button
                                                color={visitChartDateType === "m" ? "danger" : "primary"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setVisitChartDateType("m");
                                                    setVisitData(makeAnalyticsData(analyticsData, "m", "visit"));
                                                }}
                                                size="sm">
                                                월
                                            </Button>
                                        </div>
                                    </Row>
                                    <Row className="align-items-center mt-3">
                                        <div className="col"></div>
                                        <div className="col text-right">
                                            <Button
                                                color={visitChartStyle === "bar" ? "danger" : "primary"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setVisitChartStyle("bar");
                                                }}
                                                size="sm">
                                                <BsBarChartFill />
                                            </Button>
                                            <Button
                                                color={visitChartStyle === "line" ? "danger" : "primary"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setVisitChartStyle("line");
                                                }}
                                                size="sm">
                                                <MdShowChart />
                                            </Button>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <div className={"chart"}>{chartMaker(visitData, "visit", visitChartStyle)}</div>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={"col-lg-6 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">성별</h3>
                                        </div>
                                        <div className="col text-right">
                                            <Button
                                                color="primary"
                                                href="#pablo"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setViewChartSexRatio(!viewChartSexRatio);
                                                }}
                                                size="sm">
                                                {viewChartSexRatio ? <BsTable /> : <BsPieChartFill />}
                                            </Button>
                                        </div>
                                    </Row>
                                </CardHeader>
                                {!viewChartSexRatio ? (
                                    <div>
                                        <Row className={"mx-auto"}>
                                            <Table className="align-items-center table-flush" responsive>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">성별</th>
                                                        <th scope="col">인원수</th>
                                                        <th scope="col">비율</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">남자</th>
                                                        <td>{numberWithComma(stackedData.daily_male_regist.value)}</td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="mr-2">{`${stackedData.daily_male_regist.percentage}%`}</span>
                                                                <div>
                                                                    <Progress max="100" value={stackedData.daily_male_regist.percentage} barClassName="bg-gradient-danger" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">여자</th>
                                                        <td>{numberWithComma(stackedData.daily_female_regist.value)}</td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="mr-2">{`${stackedData.daily_female_regist.percentage}%`}</span>
                                                                <div>
                                                                    <Progress max="100" value={stackedData.daily_female_regist.percentage} barClassName="bg-gradient-success" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Row>
                                    </div>
                                ) : (
                                    <Row style={{ maxHeight: 250 }}>
                                        <Pie
                                            data={{
                                                labels: ["남자", "여자"],
                                                datasets: [
                                                    {
                                                        data: [stackedData.daily_male_regist.value, stackedData.daily_female_regist.value],
                                                        backgroundColor: ["#726a95", "#fbc687"],
                                                        textColor: ["white", "white"],
                                                    },
                                                ],
                                            }}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                legend: {
                                                    position: "bottom",
                                                    labels: {
                                                        boxWidth: 10,
                                                    },
                                                },
                                                plugins: {
                                                    labels: {
                                                        render: function (args) {
                                                            return args.percentage + "%";
                                                        },
                                                        fontColor: function (args) {
                                                            return args.dataset.textColor[args.index];
                                                        },
                                                        arc: false,
                                                        overlap: false,
                                                    },
                                                },
                                            }}
                                        />
                                    </Row>
                                )}
                            </Card>
                        </Col>

                        <Col className={"col-lg-6 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">연령대</h3>
                                        </div>
                                        <div className="col text-right">
                                            <Button
                                                color="primary"
                                                href="#pablo"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setViewChartAgeRatio(!viewChartAgeRatio);
                                                }}
                                                size="sm">
                                                {viewChartAgeRatio ? <BsTable /> : <BsPieChartFill />}
                                            </Button>
                                        </div>
                                    </Row>
                                </CardHeader>
                                {!viewChartAgeRatio ? (
                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">성별</th>
                                                <th scope="col">인원수</th>
                                                <th scope="col">비율</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">10대</th>
                                                <td>{numberWithComma(stackedData.daily_teen_regist.value)}</td>
                                                <td>
                                                    <div className="d-flex align-items-center ">
                                                        <span className="mr-2">{`${stackedData.daily_teen_regist.percentage}%`}</span>
                                                        <div>
                                                            <Progress max="100" value={stackedData.daily_teen_regist.percentage} barClassName="bg-gradient-danger" />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">20대</th>
                                                <td>{numberWithComma(stackedData.daily_twenty_reigst.value)}</td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <span className="mr-2">{`${stackedData.daily_twenty_reigst.percentage}%`}</span>
                                                        <div>
                                                            <Progress max="100" value={stackedData.daily_twenty_reigst.percentage} barClassName="bg-gradient-success" />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">30대</th>
                                                <td>{numberWithComma(stackedData.daily_thirty_regist.value)}</td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <span className="mr-2">{`${stackedData.daily_thirty_regist.percentage}%`}</span>
                                                        <div>
                                                            <Progress max="100" value={stackedData.daily_thirty_regist.percentage} barClassName="bg-gradient-info" />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">40대</th>
                                                <td>{numberWithComma(stackedData.daily_forty_regist.value)}</td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <span className="mr-2">{`${stackedData.daily_forty_regist.percentage}%`}</span>
                                                        <div>
                                                            <Progress max="100" value={stackedData.daily_forty_regist.percentage} barClassName="bg-gradient-warning" />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">기타</th>
                                                <td>{numberWithComma(stackedData.daily_older_regist.value)}</td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <span className="mr-2">{`${stackedData.daily_older_regist.percentage}%`}</span>
                                                        <div>
                                                            <Progress max="100" value={stackedData.daily_older_regist.percentage} barClassName="bg-gradient-secondary" />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                ) : (
                                    <Row style={{ maxHeight: 250 }}>
                                        <Pie
                                            data={{
                                                labels: lodash.map(ageGradeList, "label"),
                                                datasets: [
                                                    {
                                                        data: [
                                                            stackedData.daily_older_regist.value,
                                                            stackedData.daily_teen_regist.value,
                                                            stackedData.daily_twenty_reigst.value,
                                                            stackedData.daily_thirty_regist.value,
                                                            stackedData.daily_forty_regist.value,
                                                        ],
                                                        backgroundColor: lodash.map(ageGradeList, "bgColor"),
                                                        textColor: lodash.map(ageGradeList, "textColor"),
                                                    },
                                                ],
                                            }}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                legend: {
                                                    position: "bottom",
                                                    labels: {
                                                        boxWidth: 10,
                                                    },
                                                },
                                                plugins: {
                                                    labels: {
                                                        render: function (args) {
                                                            return args.percentage + "%";
                                                        },
                                                        fontColor: function (args) {
                                                            return args.dataset.textColor[args.index];
                                                        },
                                                        arc: false,
                                                        overlap: false,
                                                    },
                                                },
                                            }}
                                        />
                                    </Row>
                                )}
                            </Card>
                        </Col>
                    </Row>
                    <Row className={"mt-3"}></Row>
                </Container>
                <PhotologDialog
                    isOpen={showMessage}
                    toggle={() => setShowMessage(!showMessage)}
                    title={message.current.title}
                    message={message.current.message}
                    body={message.current.message !== null ? null : message.current.body}
                    button={message.current.button}
                    backdrop={"static"}
                    keyboard={message.current.keyboard}
                    showCenter
                    showFooter={message.current.showFooter}
                    renderFooter={message.current.renderFooter}
                />
            </>
        );
    }
};

export default Index;
