import React from "react";
import { Badge, Card, Container, Row } from "reactstrap";
import NormalHeader from "../../components/Headers/NormalHeader.js";
import PushDetail from "./PushDetail";
import { getPushData } from "../../lib/push.js";
import { BiSubdirectoryRight } from "react-icons/bi";
import PhotologTable from "../../modules/PhotologTable.js";

const Push = () => {
    const columns = [
        {
            dataField: "push_seq",
            text: "Seq",
            headerStyle: { width: "5%" },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
        },
        {
            dataField: "push_state",
            text: "상태",
            headerStyle: { width: "5%", minWidth: 100 },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
            formatter: (cell, row) => {
                return (
                    <div>
                        <Badge color={row.push_state === "error" ? "danger" : "info"}>{row.push_state}</Badge>
                    </div>
                );
            },
        },
        {
            dataField: "push_title",
            text: "제목",
            headerStyle: { width: "25%", minWidth: 100 },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
            formatter: (cell, row) => {
                return (
                    <div>
                        <span>{cell}</span>
                        {row.push_sub_title !== "null" && row.push_sub_title !== null && (
                            <div>
                                <span style={{ color: "gray", fontSize: 12 }}>
                                    <BiSubdirectoryRight className={"mb-1"} />
                                    {row.push_sub_title}
                                </span>
                            </div>
                        )}
                    </div>
                );
            },
        },

        {
            dataField: "push_tag",
            text: "대상",
            headerStyle: { width: "5%", minWidth: 100 },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
            formatter: (cell, row) => {
                return (
                    <div>
                        <Badge color={"warning"}>{cell}</Badge>
                    </div>
                );
            },
        },
        {
            dataField: "push_send_success",
            text: "실패/성공",
            headerStyle: { width: "5%", minWidth: 100 },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
            formatter: (cell, row) => {
                return (
                    <div>
                        <Badge color={"success"}>
                            {row.push_send_failed}/{row.push_send_success}
                        </Badge>
                    </div>
                );
            },
        },
    ];

    React.useEffect(() => {
        // doGetContents({ _category: 1, _size: 10, _pageNo: 1 });
    }, []);

    return (
        <>
            <NormalHeader />
            <Container className="mt--7 pb-7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow border-0 pt-4 pb-4">
                            <PhotologTable
                                category={1}
                                keyField={"push_seq"}
                                columns={columns}
                                detailPage={PushDetail}
                                getData={({ _category, _size, _pageNo }) => {
                                    return getPushData({ _category: _category, _size: _size, _pageNo: _pageNo });
                                }}
                                enableInsertButton
                            />
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Push;
