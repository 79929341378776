import React from "react";
import { History } from "../../modules/utils.js";
import { Card, Col, Container, Form, FormGroup, Input, Label, Row, Button } from "reactstrap";
import ToggleSwitch from "../../modules/ToggleSwitch.js";
// import Icon from "../../modules/VectorIcons"
import lodash from "lodash";
import ReactQuill from "react-quill";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import { PhotologDialog } from "../../modules/PhotologDialog.js";
import { deleteNotice, insertNotice, modifyNotice } from "../../lib/notice.js";
import { toast } from "react-toastify";
const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const NoticeDetail = (props) => {
    const [showItem, setShowItem] = React.useState(null);
    const [addMode, setAddMode] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);

    const [showMessage, setShowMessage] = React.useState(false);
    const [showBackdrop, setShowBackdrop] = React.useState(false);
    const message = React.useRef({
        tag: "",
        title: "",
        message: null,
        body: null,
        button: [],
        keyboard: true,
        toggle: true,
        renderFooter: null,
        showFooter: true,
    });

    const classes = useStyles();
    React.useEffect(() => {
        if (props === undefined) {
            History.replace("/admin/notice");
            window.location.reload();
            return;
        } else {
            if (showItem === null) {
                if (props.mode === "add") {
                    setShowItem({
                        contents: {
                            notice_contents: "",
                            notice_title: "",
                        },
                    });
                    setAddMode(true);
                } else {
                    setShowItem({
                        contents: props.getContentsItem,
                    });
                }
            } else {
            }
        }
    }, [showItem, props]);

    const doValidation = () => {
        if (editMode) {
            if (lodash.isEqual(props.getContentsItem, showItem.contents)) {
                return {
                    errCode: -1,
                    errMsg: "바뀐값이 없음",
                };
            }
        }
        // 공지사항 내용 (필수)
        if (showItem.contents.notice_title === null || showItem.contents.notice_title.length === 0) {
            return {
                errCode: 1,
                errMsg: "제목 입력란을 확인하세요",
            };
        }
        // 공지사항 내용 (필수)
        if (showItem.contents.notice_contents === null || showItem.contents.notice_contents.length === 0) {
            return {
                errCode: 1,
                errMsg: "내용 입력란을 확인하세요",
            };
        }

        return {
            errCode: 0,
            errMsg: "문제 발견되지 않음",
        };
    };

    if (showItem === null) {
        return <div></div>;
    } else {
        return (
            <>
                <Container className="mt--7">
                    {addMode ? (
                        <Row>
                            <Col className={"col-12 mx-auto mt-6 m-3"}></Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col className={"col-12 mx-auto mt-6 m-3"}>
                                <Label for="contents_editMode" style={{ color: "black" }}>
                                    수정모드
                                </Label>
                                <Row className={"ml-0"}>
                                    <ToggleSwitch
                                        id="contents_editMode"
                                        checked={editMode}
                                        onChange={(v) => {
                                            if (editMode === true) {
                                                // 수정모드 가능->불가능
                                                setShowItem({
                                                    contents: props.getContentsItem,
                                                });
                                            }
                                            setEditMode(v);
                                        }}
                                        optionLabels={["가능", "불가"]}
                                    />
                                </Row>
                            </Col>
                        </Row>
                    )}

                    <Card className="shadow border-0 pb-4">
                        <Row className={"m-3"}>
                            <Col className={"col-12 mx-auto mt-3"}>
                                <Label for="contents_notice_title">제목</Label>
                                <Input
                                    type="text"
                                    name="notice_title"
                                    id="contents_notice_title"
                                    defaultValue={showItem.contents.notice_title}
                                    value={showItem.contents.notice_title}
                                    disabled={addMode ? false : !editMode}
                                    onChange={(e) => {
                                        setShowItem({
                                            contents: {
                                                ...showItem.contents,
                                                notice_title: e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className={"ml-3 mr-3 mt-3"}>
                            <Col className={"col-12 mx-auto mt-3"}>
                                <Label for="contents_notice_contents">내용</Label>

                                <ReactQuill
                                    id="contents_notice_contents"
                                    readOnly={addMode ? false : !editMode}
                                    style={{ height: 350 }}
                                    value={`${showItem.contents.notice_contents}`}
                                    onChange={(content, delta, source, editor) => {
                                        setShowItem({
                                            contents: {
                                                ...showItem.contents,
                                                notice_contents: `${content}`,
                                            },
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-8"}>
                            <Col className={"col-11 mx-auto"}>
                                {addMode ? (
                                    <div className={"float-right"}>
                                        <Button
                                            className={"btn btn-default btn-lg"}
                                            style={{ width: 100 }}
                                            onClick={() => {
                                                const validationResult = doValidation();

                                                if (validationResult.errCode === 0) {
                                                    message.current.title = "알림";
                                                    message.current.message = "추가하시겠습니까?";
                                                    message.current.button = [
                                                        {
                                                            title: "추가",
                                                            action: () => {
                                                                setShowMessage(false);
                                                                setShowBackdrop(true);
                                                                insertNotice({
                                                                    _noticeTitle: showItem.contents.notice_title,
                                                                    _noticeContents: showItem.contents.notice_contents.replaceAll("<p>", "<div>").replaceAll("</p>", "</div>"),
                                                                }).then((insertNoticeResult) => {
                                                                    if (insertNoticeResult.data.res_code === 2) {
                                                                        toast.success("🎉 완료되었습니다");
                                                                        setTimeout(() => {
                                                                            window.location.reload();
                                                                        }, 2000);
                                                                    }
                                                                });
                                                            },
                                                            background: "red",
                                                            textColor: "white",
                                                        },
                                                        {
                                                            title: "닫기",
                                                            action: () => {
                                                                setShowMessage(false);
                                                            },
                                                            background: "purple",
                                                            textColor: "white",
                                                        },
                                                    ];
                                                    message.current.body = null;
                                                    message.current.showFooter = true;
                                                    message.current.keyboard = true;
                                                    message.current.renderFooter = null;
                                                    setShowMessage(true);
                                                } else {
                                                    message.current.title = "알림";
                                                    message.current.message = `${validationResult.errMsg}`;
                                                    message.current.button = [
                                                        {
                                                            title: "확인",
                                                            action: () => {
                                                                setShowMessage(false);
                                                            },
                                                            background: "purple",
                                                            textColor: "white",
                                                        },
                                                    ];
                                                    message.current.body = null;
                                                    message.current.showFooter = true;
                                                    message.current.keyboard = true;
                                                    message.current.renderFooter = null;
                                                    setShowMessage(true);
                                                }
                                            }}>
                                            추가
                                        </Button>
                                    </div>
                                ) : (
                                    <div className={"float-right"}>
                                        <Button
                                            className={"btn btn-default btn-lg"}
                                            style={{ width: 100 }}
                                            onClick={() => {
                                                const validationResult = doValidation();
                                                if (validationResult.errCode === 0) {
                                                    message.current.title = "알림";
                                                    message.current.message = "수정하시겠습니까?";
                                                    message.current.button = [
                                                        {
                                                            title: "수정",
                                                            action: () => {
                                                                setShowMessage(false);
                                                                modifyNotice({
                                                                    _noticeIdx: showItem.contents.notice_seq,
                                                                    _noticeTitle: showItem.contents.notice_title,
                                                                    _noticeContents: showItem.contents.notice_contents.replaceAll("<p>", "<div>").replaceAll("</p>", "</div>"),
                                                                }).then((modifyNoticeResult) => {
                                                                    if (modifyNoticeResult.data.res_code === 2) {
                                                                        props.onResult({
                                                                            actionCode: modifyNoticeResult.data.res_code,
                                                                            actionName: "edit",
                                                                            changedData: {
                                                                                ...showItem,
                                                                            },
                                                                        });
                                                                    }
                                                                });
                                                            },
                                                            background: "red",
                                                            textColor: "white",
                                                        },
                                                        {
                                                            title: "닫기",
                                                            action: () => {
                                                                setShowMessage(false);
                                                            },
                                                            background: "purple",
                                                            textColor: "white",
                                                        },
                                                    ];
                                                    message.current.body = null;
                                                    message.current.showFooter = true;
                                                    message.current.keyboard = true;
                                                    message.current.renderFooter = null;
                                                    setShowMessage(true);
                                                } else {
                                                    message.current.title = "알림";
                                                    message.current.message = `${validationResult.errMsg}`;
                                                    message.current.button = [
                                                        {
                                                            title: "확인",
                                                            action: () => {
                                                                setShowMessage(false);
                                                            },
                                                            background: "purple",
                                                            textColor: "white",
                                                        },
                                                    ];
                                                    message.current.body = null;
                                                    message.current.showFooter = true;
                                                    message.current.keyboard = true;
                                                    message.current.renderFooter = null;
                                                    setShowMessage(true);
                                                }
                                            }}>
                                            수정
                                        </Button>
                                        <Button
                                            className={"btn btn-danger btn-lg"}
                                            style={{ width: 100 }}
                                            onClick={() => {
                                                message.current.title = "알림";
                                                message.current.message = "삭제하시겠습니까?";
                                                message.current.button = [
                                                    {
                                                        title: "삭제",
                                                        action: () => {
                                                            deleteNotice({
                                                                _noticeIdx: showItem.contents.notice_seq,
                                                            }).then((deleteNoticeResult) => {
                                                                setShowMessage(false);
                                                                if (deleteNoticeResult.data.res_code === 2) {
                                                                    props.onResult({
                                                                        actionCode: deleteNoticeResult.data.res_code,
                                                                        actionName: "del",
                                                                        changedData: {
                                                                            ...showItem,
                                                                        },
                                                                    });
                                                                }
                                                            });
                                                        },
                                                        background: "red",
                                                        textColor: "white",
                                                    },
                                                    {
                                                        title: "닫기",
                                                        action: () => {
                                                            setShowMessage(false);
                                                        },
                                                        background: "purple",
                                                        textColor: "white",
                                                    },
                                                ];
                                                message.current.body = null;
                                                message.current.showFooter = true;
                                                message.current.keyboard = true;
                                                message.current.renderFooter = null;
                                                setShowMessage(true);
                                            }}>
                                            삭제
                                        </Button>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Card>
                </Container>

                <PhotologDialog
                    isOpen={showMessage}
                    toggle={() => setShowMessage(!showMessage)}
                    title={message.current.title}
                    message={message.current.message}
                    body={message.current.message !== null ? null : message.current.body}
                    button={message.current.button}
                    backdrop={"static"}
                    keyboard={message.current.keyboard}
                    showCenter
                    showFooter={message.current.showFooter}
                    renderFooter={message.current.renderFooter}
                />
                <Backdrop
                    className={classes.backdrop}
                    open={showBackdrop}
                    // invisible={true}
                    onClick={() => {}}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        );
    }
};

export default NoticeDetail;
