import React from "react";
import PropTypes from "prop-types";
import { Button, Fade, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import "../assets/scss/argon-dashboard-react.scss";

export class PhotologDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            update: false,
        };
    }
    componentDidMount() {}
    componentWillUnmount() {}
    componentDidUpdate(prevProps) {}
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.refresh !== undefined) {
            if (this.props.refresh !== nextProps.refresh) {
                this.setState({ update: !this.state.update });
                return true;
            }
        }
        if (this.props.isOpen !== nextProps.isOpen) {
            return true;
        }

        return false;
    }
    render() {
        const { showHeader, title, headerStyle, button, message, body, showCenter, large, toggle, renderFooter, showFooter } = this.props;
        const modalPosition = Boolean(showCenter) ? "modal-dialog-centered" : "modal-dialog";

        return (
            <Modal className={modalPosition} size={Boolean(large) ? "lg" : "sm"} {...this.props}>
                {showHeader && (
                    <ModalHeader className={"modal-title"} toggle={toggle === null ? undefined : toggle}>
                        <span style={{ fontSize: 16, ...headerStyle }}>{title}</span>
                    </ModalHeader>
                )}
                <ModalBody className={"modal-body col-12"}>
                    {message === undefined || message === null || message === ""
                        ? body
                        : message.split("\n").map((i, key) => {
                              return <div key={key}>{i}</div>;
                          })}
                </ModalBody>
                {showFooter && (
                    <ModalFooter className={"modal-footer"}>
                        {button !== undefined &&
                            button !== null &&
                            button.length > 0 &&
                            button.map((item, index) => {
                                return (
                                    <Button
                                        key={`modal-button-${index}`}
                                        style={{ background: item.background === undefined ? "gray" : item.background, color: item.textColor === undefined ? "black" : item.textColor }}
                                        onClick={item.action}>
                                        {item.title}
                                    </Button>
                                );
                            })}
                        {renderFooter !== null && renderFooter}
                    </ModalFooter>
                )}
            </Modal>
        );
    }
}

PhotologDialog.propTypes = {
    button: PropTypes.array.isRequired,
    isOpen: PropTypes.bool,
    autoFocus: PropTypes.bool,
    centered: PropTypes.bool,
    size: PropTypes.string,
    toggle: PropTypes.func,
    role: PropTypes.string,
    labelledBy: PropTypes.string,
    keyboard: PropTypes.bool,
    backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(["static"])]),
    scrollable: PropTypes.bool,
    external: PropTypes.node,
    onEnter: PropTypes.func,
    onExit: PropTypes.func,
    onOpened: PropTypes.func,
    onClosed: PropTypes.func,
    className: PropTypes.string,
    wrapClassName: PropTypes.string,
    modalClassName: PropTypes.string,
    backdropClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    fade: PropTypes.bool,
    cssModule: PropTypes.object,
    zIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backdropTransition: PropTypes.shape(Fade.propTypes),
    modalTransition: PropTypes.shape(Fade.propTypes),
    innerRef: PropTypes.object,
    unmountOnClose: PropTypes.bool,
    returnFocusAfterClose: PropTypes.bool,
    showCenter: PropTypes.bool,
};

PhotologDialog.defaultProps = {
    keyboard: true,
    backdrop: true,
    showHeader: true,
    showFooter: true,
    showCenter: true,
    title: "알림",
    renderFooter: null,
    large: false,
};
