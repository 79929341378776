import axios from "axios";
import { logout } from "../modules/utils";

export function insertPopup({ _popupTitle, _popupContents, _popupBtn1, _popupBtn2, _popupUrl, _popupStartDate, _popupEndDate, _popupType, _navigationCategory, _navigationSeq, _navigationCode }) {
    // return axios
    //     .post("/apis/app-popup/insert-popup", {
    //         popupTitle: _popupTitle,
    //         popupContents: _popupContents,
    //         popupBtn1: _popupBtn1,
    //         popupBtn2: _popupBtn2,
    //         popupUrl: _popupUrl,
    //         popupStartDate: _popupStartDate,
    //         popupEndDate: _popupEndDate,
    //         popupType: _popupType,
    //         navigationCategory: _navigationCategory,
    //         navigationSeq: _navigationSeq,
    //         navigationCode: _navigationCode,
    //     })
    //     .then((result) => {
    //         if (result.data.res_code === 1 || result.data.res_code === 3) {
    //             logout();
    //             return null;
    //         } else return result;
    //     })
    //     .catch((e) => {
    //         return null;
    //     });
    let popupData = new FormData();

    popupData.append("file", _popupContents);

    popupData.append("popupTitle", _popupTitle);
    popupData.append("popupBtn1", _popupBtn1);
    popupData.append("popupBtn2", _popupBtn2);
    popupData.append("popupUrl", _popupUrl); // 지도 삽입 후, _해당 코드 & 신규 스토리 작성 경우 지역 선택
    popupData.append("popupStartDate", _popupStartDate);
    popupData.append("popupEndDate", _popupEndDate);
    popupData.append("popupType", _popupType);
    popupData.append("navigationCategory", _navigationCategory);
    popupData.append("navigationSeq", _navigationSeq);
    popupData.append("navigationCode", _navigationCode);
    return axios
        .post("/apis/app-popup/insert-popup", popupData, {
            headers: {
                "content-type": "multipart/form-data; charset=utf-8;",
            },
        })
        .then((result) => {
            return result;
        })
        .catch((e) => {
            return null;
        });
}
export function modifyPopup({ _popupIdx, _popupTitle, _popupBtn1, _popupBtn2, _popupUrl, _popupStartDate, _popupEndDate, _popupType, _navigationCategory, _navigationSeq, _navigationCode }) {
    return axios
        .post("/apis/app-popup/modify-popup", {
            popupIdx: _popupIdx,
            popupTitle: _popupTitle,
            popupBtn1: _popupBtn1,
            popupBtn2: _popupBtn2,
            popupUrl: _popupUrl,
            popupStartDate: _popupStartDate,
            popupEndDate: _popupEndDate,
            popupType: _popupType,
            navigationCategory: _navigationCategory,
            navigationSeq: _navigationSeq,
            navigationCode: _navigationCode,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
export function deletePopup({ _popupIdx }) {
    return axios
        .post("/apis/app-popup/delete-popup", {
            popupIdx: _popupIdx,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}

export function getPopupData({ _category, _size, _pageNo }) {
    return axios
        .post("/apis/app-popup/get-popup-data", {
            category: _category,
            size: _size,
            pageNo: _pageNo,
        })
        .then((result) => {
            if (result.data.res_code === 1 || result.data.res_code === 3) {
                logout();
                return null;
            } else return result;
        })
        .catch((e) => {
            return null;
        });
}
