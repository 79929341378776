import React from "react";
import { Badge, Card, Container, Row } from "reactstrap";
import NormalHeader from "../../components/Headers/NormalHeader.js";
import { convertDate } from "../../modules/utils.js";
import StoreNoticeDetail from "./StoreNoticeDetail";
import PhotologTable, { SortIcon } from "../../modules/PhotologTable.js";
import { getStoreNoticeList } from "../../lib/storeNotice.js";
import lodash from "lodash";

export const noticeTypeList = [
    {
        value: "main",
        name: "상점",
        color: "info",
    },
    {
        value: "ad",
        name: "광고",
        color: "danger",
    },
];
export const noticeShowType = [
    {
        value: false,
        name: "표시안함",
        color: "light",
    },

    {
        value: true,
        name: "팝업표시",
        color: "info",
    },
];
const StoreNotice = () => {
    const columns = [
        {
            dataField: "notice_seq",
            text: "번호",
            headerStyle: { minWidth: "10%", width: "10%" },
            headerAlign: (column, colIndex) => "center",
            align: (cell, row, rowIndex, colIndex) => {
                return "center";
            },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
        },
        {
            dataField: "notice_type",
            text: "분류",
            headerStyle: { minWidth: "10%", width: "10%" },
            headerAlign: (column, colIndex) => "center",
            align: (cell, row, rowIndex, colIndex) => {
                return "center";
            },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
            formatter: (cell, row) => {
                return (
                    <div>
                        <Badge color={lodash.find(noticeTypeList, { value: cell }).color}>{lodash.find(noticeTypeList, { value: cell }).name}</Badge>
                    </div>
                );
            },
        },
        {
            dataField: "notice_popup_showing",
            text: "팝업유무",
            headerStyle: { minWidth: "15%", width: "15%" },
            headerAlign: (column, colIndex) => "center",
            align: (cell, row, rowIndex, colIndex) => {
                return "center";
            },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
            formatter: (cell, row) => {
                return (
                    <div>
                        <Badge color={lodash.find(noticeShowType, { value: Boolean(cell) }).color}>{lodash.find(noticeShowType, { value: Boolean(cell) }).name}</Badge>
                    </div>
                );
            },
        },
        {
            dataField: "notice_title",
            text: "제목",
            headerStyle: { width: "40%", minWidth: 100 },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
        },

        // {
        //     dataField: "notice_contents",
        //     text: "내용",
        //     headerStyle: { width: "35%", minWidth: 100 },
        //     style: (cell, row, rowIndex, colIndex) => {
        //         return {
        //             whiteSpace: "nowrap",
        //             textOverflow: "ellipsis",
        //             overflow: "hidden",
        //         };
        //     },
        //     formatter: (cell, row) => {
        //         return <span>{cell.replace(/<[^>]+>/g, "")}</span>;
        //     },
        // },

        {
            dataField: "notice_registed_date",
            text: "등록일시",
            sort: true,
            headerStyle: { width: "25%" },
            headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
                return (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {column.text}
                        <SortIcon order={sortElement.props.order} />
                    </div>
                );
            },
            formatter: (cell, row) => {
                return <span>{convertDate({ _originDate: cell, _convertFormat: "YYYY-MM-DD HH:mm:ss" })}</span>;
            },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
        },
    ];

    React.useEffect(() => {
        // doGetContents({ _category: 6, _size: 10, _pageNo: 1 });
    }, []);

    return (
        <>
            <NormalHeader />
            <Container className="mt--7 pb-7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow border-0 pt-4 pb-4">
                            <PhotologTable
                                category={6}
                                keyField={"notice_seq"}
                                columns={columns}
                                detailPage={StoreNoticeDetail}
                                getData={({ _category, _size, _pageNo }) => {
                                    return getStoreNoticeList({ _category: _category, _size: _size, _pageNo: _pageNo });
                                }}
                                enableInsertButton
                            />
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default StoreNotice;
