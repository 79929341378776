import React from "react";
import NormalHeader from "../../components/Headers/NormalHeader.js";
import CouponDetail from "./CouponDetail";

import { Badge, Card, Container, Row } from "reactstrap";
import lodash from "lodash";
import { convertDate, getOS, History, splitString } from "../../modules/utils.js";
import { getCouponData } from "../../lib/coupon.js";
import FileSaver from "file-saver";
import PhotologTable from "../../modules/PhotologTable.js";
import iconv from "iconv-lite";

export const couponTypeList = [
    {
        coupon_type: "amount",
        coupon_type_name: "수량할인",
        color: "info",
    },
    {
        coupon_type: "promotion",
        coupon_type_name: "프로모션",
        color: "danger",
    },
    {
        coupon_type: "price",
        coupon_type_name: "금액할인",
        color: "info",
    },
    {
        coupon_type: "percent",
        coupon_type_name: "비율할인",
        color: "info",
    },
    {
        coupon_type: "influencer",
        coupon_type_name: "인플루언서",
        color: "danger",
    },
    {
        coupon_type: "shipping_free",
        coupon_type_name: "배송비무료",
        color: "info",
    },
];

const Coupon = () => {
    const columns = [
        {
            dataField: "coupon_title",
            text: "쿠폰명",
            headerStyle: { width: "15%" },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
        },
        {
            dataField: "coupon_code",
            text: "쿠폰코드",
            headerStyle: { width: "15%" },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
            csvFormatter: (cell, row, rowIndex) => {
                let splitStrArr = splitString(cell, 4);
                return `${splitStrArr.join("-")} (${row.coupon_free_amount}장)`;
            },
        },

        {
            dataField: "coupon_type",
            text: "쿠폰타입",
            headerStyle: { width: "10%" },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
            formatter: (cell, row) => {
                return (
                    <div>
                        <Badge color={lodash.find(couponTypeList, { coupon_type: cell }).color}>{lodash.find(couponTypeList, { coupon_type: cell }).coupon_type_name}</Badge>
                    </div>
                );
            },
        },
        {
            dataField: "coupon_used_yn",
            text: "사용유무",
            headerStyle: { width: "10%" },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
            classes: "d-none d-lg-table-cell",
            headerClasses: "d-none d-lg-table-cell",
            formatter: (cell, row) => {
                return (
                    <div>
                        <Badge color={Number(cell) === 1 ? "danger" : "info"}> {Number(cell) === 1 ? "사용완료" : "사용안함"}</Badge>
                    </div>
                );
            },
        },
        {
            dataField: "coupon_registed_yn",
            text: "등록상태",
            headerStyle: { width: "10%" },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
            classes: "d-none d-lg-table-cell",
            headerClasses: "d-none d-lg-table-cell",
            formatter: (cell, row) => {
                return (
                    <div>
                        <Badge color={Number(cell) === 1 ? "danger" : "info"}> {Number(cell) === 1 ? "등록완료" : "등록안함"}</Badge>
                    </div>
                );
            },
        },
    ];

    React.useEffect(() => {
        // doGetContents({ _category: 4, _size: 10, _pageNo: 1 });
    }, []);

    const makeCSV = async (codeArr) => {
        let csvStr = `"code"`;

        await codeArr.map((item) => {
            let splitStrArr = splitString(item.coupon_code, 4);
            csvStr += `\n"${splitStrArr.join("-")} (${item.coupon_free_amount}장)"`;
        });
        let fileName = `${convertDate({ _originDate: new Date(), _convertFormat: "YYYYMMDD_HHmmss" })}.csv`;
        // let csvFile = new File([`\uFEFF${csvStr}`], fileName, { type: "text/csv;charset=ansi" });
        let csvFile = new File([iconv.encode(csvStr, getOS(window.navigator) === "Mac" ? "UTF-8" : "EUC-KR")], fileName, { type: "text/csv" });
        return FileSaver.saveAs(csvFile, fileName);
    };

    return (
        <>
            <NormalHeader />
            <Container className="mt--7 pb-7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow border-0 pt-4 pb-4">
                            <PhotologTable
                                category={4}
                                keyField={"coupon_code"}
                                columns={columns}
                                detailPage={CouponDetail}
                                getData={({ _category, _size, _pageNo }) => {
                                    return getCouponData({ _category: _category, _size: _size, _pageNo: _pageNo });
                                }}
                                enableInsertButton
                                enableExportCSV
                                enableSelectRow
                            />
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Coupon;
