import React, { Component } from "react";
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Label, Row } from "reactstrap";
import PropTypes from "prop-types";
import { Fragment } from "react";
import lodash from "lodash";
import { Card, Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import moment from "moment";

export class TimePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openHourPicker: false,
            openMinutePicker: false,
            openSecondPicker: false,
            hours: Array.from({ length: 24 }, (v, i) => i),
            minutes: Array.from({ length: 60 }, (v, i) => i),
            seconds: Array.from({ length: 60 }, (v, i) => i),
            result: { h: 0, m: 0, s: 0 },
        };
    }
    componentDidMount() {
        if (this.props.value !== null) {
            this.setState({ result: { ...this.state.result, ...this.props.value } });
        }
    }
    componentWillUnmount() {}
    componentDidUpdate(prevProps) {
        if (this.props.value !== null && prevProps !== this.props) {
            this.setState({ result: { ...this.state.result, ...this.props.value } });
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.openHourPicker !== nextState.openHourPicker) {
            return true;
        }
        if (this.state.openMinutePicker !== nextState.openMinutePicker) {
            return true;
        }
        if (this.state.openSecondPicker !== nextState.openSecondPicker) {
            return true;
        }
        if (this.state.result.h !== nextState.result.h) {
            return true;
        }
        if (this.state.result.m !== nextState.result.m) {
            return true;
        }
        if (this.state.result.s !== nextState.result.s) {
            return true;
        }
        if (this.props.value !== nextProps.value) {
            return true;
        }

        return false;
    }
    render() {
        return (
            <div style={{ ...this.props.style }}>
                {this.props.showHour && (
                    <Dropdown isOpen={this.props.viewMode !== "view" && this.state.openHourPicker} toggle={() => this.setState({ openHourPicker: !this.state.openHourPicker })} size="sm">
                        <DropdownToggle caret> {this.state.result.h < 10 ? `0${this.state.result.h}` : this.state.result.h}시</DropdownToggle>
                        <DropdownMenu
                            positionFixed
                            modifiers={{
                                setMaxHeight: {
                                    enabled: true,
                                    fn: (data) => {
                                        return {
                                            ...data,
                                            styles: {
                                                ...data.styles,
                                                overflow: "auto",
                                                maxHeight: `${this.props.maxHeight}px`,
                                            },
                                        };
                                    },
                                },
                            }}>
                            {this.state.hours.map((item, index) => {
                                return (
                                    <DropdownItem
                                        key={`TimePicker-hours-${index}`}
                                        onClick={() => {
                                            this.setState({ result: { ...this.state.result, h: item } });
                                            this.props.onChange({ ...this.state.result, h: item });
                                        }}>
                                        {item < 10 ? `0${item}` : item}
                                    </DropdownItem>
                                );
                            })}
                        </DropdownMenu>
                    </Dropdown>
                )}
                {this.props.showMinute && (
                    <Dropdown isOpen={this.props.viewMode !== "view" && this.state.openMinutePicker} toggle={() => this.setState({ openMinutePicker: !this.state.openMinutePicker })} size="sm">
                        <DropdownToggle caret>{this.state.result.m < 10 ? `0${this.state.result.m}` : this.state.result.m}분</DropdownToggle>
                        <DropdownMenu
                            positionFixed
                            modifiers={{
                                setMaxHeight: {
                                    enabled: true,
                                    fn: (data) => {
                                        return {
                                            ...data,
                                            styles: {
                                                ...data.styles,
                                                overflow: "auto",
                                                maxHeight: `${this.props.maxHeight}px`,
                                            },
                                        };
                                    },
                                },
                            }}>
                            {this.state.minutes.map((item, index) => {
                                if (item % this.props.minSeparate === 0) {
                                    return (
                                        <DropdownItem
                                            key={`TimePicker-minutes-${index}`}
                                            onClick={() => {
                                                this.setState({ result: { ...this.state.result, m: item } });
                                                this.props.onChange({ ...this.state.result, m: item });
                                            }}>
                                            {item < 10 ? `0${item}` : item}
                                        </DropdownItem>
                                    );
                                }
                            })}
                        </DropdownMenu>
                    </Dropdown>
                )}
                {this.props.showSecond && (
                    <Dropdown isOpen={this.props.viewMode !== "view" && this.state.openSecondPicker} toggle={() => this.setState({ openSecondPicker: !this.state.openSecondPicker })} size="sm">
                        <DropdownToggle caret>{this.state.result.s < 10 ? `0${this.state.result.s}` : this.state.result.s}초</DropdownToggle>
                        <DropdownMenu
                            positionFixed
                            modifiers={{
                                setMaxHeight: {
                                    enabled: true,
                                    fn: (data) => {
                                        return {
                                            ...data,
                                            styles: {
                                                ...data.styles,
                                                overflow: "auto",
                                                maxHeight: `${this.props.maxHeight}px`,
                                            },
                                        };
                                    },
                                },
                            }}>
                            {this.state.seconds.map((item, index) => {
                                return (
                                    <DropdownItem
                                        key={`TimePicker-seconds-${index}`}
                                        onClick={() => {
                                            this.setState({ result: { ...this.state.result, s: item } });
                                            this.props.onChange({ ...this.state.result, s: item });
                                        }}>
                                        {item < 10 ? `0${item}` : item}
                                    </DropdownItem>
                                );
                            })}
                        </DropdownMenu>
                    </Dropdown>
                )}
            </div>
        );
    }
}

TimePicker.defaultProps = {
    showHour: true,
    showMinute: true,
    showSecond: false,
    maxHeight: 100,
    minSeparate: 15,
    value: null,
};

export class RegionSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openRegionSelector: false,
            regionList: null,
            selectRegion: null,
        };
    }
    componentDidMount() {
        if (this.props.regionInfo !== null && this.props.regionInfo.length !== 0) {
            this.setState({ regionList: this.props.regionInfo });
            if (this.props.value !== undefined && this.props.value !== null) {
                this.setState({ selectRegion: this.props.regionInfo[lodash.findIndex(this.props.regionInfo, { standard_map_code: this.props.value })] });
            }
        }
    }
    componentWillUnmount() {}
    componentDidUpdate(prevProps) {
        if (prevProps.regionInfo !== this.props.regionInfo) {
            this.setState({ regionList: this.props.regionInfo });
        }
        if (prevProps.value !== this.props.value) {
            this.setState({ selectRegion: this.props.regionInfo[lodash.findIndex(this.props.regionInfo, { standard_map_code: this.props.value })] });
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.openRegionSelector !== nextState.openRegionSelector) {
            return true;
        }
        if (this.state.selectRegion !== nextState.selectRegion) {
            return true;
        }
        if (this.state.regionList !== nextState.regionList) {
            return true;
        }

        return false;
    }

    render() {
        if (this.state.regionList !== null) {
            return (
                <div style={{ ...this.props.style }}>
                    <Dropdown
                        style={{ minWidth: "100%" }}
                        isOpen={this.props.viewMode !== "view" && this.state.openRegionSelector}
                        toggle={() => this.setState({ openRegionSelector: !this.state.openRegionSelector })}
                        size="lg">
                        <DropdownToggle caret style={{ background: "white" }} disabled={this.props.disabled} className="col-12">
                            {this.state.selectRegion === null ? this.state.regionList[0].standard_region_name : this.state.selectRegion.standard_region_name}
                        </DropdownToggle>
                        <DropdownMenu
                            className="col-12"
                            modifiers={{
                                setMaxHeight: {
                                    enabled: true,
                                    fn: (data) => {
                                        return {
                                            ...data,
                                            styles: {
                                                ...data.styles,
                                                overflow: "auto",
                                                maxHeight: `${this.props.maxHeight}px`,
                                            },
                                        };
                                    },
                                },
                            }}>
                            {this.state.regionList.map((item, index) => {
                                return (
                                    <DropdownItem
                                        className="col-12"
                                        key={`RegionSelector-standard_region_name-${index}`}
                                        onClick={() => {
                                            this.setState({ selectRegion: item });
                                            if (this.props.onChange !== undefined && this.props.onChange !== null) {
                                                this.props.onChange(item);
                                            }
                                        }}>
                                        {item.standard_region_name}
                                    </DropdownItem>
                                );
                            })}
                        </DropdownMenu>
                    </Dropdown>
                </div>
            );
        } else {
            return <div />;
        }
    }
}

RegionSelector.defaultProps = {
    maxHeight: 200,
    disabled: false,
};

export class StoreTimeSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hourList: null,
            hourType: "every",
            hourTypeList: [
                { name: "매일", value: "every" },
                { name: "평일/주말", value: "weekend" },
                { name: "평일/주말/공휴일", value: "holiday" },
                { name: "직접설정", value: "custom" },
            ],
            isInitDone: false,
            selectDateList: [],
            originHourType: null,
            originTimeInfo: null,
            timeInfo: {
                hourMon: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourMon", order: 1, name: "월", fullname: "월요일", selected: false },
                hourTue: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourTue", order: 2, name: "화", fullname: "화요일", selected: false },
                hourWed: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourWed", order: 3, name: "수", fullname: "수요일", selected: false },
                hourThu: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourThu", order: 4, name: "목", fullname: "목요일", selected: false },
                hourFri: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourFri", order: 5, name: "금", fullname: "금요일", selected: false },
                hourSat: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourSat", order: 6, name: "토", fullname: "토요일", selected: false },
                hourSun: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourSun", order: 7, name: "일", fullname: "일요일", selected: false },
                hourHoliday: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourHoliday", order: 8, name: "공휴일", fullname: "공휴일", selected: false },
            },
            defaultTimeInfo: {
                hourMon: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourMon", order: 1, name: "월", fullname: "월요일", selected: false },
                hourTue: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourTue", order: 2, name: "화", fullname: "화요일", selected: false },
                hourWed: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourWed", order: 3, name: "수", fullname: "수요일", selected: false },
                hourThu: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourThu", order: 4, name: "목", fullname: "목요일", selected: false },
                hourFri: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourFri", order: 5, name: "금", fullname: "금요일", selected: false },
                hourSat: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourSat", order: 6, name: "토", fullname: "토요일", selected: false },
                hourSun: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourSun", order: 7, name: "일", fullname: "일요일", selected: false },
                hourHoliday: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourHoliday", order: 8, name: "공휴일", fullname: "공휴일", selected: false },
            },
            accommodationTimeInfo: {
                checkIn: { h: 0, m: 0 },
                checkOut: { h: 0, m: 0 },
            },
        };
    }

    doInitTime() {
        if (this.props.viewMode !== "add") {
            if (this.props.storeType !== undefined && this.props.storeType !== null) {
                if (this.props.storeType === "accommodation") {
                    this.setState({
                        hourType: this.props.storeHourType !== null ? this.props.storeHoursType : "every",
                        accommodationTimeInfo: {
                            checkIn: { h: Number(this.props.value.checkIn.split(":")[0]), m: Number(this.props.value.checkIn.split(":")[1]) },
                            checkOut: { h: Number(this.props.value.checkOut.split(":")[0]), m: Number(this.props.value.checkOut.split(":")[1]) },
                        },
                        isInitDone: true,
                    });
                } else {
                    let timeData = {
                        hourMon:
                            this.props.value.hourMon === "off"
                                ? { ...this.state.defaultTimeInfo["hourMon"], isFreeDay: true, selected: false }
                                : {
                                      ...this.state.defaultTimeInfo["hourMon"],
                                      open: { h: Number(this.props.value.hourMon.open.split(":")[0]), m: Number(this.props.value.hourMon.open.split(":")[1]) },
                                      close: { h: Number(this.props.value.hourMon.close.split(":")[0]), m: Number(this.props.value.hourMon.close.split(":")[1]) },
                                      isFreeDay: false,
                                      selected: true,
                                  },
                        hourTue:
                            this.props.value.hourTue === "off"
                                ? { ...this.state.defaultTimeInfo["hourTue"], isFreeDay: true, selected: false }
                                : {
                                      ...this.state.defaultTimeInfo["hourTue"],
                                      open: { h: Number(this.props.value.hourTue.open.split(":")[0]), m: Number(this.props.value.hourTue.open.split(":")[1]) },
                                      close: { h: Number(this.props.value.hourTue.close.split(":")[0]), m: Number(this.props.value.hourTue.close.split(":")[1]) },
                                      isFreeDay: false,
                                      selected: true,
                                  },
                        hourWed:
                            this.props.value.hourWed === "off"
                                ? { ...this.state.defaultTimeInfo["hourWed"], isFreeDay: true, selected: false }
                                : {
                                      ...this.state.defaultTimeInfo["hourWed"],
                                      open: { h: Number(this.props.value.hourWed.open.split(":")[0]), m: Number(this.props.value.hourWed.open.split(":")[1]) },
                                      close: { h: Number(this.props.value.hourWed.close.split(":")[0]), m: Number(this.props.value.hourWed.close.split(":")[1]) },
                                      isFreeDay: false,
                                      selected: true,
                                  },
                        hourThu:
                            this.props.value.hourThu === "off"
                                ? { ...this.state.defaultTimeInfo["hourThu"], isFreeDay: true, selected: false }
                                : {
                                      ...this.state.defaultTimeInfo["hourThu"],
                                      open: { h: Number(this.props.value.hourThu.open.split(":")[0]), m: Number(this.props.value.hourThu.open.split(":")[1]) },
                                      close: { h: Number(this.props.value.hourThu.close.split(":")[0]), m: Number(this.props.value.hourThu.close.split(":")[1]) },
                                      isFreeDay: false,
                                      selected: true,
                                  },
                        hourFri:
                            this.props.value.hourFri === "off"
                                ? { ...this.state.defaultTimeInfo["hourFri"], isFreeDay: true, selected: false }
                                : {
                                      ...this.state.defaultTimeInfo["hourFri"],
                                      open: { h: Number(this.props.value.hourFri.open.split(":")[0]), m: Number(this.props.value.hourFri.open.split(":")[1]) },
                                      close: { h: Number(this.props.value.hourFri.close.split(":")[0]), m: Number(this.props.value.hourFri.close.split(":")[1]) },
                                      isFreeDay: false,
                                      selected: true,
                                  },
                        hourSat:
                            this.props.value.hourSat === "off"
                                ? { ...this.state.defaultTimeInfo["hourSat"], isFreeDay: true, selected: false }
                                : {
                                      ...this.state.defaultTimeInfo["hourSat"],
                                      open: { h: Number(this.props.value.hourSat.open.split(":")[0]), m: Number(this.props.value.hourSat.open.split(":")[1]) },
                                      close: { h: Number(this.props.value.hourSat.close.split(":")[0]), m: Number(this.props.value.hourSat.close.split(":")[1]) },
                                      isFreeDay: false,
                                      selected: true,
                                  },
                        hourSun:
                            this.props.value.hourSun === "off"
                                ? { ...this.state.defaultTimeInfo["hourSun"], isFreeDay: true, selected: false }
                                : {
                                      ...this.state.defaultTimeInfo["hourSun"],
                                      open: { h: Number(this.props.value.hourSun.open.split(":")[0]), m: Number(this.props.value.hourSun.open.split(":")[1]) },
                                      close: { h: Number(this.props.value.hourSun.close.split(":")[0]), m: Number(this.props.value.hourSun.close.split(":")[1]) },
                                      isFreeDay: false,
                                      selected: true,
                                  },
                        hourHoliday:
                            this.props.value.hourHoliday === "off"
                                ? { ...this.state.defaultTimeInfo["hourHoliday"], isFreeDay: true, selected: false }
                                : {
                                      ...this.state.defaultTimeInfo["hourHoliday"],
                                      open: { h: Number(this.props.value.hourHoliday.open.split(":")[0]), m: Number(this.props.value.hourHoliday.open.split(":")[1]) },
                                      close: { h: Number(this.props.value.hourHoliday.close.split(":")[0]), m: Number(this.props.value.hourHoliday.close.split(":")[1]) },
                                      isFreeDay: false,
                                      selected: true,
                                  },
                    };
                    this.setState({
                        originHourType: this.props.storeHourType !== null ? this.props.storeHoursType : "every",
                        hourType: this.props.storeHourType !== null ? this.props.storeHoursType : "every",
                        originTimeInfo: timeData,
                        timeInfo: timeData,
                    });
                    this.setState({ isInitDone: true });
                }
            }
        } else {
            this.setState({ isInitDone: true });
        }
    }

    componentDidMount() {
        this.doInitTime();
    }
    componentWillUnmount() {}
    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.doInitTime();
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.show !== nextProps.show) {
            return true;
        }
        if (nextProps.storeType !== "accommodation" && !lodash.isEqual(this.state.timeInfo, nextState.timeInfo)) {
            if (this.props.onChangeHour !== undefined && this.props.onChangeHour !== null) {
                this.props.onChangeHour(lodash.orderBy(nextState.timeInfo, "order", "asc"));
            }
            return true;
        }
        if (nextProps.storeType === "accommodation" && !lodash.isEqual(this.state.accommodationTimeInfo, nextState.accommodationTimeInfo)) {
            if (this.props.onChangeHour !== undefined && this.props.onChangeHour !== null) {
                this.props.onChangeHour(nextState.accommodationTimeInfo);
            }
            return true;
        }
        if (this.state.hourType !== nextState.hourType) {
            if (this.props.viewMode === "add") {
                if (nextState.hourType === "every") {
                    this.setState({
                        timeInfo: {
                            hourMon: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourMon", order: 1, name: "월", fullname: "월요일", selected: true },
                            hourTue: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourTue", order: 2, name: "화", fullname: "화요일", selected: true },
                            hourWed: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourWed", order: 3, name: "수", fullname: "수요일", selected: true },
                            hourThu: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourThu", order: 4, name: "목", fullname: "목요일", selected: true },
                            hourFri: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourFri", order: 5, name: "금", fullname: "금요일", selected: true },
                            hourSat: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourSat", order: 6, name: "토", fullname: "토요일", selected: true },
                            hourSun: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourSun", order: 7, name: "일", fullname: "일요일", selected: true },
                            hourHoliday: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourHoliday", order: 8, name: "공휴일", fullname: "공휴일", selected: true },
                        },
                    });
                } else if (nextState.hourType === "weekend") {
                    this.setState({
                        timeInfo: {
                            hourMon: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourMon", order: 1, name: "월", fullname: "월요일", selected: true },
                            hourTue: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourTue", order: 2, name: "화", fullname: "화요일", selected: true },
                            hourWed: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourWed", order: 3, name: "수", fullname: "수요일", selected: true },
                            hourThu: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourThu", order: 4, name: "목", fullname: "목요일", selected: true },
                            hourFri: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourFri", order: 5, name: "금", fullname: "금요일", selected: true },
                            hourSat: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourSat", order: 6, name: "토", fullname: "토요일", selected: true },
                            hourSun: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourSun", order: 7, name: "일", fullname: "일요일", selected: true },
                            hourHoliday: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: true, key: "hourHoliday", order: 8, name: "공휴일", fullname: "공휴일", selected: false },
                        },
                    });
                } else if (nextState.hourType === "holiday") {
                    this.setState({
                        timeInfo: {
                            hourMon: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourMon", order: 1, name: "월", fullname: "월요일", selected: true },
                            hourTue: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourTue", order: 2, name: "화", fullname: "화요일", selected: true },
                            hourWed: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourWed", order: 3, name: "수", fullname: "수요일", selected: true },
                            hourThu: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourThu", order: 4, name: "목", fullname: "목요일", selected: true },
                            hourFri: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourFri", order: 5, name: "금", fullname: "금요일", selected: true },
                            hourSat: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourSat", order: 6, name: "토", fullname: "토요일", selected: true },
                            hourSun: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourSun", order: 7, name: "일", fullname: "일요일", selected: true },
                            hourHoliday: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, key: "hourHoliday", order: 8, name: "공휴일", fullname: "공휴일", selected: true },
                        },
                    });
                } else if (nextState.hourType === "custom") {
                    this.setState({
                        timeInfo: {
                            hourMon: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: true, key: "hourMon", order: 1, name: "월", fullname: "월요일", selected: false },
                            hourTue: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: true, key: "hourTue", order: 2, name: "화", fullname: "화요일", selected: false },
                            hourWed: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: true, key: "hourWed", order: 3, name: "수", fullname: "수요일", selected: false },
                            hourThu: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: true, key: "hourThu", order: 4, name: "목", fullname: "목요일", selected: false },
                            hourFri: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: true, key: "hourFri", order: 5, name: "금", fullname: "금요일", selected: false },
                            hourSat: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: true, key: "hourSat", order: 6, name: "토", fullname: "토요일", selected: false },
                            hourSun: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: true, key: "hourSun", order: 7, name: "일", fullname: "일요일", selected: false },
                            hourHoliday: { open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: true, key: "hourHoliday", order: 8, name: "공휴일", fullname: "공휴일", selected: false },
                        },
                    });
                } else {
                    this.setState({
                        timeInfo: this.state.defaultTimeInfo,
                    });
                }
            } else {
                // 추가모드가 아닐 경우
                // 매일 -> 평일/주말
                // 매일 -> 평일/주말/공휴일
                // 매일 -> 직접설정
                // 평일/주말

                if (this.state.originHourType === "weekend") {
                    // 평일/주말 -> 매일
                    if (nextState.hourType === "every") {
                        this.setState({
                            timeInfo: {
                                hourMon: {
                                    ...this.state.defaultTimeInfo.hourMon,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourTue: {
                                    ...this.state.defaultTimeInfo.hourTue,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourWed: {
                                    ...this.state.defaultTimeInfo.hourWed,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourThu: {
                                    ...this.state.defaultTimeInfo.hourThu,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourFri: {
                                    ...this.state.defaultTimeInfo.hourFri,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourSat: {
                                    ...this.state.defaultTimeInfo.hourSat,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourSun: {
                                    ...this.state.defaultTimeInfo.hourSun,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourHoliday: {
                                    ...this.state.defaultTimeInfo.hourHoliday,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                            },
                        });
                    }
                    // 평일/주말 -> 평일/주말
                    if (nextState.hourType === "weekend") {
                        this.setState({
                            timeInfo: {
                                hourMon: {
                                    ...this.state.defaultTimeInfo.hourMon,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourTue: {
                                    ...this.state.defaultTimeInfo.hourTue,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourWed: {
                                    ...this.state.defaultTimeInfo.hourWed,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourThu: {
                                    ...this.state.defaultTimeInfo.hourThu,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourFri: {
                                    ...this.state.defaultTimeInfo.hourFri,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourSat: {
                                    ...this.state.defaultTimeInfo.hourSat,
                                    open: { ...this.state.originTimeInfo.hourSun.open },
                                    close: { ...this.state.originTimeInfo.hourSun.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourSun: {
                                    ...this.state.defaultTimeInfo.hourSun,
                                    open: { ...this.state.originTimeInfo.hourSun.open },
                                    close: { ...this.state.originTimeInfo.hourSun.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourHoliday: { ...this.state.defaultTimeInfo.hourHoliday, open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: true, selected: false },
                            },
                        });
                    }

                    // 평일/주말 -> 평일/주말/공휴일
                    if (nextState.hourType === "holiday") {
                        this.setState({
                            timeInfo: {
                                hourMon: {
                                    ...this.state.defaultTimeInfo.hourMon,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourTue: {
                                    ...this.state.defaultTimeInfo.hourTue,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourWed: {
                                    ...this.state.defaultTimeInfo.hourWed,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourThu: {
                                    ...this.state.defaultTimeInfo.hourThu,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourFri: {
                                    ...this.state.defaultTimeInfo.hourFri,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourSat: {
                                    ...this.state.defaultTimeInfo.hourSat,
                                    open: { ...this.state.originTimeInfo.hourSun.open },
                                    close: { ...this.state.originTimeInfo.hourSun.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourSun: {
                                    ...this.state.defaultTimeInfo.hourSun,
                                    open: { ...this.state.originTimeInfo.hourSun.open },
                                    close: { ...this.state.originTimeInfo.hourSun.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourHoliday: { ...this.state.defaultTimeInfo.hourHoliday, open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, selected: true },
                            },
                        });
                    }
                    // 평일/주말 -> 직접설정
                    if (nextState.hourType === "custom") {
                        this.setState({
                            timeInfo: {
                                hourMon: {
                                    ...this.state.defaultTimeInfo.hourMon,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourTue: {
                                    ...this.state.defaultTimeInfo.hourTue,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourWed: {
                                    ...this.state.defaultTimeInfo.hourWed,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourThu: {
                                    ...this.state.defaultTimeInfo.hourThu,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourFri: {
                                    ...this.state.defaultTimeInfo.hourFri,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourSat: {
                                    ...this.state.defaultTimeInfo.hourSat,
                                    open: { ...this.state.originTimeInfo.hourSun.open },
                                    close: { ...this.state.originTimeInfo.hourSun.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourSun: {
                                    ...this.state.defaultTimeInfo.hourSun,
                                    open: { ...this.state.originTimeInfo.hourSun.open },
                                    close: { ...this.state.originTimeInfo.hourSun.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourHoliday: { ...this.state.defaultTimeInfo.hourHoliday, open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: false, selected: true },
                            },
                        });
                    }
                }

                // 평일/주말/공휴일
                if (this.state.originHourType === "holiday") {
                    // 평일/주말/공휴일 -> 매일
                    if (nextState.hourType === "every") {
                        this.setState({
                            timeInfo: {
                                hourMon: {
                                    ...this.state.defaultTimeInfo.hourMon,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourTue: {
                                    ...this.state.defaultTimeInfo.hourTue,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourWed: {
                                    ...this.state.defaultTimeInfo.hourWed,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourThu: {
                                    ...this.state.defaultTimeInfo.hourThu,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourFri: {
                                    ...this.state.defaultTimeInfo.hourFri,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourSat: {
                                    ...this.state.defaultTimeInfo.hourSat,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourSun: {
                                    ...this.state.defaultTimeInfo.hourSun,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourHoliday: {
                                    ...this.state.defaultTimeInfo.hourHoliday,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                            },
                        });
                    }
                    // 평일/주말/공휴일 -> 평일/주말
                    if (nextState.hourType === "weekend") {
                        this.setState({
                            timeInfo: {
                                hourMon: {
                                    ...this.state.defaultTimeInfo.hourMon,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourTue: {
                                    ...this.state.defaultTimeInfo.hourTue,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourWed: {
                                    ...this.state.defaultTimeInfo.hourWed,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourThu: {
                                    ...this.state.defaultTimeInfo.hourThu,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourFri: {
                                    ...this.state.defaultTimeInfo.hourFri,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourSat: {
                                    ...this.state.defaultTimeInfo.hourSat,
                                    open: { ...this.state.originTimeInfo.hourSun.open },
                                    close: { ...this.state.originTimeInfo.hourSun.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourSun: {
                                    ...this.state.defaultTimeInfo.hourSun,
                                    open: { ...this.state.originTimeInfo.hourSun.open },
                                    close: { ...this.state.originTimeInfo.hourSun.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourHoliday: { ...this.state.defaultTimeInfo.hourHoliday, open: { h: 0, m: 0 }, close: { h: 0, m: 0 }, isFreeDay: true, selected: false },
                            },
                        });
                    }
                    // 평일/주말/공휴일 -> 평일/주말
                    if (nextState.hourType === "holiday") {
                        this.setState({
                            timeInfo: {
                                hourMon: {
                                    ...this.state.defaultTimeInfo.hourMon,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourTue: {
                                    ...this.state.defaultTimeInfo.hourTue,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourWed: {
                                    ...this.state.defaultTimeInfo.hourWed,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourThu: {
                                    ...this.state.defaultTimeInfo.hourThu,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourFri: {
                                    ...this.state.defaultTimeInfo.hourFri,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourSat: {
                                    ...this.state.defaultTimeInfo.hourSat,
                                    open: { ...this.state.originTimeInfo.hourSun.open },
                                    close: { ...this.state.originTimeInfo.hourSun.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourSun: {
                                    ...this.state.defaultTimeInfo.hourSun,
                                    open: { ...this.state.originTimeInfo.hourSun.open },
                                    close: { ...this.state.originTimeInfo.hourSun.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourHoliday: {
                                    ...this.state.defaultTimeInfo.hourHoliday,
                                    open: { ...this.state.originTimeInfo.hourHoliday.open },
                                    close: { ...this.state.originTimeInfo.hourHoliday.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                            },
                        });
                    }
                    // 평일/주말/공휴일 -> 직접설정
                    if (nextState.hourType === "custom") {
                        this.setState({
                            timeInfo: {
                                hourMon: {
                                    ...this.state.defaultTimeInfo.hourMon,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourTue: {
                                    ...this.state.defaultTimeInfo.hourTue,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourWed: {
                                    ...this.state.defaultTimeInfo.hourWed,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourThu: {
                                    ...this.state.defaultTimeInfo.hourThu,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourFri: {
                                    ...this.state.defaultTimeInfo.hourFri,
                                    open: { ...this.state.originTimeInfo.hourMon.open },
                                    close: { ...this.state.originTimeInfo.hourMon.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourSat: {
                                    ...this.state.defaultTimeInfo.hourSat,
                                    open: { ...this.state.originTimeInfo.hourSun.open },
                                    close: { ...this.state.originTimeInfo.hourSun.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourSun: {
                                    ...this.state.defaultTimeInfo.hourSun,
                                    open: { ...this.state.originTimeInfo.hourSun.open },
                                    close: { ...this.state.originTimeInfo.hourSun.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                                hourHoliday: {
                                    ...this.state.defaultTimeInfo.hourHoliday,
                                    open: { ...this.state.originTimeInfo.hourHoliday.open },
                                    close: { ...this.state.originTimeInfo.hourHoliday.close },
                                    isFreeDay: false,
                                    selected: true,
                                },
                            },
                        });
                    }
                }

                if (this.state.originHourType === "custom") {
                    if (nextState.hourType === "custom") {
                        this.setState({
                            timeInfo: this.state.originTimeInfo,
                        });
                    } else {
                        this.setState({
                            timeInfo: this.state.defaultTimeInfo,
                        });
                    }
                }
            }

            if (this.props.onChangeHourType !== undefined && this.props.onChangeHourType !== null) {
                this.props.onChangeHourType(nextState.hourType);
            }
            return true;
        }

        if (this.state.selectDateList !== nextState.selectDateList) {
            return true;
        }
        if (this.props.storeType !== nextProps.storeType) {
            return true;
        }

        return false;
    }

    handleTimeSelect(hourType, timeStatus = "open", timeType, value, day = null) {
        if (this.props.storeType === null || this.props.storeType === "accommodation") {
            this.setState({
                accommodationTimeInfo: {
                    ...this.state.accommodationTimeInfo,
                    [timeStatus]: { ...value },
                },
            });
        } else {
            if (timeStatus === "freeday") {
                this.setState({
                    timeInfo: {
                        ...this.state.timeInfo,
                        [day]: { ...this.state.defaultTimeInfo[day], isFreeDay: value, selected: !value },
                    },
                });
            } else {
                if (hourType === "every") {
                    // 시간
                    this.setState({
                        timeInfo: {
                            hourMon:
                                this.state.timeInfo.hourMon === "off"
                                    ? { ...this.state.defaultTimeInfo.hourMon, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                    : { ...this.state.timeInfo.hourMon, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                            hourTue:
                                this.state.timeInfo.hourTue === "off"
                                    ? { ...this.state.defaultTimeInfo.hourTue, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                    : { ...this.state.timeInfo.hourTue, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                            hourWed:
                                this.state.timeInfo.hourWed === "off"
                                    ? { ...this.state.defaultTimeInfo.hourWed, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                    : { ...this.state.timeInfo.hourWed, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                            hourThu:
                                this.state.timeInfo.hourThu === "off"
                                    ? { ...this.state.defaultTimeInfo.hourThu, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                    : { ...this.state.timeInfo.hourThu, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                            hourFri:
                                this.state.timeInfo.hourFri === "off"
                                    ? { ...this.state.defaultTimeInfo.hourFri, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                    : { ...this.state.timeInfo.hourFri, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                            hourSat:
                                this.state.timeInfo.hourSat === "off"
                                    ? { ...this.state.defaultTimeInfo.hourSat, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                    : { ...this.state.timeInfo.hourSat, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                            hourSun:
                                this.state.timeInfo.hourSun === "off"
                                    ? { ...this.state.defaultTimeInfo.hourSun, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                    : { ...this.state.timeInfo.hourSun, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                            hourHoliday:
                                this.state.timeInfo.hourHoliday === "off"
                                    ? { ...this.state.defaultTimeInfo.hourHoliday, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                    : { ...this.state.timeInfo.hourHoliday, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                        },
                    });
                } else if (hourType === "weekend") {
                    if (timeType === "daily") {
                        // 평일
                        this.setState({
                            timeInfo: {
                                ...this.state.timeInfo,
                                hourMon:
                                    this.state.timeInfo.hourMon === "off"
                                        ? { ...this.state.defaultTimeInfo.hourMon, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                        : { ...this.state.timeInfo.hourMon, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                                hourTue:
                                    this.state.timeInfo.hourTue === "off"
                                        ? { ...this.state.defaultTimeInfo.hourTue, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                        : { ...this.state.timeInfo.hourTue, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                                hourWed:
                                    this.state.timeInfo.hourWed === "off"
                                        ? { ...this.state.defaultTimeInfo.hourWed, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                        : { ...this.state.timeInfo.hourWed, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                                hourThu:
                                    this.state.timeInfo.hourThu === "off"
                                        ? { ...this.state.defaultTimeInfo.hourThu, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                        : { ...this.state.timeInfo.hourThu, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                                hourFri:
                                    this.state.timeInfo.hourFri === "off"
                                        ? { ...this.state.defaultTimeInfo.hourFri, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                        : { ...this.state.timeInfo.hourFri, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                            },
                        });
                    } else if (timeType === "weekend") {
                        this.setState({
                            timeInfo: {
                                ...this.state.timeInfo,
                                hourSat:
                                    this.state.timeInfo.hourSat === "off"
                                        ? { ...this.state.defaultTimeInfo.hourSat, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                        : { ...this.state.timeInfo.hourSat, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                                hourSun:
                                    this.state.timeInfo.hourSun === "off"
                                        ? { ...this.state.defaultTimeInfo.hourSun, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                        : { ...this.state.timeInfo.hourSun, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                            },
                        });
                    }
                } else if (hourType === "holiday") {
                    // 시작
                    if (timeType === "daily") {
                        // 평일
                        this.setState({
                            timeInfo: {
                                ...this.state.timeInfo,
                                hourMon:
                                    this.state.timeInfo.hourMon === "off"
                                        ? { ...this.state.defaultTimeInfo.hourMon, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                        : { ...this.state.timeInfo.hourMon, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                                hourTue:
                                    this.state.timeInfo.hourTue === "off"
                                        ? { ...this.state.defaultTimeInfo.hourTue, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                        : { ...this.state.timeInfo.hourTue, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                                hourWed:
                                    this.state.timeInfo.hourWed === "off"
                                        ? { ...this.state.defaultTimeInfo.hourWed, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                        : { ...this.state.timeInfo.hourWed, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                                hourThu:
                                    this.state.timeInfo.hourThu === "off"
                                        ? { ...this.state.defaultTimeInfo.hourThu, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                        : { ...this.state.timeInfo.hourThu, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                                hourFri:
                                    this.state.timeInfo.hourFri === "off"
                                        ? { ...this.state.defaultTimeInfo.hourFri, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                        : { ...this.state.timeInfo.hourFri, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                            },
                        });
                    } else if (timeType === "weekend") {
                        if (day === "hourSat") {
                            this.setState({
                                timeInfo: {
                                    ...this.state.timeInfo,
                                    hourSat:
                                        this.state.timeInfo.hourSat === "off"
                                            ? { ...this.state.defaultTimeInfo.hourSat, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                            : { ...this.state.timeInfo.hourSat, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                                },
                            });
                        } else if (day === "hourSun") {
                            this.setState({
                                timeInfo: {
                                    ...this.state.timeInfo,
                                    hourSun:
                                        this.state.timeInfo.hourSun === "off"
                                            ? { ...this.state.defaultTimeInfo.hourSun, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                            : { ...this.state.timeInfo.hourSun, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                                },
                            });
                        }
                    } else if (timeType === "holiday") {
                        this.setState({
                            timeInfo: {
                                ...this.state.timeInfo,
                                hourHoliday:
                                    this.state.timeInfo.hourHoliday === "off"
                                        ? { ...this.state.defaultTimeInfo.hourHoliday, [timeStatus]: { ...value }, isFreeDay: false, selected: true }
                                        : { ...this.state.timeInfo.hourHoliday, [timeStatus]: { ...value }, isFreeDay: false, selected: true },
                            },
                        });
                    }
                } else if (hourType === "custom") {
                    this.setState({
                        timeInfo: {
                            ...this.state.timeInfo,
                            [day]:
                                this.state.timeInfo[day] === "off"
                                    ? { ...this.state.defaultTimeInfo[day], [timeStatus]: { ...value }, isFreeDay: false }
                                    : { ...this.state.timeInfo[day], [timeStatus]: { ...value } },
                        },
                    });
                }
            }
        }
    }

    doRenderTimeSelector() {
        if (this.state.hourType === "every") {
            return (
                <Row className="d-flex rounded m-2">
                    <div className="col-12 col-md-3 justify-content-start align-items-center " style={{}}>
                        <Row className="mt-md-2">매일</Row>
                    </div>
                    <div className={"d-flex d-md-none mt-2"} style={{ minWidth: "100%", minHeight: 1 }}></div>
                    <div className="col-12 col-md-9  justify-content-start align-items-center " style={{}}>
                        <Row className="m-sm-2">
                            <TimePicker
                                key={"every_open_time"}
                                viewMode={this.props.viewMode}
                                value={this.state.timeInfo.hourMon.open}
                                onChange={(v) => {
                                    this.handleTimeSelect(this.state.hourType, "open", null, v);
                                }}
                            />
                            <div className=" d-flex justify-content-start align-items-center " style={{}}>
                                부터
                            </div>
                        </Row>
                        <div className={"d-flex d-md-none mt-3"} style={{ minWidth: "100%", minHeight: 1 }}></div>
                        <Row className="m-sm-2">
                            <TimePicker
                                key={"every_close_time"}
                                viewMode={this.props.viewMode}
                                value={this.state.timeInfo.hourMon.close}
                                onChange={(v) => {
                                    this.handleTimeSelect(this.state.hourType, "close", null, v);
                                }}
                            />
                            <div className=" d-flex justify-content-start align-items-center " style={{}}>
                                까지
                            </div>
                        </Row>
                    </div>
                </Row>
            );
        } else if (this.state.hourType === "weekend") {
            return (
                <Fragment>
                    <Row className="d-flex rounded m-2">
                        <div className="col-12 col-md-3 justify-content-start align-items-center " style={{}}>
                            <Row className="mt-md-2">평일</Row>
                        </div>
                        <div className={"d-flex d-md-none mt-2"} style={{ minWidth: "100%", minHeight: 1 }}></div>
                        <div className="col-12 col-md-9  justify-content-start align-items-center " style={{}}>
                            <Row className="m-sm-2">
                                <TimePicker
                                    key={"weekend_open_time_daily"}
                                    viewMode={this.props.viewMode}
                                    value={this.state.timeInfo.hourMon.open}
                                    onChange={(v) => {
                                        this.handleTimeSelect(this.state.hourType, "open", "daily", v);
                                    }}
                                />
                                <div className=" d-flex justify-content-start align-items-center " style={{}}>
                                    부터
                                </div>
                            </Row>
                            <div className={"d-flex d-md-none mt-3"} style={{ minWidth: "100%", minHeight: 1 }}></div>
                            <Row className="m-sm-2">
                                <TimePicker
                                    key={"weekend_close_time_daily"}
                                    viewMode={this.props.viewMode}
                                    value={this.state.timeInfo.hourMon.close}
                                    onChange={(v) => {
                                        this.handleTimeSelect(this.state.hourType, "close", "daily", v);
                                    }}
                                />
                                <div className=" d-flex justify-content-start align-items-center " style={{}}>
                                    까지
                                </div>
                            </Row>
                        </div>
                    </Row>

                    <hr />

                    <Row className="d-flex rounded m-2">
                        <div className="col-12 col-md-3 justify-content-start align-items-center " style={{}}>
                            <Row className="mt-md-2">토요일</Row>
                            <Row className="mt-md-2">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.timeInfo.hourSat.isFreeDay}
                                            onChange={(e) => {
                                                this.handleTimeSelect(this.state.hourType, "freeday", null, !this.state.timeInfo.hourSat.isFreeDay, "hourSat");
                                            }}
                                        />
                                    }
                                    label={"휴무"}
                                />
                            </Row>
                        </div>
                        {!this.state.timeInfo.hourSat.isFreeDay && (
                            <Fragment>
                                <div className={"d-flex d-md-none mt-2"} style={{ minWidth: "100%", minHeight: 1 }}></div>
                                <div className="col-12 col-md-9  justify-content-start align-items-center " style={{}}>
                                    <Row className="m-sm-2">
                                        <TimePicker
                                            key={"weekend_open_time_weekend"}
                                            viewMode={this.props.viewMode}
                                            value={this.state.timeInfo.hourSat.open}
                                            onChange={(v) => {
                                                this.handleTimeSelect(this.state.hourType, "open", "weekend", v, "hourSat");
                                            }}
                                        />
                                        <div className=" d-flex justify-content-start align-items-center " style={{}}>
                                            부터
                                        </div>
                                    </Row>
                                    <div className={"d-flex d-md-none mt-3"} style={{ minWidth: "100%", minHeight: 1 }}></div>
                                    <Row className="m-sm-2">
                                        <TimePicker
                                            key={"weekend_close_time_weekend"}
                                            viewMode={this.props.viewMode}
                                            value={this.state.timeInfo.hourSat.close}
                                            onChange={(v) => {
                                                this.handleTimeSelect(this.state.hourType, "close", "weekend", v, "hourSat");
                                            }}
                                        />
                                        <div className=" d-flex justify-content-start align-items-center " style={{}}>
                                            까지
                                        </div>
                                    </Row>
                                </div>
                            </Fragment>
                        )}
                    </Row>
                    <hr />

                    <Row className="d-flex rounded m-2">
                        <div className="col-12 col-md-3 justify-content-start align-items-center " style={{}}>
                            <Row className="mt-md-2">일요일</Row>
                            <Row className="mt-md-2">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.timeInfo.hourSun.isFreeDay}
                                            onChange={(e) => {
                                                this.handleTimeSelect(this.state.hourType, "freeday", null, !this.state.timeInfo.hourSun.isFreeDay, "hourSun");
                                            }}
                                        />
                                    }
                                    label={"휴무"}
                                />
                            </Row>
                        </div>

                        {!this.state.timeInfo.hourSun.isFreeDay && (
                            <Fragment>
                                <div className={"d-flex d-md-none mt-2"} style={{ minWidth: "100%", minHeight: 1 }}></div>
                                <div className="col-12 col-md-9  justify-content-start align-items-center " style={{}}>
                                    <Row className="m-sm-2">
                                        <TimePicker
                                            key={"weekend_open_time_weekend"}
                                            viewMode={this.props.viewMode}
                                            value={this.state.timeInfo.hourSun.open}
                                            onChange={(v) => {
                                                this.handleTimeSelect(this.state.hourType, "open", "weekend", v, "hourSun");
                                            }}
                                        />
                                        <div className=" d-flex justify-content-start align-items-center " style={{}}>
                                            부터
                                        </div>
                                    </Row>
                                    <div className={"d-flex d-md-none mt-3"} style={{ minWidth: "100%", minHeight: 1 }}></div>
                                    <Row className="m-sm-2">
                                        <TimePicker
                                            key={"weekend_close_time_weekend"}
                                            viewMode={this.props.viewMode}
                                            value={this.state.timeInfo.hourSun.close}
                                            onChange={(v) => {
                                                this.handleTimeSelect(this.state.hourType, "close", "weekend", v, "hourSun");
                                            }}
                                        />
                                        <div className=" d-flex justify-content-start align-items-center " style={{}}>
                                            까지
                                        </div>
                                    </Row>
                                </div>
                            </Fragment>
                        )}
                    </Row>
                </Fragment>
            );
        } else if (this.state.hourType === "holiday") {
            return (
                <Fragment>
                    <Row className="d-flex rounded m-2">
                        <div className="col-12 col-md-3 justify-content-start align-items-center " style={{}}>
                            <Row className="mt-md-2">평일</Row>
                        </div>
                        <div className={"d-flex d-md-none mt-2"} style={{ minWidth: "100%", minHeight: 1 }}></div>
                        <div className="col-12 col-md-9  justify-content-start align-items-center " style={{}}>
                            <Row className="m-sm-2">
                                <TimePicker
                                    key={"holiday_open_time_daily"}
                                    viewMode={this.props.viewMode}
                                    value={this.state.timeInfo.hourMon.open}
                                    onChange={(v) => {
                                        this.handleTimeSelect(this.state.hourType, "open", "daily", v);
                                    }}
                                />
                                <div className=" d-flex justify-content-start align-items-center " style={{}}>
                                    부터
                                </div>
                            </Row>
                            <div className={"d-flex d-md-none mt-3"} style={{ minWidth: "100%", minHeight: 1 }}></div>
                            <Row className="m-sm-2">
                                <TimePicker
                                    key={"holiday_close_time_daily"}
                                    viewMode={this.props.viewMode}
                                    value={this.state.timeInfo.hourMon.close}
                                    onChange={(v) => {
                                        this.handleTimeSelect(this.state.hourType, "close", "daily", v);
                                    }}
                                />
                                <div className=" d-flex justify-content-start align-items-center " style={{}}>
                                    까지
                                </div>
                            </Row>
                        </div>
                    </Row>

                    <hr />

                    <Row className="d-flex rounded m-2">
                        <div className="col-12 col-md-3 justify-content-start align-items-center " style={{}}>
                            <Row className="mt-md-2">토요일</Row>
                            <Row className="mt-md-2">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.timeInfo.hourSat.isFreeDay}
                                            onChange={(e) => {
                                                this.handleTimeSelect(this.state.hourType, "freeday", null, !this.state.timeInfo.hourSat.isFreeDay, "hourSat");
                                            }}
                                        />
                                    }
                                    label={"휴무"}
                                />
                            </Row>
                        </div>
                        {!this.state.timeInfo.hourSat.isFreeDay && (
                            <Fragment>
                                <div className={"d-flex d-md-none mt-2"} style={{ minWidth: "100%", minHeight: 1 }}></div>
                                <div className="col-12 col-md-9  justify-content-start align-items-center " style={{}}>
                                    <Row className="m-sm-2">
                                        <TimePicker
                                            key={"holiday_open_time_weekend"}
                                            viewMode={this.props.viewMode}
                                            value={this.state.timeInfo.hourSat.open}
                                            onChange={(v) => {
                                                this.handleTimeSelect(this.state.hourType, "open", "weekend", v, "hourSat");
                                            }}
                                        />
                                        <div className=" d-flex justify-content-start align-items-center " style={{}}>
                                            부터
                                        </div>
                                    </Row>
                                    <div className={"d-flex d-md-none mt-3"} style={{ minWidth: "100%", minHeight: 1 }}></div>
                                    <Row className="m-sm-2">
                                        <TimePicker
                                            key={"holiday_close_time_weekend"}
                                            viewMode={this.props.viewMode}
                                            value={this.state.timeInfo.hourSat.close}
                                            onChange={(v) => {
                                                this.handleTimeSelect(this.state.hourType, "close", "weekend", v, "hourSat");
                                            }}
                                        />
                                        <div className=" d-flex justify-content-start align-items-center " style={{}}>
                                            까지
                                        </div>
                                    </Row>
                                </div>
                            </Fragment>
                        )}
                    </Row>
                    <hr />

                    <Row className="d-flex rounded m-2">
                        <div className="col-12 col-md-3 justify-content-start align-items-center " style={{}}>
                            <Row className="mt-md-2">일요일</Row>
                            <Row className="mt-md-2">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.timeInfo.hourSun.isFreeDay}
                                            onChange={(e) => {
                                                this.handleTimeSelect(this.state.hourType, "freeday", null, !this.state.timeInfo.hourSun.isFreeDay, "hourSun");
                                            }}
                                        />
                                    }
                                    label={"휴무"}
                                />
                            </Row>
                        </div>
                        {!this.state.timeInfo.hourSun.isFreeDay && (
                            <Fragment>
                                <div className={"d-flex d-md-none mt-2"} style={{ minWidth: "100%", minHeight: 1 }}></div>
                                <div className="col-12 col-md-9  justify-content-start align-items-center " style={{}}>
                                    <Row className="m-sm-2">
                                        <TimePicker
                                            key={"holiday_open_time_weekend"}
                                            viewMode={this.props.viewMode}
                                            value={this.state.timeInfo.hourSun.open}
                                            onChange={(v) => {
                                                this.handleTimeSelect(this.state.hourType, "open", "weekend", v, "hourSun");
                                            }}
                                        />
                                        <div className=" d-flex justify-content-start align-items-center " style={{}}>
                                            부터
                                        </div>
                                    </Row>
                                    <div className={"d-flex d-md-none mt-3"} style={{ minWidth: "100%", minHeight: 1 }}></div>
                                    <Row className="m-sm-2">
                                        <TimePicker
                                            key={"holiday_close_time_weekend"}
                                            viewMode={this.props.viewMode}
                                            value={this.state.timeInfo.hourSun.close}
                                            onChange={(v) => {
                                                this.handleTimeSelect(this.state.hourType, "close", "weekend", v, "hourSun");
                                            }}
                                        />
                                        <div className=" d-flex justify-content-start align-items-center " style={{}}>
                                            까지
                                        </div>
                                    </Row>
                                </div>
                            </Fragment>
                        )}
                    </Row>

                    <hr />

                    <Row className="d-flex rounded m-2">
                        <div className="col-12 col-md-3 justify-content-start align-items-center " style={{}}>
                            <Row className="mt-md-2">공휴일</Row>
                            <Row className="mt-md-2">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.timeInfo.hourHoliday.isFreeDay}
                                            onChange={(e) => {
                                                this.handleTimeSelect(this.state.hourType, "freeday", null, !this.state.timeInfo.hourHoliday.isFreeDay, "hourHoliday");
                                            }}
                                        />
                                    }
                                    label={"휴무"}
                                />
                            </Row>
                        </div>
                        {!this.state.timeInfo.hourHoliday.isFreeDay && (
                            <Fragment>
                                <div className={"d-flex d-md-none mt-2"} style={{ minWidth: "100%", minHeight: 1 }}></div>
                                <div className="col-12 col-md-9  justify-content-start align-items-center " style={{}}>
                                    <Row className="m-sm-2">
                                        <TimePicker
                                            key={"holiday_open_time_holiday"}
                                            viewMode={this.props.viewMode}
                                            value={this.state.timeInfo.hourHoliday.open}
                                            onChange={(v) => {
                                                this.handleTimeSelect(this.state.hourType, "open", "holiday", v);
                                            }}
                                        />
                                        <div className=" d-flex justify-content-start align-items-center " style={{}}>
                                            부터
                                        </div>
                                    </Row>
                                    <div className={"d-flex d-md-none mt-3"} style={{ minWidth: "100%", minHeight: 1 }}></div>
                                    <Row className="m-sm-2">
                                        <TimePicker
                                            key={"holiday_close_time_holiday"}
                                            viewMode={this.props.viewMode}
                                            value={this.state.timeInfo.hourHoliday.close}
                                            onChange={(v) => {
                                                this.handleTimeSelect(this.state.hourType, "close", "holiday", v);
                                            }}
                                        />
                                        <div className=" d-flex justify-content-start align-items-center " style={{}}>
                                            까지
                                        </div>
                                    </Row>
                                </div>
                            </Fragment>
                        )}
                    </Row>
                </Fragment>
            );
        } else if (this.state.hourType === "custom") {
            return (
                <div>
                    {Object.entries(this.state.timeInfo).map(([key, value]) => {
                        if (value.selected) {
                            return (
                                <Fragment>
                                    <Row className="d-flex rounded m-2">
                                        <div className="col-12 col-md-3 justify-content-start align-items-center " style={{}}>
                                            <Row className="mt-md-2">{value.fullname}</Row>
                                        </div>
                                        <div className={"d-flex d-md-none mt-2"} style={{ minWidth: "100%", minHeight: 1 }}></div>
                                        <div className="col-12 col-md-9  justify-content-start align-items-center " style={{}}>
                                            <Row className="m-sm-2">
                                                <TimePicker
                                                    key={`custom_open_time_${value.fullname}`}
                                                    viewMode={this.props.viewMode}
                                                    value={value.open}
                                                    onChange={(v) => {
                                                        this.handleTimeSelect(this.state.hourType, "open", "custom", v, value.key);
                                                    }}
                                                />
                                                <div className=" d-flex justify-content-start align-items-center " style={{}}>
                                                    부터
                                                </div>
                                            </Row>
                                            <div className={"d-flex d-md-none mt-3"} style={{ minWidth: "100%", minHeight: 1 }}></div>
                                            <Row className="m-sm-2">
                                                <TimePicker
                                                    key={`custom_close_time_${value.fullname}`}
                                                    viewMode={this.props.viewMode}
                                                    value={value.close}
                                                    onChange={(v) => {
                                                        this.handleTimeSelect(this.state.hourType, "close", "custom", v, value.key);
                                                    }}
                                                />
                                                <div className=" d-flex justify-content-start align-items-center " style={{}}>
                                                    까지
                                                </div>
                                            </Row>
                                        </div>
                                    </Row>
                                </Fragment>
                            );
                        } else {
                            return <div />;
                        }
                    })}
                </div>
            );
        }
    }

    render() {
        if (!this.props.show) return <div />;
        if (this.state.isInitDone) {
            return (
                <Card className="shadow rounded w-100 p-3">
                    {this.props.storeType === null || this.props.storeType === "accommodation" ? (
                        <Col className="col-12">
                            <Row className="d-flex rounded m-md-2">
                                <div className="col-12 col-md-3 justify-content-start align-items-center " style={{}}>
                                    <Row className="mt-md-2">체크인</Row>
                                </div>
                                <div className={"d-flex d-md-none mt-2"} style={{ minWidth: "100%", minHeight: 1 }}></div>
                                <div className="col-12 col-md-9  justify-content-start align-items-center " style={{}}>
                                    <Row className="m-sm-2">
                                        <TimePicker
                                            key={"checkin_time"}
                                            viewMode={this.props.viewMode}
                                            value={this.state.accommodationTimeInfo.checkIn}
                                            onChange={(v) => {
                                                this.handleTimeSelect(this.state.hourType, "checkIn", null, v);
                                            }}
                                        />
                                    </Row>
                                </div>
                            </Row>
                            <div className={"d-flex d-md-none mt-3"} style={{ minWidth: "100%", minHeight: 1 }}></div>
                            <Row className="d-flex rounded m-md-2">
                                <div className="col-12 col-md-3  justify-content-start align-items-center " style={{}}>
                                    <Row className="mt-sm-2">체크아웃</Row>
                                </div>
                                <div className={"d-flex d-md-none mt-2"} style={{ minWidth: "100%", minHeight: 1 }}></div>
                                <div className="col-12 col-md-9  justify-content-start align-items-center " style={{}}>
                                    <Row className="m-sm-2">
                                        <TimePicker
                                            key={"checkout_time"}
                                            viewMode={this.props.viewMode}
                                            value={this.state.accommodationTimeInfo.checkOut}
                                            onChange={(v) => {
                                                this.handleTimeSelect(this.state.hourType, "checkOut", null, v);
                                            }}
                                        />
                                    </Row>
                                </div>
                            </Row>
                        </Col>
                    ) : (
                        <Row className={"col-12"}>
                            <Col className="col-md-4 col-12">
                                <RadioGroup
                                    aria-label="영업시간"
                                    name="hourType"
                                    value={this.state.hourType}
                                    onChange={(e) => {
                                        if (this.props.viewMode !== "view") {
                                            this.setState({
                                                hourType: e.target.value,
                                            });
                                        }
                                    }}>
                                    {this.state.hourTypeList.map((item, index) => {
                                        return <FormControlLabel key={`hourType-custom-${index}`} value={item.value} control={<Radio />} label={item.name} />;
                                    })}
                                </RadioGroup>
                                {this.state.hourType === "custom" && (
                                    <Row className="col-12 mx-auto">
                                        <FormGroup
                                            onChange={(e) => {
                                                if (this.props.viewMode !== "view") {
                                                    if (this.state.timeInfo[e.target.name].selected) {
                                                        this.setState({
                                                            timeInfo: {
                                                                ...this.state.timeInfo,
                                                                [e.target.name]: this.state.defaultTimeInfo[e.target.name],
                                                            },
                                                        });
                                                    } else {
                                                        this.setState({
                                                            timeInfo: {
                                                                ...this.state.timeInfo,
                                                                [e.target.name]: { ...this.state.timeInfo[e.target.name], selected: true, isFreeDay: false },
                                                            },
                                                        });
                                                    }
                                                }
                                            }}>
                                            {Object.entries(this.state.timeInfo).map(([key, value]) => {
                                                return (
                                                    <FormControlLabel
                                                        key={`hourType-timeInfo-${key}`}
                                                        name={key}
                                                        value={value.selected}
                                                        control={this.props.viewMode === "view" ? <Checkbox checked={this.props.value[key] !== "off"} /> : <Checkbox checked={value.selected} />}
                                                        label={value.name}
                                                    />
                                                );
                                            })}
                                        </FormGroup>
                                    </Row>
                                )}
                            </Col>
                            <Col className="col-md-8 col-12 bg-secondary">{this.doRenderTimeSelector()}</Col>
                        </Row>
                    )}
                </Card>
            );
        } else {
            return <div />;
        }
    }
}

StoreTimeSelector.defaultProps = {
    storeType: "default",
    value: null,
};

export class StoreTypeSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // 숙박: accommodation, 음식점: food, 카페: cafe, 액티비티: activity
            storeTypeList: [
                { name: "음식점", value: "food" },
                { name: "숙박", value: "accommodation" },
                { name: "카페", value: "cafe" },
                { name: "액티비티", value: "activity" },
            ],
            selectStoreType: null,
        };
    }
    componentDidMount() {
        if (this.props.value !== undefined && this.props.value !== null) {
            this.setState({ selectStoreType: `${this.props.value}` });
        }
    }
    componentWillUnmount() {}
    componentDidUpdate(prevProps) {
        if (this.props.value !== undefined && this.props.value !== null) {
            this.setState({ selectStoreType: `${this.props.value}` });
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.selectStoreType !== nextState.selectStoreType) {
            return true;
        }
        if (this.props.value !== nextProps.value) {
            return true;
        }
        return false;
    }

    render() {
        return (
            <div style={{ ...this.props.style }}>
                <RadioGroup
                    row
                    aria-label="업종"
                    name="storeType"
                    value={this.state.selectStoreType}
                    onChange={(e) => {
                        this.setState({
                            selectStoreType: e.target.value,
                        });
                        if (this.props.onChange !== undefined && this.props.onChange !== null) {
                            this.props.onChange(e.target.value);
                        }
                    }}>
                    {this.state.storeTypeList.map((item, index) => {
                        return (
                            <FormControlLabel
                                key={`storeType-${index}`}
                                value={item.value}
                                control={<Radio checked={item.value === this.state.selectStoreType} />}
                                label={item.name}
                                disabled={this.props.disabled}
                            />
                        );
                    })}
                </RadioGroup>
            </div>
        );
    }
}

StoreTypeSelector.defaultProps = {
    disabled: false,
};
