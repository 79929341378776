import React from "react";
import { Button, Card, Container, Row } from "reactstrap";
import lodash from "lodash";
import NormalHeader from "../../components/Headers/NormalHeader.js";
import { convertDate, getAgeGrade } from "../../modules/utils.js";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import UsersDetail from "./UsersDetail";
import UsersAnalytics from "./UsersAnalytics.js";
import { getUserData } from "../../lib/users.js";
import PhotologTable, { SortIcon } from "../../modules/PhotologTable.js";

export const loginTypeList = [
    { value: "kakao", label: "카카오", bgColor: "#ffeb00", textColor: "3c1e1e" },
    { value: "photolog", label: "포토로그", bgColor: "#783ec3", textColor: "white" },
    { value: "facebook", label: "페이스북", bgColor: "#1877F2", textColor: "white" },
    { value: "apple", label: "애플", bgColor: "#000000", textColor: "white" },
];

export let ageGradeList = [
    { label: "알수없음", grade: -1, bgColor: "#f14668", textColor: "white" },
    { label: "10대", grade: 10, bgColor: "#587850", textColor: "white" },
    { label: "20대", grade: 20, bgColor: "#a3d2ca", textColor: "white" },
    { label: "30대", grade: 30, bgColor: "#fbc687", textColor: "white" },
    { label: "40대", grade: 40, bgColor: "#ea907a", textColor: "white" },
    { label: "50대", grade: 50, bgColor: "#726a95", textColor: "white" },
    { label: "60대", grade: 60, bgColor: "#c36a2d", textColor: "white" },
];

const Users = () => {
    const [showAnalytics, setShowAnalytics] = React.useState(false);
    const [modalParams, setModalParams] = React.useState(null);
    const columns = [
        {
            dataField: "user_id",
            text: "ID",
            headerStyle: { width: "20%" },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
            hidden: true,
        },
        {
            dataField: "user_nickname",
            text: "닉네임",
            headerStyle: { width: "20%", minWidth: 100 },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
        },

        {
            dataField: "user_alarm_yn",
            text: "알림",
            headerStyle: { width: "10%" },
            headerAlign: "center",
            formatter: (cell, row) => {
                return (
                    <span className={"badge rounded-pill"} style={{ background: cell === 1 ? "#f14668" : "#a3d2ca", color: cell === 1 ? "white" : "white" }}>
                        {cell === 1 ? "허용" : "거부"}
                    </span>
                );
            },
            align: (cell, row, rowIndex, colIndex) => {
                return "center";
            },
        },
        {
            dataField: "user_login_type",
            text: "로그인타입",
            headerStyle: { width: "15%" },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
            formatter: (cell, row) => {
                let loginInfo = lodash.find(loginTypeList, { value: cell });
                return (
                    <span className={"badge rounded-pill"} style={{ background: loginInfo.bgColor, color: loginInfo.textColor }}>
                        {loginInfo.label}
                    </span>
                );
            },
            classes: "d-none d-lg-table-cell",
            headerClasses: "d-none d-lg-table-cell",
        },
        {
            dataField: "user_birthday",
            text: "연령층",
            headerStyle: { width: "15%" },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
            formatter: (cell, row) => {
                let ageInfo = getAgeGrade(cell);
                return (
                    <span className={"badge rounded-pill"} style={{ background: ageInfo.bgColor, color: ageInfo.textColor }}>
                        {ageInfo.label}
                    </span>
                );
            },
        },
        {
            dataField: "user_regist_date",
            text: "가입일시",
            sort: true,
            headerStyle: { width: "20%" },
            headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
                return (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {column.text}
                        <SortIcon order={sortElement.props.order} />
                    </div>
                );
            },
            formatter: (cell, row) => {
                return <span>{convertDate({ _originDate: cell, _convertFormat: "YYYY-MM-DD HH:mm" })}</span>;
            },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                };
            },
        },
    ];

    React.useEffect(() => {
        // doGetContents({ _category: 0, _size: 10, _pageNo: 1 });
    }, []);

    return (
        <>
            <NormalHeader />
            <Container className="mt--7 pb-7" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow border-0 pt-4 pb-4">
                            <PhotologTable
                                category={0}
                                keyField={"user_id"}
                                columns={columns}
                                detailPage={UsersDetail}
                                getData={({ _category, _size, _pageNo }) => {
                                    return getUserData({ _category: _category, _size: _size, _pageNo: _pageNo });
                                }}
                            />
                        </Card>
                    </div>
                </Row>
            </Container>

            <Modal isOpen={showAnalytics} toggle={() => setShowAnalytics(!showAnalytics)} style={{ minWidth: "70%", padding: 0 }}>
                <ModalHeader className={"modal-title"} toggle={() => setShowAnalytics(!showAnalytics)} style={{ zIndex: 10 }} />
                <ModalBody className={"col-12"}>
                    <UsersAnalytics {...modalParams} />
                </ModalBody>
                <ModalFooter className={"modal-footer"}></ModalFooter>
            </Modal>
        </>
    );
};

export default Users;
