import React, { Component } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import lodash from "lodash";

export class DropdownList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            selectedValue: -2,
        };
    }

    componentDidMount() {
        if (this.props.defaultValue !== null) {
            this.setState({ selectedValue: this.props.defaultValue });
        }
    }
    componentWillUnmount() {}
    componentDidUpdate(prevProps) {
        if (prevProps.defaultValue !== this.props.defaultValue) {
            this.setState({ selectedValue: this.props.defaultValue });
        }
    }
    // shouldComponentUpdate(nextProps, nextState) {
    //     return false;
    // }
    render() {
        return (
            <Dropdown
                size={this.props.size}
                isOpen={this.state.dropdownOpen}
                toggle={() => this.setState({ dropdownOpen: !this.state.dropdownOpen })}
                className={this.props.className}
                disabled={this.props.disabled}>
                <DropdownToggle caret className={this.props.className}>
                    {lodash.find(this.props.dataList, { value: this.state.selectedValue }) === undefined
                        ? this.props.defaultTitle
                        : lodash.find(this.props.dataList, { value: this.state.selectedValue }).name}
                </DropdownToggle>
                <DropdownMenu>
                    {this.props.dataList.map((option) => {
                        return (
                            <DropdownItem
                                key={`dropdown-items-${option.value}`}
                                onClick={() => {
                                    if (this.props.onChange !== null) {
                                        this.props.onChange(option);
                                    }
                                    this.setState({ selectedValue: option.value });
                                }}>
                                {option.name}
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </Dropdown>
        );
    }
}

DropdownList.defaultProps = {
    dataList: [],
    size: "md",
    onChange: null,
    defaultValue: null,
    className: "col-12",
    defaultTitle: "선택",
};
