import React from "react";

import { convertDate, diffDate, History } from "../../modules/utils.js";
import { Card, Col, Container, Form, FormGroup, Input, Label, Row, Button } from "reactstrap";
import ToggleSwitch from "../../modules/ToggleSwitch.js";
// import Icon from "../../modules/VectorIcons"
import ReactQuill from "react-quill";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import lodash, { isNumber } from "lodash";
import { MuiPickersUtilsProvider, TimePicker, DateTimePicker } from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";
import "moment/locale/ko";
import { PhotologDialog } from "../../modules/PhotologDialog.js";
import { deletePopup, insertPopup, modifyPopup } from "../../lib/popup.js";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import { RiCloseFill } from "react-icons/ri";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));
class RenderImageFromFile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            image: null,
        };
    }
    readFile(file) {
        let fr = new FileReader();
        fr.readAsDataURL(file);
        fr.onloadend = (r) => {
            this.setState({ image: r.target.result });
        };
    }
    componentDidMount() {
        if (this.props.type === "local") this.readFile(this.props.image);
        else this.setState({ image: this.props.image });
    }
    componentWillUnmount() {}
    componentDidUpdate(prevProps) {}
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.image !== nextProps.image) {
            if (this.props.type === "local") this.readFile(nextProps.image);
            else this.setState({ image: nextProps.image });
            return true;
        }
        if (this.state.image !== nextState.image) {
            // this.readFile(nextProps.image);
            return true;
        }
        return false;
    }

    render() {
        if (this.state.image === null) {
            return <div />;
        } else {
            return (
                <div className={"mt-2"}>
                    {this.props.type === "local" && (
                        <Button
                            style={{ position: "absolute", top: this.props.type === "local" ? 80 : 100, right: 20 }}
                            className="btn btn-danger"
                            size="sm"
                            onClick={() => {
                                this.props.onClose();
                                this.setState({ image: null });
                            }}>
                            <RiCloseFill size={15} />
                        </Button>
                    )}
                    <img key={`image-push`} src={this.state.image} alt="" style={{ ...this.props.imageStyle, width: "100%" }} className={"border border-2"} />
                </div>
            );
        }
    }
}
const PopupDetail = (props) => {
    const [showItem, setShowItem] = React.useState(null);
    const [addMode, setAddMode] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [tempImageLocal, setTempImageLocal] = React.useState(null);
    const [tempImageRemote, setTempImageRemote] = React.useState(null);
    const [openCal, setOpenCal] = React.useState(false);
    const [openCalTag, setOpenCalTag] = React.useState("start_date");
    const [showMessage, setShowMessage] = React.useState(false);
    const [showBackdrop, setShowBackdrop] = React.useState(false);
    const message = React.useRef({
        tag: "",
        title: "",
        message: null,
        body: null,
        button: [],
        keyboard: true,
        toggle: true,
        renderFooter: null,
        showFooter: true,
    });

    const classes = useStyles();
    React.useEffect(() => {
        if (props === undefined) {
            History.replace("/admin/popup");
            window.location.reload();
            return;
        } else {
            if (showItem === null) {
                if (props.mode === "add") {
                    setShowItem({
                        contents: {
                            popup_seq: -1,
                            popup_btn1: null,
                            popup_btn2: null,
                            popup_url: "",
                            popup_title: null,
                            popup_contents: null,
                            popup_start_date: null,
                            popup_end_date: null,
                            popup_type: "external_url",
                            navigation_category: null,
                            navigation_seq: null,
                            navigation_code: null,
                        },
                    });
                    setAddMode(true);
                } else {
                    setShowItem({
                        contents: props.getContentsItem,
                    });

                    setTempImageRemote(props.getContentsItem.popup_contents);
                }
            }
        }
    }, [showItem, props]);

    const doValidation = () => {
        if (editMode) {
            if (lodash.isEqual(props.getContentsItem, showItem.contents)) {
                return {
                    errCode: -1,
                    errMsg: "바뀐값이 없음",
                };
            }
        }

        // 쿠폰코드 (필수)
        if (showItem.contents.popup_title === null || showItem.contents.popup_title.length === 0) {
            return {
                errCode: 1,
                errMsg: "팝업 제목 입력란을 확인하세요",
            };
        }
        if (showItem.contents.popup_type === null || showItem.contents.popup_type.length === 0) {
            return {
                errCode: 1,
                errMsg: "팝업 형식을 확인하세요",
            };
        } else {
            if (showItem.contents.popup_type === "navigation") {
                if (showItem.contents.navigation_category === null || showItem.contents.navigation_category.length === 0) {
                    return {
                        errCode: 1,
                        errMsg: "내부이동 화면을 확인하세요",
                    };
                } else {
                    if (showItem.contents.navigation_category === "null") {
                        setShowItem({
                            contents: {
                                ...showItem.contents,
                                navigation_category: null,
                            },
                            details: {
                                ...showItem.details,
                            },
                        });
                    }
                }
            } else if (showItem.contents.popup_type === "external_url") {
                if (showItem.contents.popup_url === null || showItem.contents.popup_url.length === 0) {
                    return {
                        errCode: 1,
                        errMsg: "팝업 URL 확인하세요",
                    };
                } else if (!showItem.contents.popup_url.startsWith("http")) {
                    return {
                        errCode: 1,
                        errMsg: "팝업 URL주소는 http(s)로 시작해야합니다",
                    };
                }
            }
        }

        if (showItem.contents.popup_btn1 === null || showItem.contents.popup_btn1.length === 0) {
            return {
                errCode: 1,
                errMsg: "왼쪽 버튼 입력란을 확인하세요",
            };
        }
        if (showItem.contents.popup_btn2 === null || showItem.contents.popup_btn2.length === 0) {
            return {
                errCode: 1,
                errMsg: "오른쪽버튼 입력란을 확인하세요",
            };
        }
        // 쿠폰사용 시작일시와 종료일시가 동일한지 확인
        // if (lodash.isEqual(showItem.contents.popup_start_date, showItem.contents.popup_end_date)) {
        //     return {
        //         errCode: 2,
        //         errMsg: "팝업 시작/종료일시가 같습니다",
        //     };
        // }
        if (showItem.contents.popup_start_date === null || showItem.contents.popup_start_date.length === 0) {
            return {
                errCode: 1,
                errMsg: "팝업 시작일시 입력란을 확인하세요",
            };
        }
        if (showItem.contents.popup_end_date === null || showItem.contents.popup_end_date.length === 0) {
            return {
                errCode: 1,
                errMsg: "팝업 종료일시 입력란을 확인하세요",
            };
        }
        let diffTime = diffDate({ _date1: showItem.contents.popup_start_date, _date2: showItem.contents.popup_end_date });

        if (diffTime.d === 0 && diffTime.h === 0 && diffTime.m === 0) {
            return {
                errCode: 1,
                errMsg: "팝업 시작일시와 팝업 종료일시가 같습니다",
            };
        } else {
            if (diffTime.d <= -1 || diffTime.h <= -1 || diffTime.m <= -1) {
                return {
                    errCode: 1,
                    errMsg: "시작일시와 종료일시를 확인하세요",
                };
            }
        }

        // if (showItem.contents.popup_contents === null || showItem.contents.popup_contents.length === 0) {
        //     return {
        //         errCode: 1,
        //         errMsg: "팝업 내용 입력란을 확인하세요",
        //     };
        // }

        if (!editMode) {
            if (tempImageLocal === null) {
                return {
                    errCode: 1,
                    errMsg: "메인 이미지를 추가하세요",
                };
            }
        }
        return {
            errCode: 0,
            errMsg: "문제 발견되지 않음",
        };
    };

    if (showItem === null) {
        return <div></div>;
    } else {
        return (
            <>
                <Container className="mt--7">
                    {addMode ? (
                        <Row>
                            <Col className={"col-12 mx-auto mt-6 m-3"}></Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col className={"col-12 mx-auto mt-6 m-3"}>
                                <Label for="contents_editMode" style={{ color: "black" }}>
                                    수정모드
                                </Label>
                                <Row className={"ml-0"}>
                                    <ToggleSwitch
                                        id="contents_editMode"
                                        checked={editMode}
                                        onChange={(v) => {
                                            if (editMode === true) {
                                                // 수정모드 가능->불가능
                                                setShowItem({
                                                    contents: props.getContentsItem,
                                                });
                                            }
                                            setEditMode(v);
                                        }}
                                        optionLabels={["가능", "불가"]}
                                    />
                                </Row>
                            </Col>
                        </Row>
                    )}
                    <Card className="shadow border-0 pb-4">
                        <Row className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                            <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto"}>
                                <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                    <Label for="contents_popup_title">팝업 제목</Label>
                                    <Input
                                        type="text"
                                        name="popup_title"
                                        id="contents_popup_title"
                                        value={showItem.contents.popup_title}
                                        disabled={addMode ? false : !editMode}
                                        onChange={(e) => {
                                            setShowItem({
                                                contents: {
                                                    ...showItem.contents,
                                                    popup_title: e.target.value,
                                                },
                                                details: {
                                                    ...showItem.details,
                                                },
                                            });
                                        }}
                                    />
                                </Col>

                                <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                    <Label for="contents_popup_type">팝업 형식</Label>
                                    <Row className={"ml-1"} id="contents_popup_type">
                                        <FormControl component="fieldset" disabled={addMode ? false : !editMode}>
                                            <RadioGroup
                                                name="gender1"
                                                row={true}
                                                value={showItem.contents.popup_type}
                                                onChange={(e) => {
                                                    setShowItem({
                                                        contents: {
                                                            ...showItem.contents,
                                                            popup_type: e.target.value,
                                                            navigation_category: null,
                                                            navigation_seq: null,
                                                            navigation_code: null,
                                                        },
                                                        details: {
                                                            ...showItem.details,
                                                        },
                                                    });
                                                }}>
                                                <FormControlLabel value="external_url" control={<Radio />} label="외부링크" />
                                                <FormControlLabel value="navigation" control={<Radio />} label="내부이동" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Row>
                                </Col>
                                <Col
                                    className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}
                                    hidden={showItem.contents.popup_type !== "navigation" || showItem.contents.popup_type === null}>
                                    <Label for="contents_navigation_category">내부이동 화면</Label>
                                    <Row className={"ml-1"} id="contents_navigation_category">
                                        <FormControl component="fieldset" disabled={addMode ? false : !editMode}>
                                            <RadioGroup
                                                name="gender1"
                                                row={true}
                                                value={showItem.contents.navigation_category}
                                                onChange={(e) => {
                                                    setShowItem({
                                                        contents: {
                                                            ...showItem.contents,
                                                            navigation_category: e.target.value,
                                                        },
                                                        details: {
                                                            ...showItem.details,
                                                        },
                                                    });
                                                }}>
                                                <FormControlLabel value="event" control={<Radio />} label="이벤트" />
                                                <FormControlLabel value="store" control={<Radio />} label="스토어" />
                                                <FormControlLabel value="notice" control={<Radio />} label="공지사항" />
                                                <FormControlLabel value="null" control={<Radio />} label="선택안함" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Row>
                                </Col>
                                <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                    <Label for="contents_popup_url">팝업 URL</Label>
                                    <Input
                                        type="text"
                                        name="popup_url"
                                        id="contents_popup_url"
                                        value={showItem.contents.popup_url === null || showItem.contents.popup_url === "null" ? "" : showItem.contents.popup_url}
                                        disabled={addMode ? false : !editMode}
                                        onChange={(e) => {
                                            setShowItem({
                                                contents: {
                                                    ...showItem.contents,
                                                    popup_url: e.target.value,
                                                },
                                                details: {
                                                    ...showItem.details,
                                                },
                                            });
                                        }}
                                    />
                                </Col>
                            </Col>
                        </Row>
                        <Row className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto"}>
                            <Col className={"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mx-auto pb-3"}>
                                <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"} hidden={showItem.contents.navigation_category === "null"}>
                                    <Label for="contents_navigation_seq">Seq</Label>

                                    {showItem.contents.navigation_category === "store" ? (
                                        <Row className={"ml-1"} id="contents_navigation_seq_store">
                                            <FormControl component="fieldset" disabled={addMode ? false : !editMode}>
                                                <RadioGroup
                                                    name="navigation_seq_store"
                                                    row={true}
                                                    value={showItem.contents.navigation_seq === null || showItem.contents.navigation_seq === "null" ? "" : Number(showItem.contents.navigation_seq)}
                                                    onChange={(e) => {
                                                        setShowItem({
                                                            contents: {
                                                                ...showItem.contents,
                                                                navigation_seq: e.target.value,
                                                            },
                                                            details: {
                                                                ...showItem.details,
                                                            },
                                                        });
                                                    }}>
                                                    <FormControlLabel value={-1} control={<Radio />} label="스토어 홈" />
                                                    <FormControlLabel value={0} control={<Radio />} label="로그카드" />
                                                    <FormControlLabel value={1} control={<Radio />} label="로그인맵 지도" />
                                                    <FormControlLabel value={2} control={<Radio />} label="로그인맵 스티커" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Row>
                                    ) : (
                                        <Input
                                            type="number"
                                            name="navigation_seq"
                                            id="contents_navigation_seq"
                                            value={showItem.contents.navigation_seq === null || showItem.contents.navigation_seq === "null" ? "" : showItem.contents.navigation_seq}
                                            disabled={addMode ? false : !editMode}
                                            onChange={(e) => {
                                                setShowItem({
                                                    contents: {
                                                        ...showItem.contents,
                                                        navigation_seq: e.target.value,
                                                    },
                                                    details: {
                                                        ...showItem.details,
                                                    },
                                                });
                                            }}
                                        />
                                    )}
                                </Col>
                                <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"} hidden={showItem.contents.navigation_category === "null"}>
                                    <Label for="contents_navigation_code">Code</Label>
                                    <Input
                                        type="text"
                                        name="navigation_code"
                                        id="contents_navigation_code"
                                        value={showItem.contents.navigation_code === null || showItem.contents.navigation_code === "null" ? "" : showItem.contents.navigation_code}
                                        disabled={addMode ? false : !editMode}
                                        onChange={(e) => {
                                            setShowItem({
                                                contents: {
                                                    ...showItem.contents,
                                                    navigation_code: e.target.value,
                                                },
                                                details: {
                                                    ...showItem.details,
                                                },
                                            });
                                        }}
                                    />
                                </Col>
                                <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                    <Label for="contents_popup_btn1">왼쪽 버튼</Label>
                                    <Input
                                        type="text"
                                        name="popup_btn1"
                                        id="contents_popup_btn1"
                                        value={showItem.contents.popup_btn1}
                                        disabled={addMode ? false : !editMode}
                                        onChange={(e) => {
                                            setShowItem({
                                                contents: {
                                                    ...showItem.contents,
                                                    popup_btn1: e.target.value,
                                                },
                                                details: {
                                                    ...showItem.details,
                                                },
                                            });
                                        }}
                                    />
                                </Col>
                                <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                    <Label for="contents_popup_btn2">오른쪽 버튼</Label>
                                    <Input
                                        type="text"
                                        name="popup_btn2"
                                        id="contents_popup_btn2"
                                        value={showItem.contents.popup_btn2}
                                        disabled={addMode ? false : !editMode}
                                        onChange={(e) => {
                                            setShowItem({
                                                contents: {
                                                    ...showItem.contents,
                                                    popup_btn2: e.target.value,
                                                },
                                                details: {
                                                    ...showItem.details,
                                                },
                                            });
                                        }}
                                    />
                                </Col>
                                <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                    <Label for="contents_popup_start_date">팝업 시작일시</Label>
                                    <Input
                                        type="text"
                                        name="popup_start_date"
                                        id="contents_popup_start_date"
                                        value={convertDate({ _originDate: showItem.contents.popup_start_date, _convertFormat: "YYYY-MM-DD HH:mm" })}
                                        disabled={addMode ? false : !editMode}
                                        onClick={() => {
                                            // setOpenCal({ status: true, tag: "start_date" });
                                            setOpenCalTag("start_date");
                                            setTimeout(() => {
                                                setOpenCal(true);
                                            }, 300);
                                        }}
                                    />
                                </Col>
                                <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                    <Label for="contents_popup_end_date">팝업 종료일시</Label>
                                    <Input
                                        type="text"
                                        name="popup_end_date"
                                        id="contents_popup_end_date"
                                        value={convertDate({ _originDate: showItem.contents.popup_end_date, _convertFormat: "YYYY-MM-DD HH:mm" })}
                                        disabled={addMode ? false : !editMode}
                                        onClick={() => {
                                            // setOpenCal({ status: true, tag: "end_date" });
                                            setOpenCalTag("end_date");
                                            setTimeout(() => {
                                                setOpenCal(true);
                                            }, 300);
                                        }}
                                    />
                                </Col>
                            </Col>
                            <Col className={"col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mx-auto"}>
                                <Col className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-3"}>
                                    <Label for="contents_push_image_local">이미지 파일</Label>
                                    {addMode && (
                                        <Input
                                            type="file"
                                            name="popup_contents"
                                            id="contents_popup_contents_local"
                                            value={showItem.contents.popup_contents}
                                            disabled={addMode ? false : !editMode}
                                            onChange={(e) => {
                                                setTempImageLocal(e.target.files[0]);
                                            }}
                                        />
                                    )}
                                    {tempImageLocal !== null && (
                                        <RenderImageFromFile
                                            type="local"
                                            image={tempImageLocal}
                                            className={"border border-2"}
                                            onClose={() => {
                                                setTempImageLocal(null);
                                            }}
                                        />
                                    )}
                                    <RenderImageFromFile
                                        type="remote"
                                        image={tempImageRemote}
                                        className={"border border-2"}
                                        onClose={() => {
                                            setTempImageRemote(null);
                                        }}
                                        style={{ minHeight: 300, background: "red" }}
                                    />
                                    {tempImageRemote !== null && <div></div>}
                                </Col>
                            </Col>
                        </Row>
                        <Row className={"mt-5"}>
                            <Col className={"col-11 mx-auto"}>
                                <div className={"float-right"}>
                                    {addMode ? (
                                        <Button
                                            className={"btn btn-default btn-lg"}
                                            style={{ width: 100 }}
                                            onClick={() => {
                                                const validationResult = doValidation();
                                                if (validationResult.errCode === 0) {
                                                    message.current.title = "알림";
                                                    message.current.message = "추가하시겠습니까?";
                                                    message.current.button = [
                                                        {
                                                            title: "추가",
                                                            action: () => {
                                                                setShowMessage(false);
                                                                insertPopup({
                                                                    _popupTitle: showItem.contents.popup_title,
                                                                    _popupContents: tempImageLocal,
                                                                    _popupBtn1: showItem.contents.popup_btn1,
                                                                    _popupBtn2: showItem.contents.popup_btn2,
                                                                    _popupUrl: showItem.contents.popup_url,
                                                                    _popupStartDate: convertDate({
                                                                        _originDate: showItem.contents.popup_start_date === null ? new Date() : showItem.contents.popup_start_date,
                                                                        _convertFormat: "YYYY-MM-DD HH:mm:ss",
                                                                    }),
                                                                    _popupEndDate: convertDate({
                                                                        _originDate: showItem.contents.popup_end_date === null ? new Date() : showItem.contents.popup_end_date,
                                                                        _convertFormat: "YYYY-MM-DD HH:mm:ss",
                                                                    }),
                                                                    _popupType: showItem.contents.popup_type,
                                                                    _navigationCategory: showItem.contents.navigation_category,
                                                                    _navigationSeq: showItem.contents.navigation_seq,
                                                                    _navigationCode: showItem.contents.navigation_code,
                                                                }).then((insertPopupResult) => {
                                                                    if (insertPopupResult.data.res_code === 2) {
                                                                        // 수정완료
                                                                        toast.success("🎉 완료되었습니다");
                                                                        setTimeout(() => {
                                                                            window.location.reload();
                                                                        }, 2500);
                                                                    } else {
                                                                        // props.onResult({ actionCode: insertPopupResult.data.res_code });
                                                                        toast.error("🤔 에러가 발생되었습니다");
                                                                    }
                                                                });
                                                            },
                                                            background: "red",
                                                            textColor: "white",
                                                        },
                                                        {
                                                            title: "닫기",
                                                            action: () => {
                                                                setShowMessage(false);
                                                            },
                                                            background: "purple",
                                                            textColor: "white",
                                                        },
                                                    ];
                                                    message.current.body = null;
                                                    message.current.showFooter = true;
                                                    message.current.keyboard = true;
                                                    message.current.renderFooter = null;
                                                    setShowMessage(true);
                                                } else {
                                                    message.current.title = "알림";
                                                    message.current.message = `${validationResult.errMsg}`;
                                                    message.current.button = [
                                                        {
                                                            title: "확인",
                                                            action: () => {
                                                                setShowMessage(false);
                                                            },
                                                            background: "purple",
                                                            textColor: "white",
                                                        },
                                                    ];
                                                    message.current.body = null;
                                                    message.current.showFooter = true;
                                                    message.current.keyboard = true;
                                                    message.current.renderFooter = null;
                                                    setShowMessage(true);
                                                }
                                            }}>
                                            추가
                                        </Button>
                                    ) : (
                                        <div>
                                            <Button
                                                className={"btn btn-default btn-lg"}
                                                style={{ width: 100 }}
                                                onClick={() => {
                                                    const validationResult = doValidation();
                                                    if (validationResult.errCode === 0) {
                                                        message.current.title = "알림";
                                                        message.current.message = "수정하시겠습니까?";
                                                        message.current.button = [
                                                            {
                                                                title: "수정",
                                                                action: () => {
                                                                    setShowMessage(false);
                                                                    modifyPopup({
                                                                        _popupIdx: showItem.contents.popup_seq,
                                                                        _popupTitle: showItem.contents.popup_title,
                                                                        _popupBtn1: showItem.contents.popup_btn1,
                                                                        _popupBtn2: showItem.contents.popup_btn2,
                                                                        _popupUrl: showItem.contents.popup_url,
                                                                        _popupStartDate: convertDate({
                                                                            _originDate: showItem.contents.popup_start_date === null ? new Date() : showItem.contents.popup_start_date,
                                                                            _convertFormat: "YYYY-MM-DD HH:mm:ss",
                                                                        }),
                                                                        _popupEndDate: convertDate({
                                                                            _originDate: showItem.contents.popup_end_date === null ? new Date() : showItem.contents.popup_end_date,
                                                                            _convertFormat: "YYYY-MM-DD HH:mm:ss",
                                                                        }),
                                                                        _popupType: showItem.contents.popup_type,
                                                                        _navigationCategory: showItem.contents.navigation_category,
                                                                        _navigationSeq: showItem.contents.navigation_seq,
                                                                        _navigationCode: showItem.contents.navigation_code,
                                                                    }).then((modifyPopupResult) => {
                                                                        if (modifyPopupResult.data.res_code === 2) {
                                                                            // 수정완료
                                                                            props.onResult({
                                                                                actionCode: modifyPopupResult.data.res_code,
                                                                                actionName: "edit",
                                                                                changedData: {
                                                                                    ...showItem,
                                                                                },
                                                                            });
                                                                        } else {
                                                                            // props.onResult({ actionCode: modifyPopupResult.data.res_code });
                                                                            toast.error("🤔 에러가 발생되었습니다");
                                                                        }
                                                                    });
                                                                },
                                                                background: "red",
                                                                textColor: "white",
                                                            },
                                                            {
                                                                title: "닫기",
                                                                action: () => {
                                                                    setShowMessage(false);
                                                                },
                                                                background: "purple",
                                                                textColor: "white",
                                                            },
                                                        ];
                                                        message.current.body = null;
                                                        message.current.showFooter = true;
                                                        message.current.keyboard = true;
                                                        message.current.renderFooter = null;
                                                        setShowMessage(true);
                                                    } else {
                                                        message.current.title = "알림";
                                                        message.current.message = `${validationResult.errMsg}`;
                                                        message.current.button = [
                                                            {
                                                                title: "확인",
                                                                action: () => {
                                                                    setShowMessage(false);
                                                                },
                                                                background: "purple",
                                                                textColor: "white",
                                                            },
                                                        ];
                                                        message.current.body = null;
                                                        message.current.showFooter = true;
                                                        message.current.keyboard = true;
                                                        message.current.renderFooter = null;
                                                        setShowMessage(true);
                                                    }
                                                }}>
                                                수정
                                            </Button>
                                            <Button
                                                className={"btn btn-danger btn-lg"}
                                                style={{ width: 100 }}
                                                onClick={() => {
                                                    message.current.title = "알림";
                                                    message.current.message = "삭제하시겠습니까?";
                                                    message.current.button = [
                                                        {
                                                            title: "삭제",
                                                            action: () => {
                                                                setShowMessage(false);
                                                                deletePopup({
                                                                    _popupIdx: showItem.contents.popup_seq,
                                                                }).then((modifyPopupResult) => {
                                                                    if (modifyPopupResult.data.res_code === 2) {
                                                                        // 삭제완료
                                                                        props.onResult({
                                                                            actionCode: modifyPopupResult.data.res_code,
                                                                            actionName: "del",
                                                                            changedData: {
                                                                                ...showItem,
                                                                            },
                                                                        });
                                                                    } else {
                                                                        // props.onResult({ actionCode: modifyPopupResult.data.res_code });
                                                                        toast.error("🤔 에러가 발생되었습니다");
                                                                    }
                                                                });
                                                            },
                                                            background: "red",
                                                            textColor: "white",
                                                        },
                                                        {
                                                            title: "닫기",
                                                            action: () => {
                                                                setShowMessage(false);
                                                            },
                                                            background: "purple",
                                                            textColor: "white",
                                                        },
                                                    ];
                                                    message.current.body = null;
                                                    message.current.showFooter = true;
                                                    message.current.keyboard = true;
                                                    message.current.renderFooter = null;
                                                    setShowMessage(true);
                                                }}>
                                                삭제
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Container>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DateTimePicker
                        margin="normal"
                        id="date-picker-dialog"
                        name="popup_start_date"
                        // disableToolbar
                        okLabel="확인"
                        cancelLabel="취소"
                        style={{ display: "none" }}
                        value={openCalTag === "start_date" ? showItem.contents.popup_start_date : showItem.contents.popup_end_date}
                        onChange={(v) => {
                            if (openCalTag === "start_date") {
                                setShowItem({
                                    contents: {
                                        ...showItem.contents,
                                        popup_start_date: convertDate({ _originDate: v, _convertFormat: "YYYY-MM-DD HH:mm" }),
                                    },
                                });
                            } else {
                                setShowItem({
                                    contents: {
                                        ...showItem.contents,
                                        popup_end_date: convertDate({ _originDate: v, _convertFormat: "YYYY-MM-DD HH:mm" }),
                                    },
                                });
                            }
                        }}
                        open={openCal}
                        onClose={() => setOpenCal(false)}
                        ampm={false}
                        disabled={addMode ? false : !editMode}
                    />
                </MuiPickersUtilsProvider>
                <PhotologDialog
                    isOpen={showMessage}
                    toggle={() => setShowMessage(!showMessage)}
                    title={message.current.title}
                    message={message.current.message}
                    body={message.current.message !== null ? null : message.current.body}
                    button={message.current.button}
                    backdrop={"static"}
                    keyboard={message.current.keyboard}
                    showCenter
                    showFooter={message.current.showFooter}
                    renderFooter={message.current.renderFooter}
                />
                <Backdrop
                    className={classes.backdrop}
                    open={showBackdrop}
                    // invisible={true}
                    onClick={() => {}}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        );
    }
};

export default PopupDetail;
